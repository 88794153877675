import { BodyText, Heading, toast, Tooltip } from '@postscript/components';
import { useGetSpendNotificationSettings } from 'components/account/AccountView/hooks/useCommunicationPreferences';
import {
  USAGE_BILLING_ENABLED,
  USER_NOTIFICATION_PREFERENCES,
} from 'components/admin/utils/feature-flags';
import FormLayout from 'components/billing/common/FormLayout';
import { formatDollars } from 'components/billing/common/utils';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useUser } from 'controllers/contexts/user';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  NOTIFICATION_CHANNEL_TYPE,
  NOTIFICATION_NAME,
} from '../../../constants';
import {
  useAddUserNotificationPreference,
  useGetUserPhoneNumber,
  useRemoveUserNotificationPreference,
} from '../../../hooks/useUsers';
import { NotificationChannel } from '../../../types';
import BillingNotificationsFields, {
  BillingNotificationsFormValues,
  billingNotificationsSchema,
} from './BillingNotificationsFormFields';

const TooltipTrigger = styled.span`
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 200px;
  white-space: normal;
  font-weight: bold;
`;

const validationSchema: yup.SchemaOf<BillingNotificationsFormValues> = yup
  .object()
  .shape(billingNotificationsSchema);

interface Props {
  channels: NotificationChannel[];
  type: 'personal' | 'business';
}

export default function UserBillingNotifications({
  channels,
  type,
}: Props): JSX.Element {
  const {
    user: { guid: userUuid, shop_id: shopId },
  } = useUser();

  const { hasInitialized, hasLabsFlag } = usePSLabs();

  const notificationPreferencesEnabled =
    hasInitialized && hasLabsFlag(USER_NOTIFICATION_PREFERENCES);

  const { data: userPhoneNumber } = useGetUserPhoneNumber(userUuid, shopId, {
    enabled: notificationPreferencesEnabled && !!userUuid,
  });

  const phoneNumberAvailable = !!userPhoneNumber;

  const { mutateAsync: addUserNotificationPreference } =
    useAddUserNotificationPreference();
  const { mutateAsync: removeUserNotificationPreference } =
    useRemoveUserNotificationPreference();
  const { hasFlag }: any = useFeatureFlags();
  const { data: spendNotificationSettings } = useGetSpendNotificationSettings({
    enabled: hasFlag(USAGE_BILLING_ENABLED),
  });
  const { thresholdAmount = 0 } = spendNotificationSettings ?? {};

  const textChannelEnabled = channels.find(
    (channel) => channel.type === NOTIFICATION_CHANNEL_TYPE.TEXT,
  );

  const emailChannelEnabled = channels.find(
    (channel) => channel.type === NOTIFICATION_CHANNEL_TYPE.EMAIL,
  );
  const initialValues: BillingNotificationsFormValues = {
    ...(textChannelEnabled
      ? { spendThresholdOptedInText: textChannelEnabled.enabled }
      : {}),
    ...(emailChannelEnabled
      ? { spendThresholdOptedInEmail: emailChannelEnabled.enabled }
      : {}),
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (
        { spendThresholdOptedInEmail, spendThresholdOptedInText },
        { resetForm },
      ) => {
        if (spendThresholdOptedInText !== undefined) {
          const variables = {
            shopId,
            userUuid,
            notificationName: NOTIFICATION_NAME.BILLING_SPEND_NOTIFICATION,
            notificationChannelType: NOTIFICATION_CHANNEL_TYPE.TEXT,
          };

          if (spendThresholdOptedInText === true) {
            await addUserNotificationPreference(variables);
          }
          if (spendThresholdOptedInText === false) {
            await removeUserNotificationPreference(variables);
          }
        }

        if (spendThresholdOptedInEmail !== undefined) {
          const variables = {
            shopId,
            userUuid,
            notificationName: NOTIFICATION_NAME.BILLING_SPEND_NOTIFICATION,
            notificationChannelType: NOTIFICATION_CHANNEL_TYPE.EMAIL,
          };

          if (spendThresholdOptedInEmail === true) {
            await addUserNotificationPreference(variables);
          }
          if (spendThresholdOptedInEmail === false) {
            await removeUserNotificationPreference(variables);
          }
        }
        resetForm();
        toast.success('Preferences saved.');
      }}
      enableReinitialize
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Heading as="h4" size="xx-small">
              Spend threshold
            </Heading>
            <div>
              <BodyText size="small">
                Every time shop monthly spend, messaging and carrier fees only,
                reaches a multiple of{' '}
                <strong>
                  <TooltipTrigger data-for="spend-threshold-tip" data-tip>
                    {formatDollars(thresholdAmount / 100)}
                  </TooltipTrigger>
                </strong>
                .
              </BodyText>
              <StyledTooltip id="spend-threshold-tip" place="top" size="small">
                You can change this amount in Shop Settings.
              </StyledTooltip>
            </div>
            <BillingNotificationsFields
              phoneNumberAvailable={phoneNumberAvailable}
              type={type}
            />
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}
