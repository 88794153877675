import cookie from 'react-cookies';
import { LegacyRoles, Permissions } from './types';

export function parseJwt(token: string): Record<string, any> {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const payload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(
        (character) =>
          `%${`00${character.charCodeAt(0).toString(16)}`.slice(-2)}`,
      )
      .join(''),
  );

  return JSON.parse(payload);
}

export function getAccessTokenPayload(): Record<string, any> | undefined {
  const jwt = cookie.load('access_token');

  if (!jwt) return;

  const payload = parseJwt(jwt);

  return payload;
}

export function getShopId(): number {
  const accessTokenPayload = getAccessTokenPayload();
  return accessTokenPayload?.shop_id;
}

export function getUserPermissions(): Permissions[] | undefined {
  const accessTokenPayload = getAccessTokenPayload();
  return accessTokenPayload?.identity?.permissions;
}

export function userHasPermission(permission: Permissions): boolean {
  const permissions = getUserPermissions();
  return permissions ? permissions.includes(permission) : false;
}

export function getLegacyUserRoles(): LegacyRoles[] {
  const accessTokenPayload = getAccessTokenPayload();
  return accessTokenPayload?.identity?.roles;
}

export function userHasLegacyRole(role: LegacyRoles): boolean {
  const roles = getLegacyUserRoles();
  return roles.includes(role);
}
