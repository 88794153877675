import {
  Button,
  ButtonAnchor,
  Layout,
  LinkText,
  ModalBody,
  ModalHeader,
} from '@postscript/components';
import { PAYMENT_PROVIDERS } from 'components/billing/common/constants';
import { convertSingleDigitToWord } from 'components/billing/common/prompts/BillingBannersHelperFunctions';
import { formatDollars } from 'components/billing/common/utils';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import PaymentMethodDetails from '../PaymentMethodDetails';
import { StyledPaymentMethod } from '../PaymentMethodManagement';
import {
  StyledAmountDue,
  StyledBoldFont,
  StyledContinueBtnContainer,
  StyledDivWithBottomMargin,
  StyledPayNowModal,
  StyledRetryBtn,
  StyledRetryButtons,
  StyledTable,
  StyledTableContainer,
  StyledTotalContainer,
} from './styles';
import { PayNowModalProps } from './types';

export const PayNowModal = ({
  approveOnShopifyProcessing,
  closeModal,
  handleApproveOnShopify,
  handlePayInvoices,
  hasActivePaymentMethod,
  modalOpen,
  numberOfFailedInvoices,
  paymentProcessingResult,
  paymentProvider,
  paymentsProcessing,
  recurringApplicationChargeActive,
  retryPayment,
  switchToAddPaymentMethodsModal,
  totalAmount,
  tableRows,
  unpaidInvoicesLoading,
}: PayNowModalProps) => {
  const {
    paymentMethods,
    unverifiedPaymentMethods,
    defaultPaymentMethodId,
    isLoading: arePaymentMethodsLoading,
  } = usePaymentMethods();

  const { paymentProcessingFinished, totalInvoices, paidInvoices } =
    paymentProcessingResult;

  if (unpaidInvoicesLoading)
    return (
      <Layout align="center">
        <LoadingSpinner />
      </Layout>
    );

  return (
    <>
      {paymentProvider &&
        paymentProvider === PAYMENT_PROVIDERS.SHOPIFY &&
        !recurringApplicationChargeActive && (
          <StyledPayNowModal onCancel={closeModal} isOpen={modalOpen}>
            <>
              <ModalHeader onCancel={closeModal}>
                Complete billing setup
              </ModalHeader>
              <ModalBody>
                Postscript needs your permission to bill you via Shopify.
              </ModalBody>
              <StyledContinueBtnContainer>
                <Button
                  size="medium"
                  variant="primary"
                  onClick={handleApproveOnShopify}
                  disabled={approveOnShopifyProcessing}
                >
                  Approve on Shopify
                </Button>
              </StyledContinueBtnContainer>
            </>
          </StyledPayNowModal>
        )}
      {paymentProvider &&
        (paymentProvider === PAYMENT_PROVIDERS.STRIPE ||
          (paymentProvider === PAYMENT_PROVIDERS.SHOPIFY &&
            recurringApplicationChargeActive)) && (
          <StyledPayNowModal
            data-testid="pay-now-stripe-modal"
            onCancel={closeModal}
            isOpen={modalOpen}
            dismissOnBackdropClick={!paymentsProcessing}
          >
            <ModalHeader
              onCancel={paymentsProcessing ? () => null : () => closeModal()}
            >
              {paymentProcessingFinished
                ? 'Overdue invoices paid'
                : 'Pay invoices'}
            </ModalHeader>
            <ModalBody>
              {!paymentProcessingFinished && hasActivePaymentMethod && (
                <>
                  <StyledDivWithBottomMargin>
                    {paymentProvider === PAYMENT_PROVIDERS.STRIPE && (
                      <span>Your default payment method will be charged </span>
                    )}
                    {paymentProvider === PAYMENT_PROVIDERS.SHOPIFY && (
                      <span>Your Shopify account will be charged </span>
                    )}
                    <StyledBoldFont>
                      {formatDollars(totalAmount / 100)}
                    </StyledBoldFont>
                    <span>.</span>
                  </StyledDivWithBottomMargin>
                  {arePaymentMethodsLoading && (
                    <LoadingSpinner>Loading payment methods</LoadingSpinner>
                  )}
                  {paymentMethods.length > 0 && !arePaymentMethodsLoading && (
                    <Layout align="left" vertical gap="var(--spacing-3)">
                      {[...paymentMethods, ...unverifiedPaymentMethods].map(
                        ({ id, card, usBankAccount, setupIntentStatus }) => {
                          if (
                            (card || usBankAccount) &&
                            id === defaultPaymentMethodId
                          )
                            return (
                              <StyledPaymentMethod
                                key={`payment_method_${id}`}
                                data-cy={`payment_method_${id}`}
                              >
                                <PaymentMethodDetails
                                  card={card ?? null}
                                  usBankAccount={usBankAccount ?? null}
                                  setupIntentStatus={setupIntentStatus}
                                  isSummary={false}
                                />
                              </StyledPaymentMethod>
                            );
                        },
                      )}
                      <ButtonAnchor
                        size="small"
                        disabled={paymentsProcessing}
                        variant="secondary"
                        onClick={() => switchToAddPaymentMethodsModal()}
                      >
                        Change Payment Method
                      </ButtonAnchor>
                    </Layout>
                  )}
                </>
              )}
              {!paymentProcessingFinished && !hasActivePaymentMethod && (
                <div>
                  <span>You have </span>
                  <StyledBoldFont>
                    {convertSingleDigitToWord(numberOfFailedInvoices)} overdue
                    invoices{' '}
                  </StyledBoldFont>
                  <span>totaling </span>
                  <StyledBoldFont>
                    {formatDollars(totalAmount / 100)}
                  </StyledBoldFont>
                  <span>.</span>
                </div>
              )}
              {paymentProcessingFinished && !retryPayment && (
                <span>
                  Thanks! {paidInvoices} of {totalInvoices} invoices paid.
                </span>
              )}
              {paymentProcessingFinished && retryPayment && (
                <span>
                  {paidInvoices} of {totalInvoices} invoices paid. You still
                  have at least one overdue invoice. Check your payment method
                  and try again. If you’re still having trouble,{' '}
                  <LinkText
                    arrowSize={undefined}
                    href="https://postscript.io/contact"
                    underline
                  >
                    contact us
                  </LinkText>
                  .
                </span>
              )}
              <StyledTableContainer>
                <StyledTable
                  id="pay-now-stripe-modal-table"
                  size="small"
                  cardWrapper={false}
                  globalColumnSettings={{
                    name: { heading: 'ID' },
                    dataColumns: [
                      {
                        heading: 'Service period',
                        minWidth: 200,
                        align: 'left',
                      },
                      {
                        heading: 'Status',
                        align: 'left',
                      },
                      {
                        heading: 'Amount',
                        align: 'right',
                      },
                    ],
                  }}
                  items={tableRows}
                />
                <StyledTotalContainer>
                  <StyledAmountDue>Amount due</StyledAmountDue>
                  <StyledBoldFont>
                    {formatDollars(totalAmount / 100)}
                  </StyledBoldFont>
                </StyledTotalContainer>
              </StyledTableContainer>
              <StyledContinueBtnContainer>
                {paymentProcessingFinished && !retryPayment && (
                  <Button size="medium" variant="primary" onClick={closeModal}>
                    Close
                  </Button>
                )}
                {!paymentProcessingFinished && !retryPayment && (
                  <Button
                    size="medium"
                    variant="primary"
                    onClick={() =>
                      hasActivePaymentMethod
                        ? handlePayInvoices()
                        : switchToAddPaymentMethodsModal()
                    }
                    disabled={arePaymentMethodsLoading || paymentsProcessing}
                  >
                    {hasActivePaymentMethod &&
                      paymentsProcessing &&
                      'Processing...'}
                    {hasActivePaymentMethod && !paymentsProcessing && 'Pay Now'}
                    {!hasActivePaymentMethod && 'Continue to Payment'}
                  </Button>
                )}
              </StyledContinueBtnContainer>
              {paymentProcessingFinished && retryPayment && (
                <StyledRetryButtons>
                  <Button
                    size="small"
                    disabled={paymentsProcessing}
                    variant="text"
                    onClick={() => switchToAddPaymentMethodsModal()}
                  >
                    Change Payment Method
                  </Button>
                  <StyledRetryBtn
                    size="medium"
                    variant="primary"
                    onClick={() =>
                      hasActivePaymentMethod
                        ? handlePayInvoices()
                        : switchToAddPaymentMethodsModal()
                    }
                    disabled={arePaymentMethodsLoading || paymentsProcessing}
                  >
                    {paymentsProcessing ? 'Processing...' : 'Retry Payment'}
                  </StyledRetryBtn>
                </StyledRetryButtons>
              )}
            </ModalBody>
          </StyledPayNowModal>
        )}
    </>
  );
};
