/**
 * Generic function for sorting a list of objects. Returns a new sorted version of the list
 *
 * @param {Array} list - input list to be sorted
 * @param {Object} settings - settings for list sort
 *
 * @returns new sorted list
 *
 * @example
 * const theList = [{fruit: 'Apple', fruit: 'Orange', fruit: 'Banana'}];
 * const sortObjects(theList, {sortBy: 'fruit', sortDirection: 'asc'})
 */
const sortObjects = (list, { sortBy, sortDirection }) => {
  return [...list].sort((a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }

    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }

    return 0;
  });
};

/**
 * Generic function for doing sanity checks by base64 decoding a string and falling back to
 * the original string value if unable to properly decode.
 *
 * @param {String} text - the text to attempt to decode
 *
 * @returns the original or decoded string
 *
 * @example
 * const base64Encoded = 'dGVzdA==';
 * const regularUtf = 'test';
 * assert safeBase64Decode(base64Encoded) === 'test' // true
 * assert safeBase64Decode(regularUtf) === 'test' // also true
 */
const safeBase64Decode = (text) => {
  // should be a valid, non-empty string
  const base64Regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  if (
    text === '' ||
    text.trim() === '' ||
    text.slice(-1) !== '=' ||
    !base64Regex.test(text)
  ) {
    return text;
  }
  try {
    const decoded = atob(text);
    if (btoa(decoded) === text) {
      return decoded;
    }
    return text;
  } catch (err) {
    return text;
  }
};

export default {
  safeBase64Decode,
  sortObjects,
};
