import { uniqBy } from 'lodash';
import GlobalBannerListItem from './GlobalBannerListItem';
import { useBanners } from './globalBanners';

const BANNERS_LIMIT = 4;

const GlobalBannerList = (): JSX.Element | null => {
  const { banners } = useBanners();

  if (banners.length < 1) return null;

  return (
    <>
      {uniqBy(banners, 'id')
        .slice(0, BANNERS_LIMIT)
        .map((banner) => (
          <GlobalBannerListItem key={`banner-${banner.id}`} {...banner} />
        ))}
    </>
  );
};

export default GlobalBannerList;
