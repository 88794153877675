import { BodyText, Heading, Icon, IconSet } from '@postscript/components';
import PageHeader from 'components/layout/PageHeader';
import OmniDashboard from 'components/omni/OmniDashboard';
import { api } from 'controllers/network/apiClient';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MediaQueries from 'utils/mediaQueries';

/* COMPONENTS */
const StyledZeroState = styled.div`
  padding: var(--spacing-6) 0;
`;

const StyledZeroStateText = styled.div`
  max-width: 600px;

  h2 {
    padding-bottom: var(--spacing-1);
  }
`;

const StyledZeroStateCards = styled.div`
  display: flex;
  gap: var(--spacing-4);
  padding: var(--spacing-8) 0 0;

  ${MediaQueries.phoneOnly} {
    flex-direction: column;
  }
`;

const StyledZeroStateCard = styled.article`
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  flex: 1 1;
`;

const SkeletonDashboard = styled.div`
  display flex;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-2);
`;

const SkeletonDashboardElement = styled.div`
  flex: 1 0 auto;
  border-radius: var(--border-radius-medium);
  height: 144px;
  padding: var(--spacing-4) var(--spacing-5);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;
`;

const Performance = () => {
  /* ZERO STATE */
  const [performanceData, setPerformanceData] = useState<string | null>(null);

  useEffect(() => {
    const fetchPerformanceDataStatus = async () => {
      const route = '/v2/convert/shop-omni-dashboard-status';
      const response = await api.get(route);
      setPerformanceData(response.status); // 'no-data' or 'data-present'
    };
    fetchPerformanceDataStatus();
  }, []);

  const getPerformanceDashboard = () => {
    return performanceData === 'no-data' ? (
      <StyledZeroState>
        <StyledZeroStateText>
          <Heading as="h2" size="small">
            Your SMS sales performance will be displayed here
          </Heading>
          <BodyText>
            Once your SMS Sales activity begins, this dashboard will show key
            metrics like revenue, orders, and commissions, along with trends
            over time. Check back soon to track your progress.
          </BodyText>
        </StyledZeroStateText>
        <StyledZeroStateCards>
          <StyledZeroStateCard>
            <Icon
              color="var(--color-topic-primary)"
              component={IconSet.Dollar}
              size={48}
            />
            <Heading size="x-small">Monitor sales impact</Heading>
            <BodyText size="small">
              Get a clear view of how your SMS campaigns are driving revenue,
              orders, and commissions in real-time.
            </BodyText>
          </StyledZeroStateCard>
          <StyledZeroStateCard>
            <Icon
              color="var(--color-topic-primary)"
              component={IconSet.TrendingUp}
              size={48}
            />
            <Heading size="x-small">Identify sales trends</Heading>
            <BodyText size="small">
              Track performance patterns over time to uncover growth
              opportunities and fine-tune your sales strategies.
            </BodyText>
          </StyledZeroStateCard>
          <StyledZeroStateCard>
            <Icon
              color="var(--color-topic-primary)"
              component={IconSet.CarDashboard}
              size={48}
            />
            <Heading size="x-small">Access detailed reports</Heading>
            <BodyText size="small">
              Daily breakdowns of attributed sales, orders, and commissions to
              better understand your campaigns.
            </BodyText>
          </StyledZeroStateCard>
        </StyledZeroStateCards>
      </StyledZeroState>
    ) : (
      <OmniDashboard id="75a878d1" />
    );
  };

  return (
    <>
      <PageHeader pageTitle="Performance" />

      {performanceData ? (
        getPerformanceDashboard()
      ) : (
        <>
          <SkeletonDashboard>
            <SkeletonDashboardElement />
          </SkeletonDashboard>
          <SkeletonDashboard>
            <SkeletonDashboardElement />
            <SkeletonDashboardElement />
            <SkeletonDashboardElement />
          </SkeletonDashboard>
          <SkeletonDashboard>
            <SkeletonDashboardElement />
          </SkeletonDashboard>
        </>
      )}
    </>
  );
};

export default Performance;
