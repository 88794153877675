/* eslint-disable func-names */
/* eslint-disable default-case */
import { toast } from '@postscript/components';
import Events from './events';
import Requests from './network/network_requests';

const Todos = {
  complete(todo) {
    let id = todo;
    switch (todo) {
      case 'popup':
        id = 9;
        break;
      case 'email':
        id = 10;
        break;
      case 'ac':
        id = 11;
        break;
    }
    Requests.post('/todos/complete', { todo_id: id }).then(function (result) {
      if (result && result.error) {
        toast.error(result.error);
      } else {
        Events.track(`Todo ${id} Completed`);
      }
    });
  },
};

export default Todos;
