/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable  @typescript-eslint/no-empty-function */
import { captureException } from '@sentry/browser';
import { CONVERT } from 'components/admin/utils/feature-flags';
import { logMaxRetriesAttempted } from 'components/sales/utils/eventUtils';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import { DateTime } from 'luxon';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { WEBSOCKET_HOST } from 'utils/envVars';
import { getIncrementalBackoff } from '../utils/getIncrementalBackoff';

export type WebSocketMessageType =
  | 'ping'
  | 'pong'
  | 'user_joined'
  | 'user_left'
  | 'broadcast_to_channel'
  | 'brodcast_to_shop'
  | 'message_sent'
  | 'new_inbound_message'
  | 'unresolved_subscriber_chat'
  | 'resolved_subscriber_chat'
  | 'assignments_updated'
  | 'new_outbound_message'
  | 'subscriber_event_stream_updated';

type WebSocketMessageFromUser = {
  username: string;
  first_name?: string;
  last_name?: string;
  avatar_url?: string;
};

type WebSocketMessage = {
  type?: WebSocketMessageType;
  channel?: string;
  message?: any;
  from_user?: WebSocketMessageFromUser;
  timestamp?: string;
  subscriber_id?: number;
  data?: Record<string, any>;
};

export interface WebSocketOutgoingMessage {
  routeKey: string;
  [key: string]: any;
}

export interface UseWebSocket {
  isConnected: boolean;
  isMaxRetriesAttempted: boolean;
  messages: WebSocketMessage[];
  lastMessage: WebSocketMessage | null;
  send: (message: Record<string, any>) => void;
}

export const WebSocketContext = createContext<UseWebSocket>({
  messages: [],
  lastMessage: null,
  isConnected: false,
  isMaxRetriesAttempted: false,
  send: () => {
    throw new Error('Not implemented');
  },
});

// Recursively parse JSON strings into objects
export const deepJsonParse = (json: string): Record<string, any> => {
  return JSON.parse(json, (key, value) => {
    if (typeof value === 'string') {
      try {
        return deepJsonParse(value);
      } catch {
        return value;
      }
    }
    return value;
  });
};

const getTicket = (): Promise<{ ticket: string }> =>
  api.post('/v2/websockets/ticket');

export const WEBSOCKET_TICKET_QUERY_KEY = 'websocket-ticket';

const MAX_RETRY_COUNT = 5;

export const WebSocketProvider: React.FC<{ baseUrl?: string }> = ({
  children,
  baseUrl = WEBSOCKET_HOST,
}) => {
  const {
    user: { id: user_id },
  } = useUser();
  const socket = useRef<WebSocket | null>(null);
  const [lastMessage, setLastMessage] = useState<WebSocketMessage | null>(null);
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isMaxRetriesAttempted, setIsMaxRetriesAttempted] = useState(false);
  const { hasLabsFlag, hasInitialized } = usePSLabs();
  /** Positive integar with a max of MAX_RETRY_COUNT */
  const retryCount = useRef(1);

  const {
    data: ticket,
    refetch: refetchTicket,
    isFetching: isFetchingTicket,
  } = useQuery({
    queryKey: [WEBSOCKET_TICKET_QUERY_KEY],
    queryFn: getTicket,
    select: (data) => data?.ticket,
    refetchOnWindowFocus: !socket.current,
  });

  const send = (message: Record<string, any>, retry = 1) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      const timestamp = DateTime.utc().toISO();
      retryCount.current = 1;
      socket.current?.send(
        JSON.stringify({ ...message, created_at: timestamp }),
      );
      setIsMaxRetriesAttempted(false);
    } else if (retry <= 3) {
      setTimeout(() => {
        send(message, retry + 1);
      }, getIncrementalBackoff(retry) /* exponential backoff */);
    }
  };

  useEffect(() => {
    const hasPSLabFeature = hasInitialized && hasLabsFlag(CONVERT);
    if (!ticket || !baseUrl || isFetchingTicket || !hasPSLabFeature) {
      return;
    }

    const search = new URLSearchParams();
    search.set('ticket', ticket);
    // instantiate a new websocket connection with the ticket
    socket.current = new WebSocket(`${baseUrl}?${search.toString()}`);

    socket.current.onmessage = (message) => {
      let parsedMessage: WebSocketMessage;
      // If the message is not JSON, ignore it.
      try {
        parsedMessage = deepJsonParse(message.data);
      } catch {
        return;
      }

      // If message is a ping, respond with a pong. Otherwise add it to the list of messages.
      if (parsedMessage.type === 'ping') {
        send({ timestamp: parsedMessage.timestamp, routeKey: 'pong' });
        return;
      }

      if (parsedMessage.type === 'pong') {
        return;
      }

      setLastMessage(parsedMessage);
      setMessages((prevMessages) => [...prevMessages, parsedMessage]);
    };

    socket.current.onopen = () => {
      setIsConnected(true);
      retryCount.current = 1;
    };

    socket.current.onclose = (e) => {
      setIsConnected(false);
      setTimeout(() => {
        const isMaxRetryCount = retryCount.current === MAX_RETRY_COUNT;
        if (isMaxRetryCount) {
          captureException(
            new Error('Max websocket connection retry count reached'),
            {
              extra: {
                ticket,
                user_id,
                code: e.code,
                was_clean: e.wasClean,
                reason: e.reason,
              },
            },
          );
          socket.current = null;
          logMaxRetriesAttempted(user_id);
          setIsMaxRetriesAttempted(isMaxRetryCount);
          return;
        }
        retryCount.current += 1;
        refetchTicket();
      }, getIncrementalBackoff(retryCount.current));
    };

    socket.current.onerror = () => {
      socket.current?.close();
    };

    return () => {
      socket.current?.close();
      socket.current = null;
    };
  }, [baseUrl, ticket, isFetchingTicket, hasInitialized]);

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        isMaxRetriesAttempted,
        messages,
        lastMessage,
        send,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = (): UseWebSocket => {
  return useContext(WebSocketContext);
};

export default useWebSocket;
