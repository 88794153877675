import React, { createContext, useContext, useState } from 'react';

type State = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const initialState: State = [false, () => undefined];

const FullScreenEditorContext = createContext(initialState);

export const useFullScreenEditor = (): State =>
  useContext(FullScreenEditorContext);

interface FullScreenEditorProviderProps {
  children: React.ReactNode;
}

export const FullScreenEditorProvider = ({
  children,
}: FullScreenEditorProviderProps): JSX.Element => {
  const value = useState<boolean>(false);

  return (
    <FullScreenEditorContext.Provider value={value}>
      {children}
    </FullScreenEditorContext.Provider>
  );
};
