/* eslint-disable no-alert */
import produce from 'immer';
import { isEmpty, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { camelCaseKeys, snakeCaseKeys } from '../../utils/formatters';
import { api } from '../network/apiClient';

const LOADING = 'LOADING';
const STATUS_LOADING = 'STATUS_LOADING';
const SET_ACTIONS = 'SET_ACTIONS';
const SET_ORDERS = 'SET_ORDERS';

const ORDER_TYPE = 'orders';

const defaultInitialState = {
  loading: null,
  statusLoading: true,
  actionMessage: '',
  actionUrl: null,
  orders: [],
  carts: [],
};

export const PsPayContext = createContext(defaultInitialState);
export const usePsPay = () => useContext(PsPayContext);

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case STATUS_LOADING:
      draft.statusLoading = action.statusLoading;
      break;
    case SET_ACTIONS:
      draft.actionMessage = action.actionMessage;
      draft.actionUrl = action.actionUrl;
      break;
    case SET_ORDERS:
      draft[ORDER_TYPE] = action.data;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const PsPayProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setStatusLoading = (statusLoading) => {
    dispatch({
      type: STATUS_LOADING,
      statusLoading,
    });
  };

  const setActions = (actionMessage, actionUrl) => {
    dispatch({
      type: SET_ACTIONS,
      actionMessage,
      actionUrl,
    });
  };

  const setOrders = (data) => {
    dispatch({
      type: SET_ORDERS,
      data,
    });
  };

  const getAccountLink = async () => {
    return api.get('/v2/pspay/shop/status');
  };

  const getActions = async () => {
    try {
      const { action_message: actionMessage, action_url: actionUrl } =
        await api.get('/v2/pspay/shop/status');

      setActions(actionMessage, actionUrl);

      setStatusLoading(false);

      return {
        actionMessage,
        actionUrl,
      };
    } catch {
      setStatusLoading(false);
    }
  };

  const refundOrder = async (orderNumber) => {
    if (
      window.confirm(`Are you sure you'd like to refund Order #${orderNumber}`)
    ) {
      window.alert(
        `Currently refunds aren't supported. Please manually create refunds through Shopify`,
      );
    }
  };

  const getBranding = async () => {
    const branding = await api.get('/v2/pspay/branding');

    return camelCaseKeys(branding);
  };

  const updateBranding = async (values) => {
    const branding = await api.put('/v2/pspay/branding', snakeCaseKeys(values));

    return camelCaseKeys(branding);
  };

  const fetchOrder = async (id) => {
    const order = (state.orders || []).find((order) => order.id === id);

    if (!isEmpty(order)) return order;

    return api.get(`/v2/pspay/orders/${id}`);
  };

  const searchOrders = async ({
    shopifyIdSearch,
    start,
    end,
    status,
    limit = 35,
    page = 1,
  } = {}) => {
    setLoading(true);

    const paramArguments = omitBy(
      {
        shopify_id__in: shopifyIdSearch,
        created_at__gt: start,
        created_at__lt: end,
        lifecycle__in: status,
        limit,
        page,
      },
      (val) => isEmpty(val) && !Number.isInteger(val),
    );

    const params = stringify(paramArguments);

    const data = await api.get(`/v2/pspay/orders?${params}`);

    const { orders = [] } = data;

    if (['/admin/pspay/orders'].some((urlPath) => pathname.includes(urlPath))) {
      push({
        search: `?${params}`,
      });
    }

    setOrders(
      orders.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    );
    setLoading(false);

    return data;
  };

  useEffect(() => {
    getActions();
  }, []);

  return (
    <PsPayContext.Provider
      value={{
        ...state,
        getAccountLink,
        getActions,
        refundOrder,
        getBranding,
        updateBranding,
        fetchOrder,
        searchOrders,
      }}
    >
      {children}
    </PsPayContext.Provider>
  );
};

PsPayProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

PsPayProvider.defaultProps = {
  initialState: defaultInitialState,
};
