export const PRODUCT_COUNT_FOR_LOW_STOCK = 5;

export const REFRESH_POLL_INTERVAL_FOR_NEW_RESPONSE = 30_000;

export const RESPONSES_QUEUE_LIMIT = 50;

export const DEFAULT_PERSONA = 'Charlotte';

export const SUBSCRIBER_CHAT_CHANNEL_PREFIX = 'subscriber-chat';

export const DEFAULT_MESSAGE_TEXT = '{shop_name}: ';
// 90px is the height of the header and footer
export const MAX_HEIGHT = 'calc(100vh - 90px)';

export const BASE_URL = '/workspace';

export const CHAT_QUERY_KEYS = {
  PROSPECTS: 'prospects',
  RESOLVED_PROSPECTS: 'resolvedProspects',
  ALL_PROSPECTS: 'allProspects',
};

export const SALES_SHOPS_DATA_QUERY_KEY = 'salesShopsData';

export const SYSTEM_EVENT_SALES_SUBSTRING = 'convert_event';
export const SYSTEM_EVENT_SALES_AI_SUBSTRING = 'convert_event_sales_ai';
export const SYSTEM_EVENT_SALES_AI_CLASSIFICATION =
  'convert_event_sales_ai/classification';
export const SYSTEM_EVENT_SALES_AI_FETCH = 'convert_event_sales_ai/fetch';
export const SUBSCRIBER_EVENT_SALES_LINK_CLICK =
  'convert_event_subscriber/link_click';

export const TIMER_STATE_KEY = 'chatTimerState';
export const SUBSCRIBER_EVENTS = [SUBSCRIBER_EVENT_SALES_LINK_CLICK];

export const VIEW_OPTIONS = {
  MERCHANT: 'Merchant View',
  SALES_ASSOCIATE: 'Sales Associate View',
  AI_DEBUGGER: 'Bub AI Debugger View',
};
