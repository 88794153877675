/* eslint-disable no-alert */
/* eslint-disable react/no-unescaped-entities */

import {
  BodyText,
  Button,
  Card,
  Checkbox,
  Heading,
  Input,
  Switch,
  toast,
  Tooltip,
} from '@postscript/components';
import PageHeader from 'components/layout/PageHeader';
import { useUser } from 'controllers/contexts/user';
import Events from 'controllers/events';
import { api } from 'controllers/network/apiClient';
import { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { logEvent } from 'utils/events';
import GorgiasLogo from '../../img/gorgias_logo.png';
import { StyledLogoImg } from './StyledLogoImg';

interface GorgiasIntegrationV2Props {
  goBack: () => any;
}

const GorgiasIntegrationV2 = ({
  goBack,
}: GorgiasIntegrationV2Props): JSX.Element => {
  const {
    user: { data: userData },
    updateUserInfo,
  } = useUser();

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<string>('');
  const [url, setUrl] = useState<string>(userData.gorgias_url || '');
  const [username, setUsername] = useState<string>(
    userData.gorgias_username || '',
  );
  const [autoCreateTickets, setAutoCreateTickets] = useState<boolean>(
    !!userData.gorgias_auto_create_tickets,
  );
  const [isEnabled, setIsEnabled] = useState<boolean>(
    !!userData.gorgias_active,
  );
  const [hasOAuthSetup, setHasOAuthSetup] = useState<boolean>(false);
  const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);

  const { search } = useLocation();
  const { push } = useHistory();

  const updateUserData = (update: any) => {
    updateUserInfo({
      data: { ...userData, ...update },
    });
  };

  const handleOAuthRedirect = async (account: string) => {
    try {
      const { url } = await api.post('/v2/integrations/gorgias/oauth/init/', {
        account,
      });

      const newWindow = window.open(url);

      // Present UI to redirect to OAuth if popup blocked
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        setIsLoading(false);
        setShowRedirectModal(true);
      }
    } catch (error) {
      if (!account) {
        setError('Account is required');
      } else {
        toast.error('Failed to redirect to Gorgias OAuth');
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const account = new URLSearchParams(search).get('account');

      if (account) {
        setAccount(account);

        return handleOAuthRedirect(account);
      }

      const { integrations } = await api.get('/integrations');

      const gorgiasIntegration = integrations?.find(
        ({ type }: any) => type === 'gorgias',
      );

      setAccount(gorgiasIntegration?.data.account);
      setHasOAuthSetup(gorgiasIntegration?.data.refresh_token);
      // set form values via integration not user data
      // refactor / remove references to user

      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <p>Loading...</p>;

  if (showRedirectModal)
    return (
      <Card>
        <Heading size="small">Authenticate</Heading>
        <BodyText
          style={{
            marginTop: 'var(--spacing-2)',
            marginBottom: 'var(--spacing-2)',
          }}
        >
          Please click the button below to continue the setup process.
        </BodyText>
        <Button onClick={() => handleOAuthRedirect(account)}>
          Redirect to Gorgias OAuth
        </Button>
      </Card>
    );

  const updateAccountDetails = async () => {
    const update = {
      gorgias_auto_create_tickets: autoCreateTickets,
      gorgias_url: url,
      gorgias_username: username,
    };

    await api.post('/add_gorgias_credentials', update);

    updateUserData(update);
  };

  const toggleIntegration = async (isEnabled: boolean) => {
    setIsEnabled(isEnabled);

    const update = {
      gorgias_active: isEnabled,
    };

    await api.post('/add_gorgias_credentials', update);

    updateUserData(update);
  };

  const deleteIntegration = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this integration?',
    );

    if (!confirmed) return;

    Events.track('Delete Gorgias Integration Clicked');
    logEvent('integration updated', {
      name: 'Gorgias',
      action: 'delete',
    });

    const update = {
      gorgias_active: null,
      gorgias_url: null,
      gorgias_username: null,
      gorgias_api_key: null,
      gorgias_auto_create_tickets: null,
    };

    const result = await api.post('/add_gorgias_credentials', update);

    updateUserData(update);

    if (result && result.error) {
      Events.track('Save Gorgias Integration Error');

      return toast.error(result.error);
    }

    Events.track('Save Gorgias Integration Success');

    return goBack ? goBack() : push('/integrations');
  };

  return (
    <>
      <PageHeader
        breadCrumb={{
          onClick: () => (goBack ? goBack() : push('/integrations')),
          text: 'Apps',
        }}
        actions={
          <>
            <Switch
              checked={isEnabled}
              disabled={!hasOAuthSetup}
              fieldSize="medium"
              id="default-switch"
              label={isEnabled ? 'Active' : 'Inactive'}
              labelPosition="left"
              onChange={(e) => toggleIntegration(e.target.checked)}
            />
            <Button
              style={{ marginLeft: 'var(--spacing-2)' }}
              disabled={!hasOAuthSetup}
              variant="delete"
              onClick={deleteIntegration}
              size="medium"
            >
              Delete
            </Button>
          </>
        }
        pageTitle={
          <>
            <StyledLogoImg
              alt="Gorgias logo"
              frame="square"
              src={GorgiasLogo}
            />
            Gorgias
          </>
        }
      />
      <div
        style={{
          display: 'grid',
          gap: 12,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        <Card>
          <Heading size="small">Account Details</Heading>
          <a
            style={{ display: 'block', marginTop: 'var(--spacing-1)' }}
            target="_blank"
            href=""
          >
            Gorgias URL
          </a>
          <a
            style={{
              display: 'block',
              marginBottom: 'var(--spacing-3)',
              marginTop: 'var(--spacing-1)',
            }}
            target="_blank"
            href=""
          >
            Gorgias Integration FAQ
          </a>
          <BodyText id="gorgias-account">
            <>
              Gorgias URL
              <FontAwesome
                style={{ marginLeft: 'var(--spacing-1)' }}
                aria-describedby="gorgias-url"
                data-event={null}
                name="info-circle"
                id="gorgias-url"
                data-for="gorgias-url"
                data-tip
              />
              <Tooltip place="top" id="gorgias-url" size="medium">
                <div style={{ maxWidth: '300px' }}>
                  {'In your Gorgias account:' +
                    '\n1. Click "Settings"' +
                    '\n2. Click "REST API"' +
                    '\n3. Copy the "Base API URL"'}
                </div>
              </Tooltip>
            </>
          </BodyText>
          <Input
            fieldSize="medium"
            fieldWidth="full"
            labelledBy="gorgias-account"
            required
            type="text"
            onChange={({ target: { value } }) => setUrl(value)}
            value={url}
          />
          <BodyText id="gorgias-username">
            <>
              Gorgias Username
              <FontAwesome
                style={{ marginLeft: 'var(--spacing-1)' }}
                aria-describedby="username-tooltip"
                data-event={null}
                name="info-circle"
                id="username-tooltip"
                data-for="username-tooltip"
                data-tip
              />
              <Tooltip place="top" id="username-tooltip" size="medium">
                <div style={{ maxWidth: '300px' }}>
                  {'In your Gorgias account:' +
                    '\n1. Click "Settings"' +
                    '\n2. Click "REST API"' +
                    '\n3. Copy the "Username"'}
                </div>
              </Tooltip>
            </>
          </BodyText>
          <Input
            fieldSize="medium"
            fieldWidth="full"
            labelledBy="gorgias-username"
            required
            type="text"
            onChange={({ target: { value } }) => setUsername(value)}
            value={username}
          />
          <Checkbox
            checked={autoCreateTickets}
            id="auto-create-tickets-checkbox"
            label={
              <>
                Auto-Create Tickets
                <FontAwesome
                  style={{ marginLeft: 'var(--spacing-1)' }}
                  aria-describedby="auto-create-tickets-tooltip"
                  data-event={null}
                  name="info-circle"
                  data-for="auto-create-tickets-tooltip"
                  data-tip
                />
                <Tooltip
                  place="top"
                  id="auto-create-tickets-tooltip"
                  size="medium"
                >
                  <div style={{ maxWidth: '300px' }}>
                    Checking this box will automatically create tickets in
                    gorgias for every SMS response from your customers. Not
                    checking this box will allow you to create tickets as needed
                    in the "Responses" section
                  </div>
                </Tooltip>
              </>
            }
            onChange={(e) => setAutoCreateTickets(e.target.checked)}
          />
          <Button
            style={{ marginLeft: 'auto', marginTop: 'var(--spacing-2)' }}
            onClick={updateAccountDetails}
          >
            Update
          </Button>
        </Card>
        <Card>
          <Heading size="small">Authentication</Heading>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Input
              fieldSize="medium"
              fieldWidth="full"
              id="my-text-field"
              label="Account"
              required
              type="text"
              onChange={({ target: { value } }) => setAccount(value)}
              value={account}
            />
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => handleOAuthRedirect(account)}
            >
              OAuth
            </Button>
          </div>
          {error && (
            <BodyText size="x-small" style={{ color: 'var(--red-core)' }}>
              {error}
            </BodyText>
          )}
        </Card>
      </div>
    </>
  );
};

export default GorgiasIntegrationV2;
