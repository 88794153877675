import React from 'react';
import { MessageList } from './MessageList';

export interface MessageThreadProps {
  subscriber: any;
  isChatLoading?: boolean;
  messageThreadRef: React.RefObject<HTMLUListElement>;
}

const MessageThread = ({
  subscriber,
  isChatLoading,
  messageThreadRef,
}: MessageThreadProps): JSX.Element => {
  if (isChatLoading) {
    // For Responses, return empty div
    // to fill out the parent grid area
    return <div />;
  }
  return (
    <MessageList messageThreadRef={messageThreadRef} subscriber={subscriber} />
  );
};

export default React.memo(MessageThread);
