/* eslint-disable camelcase */
import { api } from 'controllers/network/apiClient';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { AgentInfo, SessionInfo, ShopInfo } from '../types';

export interface ActiveAgent {
  [agent_id: number]: AgentInfo;
}

export interface AgentDashboardResponse {
  active_agents: ActiveAgent;
  shop_names: ShopInfo;
}

export interface SessionDashboardResponse {
  agent_sessions: SessionInfo[];
  shop_names: ShopInfo;
}

export interface SalesShopData {
  id: number;
  name: string;
  total_unresolved_messages: number;
  unassigned_unresolved_messages: number;
  online_agent_ids: number[];
  oldest_prospect_id?: number;
  oldest_prospect_receive_time?: string;
}

interface AgentNames {
  [agent_id: number]: {
    name: string;
    username: string;
  };
}

export interface ShopDashboardResponse {
  shops: SalesShopData[];
  agent_names: AgentNames;
}

export interface SubscriberShopTicketStatusResponse {
  ticket_type: string | null;
  ticket_id?: number | null;
}

export const AGENT_DASHBOARD_QUERY = 'agentDashboard';
export const SHOP_DASHBOARD_QUERY = 'shopDashboard';
export const SESSION_DASHBOARD_QUERY = 'sessionDashboard';
export const SUBSCRIBER_TICKET_STATUS_QUERY = 'SUBSCRIBER_TICKET_STATUS_QUERY';

export const useAgentDashboard = (): UseQueryResult<AgentDashboardResponse> => {
  return useQuery(
    [AGENT_DASHBOARD_QUERY],
    async (): Promise<AgentDashboardResponse> => {
      return api.get('/v2/convert/agent/dashboard');
    },
  );
};

export const useShopDashboard = (): UseQueryResult<ShopDashboardResponse> => {
  return useQuery(
    [SHOP_DASHBOARD_QUERY],
    async (): Promise<ShopDashboardResponse> => {
      return api.get('/v2/convert/shop/dashboard');
    },
  );
};

export const useSessionDashboard = (
  onlineTime?: string,
): UseQueryResult<SessionDashboardResponse> => {
  return useQuery(
    [SESSION_DASHBOARD_QUERY],
    async (): Promise<SessionDashboardResponse> => {
      return api.post('/v2/convert/agent_session/dashboard', {
        online_time: onlineTime || undefined,
      });
    },
  );
};

export interface UseSetAgentStatusProps {
  agentId: string;
  isOnline: boolean;
}

export const useSetAgentStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (props: UseSetAgentStatusProps) => {
      return api.put(`/v2/convert/agent/${props.agentId}/agent_session`, {
        active: props.isOnline,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(AGENT_DASHBOARD_QUERY);
    },
  });
};

export interface UseEmptyAgentInboxProps {
  subscriberIds: number[];
}

export const useEmptyAgentInbox = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (props: UseEmptyAgentInboxProps) => {
      return api.post(`/v2/convert/agent/unassign`, {
        subscriber_ids: props.subscriberIds,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(AGENT_DASHBOARD_QUERY);
    },
  });
};

interface UpdateConversationDurationProps {
  subscriberId: number;
  sessionDurationSeconds: number;
}

export const useUpdateConversationDuration = () => {
  return useMutation({
    mutationFn: (props: UpdateConversationDurationProps) => {
      return api.put('/v2/convert/agent/agent-subscriber-session', {
        subscriber_id: props.subscriberId,
        session_duration: props.sessionDurationSeconds,
      });
    },
    onError: (error) => {
      // There are cases where the agent will fail to update correctly, we don't want to bubble
      // that up to the ui as it doesn't concern the agents
      console.error('Failed to update conversation duration:', error);
    },
  });
};

export const useSubscriberTicketStatus = (
  subscriberId: number,
): UseQueryResult<SubscriberShopTicketStatusResponse> => {
  return useQuery(
    [SUBSCRIBER_TICKET_STATUS_QUERY, subscriberId],
    async (): Promise<SubscriberShopTicketStatusResponse> => {
      return api.get(
        `/v2/convert/subscriber/${subscriberId}/insights/ticket-status`,
      );
    },
  );
};
