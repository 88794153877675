import {
  INVOICING_FREQUENCIES,
  LEDGER_RECORD_TYPES,
  TRANSACTION_ERRORS_MAP,
  TRANSACTION_ERROR_FALLBACK,
} from 'components/billing/common/constants';
import {
  Invoice,
  InvoiceStatuses,
  InvoicingFrequencies,
  LedgerRecord,
  Transaction,
} from 'components/billing/common/types';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';
import { logEvent } from 'utils/events';
import { openInvoiceStatus } from './hooks/useGenerateOpenInvoice';
import { FilterOption, SortOption } from './Invoices';
import { InvoiceMessageCounts } from './messageCounts';

export const getInvoiceServicePeriod = (invoice: Invoice): string => {
  const { effectiveStartDate, effectiveEndDate, ledgerRecords } = invoice;

  const startDate = DateTime.fromISO(
    effectiveStartDate ?? ledgerRecords[0]?.createdAt,
  );
  const endDate = DateTime.fromISO(
    effectiveEndDate ?? ledgerRecords[ledgerRecords.length - 1]?.createdAt,
  );

  if (invoice.status === openInvoiceStatus) {
    return `Started ${startDate.toLocaleString(DateTime.DATE_MED)}`;
  }

  const daysEqual = startDate.day === endDate.day;
  const monthsEqual = startDate.month === endDate.month;
  const yearsEqual = startDate.year === endDate.year;

  if (monthsEqual && yearsEqual && !daysEqual) {
    return `${startDate.toFormat('MMM d')} - ${endDate.toFormat('d, yyyy')}`;
  }
  if (!monthsEqual && yearsEqual) {
    return `${startDate.toFormat('MMM d')} - ${endDate.toLocaleString(
      DateTime.DATE_MED,
    )}`;
  }
  if (!monthsEqual && !yearsEqual) {
    return `${startDate.toLocaleString(
      DateTime.DATE_MED,
    )} - ${endDate.toLocaleString(DateTime.DATE_MED)}`;
  }
  return `${startDate.toLocaleString(DateTime.DATE_MED)}`;
};

export function getOpenInvoiceEffectiveStartDate({
  frequency,
  latestInvoice,
  oldestUninvoicedLedgerRecord,
}: {
  frequency: InvoicingFrequencies;
  latestInvoice?: Invoice;
  oldestUninvoicedLedgerRecord?: LedgerRecord;
}): string {
  // Shop is billed monthly
  if (frequency === INVOICING_FREQUENCIES.MONTHLY) {
    return DateTime.utc().startOf('month').toISODate();
  }

  // Shop is billed immediately and has no past invoices and no uninvoiced ledger records
  if (!latestInvoice && !oldestUninvoicedLedgerRecord) {
    return DateTime.local().toISODate();
  }

  // Shop is billed immediately and has no past invoices but has uninvoiced ledger records
  if (!latestInvoice && oldestUninvoicedLedgerRecord) {
    return DateTime.fromISO(
      oldestUninvoicedLedgerRecord.ledgerMetadata?.effectiveStartDate ??
        oldestUninvoicedLedgerRecord.createdAt,
    ).toISODate();
  }

  // Shop is billed immediately and has a past invoice and possibly an uninvoiced ledger record
  // We need to first check for uninvoiced ledger records because there could be an uninvoiced ledger record that was not charged on the lastest invoice
  return DateTime.fromISO(
    oldestUninvoicedLedgerRecord?.ledgerMetadata?.effectiveStartDate ??
      latestInvoice?.effectiveEndDate ??
      latestInvoice?.createdAt ??
      '',
  ).toISODate();
}

export const getTransactionNames = (invoice: Invoice): string => {
  const chargesSet = new Set(
    invoice.ledgerRecords
      .filter(({ amount }) => amount !== 0)
      .map(({ type }) => type),
  );
  const chargesArray = Array.from(chargesSet);
  const chargesNames = chargesArray.map((charge) => {
    const { name } = LEDGER_RECORD_TYPES[charge];
    return name;
  });
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });

  return formatter.format(chargesNames.sort());
};

export function invoiceHasUsageCharges({ ledgerRecords }: Invoice): boolean {
  return ledgerRecords.some(
    ({ type }) => type === LEDGER_RECORD_TYPES.USAGE_CHARGE.value,
  );
}

// remove commas, dashes and spaces from a string
export function sanitizeString(input: string): string {
  return input.replace(/[\s,-]/g, '');
}

export function useReplaceHistoryPath() {
  const history = useHistory();
  const { pathname } = useLocation();

  return (key: string, value?: string) => {
    const queryParams = new URLSearchParams(history.location.search);

    if (value && value.trim() !== '') {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }

    history.replace({
      pathname,
      search: queryParams.toString(),
    });
  };
}

export const getPercentageDifference = (from: number, to: number): number => {
  // If the change is from 0 to a positive number, return 100
  if (from === 0 && to > 0) return 100;

  const percentDifference = Math.round(((to - from) / from) * 100);

  // Return 0 in the case of NaN or Infinity
  return Number.isFinite(percentDifference) ? percentDifference : 0;
};

export function searchInvoices(
  invoices: Invoice[],
  searchValue = '',
): Invoice[] {
  if (!searchValue) return invoices;

  const lowerCasedValue = searchValue.toLowerCase();
  return invoices.filter((invoice) => {
    return (
      invoice.invoiceNumber.toLowerCase().includes(lowerCasedValue) ||
      getTransactionNames(invoice).toLowerCase().includes(lowerCasedValue) ||
      sanitizeString(getInvoiceServicePeriod(invoice).toLowerCase()).includes(
        sanitizeString(lowerCasedValue),
      )
    );
  });
}

export function filterInvoices(
  invoices: Invoice[],
  filterOption: FilterOption | null,
): Invoice[] {
  if (!filterOption) return invoices;

  logEvent(`billing invoices table filtered by ${filterOption.label}`);

  return invoices.filter(({ status }) => status === filterOption.value);
}

export function sortInvoices(
  invoices: Invoice[],
  sortOption: SortOption,
  messageCounts: InvoiceMessageCounts,
): Invoice[] {
  const {
    value: { property, direction },
  } = sortOption;
  const isDescending = direction === 'desc';

  logEvent(`billing invoices table sorted by ${property}`, { direction });

  return invoices.sort((a, b) => {
    let valA: number;
    let valB: number;

    switch (property) {
      case 'effectiveStartDate':
        valA = new Date(
          a.effectiveStartDate ?? a.ledgerRecords[0]?.createdAt,
        ).getTime();
        valB = new Date(
          b.effectiveStartDate ?? b.ledgerRecords[0]?.createdAt,
        ).getTime();

        // Allows us to keep the open invoice at the top or bottom for immediate shops even if the effective start date is before a previous invoice effective start date
        if (a.status === openInvoiceStatus) valA = Infinity;
        if (b.status === openInvoiceStatus) valB = Infinity;

        break;
      case 'updatedAt':
        valA = new Date(a.updatedAt).getTime();
        valB = new Date(b.updatedAt).getTime();
        break;
      case 'messages':
        valA = messageCounts[a.id] ?? 0;
        valB = messageCounts[b.id] ?? 0;
        break;
      case 'amount':
        valA = a.amount ?? 0;
        valB = b.amount ?? 0;
        break;
      default:
        return 0;
    }

    return isDescending ? valB - valA : valA - valB;
  });
}

export function formatInvoiceStatus(status: InvoiceStatuses): string {
  switch (status) {
    case 'PAID':
      return 'Paid';
    case 'FAILED':
      return 'Overdue';
    case 'FORGIVEN':
      return 'Forgiven';
    case 'REFUNDED':
      return 'Refunded';
    case 'PENDING':
      return 'Pending';
    case 'PROCESSING':
      return 'Processing';
    case 'SETTLING_TAXES':
      return 'Finalizing';
    case 'CHARGING':
      return 'Charging';
    default:
      return 'Open';
  }
}

export function getInvoiceTransactionError(invoice: Invoice): string {
  const failedTransactions: Transaction[] = invoice.transactions.filter(
    ({ status }) => status === 'FAILED',
  );

  const mostRecentFailedTransaction =
    failedTransactions[failedTransactions.length - 1];

  if (!mostRecentFailedTransaction) {
    return '';
  }

  if (!mostRecentFailedTransaction?.receipt?.error) {
    return TRANSACTION_ERROR_FALLBACK;
  }

  const { error } = mostRecentFailedTransaction.receipt;

  const errorMessage = Array.isArray(error) ? error[0] ?? '' : error.message;

  const foundError = Object.entries(TRANSACTION_ERRORS_MAP).find(([errorKey]) =>
    errorMessage.includes(errorKey),
  );

  return foundError ? foundError[1] : TRANSACTION_ERROR_FALLBACK;
}
