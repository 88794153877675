/* eslint-disable no-unused-vars */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

// Main application shell function.

import { Banner } from '@postscript/components';
import { EXEMPT_FROM_SHOP_TOS_GATE } from 'components/admin/utils/feature-flags';
import AcceptTOSView from 'components/dtos/AcceptTOSView';
import ErrorBoundary from 'components/generic/ErrorBoundary';
import LoadingSpinnerBlock from 'components/generic/Loading/LoadingSpinnerBlock';
import AppBannerList from 'components/GlobalBanners/AppBannerList';
import GlobalBannerList from 'components/GlobalBanners/GlobalBannerList';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import GlobalModalContainer from 'components/GlobalModal/GlobalModalContainer';
import LegacyGlobalBannerList from 'components/LegacyGlobalBanners/LegacyGlobalBannerList';
import { logNavItemClicked } from 'components/navigation/eventUtils';
import NavMenuBub from 'components/navigation/NavMenuBub';
import NavMenuButton from 'components/navigation/NavMenuButton';
import SidebarNavigation from 'components/navigation/SidebarNavigation';
import { POSTSCRIPT_DARK_MODE } from 'constants/constants';
import { LocationContext } from 'controllers/contexts';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useFullScreenEditor } from 'controllers/contexts/fullScreenEditor';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PRODUCT_AREAS } from 'utils/events';
import { isSalesRoute } from 'utils/isSalesRoute';
import MediaQueries from 'utils/mediaQueries';
import useAccountStatus from '../account/useAccountStatus';

/* Page Layout: Main container */
const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQueries.desktopOnly} {
    &.full-screen-editor.sidebar-navigation-open {
      overflow: hidden;
      height: 100vh;
    }
  }

  ${MediaQueries.tabletAndPhone} {
    &.sidebar-navigation-open {
      overflow: hidden;
      height: 100vh;
    }
  }
`;

/* Sidebar Navigation + Main Content */

const AppContents = styled.div`
  --nav-menu-transition-speed: 500ms;
  --nav-menu-inner-transition-speed: 150ms;
  --nav-menu-width: 288px;

  display: flex;
  align-items: stretch;
  flex: 1 0 auto;
  padding: 36px 36px 0;
  width: 100%;
  min-height: 100vh;
  max-width: 1632px;
  margin: 0 auto;
  transition: padding var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);
  position: relative;
  z-index: 1;

  ${MediaQueries.desktopOnly} {
    .full-screen-editor & {
      padding-top: 12px;
      max-width: 100%;
    }
  }

  ${MediaQueries.tabletOnly} {
    padding: 24px 30px 0;

    .full-screen-editor & {
      padding-top: 12px;
    }

    ${PageLayout}.sidebar-navigation-open & {
      transform: translateX(calc(var(--nav-menu-width) + var(--spacing-4)));
    }
  }

  ${MediaQueries.phoneOnly} {
    padding: 0;

    ${PageLayout}.sidebar-navigation-open & {
      transform: translateX(var(--nav-menu-width));
    }
  }
`;

/* Main Content */
const MainContent = styled.main`
  padding: 0 54px 36px 18px;
  padding-right: 18px; /* remove when new modular layouts are implemented */
  flex: 1 0 calc(100% - 288px);
  display: flex;
  flex-direction: column;
  transition: opacity var(--nav-menu-transition-speed),
    padding var(--nav-menu-transition-speed),
    max-width var(--nav-menu-transition-speed);

  ${MediaQueries.desktopOnly} {
    ${PageLayout}.full-screen-editor & {
      padding-left: 42px;
    }

    ${PageLayout}:not(.full-screen-editor) & {
      padding-top: 18px;
      max-width: calc(100% - 288px);
    }

    ${PageLayout}.full-screen-editor:not(.sidebar-navigation-open) & {
      padding-right: 18px;
      max-width: 100%;
    }

    ${PageLayout}.full-screen-editor.sidebar-navigation-open & {
      max-width: calc(100% - 288px);
      opacity: 0.5;

      * {
        pointer-events: none !important;
      }
    }
  }

  ${MediaQueries.tabletAndPhone} {
    width: 100%;
    flex: 1 0 100%;
    padding: 0 18px 36px;

    ${PageLayout}:not(.full-screen-editor) & {
      padding-top: 30px;
    }

    ${PageLayout}.sidebar-navigation-open & {
      opacity: 0.5;

      * {
        pointer-events: none !important;
      }
    }
  }

  ${MediaQueries.tabletOnly} {
    ${PageLayout}.full-screen-editor & {
      padding-left: 42px;
    }
  }

  ${MediaQueries.phoneOnly} {
    padding: 54px 24px 48px;

    ${PageLayout}.full-screen-editor & {
      padding-left: 45px;
    }
  }
`;

const StyledAppDiv = styled.div`
  width: 100%;
  height: 100%;
`;

/* Wraps the application in top-level components as well as contexts. */
const AppShell = ({
  appUpdateHandler,
  changeAppState,
  children,
  getUserInfo,
  logOut,
  user,
  shops,
  location,
  inSuspense,
}) => {
  const { hasFlag } = useFeatureFlags();
  const history = useHistory();
  const [isSidebarNavigationOpen, setIsSidebarNavigationOpen] = useState(false);
  const { billingActive, installed, isFetched } = useAccountStatus();

  const showGlobalBanners = isFetched && billingActive && installed;

  // use ONLY for deep editing experiences, such as flow builder or popups
  const [isFullScreenEditor] = useFullScreenEditor();

  useEffect(() => {
    return history.listen(() => window?.scrollTo(0, 0));
  }, [history]);

  useEffect(() => {
    if (localStorage.getItem(POSTSCRIPT_DARK_MODE)) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-theme');
    }
  }, [hasFlag]);

  const closeSidebarNavigation = () => {
    if (isSidebarNavigationOpen) {
      setIsSidebarNavigationOpen(false);
    }
  };

  const { banners } = useBanners();
  const isSales = isSalesRoute();

  return (
    <LocationContext.Provider value={location}>
      <GlobalModalContainer />
      <PageLayout
        isSidebarNavigationOpen={isSidebarNavigationOpen}
        isFullScreenEditor={isFullScreenEditor}
        className={`${
          isSidebarNavigationOpen ? 'sidebar-navigation-open' : ''
        } ${isFullScreenEditor ? 'full-screen-editor' : ''}`}
      >
        <NavMenuBub />
        <AppContents isSidebarNavigationOpen={isSidebarNavigationOpen}>
          <NavMenuButton
            isSidebarNavigationOpen={isSidebarNavigationOpen}
            onClick={(event) => {
              logNavItemClicked(event, 'Mobile Menu Button');
              setIsSidebarNavigationOpen(!isSidebarNavigationOpen);
            }}
          />
          <SidebarNavigation
            user={user}
            logOut={logOut}
            shops={shops}
            getUserInfo={getUserInfo}
            changeAppState={changeAppState}
            isFullScreenEditor={isFullScreenEditor}
            isSidebarNavigationOpen={isSidebarNavigationOpen}
            setIsSidebarNavigationOpen={setIsSidebarNavigationOpen}
            isSales={isSales}
          />
          <MainContent
            aria-hidden={isSidebarNavigationOpen}
            onClick={closeSidebarNavigation}
          >
            {!isFullScreenEditor && (
              <AppBannerList
                id="global-banner-list"
                data-bannercount={banners.length}
                data-legacybannercount={2} // data-bannercount={banners.length}
              >
                {showGlobalBanners && (
                  <>
                    <GlobalBannerList />
                    <LegacyGlobalBannerList />
                  </>
                )}
                {user &&
                  !user.updated &&
                  !user.shadow_ban &&
                  (!user.data || !user.data.competitor_shop) && (
                    <Banner
                      bodyText="Some new features are unavailable until you update your app permissions"
                      primaryAction={{
                        text: 'Update App',
                        onClick: () => appUpdateHandler(),
                      }}
                      variant="warning"
                    />
                  )}
              </AppBannerList>
            )}
            <StyledAppDiv>
              <ErrorBoundary
                productArea={PRODUCT_AREAS.GLOBAL}
                secondaryErrorPage={false}
              >
                {!hasFlag(EXEMPT_FROM_SHOP_TOS_GATE) && !user.is_admin && (
                  <AcceptTOSView />
                )}
                {inSuspense ? <LoadingSpinnerBlock /> : children}
              </ErrorBoundary>
            </StyledAppDiv>
          </MainContent>
        </AppContents>
      </PageLayout>
    </LocationContext.Provider>
  );
};

AppShell.defaultProps = {
  contexts: [],
};

export default AppShell;
