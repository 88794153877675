/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BodyText, Icon, IconSet } from '@postscript/components';
import parseMessageForHtml from 'components/responses/utils/parseMessageForHtml';
import parseMessagesForPreview from 'components/responses/utils/parseMessageForPreview';
import { isVcardFileUrl } from 'components/vcards/VcardFileUrl';
import VcardPhoneMessage from 'components/vcards/VcardPhoneMessage';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import styled, { css } from 'styled-components';
import { useView } from '../hooks/useView';
import { Message } from '../types';
import { getAvatar } from '../utils/roles';

interface MessageItemProps {
  incoming: boolean;
  messageType?: string;
}

const shouldObscureOutboundAvatar = () => {
  const storageKey = localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY);
  return !storageKey || storageKey === '' || storageKey === 'Merchant View';
};

const getMessageType = ({ incoming, messageType }: MessageItemProps) => {
  if (incoming) return 'inbound';
  if (messageType === 'reply') return 'outbound';
  if (messageType?.includes('convert_event')) return 'convert';
  return 'system';
};

const MediaFlexWrapper = styled.div<MessageItemProps>`
  padding: ${({ incoming }) =>
    incoming
      ? '0 var(--spacing-7) 0 var(--spacing-5)'
      : '0 var(--spacing-5) 0 var(--spacing-7)'};
  max-width: 192px;
  margin: 0 0 var(--spacing-1);
  margin-left: ${({ incoming }) => (incoming ? '0' : 'auto')};
`;

const MediaThreadPreview = styled.img`
  display: block;
  border-radius: var(--border-radius-small);
  width: 100%;
  cursor: pointer;
`;

const MessageFlexWrapper = styled.div<MessageItemProps>`
  display: flex;
  padding: ${({ incoming }) =>
    incoming
      ? '0 var(--spacing-7) 0 var(--spacing-5)'
      : '0 var(--spacing-5) 0 var(--spacing-7)'};
  justify-content: ${({ incoming }) => (incoming ? 'flex-start' : 'flex-end')};
  position: relative;

  & .message-avatar {
    margin: 0;
    position: absolute;
    bottom: 0;
    ${({ incoming }) => (incoming ? 'left: 0;' : 'right: 0;')}
  }
`;

const StyledMessageBubble = styled.div<MessageItemProps>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  border-radius: var(--border-radius-medium);
  border-bottom-${({ incoming }) => (incoming ? 'left' : 'right')}-radius: 0;
  padding: var(--spacing-1) var(--spacing-2);
  background: var(--color-message-bubble-${({ incoming, messageType }) =>
    getMessageType({ incoming, messageType })});
  color: var(--color-message-text-${({ incoming, messageType }) =>
    getMessageType({ incoming, messageType })});
  word-break: break-word;
  text-align: left;
`;

const StyledBodyText = styled(BodyText)<{ truncate?: boolean }>`
  display: inline;

  &:empty {
    display: none;
  }

  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      line-clamp: 10;
      -webkit-box-orient: vertical;
    `}

  & a {
    color: currentColor;
    text-decoration: underline;

    &:visited {
      opacity: 0.85;
    }
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.5;
    }
    &:focus-visible {
      color: var(--purple-core);
    }
  }
`;

const StyledAvatar = styled.div<MessageItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--body-text-small);
  font-weight: var(--body-text-bold-font-weight);
  background: transparent;
  background: var(
    --color-message-bubble-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  color: var(
    --color-message-text-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-round);
  position: absolute;
  bottom: 0;
  ${({ incoming }) => (incoming ? 'left: 0;' : 'right: 0;')}
`;

const StyledTimestamp = styled.span<MessageItemProps>`
  font: var(--body-text-normal-font-weight) 0.625rem / 0.75rem
    var(--body-text-typeface);
  color: var(
    --color-message-timestamp-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  text-align: ${({ incoming }) => (incoming ? 'left' : 'right')};
`;

const parseMessageText = (message: string) =>
  parseMessageForHtml(parseMessagesForPreview(message));

interface MessageProps {
  message: Message;
  shopName?: string;
  id: string;
  showTimestamp?: boolean;
  truncate?: boolean;
  forceObscureAvatar?: boolean;
}

const MessageContainer = ({
  message,
  shopName,
  id,
  showTimestamp = true,
  truncate = false,
  forceObscureAvatar = false,
}: MessageProps): JSX.Element => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const { view: currentView } = useView();

  const incoming = message.direction === 'incoming';

  const getBubbleAvatar = () => {
    let avatar;
    if (incoming) {
      avatar = (
        <StyledAvatar
          className="message-avatar"
          messageType={message.type}
          incoming={message.direction === 'incoming'}
        >
          <Icon color="var(--blue-5)" component={IconSet.User} size={18} />
        </StyledAvatar>
      );
    }
    if (message.direction === 'outgoing') {
      if (shouldObscureOutboundAvatar() || forceObscureAvatar) {
        avatar = (
          <StyledAvatar
            className="message-avatar"
            messageType="system"
            incoming={false}
          >
            <Icon
              color="var(--purple-core)"
              component={IconSet.Bub}
              size={18}
            />
          </StyledAvatar>
        );
      } else {
        avatar = getAvatar({ message, currentView, shopName, id });
      }
    }
    return avatar;
  };

  return (
    <>
      {/* Message Block */}
      {message.media_url ? (
        <MediaFlexWrapper incoming={message.direction === 'incoming'}>
          {isVcardFileUrl(message.media_url) ? (
            <VcardPhoneMessage messageContent={shopName} />
          ) : (
            <MediaThreadPreview
              data-testid="media-thread-preview"
              alt="mms-media"
              src={message.media_url}
              onClick={() => setIsLightboxOpen(true)}
            />
          )}
        </MediaFlexWrapper>
      ) : null}
      <MessageFlexWrapper incoming={incoming}>
        <StyledMessageBubble
          className="message-bubble"
          messageType={message.type}
          incoming={message.direction === 'incoming'}
        >
          <StyledBodyText
            forwardedAs="span"
            color="currentColor"
            size={message.type?.includes('convert_event') ? 'x-small' : 'small'}
            truncate={truncate}
          >
            {parseMessageText(message.text)}
          </StyledBodyText>
          {showTimestamp && (
            <StyledTimestamp
              messageType={message.type}
              incoming={message.direction === 'incoming'}
            >
              {message.createdAt.format('HH:mm')}
            </StyledTimestamp>
          )}
        </StyledMessageBubble>

        {getBubbleAvatar()}
      </MessageFlexWrapper>

      {/* Message Lightbox */}
      {isLightboxOpen && message.media_url ? (
        <Lightbox
          mainSrc={message.media_url}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      ) : null}
    </>
  );
};

export default MessageContainer;
