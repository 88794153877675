/* eslint-disable camelcase */
import { BASE_FLOW_BUILDER_API_URL } from 'components/flowBuilder/constants';
import {
  LegacyTagsDict,
  LEGACY_TAG_REGEX,
} from 'components/flowBuilder/constants/tags';
import {
  MessageTemplateContexts,
  PreviewRequestParams,
  TriggerEvent,
} from 'components/flowBuilder/types';
import { FlowContext } from 'components/flowBuilder/types/tags';
import { isFlowContext } from 'components/flowBuilder/types/tags/typeGuards';
import { api } from 'controllers/network/apiClient';

const defaultUploadUrl = 'image/upload';

export interface SaveImageFileResponse {
  media_url: string;
  success: boolean;
}

const isSaveImageFileResponse = (
  response: unknown,
): response is SaveImageFileResponse => {
  const responseValue = response as SaveImageFileResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.media_url || typeof responseValue.media_url !== 'string')
    return false;
  if (typeof responseValue.success !== 'boolean') return false;

  return true;
};

export const saveImageFile = async (
  imageFile: File,
  uploadUrl = defaultUploadUrl,
): Promise<string> => {
  const formData = new FormData();
  formData.append('image', imageFile);
  const response: unknown = await api.postFormData(uploadUrl, formData);

  if (!isSaveImageFileResponse(response))
    throw new Error('Unexpected save image file response');
  if (!response.success) throw new Error('Error saving image file');
  return response.media_url;
};

interface Kwargs {
  key: string;
  type: string;
}

interface ContextAttributes {
  key: string;
  title: string;
  kwargs?: Array<Kwargs>;
}

const isContextAttributes = (
  attributes: unknown,
): attributes is ContextAttributes => {
  const attributesValue = attributes as ContextAttributes;

  if (!attributesValue || typeof attributesValue !== 'object') return false;

  return true;
};

interface ContextSchema {
  title: string;
  description: string;
  attributes: Array<ContextAttributes>;
}

const isContextSchema = (schema: unknown): schema is ContextSchema => {
  const schemaValue = schema as ContextSchema;

  if (!schemaValue || typeof schemaValue !== 'object') return false;
  if (!schemaValue.title || typeof schemaValue.title !== 'string') return false;
  if (!schemaValue.description || typeof schemaValue.description !== 'string')
    return false;
  if (!schemaValue.attributes || !isContextAttributes(schemaValue.attributes))
    return false;

  return true;
};

export interface Context {
  key: string;
  schema: ContextSchema;
}

const isContext = (context: unknown): context is Context => {
  const contextValue = context as Context;

  if (!contextValue || typeof contextValue !== 'object') return false;
  if (!contextValue.key || typeof contextValue.key !== 'string') return false;
  if (!contextValue.schema || !isContextSchema(contextValue.schema))
    return false;

  return true;
};

interface ContextsResponse {
  contexts: Context[];
}

const isContextsResponse = (
  response: unknown,
): response is ContextsResponse => {
  const responseValue = response as ContextsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;

  if (!responseValue.contexts || !Array.isArray(responseValue.contexts))
    return false;
  if (responseValue.contexts.some((context) => !isContext(context)))
    return false;

  return true;
};

export const fetchContexts = async (): Promise<Context[]> => {
  const response: unknown = await api.get(
    `${BASE_FLOW_BUILDER_API_URL}contexts/`,
  );
  if (!isContextsResponse(response))
    throw new Error('Unexpected contexts response');
  return response.contexts;
};

interface MessagePreviewResponse {
  preview: string;
}

interface FlowContextsResponse {
  contexts: FlowContext[];
}

const isFlowContextsResponse = (
  response: unknown,
): response is FlowContextsResponse => {
  const responseValue = response as FlowContextsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.contexts || !Array.isArray(responseValue.contexts))
    return false;
  if (responseValue.contexts.some((context) => !isFlowContext(context)))
    return false;

  return true;
};

export const fetchFlowContexts = async (
  events: TriggerEvent[],
): Promise<FlowContext[]> => {
  const eventsParam = events.map((event) => `"${event.eventType}"`).join();
  const response: unknown = await api.get(
    `${BASE_FLOW_BUILDER_API_URL}contexts/tags/func?events=[${eventsParam}]`,
  );
  if (!isFlowContextsResponse(response))
    throw new Error('Unexpected contexts response');
  return response.contexts;
};

const isMessageCountsResponse = (
  response: unknown,
): response is MessageCountsResponse => {
  const responseValue = response as MessageCountsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (typeof responseValue.characterCount !== 'number') return false;
  if (typeof responseValue.divisor !== 'number') return false;
  if (typeof responseValue.messageCount !== 'number') return false;
  if (
    !responseValue.specificMessageType ||
    typeof responseValue.specificMessageType !== 'string'
  )
    return false;

  return true;
};

interface MessageCountsPayload {
  message: string;
  hasMediaUrl: boolean;
}

export interface MessageCountsResponse {
  characterCount: number;
  divisor: number;
  messageCount: number;
  specificMessageType: string;
}

const isMessagePreviewResponse = (
  response: unknown,
): response is MessagePreviewResponse => {
  const responseValue = response as MessagePreviewResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.preview || typeof responseValue.preview !== 'string')
    return false;

  return true;
};

interface MessagePreviewPayload {
  contexts?: MessageTemplateContexts;
  params: {
    content: string;
  };
}

export const fetchMessagePreview = async (
  params: PreviewRequestParams,
  contexts?: MessageTemplateContexts,
): Promise<string> => {
  const payload: MessagePreviewPayload = {
    params,
  };

  if (contexts) payload.contexts = contexts;

  const response: unknown = await api.post(
    `${BASE_FLOW_BUILDER_API_URL}actions/preview/`,
    payload,
  );

  if (!isMessagePreviewResponse(response))
    throw new Error('Unexpected message preview response');

  return response.preview;
};

export const fetchMessageCounts = async (
  content: string,
  hasMediaValue: boolean,
): Promise<MessageCountsResponse> => {
  const payload: MessageCountsPayload = {
    message: content,
    hasMediaUrl: hasMediaValue,
  };
  const response: unknown = await api.post(
    `${BASE_FLOW_BUILDER_API_URL}actions/message/characters/`,
    payload,
  );

  if (!isMessageCountsResponse(response))
    throw new Error('Unexpected message preview response');

  return response;
};

const replaceLegacyMergeTagsWithMergeTags = (content: string) => {
  return Object.entries(LegacyTagsDict).reduce(
    (accContent, [legacyMergeTag, mergeTag]) => {
      return accContent.replace(new RegExp(legacyMergeTag, 'g'), mergeTag);
    },
    content,
  );
};

const replaceLegacyMergeTagsWithSquareTags = (content: string) => {
  return content.replace(
    LEGACY_TAG_REGEX,
    (match) => `[${match.slice(1, -1).toUpperCase()}]`,
  );
};

export const replaceLegacyMergeTags = (content: string): string => {
  return replaceLegacyMergeTagsWithSquareTags(
    replaceLegacyMergeTagsWithMergeTags(content),
  );
};
