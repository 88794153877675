/* Location by React-Router context provider.
 *
 * Future versions of react-router actually work like this- this is a backported
 * version of the API.
 */

import { createContext } from 'react';

const LocationContext = createContext({});

export default LocationContext;
