import cookie from 'react-cookies';
import Requests from './network_requests';

const Tokens = {
  refreshAccessToken() {
    const promise = new Promise((resolve) => {
      Requests.post('/token/refresh', {}, true).then((result) => {
        if (result.access_token) {
          cookie.save('access_token', result.access_token, { path: '/' });
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  },
};

export default Tokens;
