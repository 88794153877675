/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import {
  Badge,
  BodyText,
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  IconSet,
  SelectMenu,
  Table,
  toast,
  Tooltip,
} from '@postscript/components';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import {
  AgentDashboardResponse,
  useEmptyAgentInbox,
  UseEmptyAgentInboxProps,
  useSetAgentStatus,
  UseSetAgentStatusProps,
} from 'components/sales/hooks/useDashboard';
import { useMemo, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { AgentInfo, ShopInfo } from '../types';

const PAGE_LIMIT = 20;

const SortColumns = {
  ONLINE_STATUS: 'online',
  INBOX_SIZE: 'inbox_size',
  ALPHABETICAL: 'alphabetical',
} as const;

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

const SortOptions = [
  {
    label: 'Online Status',
    value: {
      sortColumn: SortColumns.ONLINE_STATUS,
      sortOrder: SortOrders.DESC,
    },
  },
  {
    label: 'Inbox Size (most first)',
    value: { sortColumn: SortColumns.INBOX_SIZE, sortOrder: SortOrders.DESC },
  },
  {
    label: 'Inbox Size (least first)',
    value: { sortColumn: SortColumns.INBOX_SIZE, sortOrder: SortOrders.ASC },
  },
  {
    label: 'Alphabetical by username (a-z)',
    value: { sortColumn: SortColumns.ALPHABETICAL, sortOrder: SortOrders.ASC },
  },
  {
    label: 'Alphabetical by username (z-a)',
    value: { sortColumn: SortColumns.ALPHABETICAL, sortOrder: SortOrders.DESC },
  },
] as const;

type SortOption = typeof SortOptions[number];

interface AgentListItemProps {
  agentId: string;
  agentInfo: AgentInfo;
  nameMapping: ShopInfo;
  setAgentStatus: UseMutateFunction<
    any,
    unknown,
    UseSetAgentStatusProps,
    unknown
  >;
  emptyAgentInbox: UseMutateFunction<
    any,
    unknown,
    UseEmptyAgentInboxProps,
    unknown
  >;
}

const StatusBadge = ({
  status,
  hasAssignedProspects,
}: {
  status: 'online' | 'offline';
  hasAssignedProspects: boolean;
}): JSX.Element | null => {
  if (status === 'online') {
    return <Badge variant="success">Online</Badge>;
  }
  if (hasAssignedProspects) {
    return (
      <>
        <Badge data-tip data-for="status-tooltip-id" variant="error">
          Offline
        </Badge>
        <Tooltip id="status-tooltip-id">
          Agent is offline with assigned prospects
        </Tooltip>
      </>
    );
  }
  return <Badge>Offline</Badge>;
};

const getAgentRow = ({
  agentId,
  agentInfo,
  nameMapping,
  setAgentStatus,
  emptyAgentInbox,
}: AgentListItemProps) => {
  const { name, status, username, assigned_conversations, assigned_shops } =
    agentInfo;
  const shopNames = assigned_shops.map((shopId) => nameMapping[shopId]);

  const onSetOffline = async () => {
    setAgentStatus(
      {
        agentId,
        isOnline: false,
      },
      {
        onSuccess: () => {
          toast.success(`${agentInfo.username} was set offline`);
        },
        onError: (err) => {
          toast.error(err as string);
        },
      },
    );
  };

  const onEmptyInbox = async () => {
    emptyAgentInbox(
      {
        subscriberIds: assigned_conversations.map(
          (conversation) => conversation.subscriber_id,
        ),
      },
      {
        onSuccess: () => {
          toast.success(`Emptied ${agentInfo.username}'s inbox`);
        },
        onError: (err) => {
          toast.error(err as string);
        },
      },
    );
  };

  return (
    <Table.Item
      key={agentId}
      actionColumn={
        <DropdownButton
          className="agentTableActionDropdownButton"
          icon={IconSet.DotsHorizontalLarge}
          iconFlipAnimation
          id="agent-dashboard-dropdown"
          size="small"
          variant="secondary"
        >
          <DropdownMenu
            aria-labelledby="agent-dashboard-dropdown"
            menuAnchor="right"
          >
            <DropdownMenuItem>
              <button onClick={onSetOffline} type="button">
                Set Offline
              </button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <button onClick={onEmptyInbox} type="button">
                Empty Agent&apos;s Inbox
              </button>
            </DropdownMenuItem>
          </DropdownMenu>
        </DropdownButton>
      }
      dataColumns={[
        <Table.ItemNumber
          key="agent-status"
          primary={
            <StatusBadge
              data-for="status-tooltip-id"
              data-tip
              status={status}
              hasAssignedProspects={assigned_conversations.length > 0}
            />
          }
        />,
        <Table.ItemNumber
          key="agent-prospects"
          primary={<BodyText>{assigned_conversations.length}</BodyText>}
        />,
        <Table.ItemNumber
          key="agent-shops"
          primary={
            shopNames.length > 0 ? (
              <div
                key={agentId}
                data-testid="agent-shops-column"
                style={{
                  marginBottom: 'var(--spacing-2)',
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto',
                  gap: 'var(--spacing-1)',
                }}
              >
                {assigned_shops.map((shopId) => (
                  <div key={shopId}>
                    <Badge>{nameMapping[shopId]}</Badge>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />,
        <Table.ItemNumber
          key="agent-ws-connections"
          primary={<BodyText>{agentInfo.websocket_connections}</BodyText>}
        />,
      ]}
      nameColumn={{
        description: username,
        text: name,
      }}
    />
  );
};

interface AgentListProps {
  data: AgentDashboardResponse;
  isLoading: boolean;
}

interface SortConfig {
  column: string;
  order: string;
}

const onlineSort = (a: AgentInfo, b: AgentInfo) => {
  if (a.status === 'online' && b.status === 'offline') {
    return -1;
  }
  if (a.status === 'offline' && b.status === 'online') {
    return 1;
  }
  return b.assigned_conversations.length - a.assigned_conversations.length;
};

const alphabeticalSort = (a: AgentInfo, b: AgentInfo, asc: boolean) => {
  const reverse = asc ? 1 : -1;
  if (a.username < b.username) {
    return -1 * reverse;
  }
  if (a.username > b.username) {
    return 1 * reverse;
  }
  return 0;
};

const inboxSizeSort = (a: AgentInfo, b: AgentInfo, asc: boolean) => {
  if (asc) {
    return a.assigned_conversations.length - b.assigned_conversations.length;
  }
  return b.assigned_conversations.length - a.assigned_conversations.length;
};

const AgentList = ({ data, isLoading }: AgentListProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOptions, setSortOptions] = useState<SortConfig>({
    column: SortColumns.ONLINE_STATUS,
    order: SortOrders.DESC,
  });
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const { mutate: emptyAgentInbox } = useEmptyAgentInbox();
  const { mutate: setAgentStatus } = useSetAgentStatus();

  const agentData: [string, AgentInfo][] = Object.entries(data?.active_agents);

  const sortedAgentData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return agentData.sort(([_aid, a], [_bid, b]) => {
      // Online sorting
      if (sortOptions.column === SortColumns.ONLINE_STATUS) {
        return onlineSort(a, b);
      }

      // Alphabetical sorting
      if (sortOptions.column === SortColumns.ALPHABETICAL) {
        return alphabeticalSort(a, b, sortOptions.order === SortOrders.ASC);
      }

      // Queue depth sorting
      if (sortOptions.column === SortColumns.INBOX_SIZE) {
        return inboxSizeSort(a, b, sortOptions.order === SortOrders.ASC);
      }
      return 0;
    });
  }, [sortOptions, data]);

  return (
    <Table
      filters={
        <SelectMenu
          fieldWidth={250}
          isClearable={false}
          onChange={(option) => {
            const { sortColumn, sortOrder } = (option as SortOption).value;
            setCurrentPage(1);
            setSortOptions({ column: sortColumn, order: sortOrder });
          }}
          options={Object.values(SortOptions)}
          value={Object.values(SortOptions).find(
            ({ value }) =>
              value.sortColumn === sortOptions.column &&
              value.sortOrder === sortOptions.order,
          )}
        />
      }
      globalColumnSettings={{
        action: {
          align: 'right',
          heading: 'Actions',
        },
        dataColumns: [
          {
            heading: 'Status',
          },
          {
            heading: 'Assigned Prospects',
          },
          {
            heading: 'Shops',
            minWidth: 450,
          },
          {
            heading: 'Active WS Connections',
          },
        ],
        name: {
          heading: 'Name',
          minWidth: 100,
        },
      }}
      emptyState={{
        heading: "There's nothing here",
        description: 'No agents exist',
      }}
      search={{
        placeholder: 'Search agents',
        onChange: (e) => {
          setCurrentSearchTerm(e.target.value);
        },
        id: 'agent-list-search',
        value: currentSearchTerm,
      }}
      id="agent-list-table"
      isEmpty={Object.keys(data?.active_agents || {}).length === 0}
      isLoading={isLoading}
      labelledBy="agent-list-table"
      loadingState={<LoadingSpinner>Loading agents...</LoadingSpinner>}
      size="medium"
      pagination={{
        currentPage,
        selectPage: setCurrentPage,
        totalPages: Math.ceil(
          (Object.keys(data?.active_agents || {}).length ?? 0) / PAGE_LIMIT,
        ),
      }}
    >
      {sortedAgentData
        .filter(
          ([, agentData]) =>
            agentData.username.includes(currentSearchTerm) ||
            agentData.name.includes(currentSearchTerm),
        )
        .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
        .map(([agentId, agentData]) => {
          return getAgentRow({
            agentId,
            agentInfo: agentData,
            nameMapping: data.shop_names,
            setAgentStatus,
            emptyAgentInbox,
          });
        })}
    </Table>
  );
};

export default AgentList;
