import { BodyText } from '@postscript/components';
import MessageComponent from 'components/sales/components/Message';
import SystemMessage from 'components/sales/components/SystemMessage';
import {
  SYSTEM_EVENT_SALES_SUBSTRING,
  VIEW_OPTIONS,
} from 'components/sales/constants';
import { uniqueId } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import utils from 'utils';
import { ChatHistory, Message, MessageGroup, Subscriber } from '../types';

const TODAY = 'Today';
const YESTERDAY = 'Yesterday';
const MESSAGE_GROUP_TYPE = 'message-group';

type Groups = { [key: string]: Message[] };

interface MessageGroupTextProps {
  group: MessageGroup;
}

const isSystemMessage = (message: Message): boolean => {
  return message.type?.includes(SYSTEM_EVENT_SALES_SUBSTRING);
};

export const makeGroupNamePretty = (groupName: string): string => {
  if (groupName === TODAY) {
    return `${TODAY} - ${moment().format('ddd, MMM Do')}`;
  }

  if (groupName === YESTERDAY) {
    return `${YESTERDAY} - ${moment()
      .subtract(1, 'day')
      .format('ddd, MMM Do')}`;
  }

  const now = moment();
  const groupDate = moment(`${groupName} 00:00`, 'YYYY-MM-DD HH:mm');

  return groupDate.isBefore(now, 'year')
    ? groupDate.format('ddd, MMM Do, YYYY')
    : groupDate.format('ddd, MMM Do');
};

const ThreadTimestamp = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  padding: var(--spacing-2);

  &::before,
  &::after {
    background: var(--border-color-dim);
    display: block;
    content: '';
    height: 1px;
    flex: 1 0;
  }
`;

const MessageGroupText = ({ group }: MessageGroupTextProps): JSX.Element => (
  <ThreadTimestamp>
    <BodyText color="var(--text-color)" size="x-small">
      {makeGroupNamePretty(group.text)}
    </BodyText>
  </ThreadTimestamp>
);

const generateKey = (
  subscriber: Subscriber,
  chatHistory: ChatHistory,
): string => {
  if (chatHistory.incoming_messages_id) {
    return `${subscriber.subscriber.id}_${chatHistory.incoming_messages_id}`;
  }
  if (chatHistory.sent_messages_id) {
    return `${subscriber.subscriber.id}_${chatHistory.sent_messages_id}`;
  }
  return uniqueId(`${subscriber.subscriber.id}_`);
};

export const createMessageForSubscriber = (
  subscriber: Subscriber,
  chatHistory: ChatHistory,
): Message => {
  const createdAt = moment.utc(chatHistory.created_at).local();
  return {
    type: chatHistory.type,
    createdAt,
    text: utils.safeBase64Decode(chatHistory.body),
    media_url: chatHistory.media_url || chatHistory.data?.MediaUrl0,
    preview_link_map: chatHistory.preview_link_map,
    direction: chatHistory.direction,
    transactional: chatHistory.transactional,
    user_guid: chatHistory.user_guid,
    key: generateKey(subscriber, chatHistory),
    user_first_name: chatHistory.user_first_name,
    user_last_name: chatHistory.user_last_name,
    user_avatar_url: chatHistory.user_avatar_url,
    eventData: chatHistory.data,
    is_sent_by_salesbub: chatHistory.is_sent_by_salesbub,
  };
};

export const getGroupNameForMessage = ({ createdAt }: Message): string => {
  const now = moment();
  const dayOf = now.clone().startOf('day');
  const dayBefore = dayOf.clone().subtract(1, 'days');

  if (createdAt.isSameOrAfter(dayOf)) {
    return TODAY;
  }

  if (createdAt.isSameOrAfter(dayBefore) && createdAt.isBefore(dayOf)) {
    return YESTERDAY;
  }

  return createdAt.format('YYYY-MM-DD');
};

export const createGroups = (messages: Message[]): Groups => {
  const groups: Groups = {};
  messages.forEach((message: Message) => {
    const group = getGroupNameForMessage(message);
    if (groups[group]) {
      groups[group].push(message);
    } else {
      groups[group] = [message];
    }
  });

  return groups;
};

export const sortGroupNames = (names: string[]): string[] => {
  return names
    .filter((name) => ![TODAY, YESTERDAY].includes(name))
    .sort()
    .concat([YESTERDAY, TODAY]);
};

export const getMessagesWithGroups = (
  groups: Groups,
  view: string,
): (Message | MessageGroup)[] => {
  const groupNamesSorted = sortGroupNames(Object.keys(groups));

  return groupNamesSorted.reduce((messages: any[], name: string) => {
    const groupMessages = groups[name];
    if (!groupMessages?.length) return messages;

    const groupHasOnlySystemMessages =
      groupMessages.filter((m: Message) => !isSystemMessage(m)).length === 0;
    if (view === VIEW_OPTIONS.MERCHANT && groupHasOnlySystemMessages)
      return messages;

    groupMessages.sort((a: Message, b: Message) =>
      a.createdAt.isAfter(b.createdAt) ? 1 : -1,
    );
    return [
      ...messages,
      { type: MESSAGE_GROUP_TYPE, text: name },
      ...groupMessages,
    ];
  }, []);
};

export const getSubscriberChatItems = (
  subscriber: Subscriber,
  view: string,
): (Message | MessageGroup)[] => {
  const groups: Groups = createGroups(
    subscriber.chat_history.map((c) =>
      createMessageForSubscriber(subscriber, c),
    ),
  );
  return getMessagesWithGroups(groups, view);
};

export interface MessageListItem {
  key: string;
  el: JSX.Element;
}

export const getMessageListItemsForSubscriber = (
  subscriber: Subscriber,
  view: string,
): MessageListItem[] => {
  if (!subscriber) return [];

  const items = getSubscriberChatItems(subscriber, view);

  return items.map((item, i) => {
    if (item.type === MESSAGE_GROUP_TYPE) {
      return {
        key: item.text,
        el: <MessageGroupText group={item} />,
      };
    }

    const message: any = item;

    if (message.type?.includes(SYSTEM_EVENT_SALES_SUBSTRING))
      return { key: message.key, el: <SystemMessage message={message} /> };

    return {
      key: message.key,
      el: (
        <MessageComponent
          message={message}
          shopName={subscriber?.shop_name}
          id={i.toString()}
        />
      ),
    };
  });
};
