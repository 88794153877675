import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useEffect } from 'react';
import { User } from 'types';
import { BANNER_TYPES } from 'utils/events';

interface UserWrap {
  user: User;
}

const DataSyncingBanner = ({ user }: UserWrap): null => {
  const { addBanner } = useBanners();

  const updateStatusAndBanner = () => {
    if (!user.has_synced) {
      addBanner({
        id: 'data-syncing',
        isDismissable: true,
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.DATA_SYNCING,
        },
        data: {
          variant: 'guidance',
          heading: 'Your Shopify data is syncing',
          bodyText:
            'This can take anywhere from a few minutes up to a few days to finish for larger shops. Some features like segments and merge tags may not work as expected until the sync is finished.',
        },
      });
    }
  };

  useEffect(() => {
    updateStatusAndBanner();
  }, [user]);

  return null;
};

export default DataSyncingBanner;
