/* eslint-disable camelcase */
import { AGENT_EVENTS_TO_RECORD } from 'components/responses/constants';
import { ENV } from './envVars';

declare global {
  interface Window {
    dataLayer: {
      push: (event: Event) => void;
    };
  }
}

export const BANNER_TYPES = {
  BILLING_FAILED_INVOICE: 'failed invoice',
  BILLING_FREE_TRIAL: 'free trial',
  BILLING_FREE_TRIAL_AND_USAGE_CREDIT: 'free trial & usage credit',
  BILLING_PAST_DUE: 'past due',
  BILLING_PAYMENT_METHOD: 'payment method',
  BILLING_RAC_APPROVAL: 'rac approval',
  BILLING_RAC_APPROVED_CONFIRMATION: 'rac approved confirmation',
  BILLING_USAGE_CREDIT: 'usage credit',
  BILLING_LEGACY_PAST_DUE: 'legacy past due',
  CAMPAIGNS_RECOVERABLE_ERRORS: 'campaigns_recoverable_errors',
  CAMPAIGNS_UNRECOVERABLE_ERRORS: 'campaigns_unrecoverable_errors',
  TFN_VERIFICATION_STATUS: 'tfn verification status',
  CALL_FORWARDING: 'call forwarding',
  DATA_SYNCING: 'data syncing',
  SDK_APP_EXTENSION_INSTALL: 'sdk app extension install',
} as const;

const INPUT_EVENT_NAMES = {
  INPUT_BLURRED: 'input blurred',
  INPUT_CHANGED: 'input changed',
  INPUT_CHANGED_TO: 'input changed to', // intended to log data after change
  INPUT_CLICKED: 'input clicked',
} as const;

const MODAL_EVENT_NAMES = {
  MODAL_CTA_CLICKED: 'modal cta clicked',
  MODAL_DELETE_CLICKED: 'modal delete clicked',
  MODAL_DISMISSED: 'modal dismissed',
  MODAL_OPENED: 'modal opened',
} as const;

export const COMMON_EVENT_NAMES = {
  BANNER_DISMISSED: 'banner dismissed',
  BANNER_DISPLAYED: 'banner displayed',
  BUTTON_CLICKED: 'button clicked',
  COMPONENT_RENDERED: 'component rendered',
  ERROR_BOUNDARY_ERROR: 'error boundary caught an error',
  ...INPUT_EVENT_NAMES,
  LINK_CLICKED: 'link clicked',
  ...MODAL_EVENT_NAMES,
  ONBOARDING_STEP_SAVED: 'onboarding step saved',
  SWITCHED_SHOP: 'switched shop',
  TABLE_ITEM_CLICKED: 'table item clicked',
  TABLE_ROW_CLICKED: 'table row clicked',
  TAB_SELECTED: 'tab selected',
  TOOLTIP_SHOWN: 'tooltip shown',
  UNLOAD_TRIGGERED: 'unload triggered',
} as const;

const EVENT = 'log_event';

export const MODAL_TYPES = {
  BILLING_CONTACT_SALES: 'billing contact sales',
  BILLING_DOWNGRADE: 'billing downgrade',
  BILLING_PAYMENT_METHODS: 'billing payment methods',
  BILLING_PAY_NOW: 'billing pay now',
  BILLING_UPGRADE: 'billing upgrade',
  BILLING_UPSELL: 'billing upsell',
  BILLING_ALL_MESSAGE_RATES: 'billing all message rates',
  BILLING_ESTIMATED_CAMPAIGN_COST: 'billing estimated campaign cost',
  BUILDER_TYPE: 'builder type',
  ONBOARDING_BILLING_ADDRESS: 'onboarding billing address',
  ONBOARDING_USER_INVITE: 'onboarding user invite',
  POPUP_ANALYTICS_EXPORT: 'popup analytics export',
  POPUP_CSS_OVERWRITE: 'popup css overwrite confirmation',
  POPUP_EMAIL_EXPORT: 'popup email export',
  POPUP_PRE_CSS_CONFIRM: 'popup pre css save confirmation',
  POPUP_SCHEDULE: 'popup schedule',
  POPUP_SPLIT_TEST: 'popup split test platform',
  POPUP_SPLIT_TEST_END: 'popup split test end',
  SECOND_CAMPAIGN_MESSAGE_CONFIRM: 'second campaign message confirmation',
  SLTV_ANALYTICS_EXPORT: 'sltv analytics export',
  TEMPLATES: 'templates',
} as const;

export const PRODUCT_AREAS = {
  ADMIN: 'admin',
  ANALYTICS: 'analytics',
  AUTOMATIONS: 'automations',
  BILLING: 'billing', // Usage Billing
  BLOCK_POPUPS: 'block popups',
  BRAND_CENTER: 'brand center',
  LEGACY_BILLING: 'legacy billing', // Credits
  CAMPAIGNS: 'campaigns',
  DASHBOARD: 'dashboard',
  DESKTOP_POPUP: 'desktop popup',
  FLOW_BUILDER: 'flow builder',
  GLOBAL: 'global', // only for global components like the site nav
  INTEGRATIONS: 'integrations',
  KEYWORDS: 'keywords',
  MESSAGE_PLANNER: 'message planner',
  MOBILE_POPUP: 'mobile popup',
  ONBOARDING: 'onboarding',
  POPUPS: 'popups',
  POPUPS_CSS: 'popup css',
  POPUPS_SPLIT_TEST: 'popup split test',
  REPORTS: 'reports',
  RESPONSES: 'responses',
  SEGMENTS: 'segments',
  SMS_SALES: 'sms sales',
  SUBSCRIBERS: 'subscribers',
} as const;

export const TEST_PRODUCT_AREAS = {
  ANY: 'any product area',
} as const;

export type Data = RequiredProps & Record<string, any>;

export type EventNames =
  | typeof COMMON_EVENT_NAMES[keyof typeof COMMON_EVENT_NAMES]
  | string;

export type InputEventNames =
  typeof INPUT_EVENT_NAMES[keyof typeof INPUT_EVENT_NAMES];

export type ModalEventNames =
  typeof MODAL_EVENT_NAMES[keyof typeof MODAL_EVENT_NAMES];

export type ModalTypes = typeof MODAL_TYPES[keyof typeof MODAL_TYPES];

export type ProductAreas =
  | typeof PRODUCT_AREAS[keyof typeof PRODUCT_AREAS]
  | TestProductAreas;

export type TestProductAreas =
  typeof TEST_PRODUCT_AREAS[keyof typeof TEST_PRODUCT_AREAS];

export type RequiredProps = {
  location?: string;
  product_area?: ProductAreas | string;
};

interface Event {
  event: typeof EVENT;
  event_properties?: EventProperties;
  event_type: EventNames;
  _clear: true; // necessary to prevent event_properties from being merged with previous event_properties
}

type EventProperties = Data & Path;

type Path = { path: string };

const debugConsole = (
  eventName: string,
  eventProperties: Data,
): boolean | void => {
  const isCommonEventName = Object.values(COMMON_EVENT_NAMES as any).includes(
    eventName,
  );
  const numberOfProperties = Object.keys(eventProperties).length;

  if (!isCommonEventName && numberOfProperties > 20) {
    console.log(
      `event: '${eventName}' | custom | ❗️${numberOfProperties} props`,
      eventProperties,
    );
    return true;
  }

  if (isCommonEventName && numberOfProperties > 8) {
    console.log(
      `event: '${eventName}' | common | ❗️${numberOfProperties} props`,
      eventProperties,
    );
    return true;
  }

  if (!isCommonEventName) {
    console.log(
      `event: '${eventName}' | custom | ${numberOfProperties} props`,
      eventProperties,
    );
    return true;
  }

  console.log(
    `event: '${eventName}' | common | ${numberOfProperties} props`,
    eventProperties,
  );
};

export const logEvent = (eventName: EventNames, data?: Data): boolean => {
  window.dataLayer = window.dataLayer || [];

  const eventProperties: EventProperties = {
    ...data,
    path: window.location.pathname,
  };

  const event: Event = {
    event: EVENT,
    event_properties: eventProperties,
    event_type: eventName,
    _clear: true,
  };

  // Add debugging info if in non-production environment
  if (ENV !== 'production' && ENV !== 'test') {
    debugConsole(eventName, eventProperties);
  }

  // Push event to data layer so Google Tag Manager can pick it up
  window.dataLayer.push(event);

  // Emit event for sms sales events for recording
  if (
    Object.values(AGENT_EVENTS_TO_RECORD).includes(eventProperties?.action) ||
    Object.values(AGENT_EVENTS_TO_RECORD).includes(eventName)
  ) {
    // Normalize object shape for different event types (resolve)
    if (
      Object.values(AGENT_EVENTS_TO_RECORD).includes(eventName) &&
      event.event_properties
    ) {
      event.event_properties.action = eventName;
    }

    const customEvent = new CustomEvent('logAgentEvent', { detail: event });
    window.dispatchEvent(customEvent);
  }

  return true;
};

export const logButtonClickEvent = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  event: any,
  props?: { [key: string]: any },
): void => {
  const { innerText: text } = event.target as HTMLButtonElement;

  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    text,
    ...props,
  });
};

export const logLinkClickEvent = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  event: any,
  props?: { [key: string]: any },
): void => {
  const { href, innerText: text } = event.target as HTMLAnchorElement;

  logEvent(COMMON_EVENT_NAMES.LINK_CLICKED, {
    text,
    href,
    ...props,
  });
};
