import { OPT_IN_METHODS, POPUP_TYPES, QUESTION_TYPES } from '../constants';
import { OptInMethod, PopupLocalValues } from '../types';

export const getOptInMethod = (popup: PopupLocalValues): OptInMethod => {
  // Legacy desktop popups (without pages) will have `twoTouchEnabled` set to true so we need to explicitly check the type here
  const legacyOptInMethod =
    popup.twoTouchEnabled && popup.popupType === POPUP_TYPES.MOBILE
      ? OPT_IN_METHODS.TWO_TOUCH
      : OPT_IN_METHODS.DOUBLE_OPT_IN;

  const allPopupQuestions =
    popup?.pages?.flatMap((page) => page?.questions ?? []) ?? [];
  const phoneQuestion = allPopupQuestions.find(
    (q) => q.type === QUESTION_TYPES.PHONE,
  );

  return phoneQuestion?.optInMethod ?? legacyOptInMethod;
};

export const isTwoTouchPopup = (popup: PopupLocalValues): boolean =>
  getOptInMethod(popup) === OPT_IN_METHODS.TWO_TOUCH;
