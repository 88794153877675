/* eslint-disable @typescript-eslint/no-unused-vars */
import { BodyText, Layout } from '@postscript/components';
import MessageContainer from 'components/sales/components/Message';
import moment from 'moment';
import styled from 'styled-components';
import {
  ConversationIntelligenceUser,
  ConversationWithInsights,
} from '../hooks/useConversationSummaries';

const StyledConversationContainer = styled.div`
  width: 100%;
`;

const StyledConversationHeading = styled(BodyText)`
  color: var(--text-color);
  padding: var(--spacing-2);
  text-align: center;
`;

const StyledMessages = styled(Layout).attrs({
  vertical: true,
  align: 'right',
})``;

const StyledMessage = styled.div`
  width: 100%;
  position: relative;

  & > div:not(:last-child) {
    margin-bottom: var(--spacing-1);
  }
`;

const findMostRecentExchange = (conversation: ConversationWithInsights) => {
  // eslint-disable-next-line no-plusplus
  for (let i = conversation.messages.length - 1; i > 0; i--) {
    if (
      conversation.messages[i].direction !==
      conversation.messages[i - 1].direction
    ) {
      return i - 1; // Index of the last message before the exchange
    }
  }
  return -1; // If no exchange found
};

const renderMostRecentExchange = (
  conversation: ConversationWithInsights,
  participatingUsers: { [id: number]: ConversationIntelligenceUser },
  isMultiShop: boolean,
  truncate: boolean,
  subscriberName?: string,
) => {
  const mostRecentExchange = findMostRecentExchange(conversation);
  let bottomMessage;
  let topMessage;
  if (mostRecentExchange === -1) {
    [topMessage] = conversation.messages;
    bottomMessage =
      conversation.messages.length > 1 ? conversation.messages[1] : null;
  } else {
    topMessage = conversation.messages[mostRecentExchange];
    bottomMessage = conversation.messages[mostRecentExchange + 1];
  }

  const topMessageUserId = topMessage?.user_id;
  const bottomMessageUserId = bottomMessage?.user_id;
  const topMessageId =
    topMessage.oid?.toString() ?? topMessage.external_id ?? '';
  const bottomMessageId =
    bottomMessage?.oid?.toString() ?? bottomMessage?.external_id ?? '';

  return (
    <>
      <MessageContainer
        message={{
          type: '',
          text: topMessage.body,
          createdAt: moment(topMessage.created_at),
          media_url: null,
          preview_link_map: null,
          direction:
            topMessage.direction === 'INBOUND' ? 'incoming' : 'outgoing',
          transactional: false,
          user_guid: topMessageUserId
            ? participatingUsers[topMessageUserId]?.guid
            : null,
          key: topMessageId,
          user_first_name: topMessageUserId
            ? participatingUsers[topMessageUserId]?.first_name
            : undefined,
          user_last_name: topMessageUserId
            ? participatingUsers[topMessageUserId]?.last_name
            : undefined,
          user_avatar_url: topMessageUserId
            ? participatingUsers[topMessageUserId]?.avatar_url
            : undefined,
        }}
        forceObscureAvatar
        id={topMessageId}
        showTimestamp={false}
        truncate
      />

      {bottomMessage && (
        <MessageContainer
          message={{
            type: '',
            text: bottomMessage.body,
            createdAt: moment(bottomMessage.created_at),
            media_url: '',
            preview_link_map: '',
            direction:
              bottomMessage.direction === 'INBOUND' ? 'incoming' : 'outgoing',
            transactional: false,
            user_guid: bottomMessageUserId
              ? participatingUsers[bottomMessageUserId]?.guid
              : null,
            key: bottomMessageId,
            user_first_name: bottomMessageUserId
              ? participatingUsers[bottomMessageUserId]?.first_name
              : undefined,
            user_last_name: bottomMessageUserId
              ? participatingUsers[bottomMessageUserId]?.last_name
              : undefined,
            user_avatar_url: bottomMessageUserId
              ? participatingUsers[bottomMessageUserId]?.avatar_url
              : undefined,
          }}
          forceObscureAvatar
          id={bottomMessageId}
          truncate
        />
      )}
    </>
  );
};

interface ConversationCardNoSummaryBodyProps {
  conversation?: ConversationWithInsights;
  participatingUsers?: { [id: number]: ConversationIntelligenceUser };
  isMultiShop?: boolean;
  truncate?: boolean;
}

const ConversationCardNoSummaryBody = ({
  conversation,
  participatingUsers,
  isMultiShop = false,
  truncate = false,
}: ConversationCardNoSummaryBodyProps): JSX.Element => {
  if (!conversation || !participatingUsers) {
    return <></>;
  }
  return (
    <StyledConversationContainer>
      <StyledConversationHeading size="x-small">
        Last exchange
      </StyledConversationHeading>
      <StyledMessages>
        <StyledMessage>
          {renderMostRecentExchange(
            conversation,
            participatingUsers,
            isMultiShop,
            truncate,
          )}
        </StyledMessage>
      </StyledMessages>
    </StyledConversationContainer>
  );
};

export default ConversationCardNoSummaryBody;
