/* eslint-disable no-restricted-syntax */
import Requests from './network/network_requests';
import { trackEvent, vwoEvent } from './vwo';

let isAdmin = false;

const Events = {
  initialize(username) {
    if (username.includes('@postscript.io')) {
      isAdmin = true;
    } else {
      isAdmin = false;
    }
  },

  track(event, data) {
    if (isAdmin) {
      return;
    }
    const payload = {
      event_name: event,
    };
    if (data && Object.keys(data) && Object.keys(data).length > 0) {
      for (const key in data) {
        if (key !== 'event_name') {
          payload[key] = data[key];
        }
      }
    }
    Requests.post('/ps_event', payload);
    if (event in vwoEvent) {
      trackEvent(vwoEvent[event]);
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        event,
      });
    }
  },
};

export default Events;
