import React from 'react';
import YC from '../../controllers/yc';

// This is an invisible component meant to auth shops and redirect only

const initialState = {};

class YCLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    YC.login();
  }

  render() {
    return <div />;
  }
}

export default YCLogin;
