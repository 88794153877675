import {
  LEGACY_ROLES,
  PERMISSIONS,
} from 'components/account/AccountView/users/constants';
import {
  userHasLegacyRole,
  userHasPermission,
} from 'components/account/AccountView/users/helpers';
import Robot from 'img/robot.svg';
import { User } from 'types';
import UserIcon from '../components/UserIcon';
import { SYSTEM_EVENT_SALES_AI_SUBSTRING, VIEW_OPTIONS } from '../constants';
import { Message } from '../types';

export const getMaxViewLevelForUser = (user: Partial<User>): string => {
  if (userHasLegacyRole(LEGACY_ROLES.AI_ALPHA_TESTER)) {
    return VIEW_OPTIONS.AI_DEBUGGER;
  }

  if (
    user.is_admin ||
    userHasPermission(PERMISSIONS.limit_sms_sales_agent_access)
  ) {
    return VIEW_OPTIONS.SALES_ASSOCIATE;
  }

  return VIEW_OPTIONS.MERCHANT;
};

export const canViewSystemMessage = (
  message: Partial<Message>,
  view: string,
): boolean => {
  if (view === VIEW_OPTIONS.AI_DEBUGGER) return true;
  if (view === VIEW_OPTIONS.MERCHANT) return false;

  return (
    view === VIEW_OPTIONS.SALES_ASSOCIATE &&
    !message?.type?.includes(SYSTEM_EVENT_SALES_AI_SUBSTRING)
  );
};

interface GetAvatarInput {
  message: Message;
  shopName?: string;
  currentView: string;
  id: string;
}

interface GetAvatarInput {
  message: Message;
  currentView: string;
  shopName?: string;
  id: string;
}

export const getAvatar = ({
  message,
  currentView,
  shopName,
  id,
}: GetAvatarInput): JSX.Element => {
  if (message.user_first_name && message.user_last_name) {
    return (
      <UserIcon
        color="var(--color-message-text-outbound)"
        title={`${message.user_first_name} ${message.user_last_name}`}
        display={`${message.user_first_name[0]}${message.user_last_name}`}
        backgroundColor="var(--color-message-bubble-outbound)"
        image={message.user_avatar_url}
        id={id}
      />
    );
  }

  const avatarImage =
    [VIEW_OPTIONS.AI_DEBUGGER, VIEW_OPTIONS.SALES_ASSOCIATE].includes(
      currentView,
    ) && message.is_sent_by_salesbub
      ? Robot
      : undefined;
  return (
    <UserIcon
      title={shopName}
      display={shopName?.[0].toUpperCase()}
      color="var(--color-message-text-system)"
      backgroundColor="var(--color-message-bubble-system)"
      image={avatarImage}
      id={id}
      isShop
    />
  );
};
