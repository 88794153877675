import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useUser } from 'controllers/contexts/user';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BANNER_TYPES } from 'utils/events';

const bannerId = 'add-call-forwarding-number-banner';

const LEARN_MORE_URL =
  'https://help.postscript.io/hc/en-us/articles/17052127565083';
const SETTINGS_URL = '/account/settings/compliance';

export default function CallForwardingBanner(): null {
  const { addBanner, removeBanner } = useBanners();
  const { push } = useHistory();
  const {
    user: {
      customer_support_phone_number: customerSupportPhone,
      installed_at: installedAt,
    },
  } = useUser();

  // Call forwarding banner should only be displayed to shops installed after November 10th, 2023
  const callForwardingRequiredAfterDate = DateTime.fromISO('2023-11-10');
  const installDate = DateTime.fromISO(installedAt);
  const dateDiff = installDate.diff(
    callForwardingRequiredAfterDate,
    'days',
  ).days;
  const isExemptByInstallDate = dateDiff < 0;

  const addCallForwardingBanner = () => {
    addBanner({
      id: bannerId,
      isDismissable: false,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.CALL_FORWARDING,
      },
      data: {
        variant: 'warning',
        heading: 'Compliance update action required',
        bodyText:
          'Add a Customer Contact Phone Number to your account to meet call-forwarding regulations.',
        secondaryAction: {
          text: 'Learn More',
          onClick: () => window.open(LEARN_MORE_URL, '_blank'),
        },
        primaryAction: {
          text: 'Update Now',
          onClick: () => push(SETTINGS_URL),
        },
      },
    });
  };

  useEffect(() => {
    if (customerSupportPhone || isExemptByInstallDate) {
      return removeBanner(bannerId);
    }

    addCallForwardingBanner();
  }, [customerSupportPhone]);

  return null;
}
