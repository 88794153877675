// eslint-disable-next-line import/no-extraneous-dependencies
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { api } from 'controllers/network/apiClient';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const IFrameWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  id: number;
}

export default function LookerDashboard({ id }: Props): JSX.Element {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      if (!element.current) return;

      const { url } = await api.get(
        `/v2/analytics/looker/signed_url?dashboard_id=${id}`,
      );

      LookerEmbedSDK.createDashboardWithUrl(url)
        .appendTo(element.current)
        .build()
        .connect();
    })();
  }, [element, id]);

  return (
    <IFrameWrapper>
      <div ref={element} />
    </IFrameWrapper>
  );
}
