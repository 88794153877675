import {
  Button,
  Card,
  Emoji,
  EmojiSet,
  EmptyState,
  Layout,
} from '@postscript/components';
import { captureException } from '@sentry/browser';
import React from 'react';
import styled from 'styled-components';
import { ProductAreas } from 'utils/events';
import { logErrorBoundaryError } from './eventUtils';

export const ERROR_DESCRIPTION = 'Refresh the page or try again later.';
export const ERROR_HEADING = 'Something went wrong';
export const ERROR_BUTTON_TEXT = 'Refresh Page';

const StyledEmoji = styled(Emoji)`
  margin-bottom: var(--spacing-3);
`;

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledLayout = styled(Layout)`
  height: 100%;
`;

interface ErrorBoundaryProps {
  children: React.ReactNode;
  productArea: ProductAreas;
  fallback?: React.ReactNode;
  location?: string;
  secondaryErrorPage?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<ErrorBoundaryProps> = {
    secondaryErrorPage: true,
  } as Partial<ErrorBoundaryProps>;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: unknown): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: unknown): void {
    const { location, productArea } = this.props;
    logErrorBoundaryError({ error, location, productArea });
    captureException(error);
  }

  static handleClick(): void {
    window.location.reload();
  }

  render(): React.ReactNode {
    const { children, fallback, secondaryErrorPage } = this.props;
    const { hasError } = this.state;

    const secondaryErrorPageComponent = (
      <Card>
        <EmptyState
          description={ERROR_DESCRIPTION}
          heading={ERROR_HEADING}
          size="medium"
        >
          <Button
            size="medium"
            variant="secondary"
            onClick={ErrorBoundary.handleClick}
          >
            {ERROR_BUTTON_TEXT}
          </Button>
        </EmptyState>
      </Card>
    );

    const primaryErrorPageComponent = (
      <StyledLayout align="center" valign="center" vertical>
        <StyledEmoji component={EmojiSet.ThinkingFace} size={54} />
        <EmptyState
          description={ERROR_DESCRIPTION}
          heading={ERROR_HEADING}
          primaryAction={{
            onClick: ErrorBoundary.handleClick,
            text: ERROR_BUTTON_TEXT,
            variant: 'secondary',
          }}
          size="medium"
        />
      </StyledLayout>
    );

    const renderErrorPageComponent = secondaryErrorPage
      ? secondaryErrorPageComponent
      : primaryErrorPageComponent;

    return hasError ? (
      <StyledDiv role="alert">{fallback ?? renderErrorPageComponent}</StyledDiv>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
