import {
  PAYMENT_PROVIDERS,
  RAC_STATUSES,
} from 'components/billing/common/constants';
import { useGetPaymentMethod } from 'components/billing/context/useBilling';

export const useCanPayInvoice = () => {
  const { data: paymentMethodData } = useGetPaymentMethod();
  const { card, paymentProvider, usBankAccount, recurringApplicationCharge } =
    paymentMethodData ?? {};

  if (paymentProvider === PAYMENT_PROVIDERS.STRIPE) {
    return Boolean(card ?? usBankAccount);
  }

  return Boolean(recurringApplicationCharge?.status === RAC_STATUSES.ACTIVE);
};
