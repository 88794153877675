/* eslint-disable @typescript-eslint/no-empty-function */
import { Replay } from '@sentry/browser';
import { createContext, useContext } from 'react';

interface SentryAPI {
  replay: Replay;
}

export const SentryContext = createContext<SentryAPI | null>(null);

const useSentry = () => {
  return useContext(SentryContext);
};

export default useSentry;
