import { Button } from '@postscript/components';
import PageHeader from 'components/layout/PageHeader';
import { useQueryClient } from 'react-query';
import {
  SESSION_DASHBOARD_QUERY,
  useSessionDashboard,
} from '../hooks/useDashboard';
import SessionsList from './SessionsList';

const SessionDashboard = (): JSX.Element => {
  const queryClient = useQueryClient();
  const refreshSessionData = () => {
    queryClient.invalidateQueries(SESSION_DASHBOARD_QUERY);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const timeFilter = searchParams.get('datetime') ?? '';
  const { data, isLoading } = useSessionDashboard(timeFilter);

  return (
    <div>
      <PageHeader
        pageTitle="Agent Sessions"
        description="View agent session history"
        actions={<Button onClick={() => refreshSessionData()}>Refresh</Button>}
      />
      {data && (
        <SessionsList
          data={data}
          isLoading={isLoading}
          timeFilter={timeFilter}
        />
      )}
    </div>
  );
};

export default SessionDashboard;
