import {
  Banner,
  Button,
  IconSet,
  Layout,
  SelectMenu,
} from '@postscript/components';
import { userHasLegacyRole } from 'components/account/AccountView/users/helpers';
import {
  OMNI_SANDBOX,
  OMNI_TRENDS,
} from 'components/admin/utils/feature-flags';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import PageHeader from 'components/layout/PageHeader';
import { useUser } from 'controllers/contexts/user';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useParseLabsFeature from './hooks/useParseLabsFeature';
import OmniDashboard from './OmniDashboard';
import OmniDashboardsSettingsModal from './OmniDashboardsSettingsModal';

export type FeatureKey = typeof OMNI_SANDBOX | typeof OMNI_TRENDS;

export interface Dashboard {
  value: string;
  label: string;
}

const StyledBanner = styled(Banner)`
  margin-bottom: var(--spacing-3);
`;

interface Props {
  title: string;
  featureKey: FeatureKey;
  adminOnly?: boolean;
}

export default function OmniDashboards({
  title,
  featureKey,
  adminOnly = false,
}: Props): JSX.Element | null {
  const { dashboards } = useParseLabsFeature(featureKey);
  const { search: searchQuery } = useLocation();
  const queryParams = new URLSearchParams(searchQuery);
  const [dashboardID, setDashboardID] = useState(queryParams.get('id') ?? '');
  const {
    user: { is_admin: isAdmin },
  } = useUser();
  const { showModal } = useGlobalModal();

  useEffect(() => {
    if (dashboardID || !dashboards.length) return;
    setDashboardID(dashboards[0].value);
  }, [dashboards]);

  useEffect(() => {
    if (!dashboardID) return;

    queryParams.set('id', dashboardID);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${queryParams}`,
    );
  }, [dashboardID]);

  if (!isAdmin && adminOnly) return null;

  return (
    <>
      <PageHeader
        pageTitle={title}
        actions={
          <Layout valign="bottom">
            {userHasLegacyRole('EMBEDDED_ANALYTICS_ADMIN') && (
              <Button
                variant="text"
                monochrome
                icon={IconSet.Settings2}
                onClick={() => {
                  showModal({
                    children: (
                      <OmniDashboardsSettingsModal featureKey={featureKey} />
                    ),
                  });
                }}
              />
            )}
            <SelectMenu
              fieldWidth={250}
              label="Dashboard"
              options={dashboards}
              onChange={({ value }: any) => {
                setDashboardID(value);
              }}
              value={dashboards.find(({ value }) => value === dashboardID)}
              disabled={!dashboards.length}
              isClearable={false}
            />
          </Layout>
        }
      />
      {adminOnly && (
        <StyledBanner bodyText="This page is for internal testing only. Do not share this URL with anyone outside Postscript." />
      )}
      {dashboardID && <OmniDashboard id={dashboardID} />}
    </>
  );
}
