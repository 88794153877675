import cookie from 'react-cookies';
import { useRouteMatch } from 'react-router-dom';

const ReferralRedirect = () => {
  // Sets a referral cookie and then redirects the user to the marketing install page.

  const {
    params: { id },
  } = useRouteMatch('/ref/:id');
  cookie.save('ps_ref', id, {
    path: '/',
    domain: 'postscript.io',
  });
  window.location = 'https://www.postscript.io/install/';
  return '';
};

export default ReferralRedirect;
