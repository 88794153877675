import { FieldError, FieldLabel, FieldMessage } from '@postscript/components';
import DropdownIndicator from '@postscript/components/dist/esm/components/form/SelectMenu/Components/DropdownIndicator';
import { CLASS_NAME_PREFIX } from '@postscript/components/dist/esm/components/form/SelectMenu/constants';
import { getSharedStyles } from '@postscript/components/dist/esm/components/form/SelectMenu/style';
import { FlowTypes } from 'components/flowBuilder/constants';
import { DynamicAction } from 'components/flowBuilder/types/actions';
import { FlowSelectionDynamicField } from 'components/flowBuilder/types/dynamicFields';
import { formatErrorMessage } from 'components/flowBuilder/utils/errors';
import {
  fetchDetailedFlows,
  fetchFlow,
} from 'components/flowBuilder/utils/flows';
import { useField } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import * as Yup from 'yup';

const EMPTY_VALUE = null;
const DEBOUNCE_WAIT = 200;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${() =>
    getSharedStyles({
      error: false,
      fieldSize: 'small',
      fieldWidth: 'full',
      isCreatable: false,
      isMulti: false,
      isScrollable: false,
      menuMaxVisible: 10,
      menuPlacement: 'auto',
      menuWidth: 'full',
    })}
`;

const getComponents = () => {
  return {
    DropdownIndicator: (props: any) => (
      <DropdownIndicator {...props} selectProps={{ fieldSize: 'small' }} />
    ),
    IndicatorSeparator: null,
  };
};

const isFlowOption = (option: unknown): option is FlowOption => {
  const optionValue = option as FlowOption;

  if (!optionValue || typeof optionValue !== 'object') return false;
  if (!optionValue.label || typeof optionValue.label !== 'string') return false;
  if (!optionValue.value || typeof optionValue.value !== 'string') return false;

  return true;
};

interface FlowOption {
  label: string;
  value: string;
}

const loadOptions = debounce(
  (inputValue: string, callback: (options: FlowOption[]) => void) => {
    fetchDetailedFlows({
      name: inputValue,
      type: FlowTypes.AUTOMATION,
      triggerEvent: 'AddedViaAnotherFlow',
    }).then((detailedFlows) => {
      const options = detailedFlows.map((detailedFlow) => ({
        label: detailedFlow.name,
        value: detailedFlow.flow_collection_guid,
      }));
      callback(options);
    });
  },
  DEBOUNCE_WAIT,
);

export const flowIdParamSchema = Yup.string()
  .required('Please select a flow')
  .nullable();

interface FlowSelectionFieldProps {
  action: DynamicAction;
  field: FlowSelectionDynamicField;
}

const FlowSelectionField = ({
  action,
  field,
}: FlowSelectionFieldProps): JSX.Element => {
  const [formikField, meta, helpers] = useField<string | null>({
    name: field.options.paramName,
    validate: (value) => {
      try {
        flowIdParamSchema.validateSync(value);
      } catch (e) {
        return formatErrorMessage(e);
      }
    },
  });

  const [selectedFlowOption, setSelectedFlowOption] =
    useState<FlowOption | null>(EMPTY_VALUE);

  useEffect(() => {
    const initialFlowIdParamValue = action.params[field.options.paramName];

    if (!flowIdParamSchema.isValidSync(initialFlowIdParamValue)) {
      helpers.setValue(EMPTY_VALUE);
      return;
    }

    fetchFlow(initialFlowIdParamValue)
      .then((flow) => {
        helpers.setValue(initialFlowIdParamValue);
        setSelectedFlowOption({
          label: flow.name,
          value: flow.guid,
        });
      })
      .catch(() => {
        helpers.setValue(EMPTY_VALUE);
      });
  }, []);

  const showError = meta.error && meta.touched;

  return (
    <div>
      <FieldLabel
        id={`${formikField.name}-label`}
        fieldSize="small"
        htmlFor={formikField.name}
      >
        {field.options.label}
      </FieldLabel>
      <StyledAsyncSelect
        aria-label={field.options.label}
        aria-labelledby={`${formikField.name}-label`}
        cacheOptions
        classNamePrefix={CLASS_NAME_PREFIX}
        components={getComponents()}
        defaultOptions
        id={formikField.name}
        loadOptions={loadOptions}
        name={formikField.name}
        onChange={(option: FlowOption | null) => {
          if (isFlowOption(option)) {
            helpers.setValue(option.value);
            setSelectedFlowOption(option);
          }
        }}
        placeholder="Search or select option"
        value={selectedFlowOption ?? EMPTY_VALUE}
      />
      {showError && (
        <FieldError fieldSize="small" htmlFor={formikField.name}>
          {meta.error}
        </FieldError>
      )}
      {field.options.message && !showError && (
        <FieldMessage fieldSize="small" htmlFor={formikField.name}>
          {field.options.message}
        </FieldMessage>
      )}
    </div>
  );
};

export default FlowSelectionField;
