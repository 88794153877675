import { Integration } from 'components/integrations/types/integrationTypes';
import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryResult } from 'react-query';

export const useIntegrations = (): UseQueryResult<Integration[]> => {
  return useQuery(['integrations'], async () => {
    const data = await api.get('/v2/integrations');
    return data.integrations;
  });
};

export default useIntegrations;
