/* eslint-disable no-extend-native */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { Promise as BPromise } from 'bluebird';

// it's a good idea to pre-seed the array with Promise.resolve(SOME_INITIAL_VALUE) otherwise the first
// call will have an `undefined` argument
Promise.syncAll = function (arr) {
  // first, assure the incoming argument is an array
  if (!Array.isArray(arr)) {
    arr = arr ? [arr] : [];
  }
  // now, synchronously loop through and assure that all calls are
  // resolved and rejected as a promise
  let promise = BPromise.resolve().bind({
    next: arr ? [...arr] : [],
  }); // we'll consider this an empty "factory" for typing convenience
  for (const _ of arr) {
    promise = promise.then(function (data) {
      const a = this.next.shift();
      // let's do some type checking
      if (a instanceof Promise) {
        return a;
      }
      // if it's a function, it needs to be called
      if (typeof a === 'function') {
        // so that we can resolve/reject properly, we'll need to wrap the call in a try/catch
        try {
          const result = a(data);
          return BPromise.resolve(result);
        } catch (e) {
          return BPromise.reject(e);
        }
      }
      // if it's just an object, we'll reject an error or resolve
      return a instanceof Error ? BPromise.reject(a) : BPromise.resolve(a);
    });
  }
  return promise;
};

// BE SURE TO TREAT THESE AS POLLYFILLS AND DO "EXISTS" CHECKS

// break up an array into chunks of a specific size
if (!Array.prototype.chunk) {
  Object.defineProperty(Array.prototype, 'chunk', {
    value(n) {
      return Array.from(Array(Math.ceil(this.length / n)), (_, i) =>
        this.slice(i * n, i * n + n),
      );
    },
  });
}

// break up an array into chunks of a specific size
if (!Array.sanitize) {
  Object.defineProperty(Array, 'sanitize', {
    value(val, explicit = false) {
      // keep nulls/undefines
      if (!explicit && !val) return val;
      // if this is an array, we filter nulls/undefines
      if (Array.isArray(val))
        val = (!explicit && val) || val.filter((v) => !!v);
      // not an array but not null/undefined, so we need to wrap it
      else if (val) val = [val];
      // null/undefined
      else return [];
      // we filter nulls/undefines and return
      return (!explicit && val) || val.filter((v) => !!v);
    },
  });
}

// check if an object is a promise
if (!Promise.isPromise) {
  Object.defineProperty(Promise, 'isPromise', {
    value(p) {
      return (
        p &&
        (p instanceof Promise ||
          (typeof p === 'object' && 'then' in p && 'catch' in p))
      );
    },
  });
}

// check if an object is iterable
if (!Object.isIterable) {
  Object.defineProperty(Object, 'isIterable', {
    value(o) {
      // if it's an array, it's guaranteed iterable
      if (Array.isArray(o)) return true;
      // if it has an iterator, it's guaranteed iterable
      if (o != null && typeof o[Symbol.iterator] === 'function') return true;
      // if it's a dictionary (object of non-null and non Date), it's iterable
      if (typeof o === 'object' && o !== null && !(o instanceof Date))
        return true;
      // otherwise, it's non-iterable (including strings)
      return false;
    },
  });
}

// filter out a dictionary object
if (!Object.prototype.filterKV) {
  Object.defineProperty(Object.prototype, 'filterKV', {
    value(cb) {
      if (!Object.isIterable(this)) return this;
      return Object.keys(this).reduce((res, cur) => {
        if (cb(cur, this[cur])) res[cur] = this[cur];
        return res;
      }, {});
    },
  });
}

// checks if a key-value object is empty
if (!Object.prototype.cloneWith) {
  Object.defineProperty(Object.prototype, 'cloneWith', {
    value(values) {
      values = values || {};
      return { ...this, ...values };
    },
  });
}

// Find all values or a subset of values from the browser storage storage
if (!Storage.prototype.find) {
  Object.defineProperty(Storage.prototype, 'find', {
    value(startsWith = null) {
      return Object.keys(this).reduce((obj, key) => {
        if (!startsWith || key.startsWith(startsWith)) {
          obj[key] = this.getItem(key);
        }
        return obj;
      }, {});
    },
  });
}

// Formats a number as currency
if (!Number.prototype.formatCurrency) {
  Object.defineProperty(Number.prototype, 'formatCurrency', {
    value({ separator = ',', symbol = '$' }) {
      const [dollars, cents] = (this / 100).toFixed(2).toString().split('.');

      const formattedDollars = `${dollars
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)}`;

      return `${symbol}${formattedDollars}.${cents}`;
    },
  });
}

// Formats a number with separators
if (!Number.prototype.formatWithSeparators) {
  Object.defineProperty(Number.prototype, 'formatWithSeparators', {
    value(separator = ',') {
      const [integer, decimal] = this.toString().split('.');

      const formattedNum = `${integer
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)}`;

      return decimal ? `${formattedNum}.${decimal}` : formattedNum;
    },
  });
}
