import Helpers from 'controllers/helpers';

const parseMessagesForPreview = (text: string): string => {
  if (!text) {
    return '';
  }

  let messageText = text;

  // Replace URLs with links
  const { urlRegex } = Helpers;
  urlRegex.lastIndex = 0;
  const msgMatchArray = messageText.match(urlRegex);
  if (msgMatchArray) {
    let offset = 0;
    msgMatchArray.forEach((url) => {
      const index = messageText.indexOf(url, offset);
      const anchor = `<a href="${url}" target="_blank">${url}</a>`;
      messageText =
        messageText.substring(0, index) +
        anchor +
        messageText.substring(index + url.length);
      offset = index + anchor.length;
    });
  }

  // Replace linebreaks with <br>
  messageText = messageText.replace(/\n/g, '<br>');

  return messageText;
};

export default parseMessagesForPreview;
