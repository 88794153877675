/* eslint-disable max-classes-per-file */
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import { userHasPermission } from 'components/account/AccountView/users/helpers';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import { lazyLoadWithChunkRefresh } from 'utils/lazyLoadWithChunkRefresh';
import AgentDashboard from './components/AgentDashboard';
import SessionDashboard from './components/SessionsDashboard';
import ShopDashboard from './components/ShopDashboard';
import ToggleAgentActionRecording from './components/ToggleAgentActionRecording';
import { ViewSelector } from './components/ViewSelector';
import { VIEW_OPTIONS } from './constants';
import ViewContext from './hooks/useView';
import ConversationalInsights from './pages/ConversationalInsights';
import ConversationIntelligence from './pages/ConversationIntelligence';

interface TitleRouteProps extends RouteProps {
  title?: string;
}

const TitleRoute: React.FC<TitleRouteProps> = ({ title, ...rest }) => {
  return (
    <>
      <Helmet>
        <title>{title || 'Postscript'}</title>
      </Helmet>
      <Route {...rest} />
    </>
  );
};

const MyProspectsInbox = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./pages/MyProspectsInbox'),
    'MyProspectsInbox',
  ),
);

const AllProspectsInbox = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./pages/AllProspectsInbox'),
    'AllProspectsInbox',
  ),
);

const Admin = React.lazy(() =>
  lazyLoadWithChunkRefresh(() => import('./components/Admin'), 'Admin'),
);

export const SALES_ROUTE_PREFIX = '/workspace';

const AIDebuggerToolsWrapper = styled.div`
  position: absolute;
  top: 13px;
  z-index: 101;
  right: 54px;
  display: flex;
  align-items: center;
`;

const Routes = ({ match }: RouteComponentProps): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    // Callback to handle route changes
    const handleRouteChange = (location: Location) => {
      // If the location is outside the routes managed by this sub-app, reset the title
      if (!location.pathname.startsWith(match.url)) {
        document.title = 'Postscript';
      }
    };

    // Add the listener
    const unlisten = history.listen(handleRouteChange);

    // Cleanup: remove the listener when component is unmounted
    return () => {
      unlisten();
    };
  }, [history, match.url]);

  const {
    user: { is_admin: isAdmin },
  } = useUser();
  const [view, setView] = useState(
    localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ||
      VIEW_OPTIONS.MERCHANT,
  );

  // Do not allow access to non-admins or non-sales agents
  if (
    !userHasPermission(PERMISSIONS.limit_sms_sales_agent_access) &&
    !isAdmin
  ) {
    return <Redirect to="/" />;
  }

  const content = (
    <>
      <AIDebuggerToolsWrapper>
        <ToggleAgentActionRecording />
        <div style={{ marginLeft: 'var(--spacing-2)' }}>
          <ViewSelector />
        </div>
      </AIDebuggerToolsWrapper>
      <Switch>
        <TitleRoute
          path={`${match.url}/inbox/:id?`}
          exact
          component={MyProspectsInbox}
          title="(0) My Prospects - Postscript"
        />
        <TitleRoute
          path={`${match.url}/all-prospects/:id?`}
          exact
          component={AllProspectsInbox}
          title="(0) All Prospects - Postscript"
        />
        <TitleRoute
          path={`${match.url}/agent-activity`}
          exact
          component={AgentDashboard}
          title="(0) Agent Activity - Postscript"
        />
        <TitleRoute
          path={`${match.url}/shop-activity`}
          exact
          component={ShopDashboard}
          title="(0) Shop Activity - Postscript"
        />
        <TitleRoute
          path={`${match.url}/session-history`}
          exact
          component={SessionDashboard}
          title="Agent Sessions - Postscript"
        />
        <TitleRoute
          path={`${match.url}/admin`}
          exact
          component={Admin}
          title="Admin - Postscript "
        />
        <TitleRoute
          path={`${match.url}/conversations/:id?`}
          exact
          component={ConversationalInsights}
          title="Conversations - Postscript"
        />
        <TitleRoute
          path={`${match.url}/conversation-intelligence/:id?`}
          exact
          component={ConversationIntelligence}
          title="Conversation Intelligence - Postscript"
        />
        <Redirect to={`${match.url}/inbox`} />
      </Switch>
    </>
  );

  return (
    <ViewContext.Provider value={{ view, setView }}>
      {content}
    </ViewContext.Provider>
  );
};

export default withRouter(Routes);
