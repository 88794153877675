import styled from 'styled-components';

/* TODO(Kameron Ahler): EditorCard does something very similar to this component
and should probably be combined in some. It's probably better that the grid stay
independent. A child grid item that could be full width may help too (maybe). */

const EditorFormGrid = styled.div`
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

export default EditorFormGrid;
