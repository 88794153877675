import $ from 'jquery';
import cookie from 'react-cookies';
import Constants from '../constants';
import HeaderUtils from './header_utils';
import Token from './tokens';

const { apiHost: host, internalApiHost } = Constants;

const EBS_TRAFFIC_PERCENTAGE = 1;

const getHost = (path) => {
  // TODO: Fix non-ebs compliant endpoints on the backend
  const blacklistedPaths = ['campaigns/send'];

  if (
    Math.random() <= EBS_TRAFFIC_PERCENTAGE &&
    blacklistedPaths.every((item) => !path.includes(item))
  ) {
    return internalApiHost;
  }

  return host;
};

const paramStringFromObject = (params) => {
  const searchParams = new URLSearchParams();

  Object.keys(params).map((key) => searchParams.set(key, params[key]));

  return `?${searchParams.toString()}`;
};

const Requests = {
  get(path, params) {
    const requestHost = getHost(path);

    const paramString = params ? paramStringFromObject(params) : '';

    const promise = new Promise((resolve) => {
      $.ajax({
        url: `${requestHost}${path}${paramString}`,
        type: 'GET',
        headers: HeaderUtils.createHeadersAccess(),
        xhrFields: { withCredentials: true },
        tryCount: 0,
        retryLimit: 2,
        timeout: 30000,
        success(data) {
          resolve(data);
        },
        error(result) {
          if (
            result &&
            [401, 422].indexOf(result.status) > -1 &&
            cookie.load('refresh_token')
          ) {
            Token.refreshAccessToken().then(() => {
              this.headers = HeaderUtils.createHeadersAccess();
              this.tryCount += 1;
              if (this.tryCount <= this.retryLimit) {
                $.ajax(this);
              } else {
                resolve(false);
              }
            });
          } else {
            resolve(false);
          }
        },
      });
    });
    return promise;
  },
  post(path, data, refreshHeaders, method = 'POST', rejectErrors = false) {
    const promise = new Promise((resolve, reject) => {
      const requestHost = getHost(path);

      const headers = refreshHeaders
        ? HeaderUtils.createHeadersRefresh()
        : HeaderUtils.createHeadersAccess();

      $.ajax({
        url: `${requestHost}${path}`,
        type: method,
        headers,
        // async: false,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(data),
        tryCount: 0,
        retryLimit: 2,
        success(data) {
          resolve(data);
        },
        error: async (result) => {
          if (
            result &&
            [401, 422].indexOf(result.status) > -1 &&
            cookie.load('refresh_token')
          ) {
            if (path === '/token/refresh') {
              cookie.remove('refresh_token');
              cookie.remove('access_token');
              resolve(false);
              // window.location.replace('/login');
              return;
            }
            await Token.refreshAccessToken();

            this.tryCount += 1;

            this.headers = HeaderUtils.createHeadersAccess();
            if (this.tryCount <= this.retryLimit) {
              $.ajax(this);
            } else {
              resolve(false);
            }
          } else if (result.status === 400) {
            if (rejectErrors) {
              const { errors } = result.responseJSON;
              return reject(errors);
            }

            return resolve(false);
          } else {
            resolve(false);
          }
        },
      });
    });
    return promise;
  },
  patch(path, data, refreshHeaders, rejectErrors = false) {
    return Requests.post(path, data, refreshHeaders, 'PATCH', rejectErrors);
  },
  put(path, data, refreshHeaders, rejectErrors = false) {
    return Requests.post(path, data, refreshHeaders, 'PUT', rejectErrors);
  },
  delete(path, data, refreshHeaders, rejectErrors = false) {
    return Requests.post(path, data, refreshHeaders, 'DELETE', rejectErrors);
  },
  postReject(path, data, refreshHeaders) {
    // Totally temporary... remove me when networking is fixed
    return Requests.post(path, data, refreshHeaders, 'POST', true);
  },
  postFormData(path, data, refreshHeaders) {
    const promise = new Promise((resolve) => {
      let headers;

      const requestHost = getHost(path);

      if (refreshHeaders) {
        headers = HeaderUtils.createHeadersRefresh();
      } else {
        headers = HeaderUtils.createHeadersAccess();
      }
      $.ajax({
        url: `${requestHost}${path}`,
        type: 'POST',
        headers,
        async: false,
        processData: false,
        contentType: false,
        data,
        tryCount: 0,
        retryLimit: 2,
        success(data) {
          resolve(data);
        },
        error(result) {
          if (
            result &&
            [401, 422].indexOf(result.status) > -1 &&
            cookie.load('refresh_token')
          ) {
            if (path === '/token/refresh') {
              cookie.remove('refresh_token');
              cookie.remove('access_token');
              // window.location.replace('/login');
              return;
            }
            Token.refreshAccessToken().then(() => {
              this.headers = HeaderUtils.createHeadersAccess();
              this.tryCount += 1;
              if (this.tryCount <= this.retryLimit) {
                $.ajax(this);
              }
            });
          } else {
            console.log('API ERROR', JSON.stringify(result));
            resolve(false);
          }
        },
      });
    });
    return promise;
  },
};

export default Requests;
export { host };
