import AnnouncementBanner from 'components/admin/Announcements/AnnouncementBanner';
import {
  logAnnouncementCTAClicked,
  logAnnouncementDismissed,
} from 'components/admin/Announcements/eventUtil';
import { AnnouncementType } from 'components/admin/Announcements/utils';
import React from 'react';
import {
  Banner as BannerInterface,
  useLegacyBanners,
} from '../../controllers/contexts/legacyGlobalBanners';

export const ConvertedBanner = ({
  id,
  data: { closeable, color, message, primaryAction },
}: BannerInterface): JSX.Element => {
  const { removeBanner } = useLegacyBanners();
  const primaryActionCallback = primaryAction?.onClick;

  return (
    <AnnouncementBanner
      bodyText={message}
      color={color}
      key={`banner-${id}`}
      id={id}
      data-cy={`banner-id-${id}`}
      onDismiss={
        closeable
          ? () => {
              logAnnouncementDismissed({
                bannerContent: message,
                id,
              });
              removeBanner(id);
            }
          : undefined
      }
      primaryAction={
        primaryActionCallback
          ? {
              ...primaryAction,
              onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
                logAnnouncementCTAClicked({
                  bannerContent: message,
                  event,
                  id,
                });
                primaryActionCallback(event);
              },
            }
          : primaryAction
      }
    />
  );
};

/* END LEGACY BANNER */

const BANNERS_LIMIT = 3;

const LegacyGlobalBannerList = (): JSX.Element => {
  const { banners } = useLegacyBanners();
  const filteredAppTypeBanners = banners.filter(
    (banner) =>
      !banner.data.announcementType ||
      banner.data.announcementType === AnnouncementType.APP_WIDE,
  );

  return (
    <>
      {filteredAppTypeBanners.slice(0, BANNERS_LIMIT).map((banner) => {
        return <ConvertedBanner key={`banner-${banner.id}`} {...banner} />;
      })}
    </>
  );
};

export default LegacyGlobalBannerList;
