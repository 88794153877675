/* eslint-disable no-unused-vars */
// History context.

import { createContext } from 'react';

const HistoryContext = createContext({
  push: (_) => {},
});

export default HistoryContext;
