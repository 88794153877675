import { Layout } from '@postscript/components';
import { LayoutProps } from '@postscript/components/dist/esm/components/basic/Layout/Layout';
import styled from 'styled-components';

const StyledLayout = styled(Layout).attrs({
  vertical: true,
})`
  & > div,
  // Supports nested Layout component for side-by-side fields
  & > div > div:has(input) {
    width: 100%;
  }

  button[type='submit']:not(.modal__footer button[type='submit']) {
    margin-top: var(--spacing-2);
  }
`;

interface Props extends LayoutProps {
  children: React.ReactNode;
  vertical?: true; // Layout doesn't want a boolean for vertical for some reason; this should be fixed
}

export default function FormLayout({
  children,
  ...props
}: Readonly<Props>): JSX.Element {
  return <StyledLayout {...props}>{children}</StyledLayout>;
}
