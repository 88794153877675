import { useGetUsageStats } from 'components/billing/context/useBilling';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export default function useGetUsageStatsTodaySum(): number {
  const [amount, setAmount] = useState(0);
  const { data: usageStatsToday, isLoading: loadingUsageStatsToday } =
    useGetUsageStats({
      startDate: DateTime.now().toUTC().toISODate(),
      endDate: DateTime.now().toUTC().toISODate(),
    });

  useEffect(() => {
    if (loadingUsageStatsToday) {
      return;
    }

    const usageStatsTodaySum =
      usageStatsToday?.reduce(
        (acc, { totalAmountInCents }) => acc + totalAmountInCents,
        0,
      ) || 0;

    setAmount(usageStatsTodaySum);
  }, [loadingUsageStatsToday]);

  return amount;
}
