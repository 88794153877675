import { Badge } from '@postscript/components';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  NUMBER_OF_UNRESOLVED_RESPONSES_QUERY_KEY,
  useResponses,
} from './hooks/useResponses';

export interface Props {
  isActive?: boolean;
}

const Notification = styled.div<Props>`
  border-radius: 50%;
  background-color: var(--red-core);
  ${({ isActive }) =>
    !isActive &&
    css`
      position: absolute;
      width: 6px;
      height: 6px;
      bottom: 14px;
      left: 21px;
      z-index: 1;
      border: 1px solid var(--gray-1);
    `}
`;

const ResponsesNumberBadge = styled(Badge)`
  margin-left: var(--spacing-1);
  align-self: center;
`;

const UnresolvedNotificationsBadge =
  (isMessages: boolean): React.FunctionComponent<Props> =>
  ({ isActive }: Props): JSX.Element | null => {
    const { data } = useResponses(
      {
        includeSubscribers: true,
        unresolved: true,
        includeOptedOutSubscriber: false,
        filter_last_30: true,
      },
      NUMBER_OF_UNRESOLVED_RESPONSES_QUERY_KEY,
    );

    const numberOfResponses = data?.totalResults || 0;
    if (numberOfResponses <= 0) {
      return null;
    }

    if (!isMessages) {
      return (
        <ResponsesNumberBadge
          size="small"
          square
          variant="error"
          aria-label="There are unresolved notifications"
        >
          {numberOfResponses > 99 ? '99+' : numberOfResponses.toString()}
        </ResponsesNumberBadge>
      );
    }

    return (
      <Notification
        className="notification-badge"
        aria-label="There are unresolved notifications"
        isActive={isActive}
      />
    );
  };

export default UnresolvedNotificationsBadge;
