import {
  BodyText,
  Button,
  Checkbox,
  ModalFooter,
  ModalHeader,
  toast,
} from '@postscript/components';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { useState } from 'react';
import styled from 'styled-components';
import { useSkipTFNValidation } from './hooks/useTFNVerification';

const StyledList = styled.ul`
  margin: var(--spacing-1) 0 var(--spacing-3) var(--spacing-4);
`;

export default function TNFVerificationSubmissionModal(): JSX.Element {
  const [confirmed, setConfirmed] = useState(false);
  const { hideModal } = useGlobalModal();
  const { mutateAsync: skipTFNValidation } = useSkipTFNValidation();

  return (
    <>
      <ModalHeader onCancel={hideModal}>Retry TFN verification</ModalHeader>
      <BodyText color="var(--text-color)">
        There was a problem submitting your verification request. Before
        submitting again, please ensure the following:
      </BodyText>
      <StyledList>
        <li>your custom domain is set up correctly with your Shopify shop</li>
        <li>your shop is not password protected</li>
        <li>
          your calalog is updated and you have multiple products available for
          purchase
        </li>
        <li>the address in your Shopify account is complete and correct</li>
      </StyledList>
      <Checkbox
        id="confirm"
        name="confirm"
        data-testid="confirm-checkbox"
        label="I have confirmed the items listed above and/or made necessary changes."
        checked={confirmed}
        onChange={() => setConfirmed(!confirmed)}
      />
      <ModalFooter>
        <Button
          disabled={!confirmed}
          onClick={async () => {
            await skipTFNValidation();
            toast.success('Submitted TFN verification request.');
            hideModal();
          }}
        >
          Submit Request
        </Button>
        <Button variant="text" onClick={() => hideModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
}
