import {
  Badge,
  BodyText,
  Button,
  Card,
  DataCard,
  EmptyState,
  Heading,
  Icon,
  IconSet,
  Layout,
  Pagination,
  Switch,
  TabGroup,
  toast,
} from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import PageHeader from 'components/layout/PageHeader';
import useSubscriber from 'components/responses/hooks/useSubscriber';
import ConversationCardNoSummaryBody from 'components/sales/components/ConversationCardNoSummaryBody';
import MessageThread from 'components/sales/components/MessageThread';
import { useSalesShopData } from 'components/sales/hooks/useShopData';
import { ChatSubContainer } from 'components/sales/pages/ConversationIntelligence';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import LogRocket from 'logrocket';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ConversationIntelligenceControls, {
  Controls,
  HAS_SUMMARY,
  MOST_RECENT_INBOUND,
} from '../components/ConversationIntelligenceControls';
import { ViewSelector } from '../components/ViewSelector';
import {
  ConversationIntelligenceUser,
  ConversationsWithInsightsResponse,
  ConversationWithInsights,
  InsightsPayload,
} from '../hooks/useConversationSummaries';

const BREAKPOINT = '1280px';

const StyledCIColumns = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  position: relative;
`;

const StyledCardButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing-3);
  gap: var(--spacing-1);
`;

const StyledTitleElements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: var(--spacing-2);
  gap: var(--spacing-2);
`;

const SummariesContainer = styled(Layout)`
  flex: 1 0;

  > * {
    width: 100%;
  }
`;

const StyledPaginationWrapper = styled.div`
  padding-top: var(--spacing-4);
`;

const DataCardLayout = styled.div`
  display: flex;
  gap: var(--spacing-2);

  > * {
    flex: 1 0 auto;
  }

  @media only screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }
`;

const StyledSkeletonCard = styled.div`
  flex: 1 0 auto;
  background: var(--gray-3);
  border-radius: var(--border-radius-medium);
  height: 120px;
  padding: var(--spacing-4) var(--spacing-5);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;
`;

const StyledSkeletonCardTall = styled(StyledSkeletonCard)`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVoidCard = styled.div`
  background: var(--surface-bkg-color);
  height: 280px;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-small);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatContainer = styled.div`
  max-width: 420px;
  min-width: 360px;
  flex: 1 1 420px;
  border-radius: var(--border-radius-medium);
  align-self: stretch;

  @media only screen and (max-width: ${BREAKPOINT}) {
    max-width: 240px;
    min-width: 240px;
    flex: 1 1 240px;
  }
`;

const ChatCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 0;
  height: calc(100vh - 204px - 36px);
  padding: 0;
  border-radius: var(--border-radius-medium);
  position: sticky;
  top: 12px;
`;

const ChatActions = styled(Layout)`
  border-top: 1px solid var(--border-color-dim);
  padding: var(--spacing-2);
  flex: 0 1 48px;
`;

const ChatEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--spacing-4);
  align-items: center;
  justify-content: center;
`;

const StyledCardHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-4);
  padding: 0 var(--spacing-2) var(--spacing-2);
`;

const StyledCardHeading = styled(Heading).attrs({
  size: 'x-small',
})`
  color: var(--text-color-dim);
`;

const StyledSummaryCard = styled.article`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  border: 1px solid transparent;
  position: relative;
  border-radius: var(--border-radius-medium);
  border: 1px solid transparent;
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  transition: border-color var(--hover-transition);
  padding: var(--spacing-4) var(--spacing-5);

  &.active {
    border: 1px solid var(--highlight-color);
    box-shadow: 0px 0px 5px 0px var(--highlight-color);
  }
`;

const StyledTitle = styled(Heading)`
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-4);
`;

const StyledSummaryHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  width: 100%;
`;

const StyledStatusDescription = styled.div`
  display: flex;

  @media only screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;

    & .bullet {
      display: none;
    }
  }
`;

const StyledSummaryInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-3);

  @media only screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }
`;

const StyledConversationSummaryContainer = styled.div`
  flex: 1 1 auto;
`;

const StyledTopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);

  flex: 0 0 204px;
  padding-left: var(--spacing-2);
  border-left: 1px solid var(--border-color-dim);
  height: 100%;

  @media only screen and (max-width: ${BREAKPOINT}) {
    flex-direction: column;
    flex: 1 0 100%;
    width: 100%;
    padding-left: 0;
    padding-top: var(--spacing-2);
    border-top: 1px solid var(--border-color-dim);
    border-left: none;
  }
`;

const StyledTopic = styled.a`
  display: flex;
  gap: var(--spacing-1);
  color: var(--text-color-dim);
  width; 100%;
  font: var(--body-text-x-small);

  &.bold {
    font-weight: 650;
    text-decoration: underline;
  }

  &:hover {
    color: var(--link-color);
    text-decoration: underline;
  }
  &:active {
    color: var(--link-color-hover);
    text-decoration: underline;
  }
  &:focus-visible {
    outline: 0;
    text-decoration: underline;
  }
`;

const StyledTopicIcon = styled(Icon).attrs({
  size: 18,
})``;

interface NavItemRange {
  start: moment.Moment;
  end: moment.Moment;
}

interface NavItem {
  display: string;
  range: NavItemRange;
  id: string;
}

interface NavItems {
  [key: string]: NavItem;
}

const navItems: NavItems = {
  today: {
    display: 'Today',
    range: {
      start: moment().subtract(1, 'days'),
      end: moment(),
    },
    id: 'today',
  },
  last_7_days: {
    display: 'Last 7 Days',
    range: {
      start: moment().subtract(7, 'days'),
      end: moment(),
    },
    id: 'last_7_days',
  },
  last_30_days: {
    display: 'Last 30 Days',
    range: {
      start: moment().subtract(30, 'days'),
      end: moment(),
    },
    id: 'last_30_days',
  },
};
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const MAX_PAGE_SIZE = 20;

const ViewSelectorWrapper = styled.div`
  height: 24px;
  width: 252px;
  opacity: 1;
  position: absolute;
  z-index: 101;
  right: 54px;
  top: 13px;
  transition: opacity var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);
`;

const getPayloadFromControls = (controls: Controls): InsightsPayload => {
  /**
   * This function converts the controls to a payload, making sure that empty array
   * and strings are not passed to the API e.g. shop_ids = []
   */
  const payload: InsightsPayload = {
    max_page_size: MAX_PAGE_SIZE,
    page: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  payload.page = controls.page > 0 ? controls.page - 1 : 0;
  if (controls.maxPageSize) {
    payload.max_page_size = controls.maxPageSize;
  }

  if (controls.shopIds.length) {
    payload.shop_ids = controls.shopIds;
  }
  if (controls.agentIds.length) {
    payload.sales_agent_ids = controls.agentIds;
  }
  if (controls.filters.length) {
    payload.filters = controls.filters;
  }
  if (controls.searchText) {
    payload.search_text = controls.searchText;
  }
  if (controls.searchDirection) {
    payload.search_direction = controls.searchDirection;
  }
  if (controls.topicLabel) {
    payload.topic_label = controls.topicLabel;
  }
  if (controls.sortBy) {
    payload.sort_by = controls.sortBy;
  }
  if (controls.dateRangeStart) {
    payload.date_range_start = controls.dateRangeStart;
  }
  if (controls.dateRangeEnd) {
    payload.date_range_end = controls.dateRangeEnd;
  }

  return payload;
};

const updateHistoryFromControls = (history: any, controls: Controls) => {
  const newQueryParams = new URLSearchParams();
  if (controls.shopIds.length > 0) {
    newQueryParams.set('shop_ids', controls.shopIds.join(','));
  }
  if (controls.agentIds.length > 0) {
    newQueryParams.set('agent_ids', controls.agentIds.join(','));
  }
  if (controls.filters.length > 0) {
    newQueryParams.set('filters', controls.filters.join(','));
  }
  if (controls.searchText) {
    newQueryParams.set('search_text', controls.searchText);
  }
  if (controls.searchDirection) {
    newQueryParams.set('search_direction', controls.searchDirection);
  }
  if (controls.topicLabel) {
    newQueryParams.set('topic_label', controls.topicLabel);
  }
  if (controls.sortBy) {
    newQueryParams.set('sort_by', controls.sortBy);
  }
  if (controls.dateRangeStart) {
    newQueryParams.set('date_range_start', controls.dateRangeStart);
  }
  if (controls.dateRangeEnd) {
    newQueryParams.set('date_range_end', controls.dateRangeEnd);
  }
  if (controls.page) {
    newQueryParams.set('page', controls.page.toString());
  }
  history.push(`?${newQueryParams.toString()}`);
};

const formatDate = (inputDate: string): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateObj = new Date(inputDate);
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

const getStatusColor = (conversation: ConversationWithInsights) => {
  if (conversation.active) {
    return 'var(--green-2)';
  }

  if (
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    return 'var(--blue-2)';
  }

  if (
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return 'var(--red-3)';
  }
  return 'var(--purple-core)';
};

const getEngagementBody = (conversation: ConversationWithInsights) => {
  const messageCount = conversation.messages.length;
  let engagementBody = `${messageCount} message${messageCount > 1 ? 's' : ''}`;
  if (messageCount >= 3) {
    engagementBody = `🙋🏽‍♂️ Brief exchange (${messageCount} messages)`;
  }
  if (messageCount >= 6) {
    engagementBody = `🔥 Engaged discussion (${messageCount} messages)`;
  }
  return engagementBody;
};

const getStatusBody = (conversation: ConversationWithInsights) => {
  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment().add(1, 'hour')) &&
    moment(conversation.expiry_at).isSameOrBefore(moment().add(47, 'hour'))
  ) {
    return `Happening now`;
  }

  if (
    conversation.active &&
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    const updatedAt = moment.utc(conversation.updated_at);
    const duration = moment.duration(moment().diff(updatedAt));
    const minutesAgo = Math.floor(duration.asMinutes());
    return `${minutesAgo} min ago`;
  }

  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return `Expiring soon`;
  }
  return `Inactive since ${formatDate(conversation.closed_at)}`;
};

const getStatusDescription = (conversation: ConversationWithInsights) => {
  return (
    <StyledStatusDescription>
      <Layout gap="var(--spacing-1)" valign="top">
        <Icon
          component={IconSet.Disc}
          size={16}
          color={getStatusColor(conversation)}
        />
        <BodyText size="x-small">{getStatusBody(conversation)}</BodyText>
      </Layout>
      <BodyText className="bullet" size="x-small">
        &nbsp;·&nbsp;
      </BodyText>
      <BodyText size="x-small">{getEngagementBody(conversation)}</BodyText>
    </StyledStatusDescription>
  );
};

const ConversationalInsights = (): JSX.Element => {
  const isMultiShop = window.location.href.includes('workspace');
  const queryParams = new URLSearchParams(window.location.search);

  const queryShops = queryParams.get('shop_ids') ?? '';
  const currentShopId = getShopId();
  const shopIds = isMultiShop
    ? queryShops
        .split(',')
        .filter((shopId) => shopId !== '')
        .map(parseInt)
    : [currentShopId];

  const queryAgents = queryParams.get('agent_ids') ?? '';
  const agentIds = queryAgents
    .split(',')
    .filter((agentId) => agentId !== '')
    .map(parseInt);

  const searchText = queryParams.get('search_text') ?? '';
  const searchDirection = queryParams.get('search_direction') ?? 'ALL';
  const topicLabel = queryParams.get('topic_label') ?? '';
  const page = parseInt(queryParams.get('page') ?? '0');
  const maxPageSize = parseInt(
    queryParams.get('maxPageSize') ?? MAX_PAGE_SIZE.toString(),
  );

  const queryFilters = queryParams.get('filters') ?? HAS_SUMMARY;
  const filters = queryFilters.split(',').filter((filter) => filter !== '');

  const sortBy = queryParams.get('sort_by') ?? MOST_RECENT_INBOUND;
  const dateRangeStart =
    queryParams.get('date_range_start') ??
    moment().subtract(7, 'days').format(DATE_FORMAT);
  const dateRangeEnd =
    queryParams.get('date_range_end') ?? moment().format(DATE_FORMAT);

  const messageThreadRef = useRef<HTMLUListElement | null>(null);
  const [subscriberId, setSubscriberId] = useState<number | undefined>(
    undefined,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchControlsAreOpen, setSearchControlsAreOpen] = useState(false);

  const [controls, setControls] = useState<Controls>({
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    filters,
    topicLabel,
    sortBy,
    dateRangeEnd,
    dateRangeStart,
    page,
    maxPageSize,
  });
  const [summaries, setSummaries] = useState<
    ConversationsWithInsightsResponse | undefined
  >({
    conversations: [],
    users: [],
    matching_conversations: 0,
    total_conversations: 0,
    active_conversations: 0,
    matching_unique_subscribers: 0,
    total_unique_subscribers: 0,
  });
  const startDate = moment(dateRangeStart, DATE_FORMAT);
  const endDate = moment(dateRangeEnd, DATE_FORMAT);
  const numDays = endDate.diff(startDate, 'days');
  const rangeIdMapping: { [key: number]: string } = {
    1: navItems.today.id,
    7: navItems.last_7_days.id,
    30: navItems.last_30_days.id,
  };
  const [selectedDateId, setSelectedDateId] = useState<string | null>(
    rangeIdMapping[numDays] || null,
  );
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const { data: subscriber } = useSubscriber(subscriberId);
  const [isLoading, setIsLoading] = useState(false);
  const { data: salesShopsData } = useSalesShopData();

  const history = useHistory();

  const {
    user: { id: userId, username, shop_id: shopId, is_admin: isAdmin },
  } = useUser();

  useEffect(() => {
    if (!userId) return;

    if (window.location.host !== 'app.postscript.io') return;

    LogRocket.init('ssovkv/conversations-uijdo');

    LogRocket.identify(userId.toString(), {
      name: username,
      jwt_shop_id: shopId,
      is_admin: isAdmin,
    });
  }, [userId]);

  useEffect(() => {
    if (isMultiShop && shopId !== 1) {
      toast.error('Must be logged in as shop 1');
      return;
    }
    updateHistoryFromControls(history, controls);

    const fetchConversations = async () => {
      const route = isMultiShop
        ? '/v2/convert/conversations'
        : '/v2/convert/shop-conversations';
      setIsLoading(true);
      const response = await api.post(route, getPayloadFromControls(controls));
      setSummaries(response);
      setIsLoading(false);
    };
    fetchConversations();
  }, [controls]);

  // auto scroll to bottom of message thread
  useEffect(() => {
    if (messageThreadRef.current) {
      const conversation = summaries?.conversations.find(
        (conversation) => conversation.id === selectedCardId,
      );
      const firstMessageId =
        conversation?.messages[0].oid ??
        conversation?.messages[0].external_id ??
        '';

      const targetMessage = messageThreadRef.current.querySelector(
        `#message-${subscriberId}_${firstMessageId}`,
      );

      if (targetMessage) {
        targetMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [subscriber]);

  // TODO: Lets move this into it's own component
  const dateRangeTabGroup = () => {
    return (
      <TabGroup description="Select a date range" id="insights-date-tabgroup">
        {Object.keys(navItems).map((item) => {
          const { start, end } = navItems[item].range;

          return (
            <button
              data-selected={navItems[item].id === selectedDateId}
              key={item}
              onClick={() => {
                const formattedFrom = start.format(DATE_FORMAT);
                const formattedTo = end.format(DATE_FORMAT);
                setControls({
                  ...controls,
                  dateRangeStart: formattedFrom,
                  dateRangeEnd: formattedTo,
                });
                setSelectedDateId(navItems[item].id);
              }}
              data-cy={`insights-date-nav-button-${navItems[item].display
                .replace(/\s/g, '')
                .toLowerCase()}`}
              type="button"
            >
              {navItems[item].display}
            </button>
          );
        })}
      </TabGroup>
    );
  };

  const selectedDateRangeLabel = () => {
    const label = `in the last ${numDays} days`;
    if (selectedDateId && selectedDateId === 'today') {
      return 'today';
    }
    return label;
  };

  const formatNumberWithCommas = (numberString: string): string => {
    const number = parseInt(numberString, 10);
    return new Intl.NumberFormat('en-US').format(number);
  };

  const formatPhoneNumber = (phoneNumber: string | undefined): string => {
    if (!phoneNumber) return '';
    const cleaned = String(phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  const formatTopicLabel = (label: string): string => {
    const lowerCaseLabel = label.trim().toLowerCase();
    const sentenceCase = `${lowerCaseLabel
      .charAt(0)
      .toUpperCase()}${lowerCaseLabel.slice(1)}`;
    const strippedPunctuation = sentenceCase
      .replace(/[^\w\s]|_/g, '')
      .replace(/\s+/g, ' ');
    return strippedPunctuation;
  };

  const renderConversationCard = (conversation: ConversationWithInsights) => {
    // Using first insight in list (expected to be the only one)
    const insight =
      conversation.insights && conversation.insights.length > 0
        ? conversation.insights[0]
        : undefined;

    const formattedName =
      conversation.subscriber?.first_name && conversation.subscriber?.last_name
        ? `${
            conversation.subscriber.first_name
          } ${conversation.subscriber.last_name[0].toUpperCase()}`
        : '';

    const formattedPhoneNumber =
      conversation.subscriber?.phone_number &&
      formatPhoneNumber(conversation.subscriber?.phone_number);

    const identifier =
      (formattedName || formattedPhoneNumber) &&
      `${formattedName || ''}${formattedName && formattedPhoneNumber && ' · '}${
        formattedPhoneNumber || ''
      }`;

    const participatingUsers: { [id: number]: ConversationIntelligenceUser } =
      {};

    if (summaries && summaries.users) {
      summaries.users.forEach((user) => {
        participatingUsers[user.id] = user;
      });
    }
    const participantNamesSet = new Set<string>();

    conversation.messages
      .map((message) => message.user_id)
      .filter((userId) => userId !== null)
      .forEach((userId) => {
        if (!userId) {
          return;
        }

        if (
          !participatingUsers[userId] ||
          !participatingUsers[userId].first_name ||
          !participatingUsers[userId].last_name
        ) {
          return;
        }
        const { first_name: firstName, last_name: lastName } =
          participatingUsers[userId];
        const name = `${firstName} ${
          lastName && lastName.length > 0 && lastName[0]
        }.`;
        participantNamesSet.add(name);
      });

    const participantNames: string[] = Array.from(participantNamesSet);

    return (
      <StyledSummaryCard
        className={selectedCardId === conversation.id ? 'active' : ''}
        key={conversation.id}
        data-testid="summary-card"
      >
        <Layout vertical gap="var(--spacing-2)">
          {isMultiShop && (
            <Badge
              style={{
                backgroundColor: salesShopsData
                  ? salesShopsData.shop_data.find(
                      (shop) => shop.id === conversation.shop?.id,
                    )?.sales_shop_color
                  : undefined,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflowX: 'hidden',
                color: salesShopsData
                  ? salesShopsData.shop_data.find(
                      (shop) => shop.id === conversation.shop?.id,
                    )?.sales_shop_text_color
                  : undefined,
              }}
            >
              {conversation.shop?.name || 'Unknown Shop'}
            </Badge>
          )}

          <StyledSummaryHeader>
            {identifier && <Heading size="x-small">{identifier}</Heading>}
            {getStatusDescription(conversation)}
          </StyledSummaryHeader>
        </Layout>

        <StyledSummaryInfo>
          <StyledConversationSummaryContainer>
            {insight ? (
              <BodyText>{insight.summary}</BodyText>
            ) : (
              <ConversationCardNoSummaryBody
                conversation={conversation}
                participatingUsers={participatingUsers}
                isMultiShop={isMultiShop}
              />
            )}
            <StyledCardButtons>
              <Button
                icon={IconSet.TwoWayConvo}
                onClick={() => {
                  setSubscriberId(conversation.subscriber?.id);
                  setSelectedCardId(conversation.id);
                }}
                monochrome
                variant="text"
                size="small"
                data-testid="view-conversation"
              >
                View Conversation
              </Button>
              <Button
                icon={IconSet.User}
                onClick={() => {
                  const subscriberId = conversation.subscriber?.id;
                  window.location.href = `${window.location.origin}/subscribers/${subscriberId}`;
                }}
                monochrome
                variant="text"
                size="small"
              >
                Subscriber Detail
              </Button>
            </StyledCardButtons>
          </StyledConversationSummaryContainer>

          <StyledTopicContainer>
            {isMultiShop && participantNames.length > 0 && (
              <StyledTopic>
                <StyledTopicIcon
                  component={IconSet.Bub}
                  color="var(--highlight-color)"
                />
                <span>{participantNames.join(',')}</span>
              </StyledTopic>
            )}

            {insight
              ? insight.topic_labels.map((topicLabel) => {
                  return (
                    <StyledTopic
                      key={topicLabel.label}
                      tabIndex={0}
                      onClick={() =>
                        setControls({
                          ...controls,
                          topicLabel: topicLabel.label,
                        })
                      }
                      role="button"
                      href="#"
                    >
                      <StyledTopicIcon
                        component={IconSet.Hashtag}
                        color="var(--blue-5)"
                      />

                      {formatTopicLabel(topicLabel.label)}
                    </StyledTopic>
                  );
                })
              : null}
          </StyledTopicContainer>
        </StyledSummaryInfo>
      </StyledSummaryCard>
    );
  };

  const renderPaginationControls = () => {
    // TODO this needs proper navigation functionality
    if (!summaries?.total_conversations) {
      return;
    }
    return (
      <Pagination
        currentPage={controls.page}
        selectPage={(selectedPage) => {
          return setControls({
            ...controls,
            page: selectedPage,
          });
        }}
        size="large"
        showFirstLastPageButtons
        totalPages={Math.ceil(
          summaries?.matching_conversations / MAX_PAGE_SIZE,
        )}
      />
    );
  };

  if (!summaries) {
    return <StyledSkeletonCardTall />;
  }

  const copyConversationLinkToClipboard = () => {
    const url = `${
      window.location.origin + window.location.pathname
    }?search_text=${selectedCardId}`;
    navigator.clipboard.writeText(url);
    toast.success('Copied.');
  };

  const copyConversationToClipboard = () => {
    const conversation = summaries.conversations.find(
      (conversation) => selectedCardId === conversation.id,
    );
    if (!conversation) {
      toast.error('Cannot copy conversation to clipboard');
      return;
    }
    const text = conversation.messages
      .map((message, index, messages) => {
        const previousDirection =
          index > 0 ? messages[index - 1].direction : null;
        const messageDirection =
          message.direction === 'INBOUND' ? 'INBOUND' : 'OUTBOUND';

        const extraNewline = previousDirection !== messageDirection ? '\n' : '';

        return `${extraNewline}${messageDirection}: ${message.body}`;
      })
      .join('\n');
    navigator.clipboard.writeText(text);
    toast.success('Copied.');
  };

  let titleText = <></>;
  if (controls.topicLabel) {
    titleText = (
      <StyledTitleElements>
        <Heading as="h2" size="x-small">
          Conversations about{' '}
          <span style={{ color: 'var(--highlight-color)' }}>
            {controls.topicLabel}
          </span>{' '}
          {selectedDateRangeLabel()}
        </Heading>
        <Button
          size="small"
          onClick={() => setControls({ ...controls, topicLabel: undefined })}
          variant="secondary"
          style={{ minWidth: '92px' }}
        >
          Clear Topic
        </Button>
      </StyledTitleElements>
    );
  } else {
    titleText = (
      <span>
        {`Conversations matching filter criteria ${selectedDateRangeLabel()}`}
      </span>
    );
  }

  const toggleHasSummaryFilter = () => {
    setControls((prevControls) => {
      const updatedFilters = prevControls.filters.includes(HAS_SUMMARY)
        ? prevControls.filters.filter((filter) => filter !== HAS_SUMMARY)
        : [...prevControls.filters, HAS_SUMMARY];

      return {
        ...prevControls,
        filters: updatedFilters,
      };
    });
  };

  return (
    <>
      {!isMultiShop && (
        <ViewSelectorWrapper>
          <ViewSelector />
        </ViewSelectorWrapper>
      )}

      <PageHeader
        pageTitle="Conversations"
        sticky={false}
        actions={dateRangeTabGroup()}
      />

      <StyledCIColumns>
        {isLoading || !summaries ? (
          <SummariesContainer vertical>
            <DataCardLayout>
              <StyledSkeletonCard />
              <StyledSkeletonCard />
            </DataCardLayout>
            {isLoading ? (
              <StyledSkeletonCardTall />
            ) : (
              <StyledVoidCard>
                <EmptyState
                  size="medium"
                  heading="No conversations available"
                  description="Please refine your filters or return to the default view"
                />
              </StyledVoidCard>
            )}
          </SummariesContainer>
        ) : (
          <SummariesContainer vertical>
            <StyledTitle size="small">{titleText}</StyledTitle>
            <StyledCardHeadingRow>
              <StyledCardHeading size="x-small">
                Highlights and summaries
              </StyledCardHeading>
              <Switch
                checked={!controls.filters.includes(HAS_SUMMARY)}
                onChange={toggleHasSummaryFilter}
                fieldSize="small"
                id="unsummarized-toggle"
                label="Show unsummarized"
              />
            </StyledCardHeadingRow>
            <DataCardLayout>
              <DataCard
                accordion={false}
                metric={formatNumberWithCommas(
                  summaries.matching_conversations.toString(),
                )}
                title="Total Conversations"
                visual={<Icon component={IconSet.Message} size={30} />}
                periodLabel={`${Math.round(
                  summaries.total_conversations
                    ? (summaries.matching_conversations /
                        summaries.total_conversations) *
                        100
                    : 0,
                )}% of ${formatNumberWithCommas(
                  summaries.total_conversations.toString(),
                )} ${selectedDateRangeLabel()}`}
              />
              <DataCard
                accordion={false}
                metric={formatNumberWithCommas(
                  summaries.matching_unique_subscribers.toString(),
                )}
                title="Unique Subscribers"
                visual={<Icon component={IconSet.Users} size={30} />}
                periodLabel={
                  summaries.matching_unique_subscribers ===
                  summaries.total_unique_subscribers
                    ? ''
                    : `${Math.round(
                        (100 * summaries.matching_unique_subscribers) /
                          summaries.total_unique_subscribers,
                      )}% of ${formatNumberWithCommas(
                        summaries.total_unique_subscribers.toString(),
                      )} ${selectedDateRangeLabel()}`
                }
              />
            </DataCardLayout>
            {summaries.conversations.length > 0 ? (
              summaries.conversations.map(renderConversationCard)
            ) : (
              <StyledVoidCard>
                <EmptyState
                  size="medium"
                  heading="No conversations available"
                  description="Please refine your filters or return to the default view"
                />
              </StyledVoidCard>
            )}

            <StyledPaginationWrapper>
              {renderPaginationControls()}
            </StyledPaginationWrapper>
          </SummariesContainer>
        )}
        <ChatContainer className={subscriber && 'filled'}>
          <ConversationIntelligenceControls
            controls={controls}
            onControlsUpdate={(newControls) => {
              setControls({ ...controls, ...newControls });
            }}
            isLoading={isLoading}
            isMultiShop={isMultiShop}
            isOpen={false}
            toggleFunction={setSearchControlsAreOpen}
          />
          <ChatCard>
            {subscriber ? (
              <>
                <ChatSubContainer>
                  <MessageThread
                    subscriber={subscriber}
                    messageThreadRef={messageThreadRef}
                  />
                </ChatSubContainer>

                <ChatActions align="right" gap="var(--spacing-1)">
                  <Button
                    onClick={() => copyConversationLinkToClipboard()}
                    icon={IconSet.Link}
                    size="small"
                    variant="secondary"
                  >
                    Copy Link
                  </Button>
                  <Button
                    onClick={() => copyConversationToClipboard()}
                    icon={IconSet.Text}
                    size="small"
                    variant="secondary"
                  >
                    Copy Conversation
                  </Button>
                </ChatActions>
              </>
            ) : (
              <ChatEmpty>
                <EmptyState
                  size="medium"
                  description="Select a conversation summary from the list"
                />
              </ChatEmpty>
            )}
          </ChatCard>
        </ChatContainer>
      </StyledCIColumns>
    </>
  );
};

export default ConversationalInsights;
