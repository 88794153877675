import {
  AutomationFlowStatuses,
  CampaignFlowStatuses,
  CompletedOptimizedABSplitTestStatuses,
  FlowOrigins,
  FlowTemplateTypes,
  FlowTypes,
  OptimizedABSplitTestStatuses,
  PriceDropAmountVariables,
  PriceDropCategories,
  PRICE_DROP_CATEGORY_VARIABLE,
  TextToBuySidebarNames,
} from 'components/flowBuilder/constants';
import type {
  ABSplitActionBranch,
  ActionAnalytics,
  ActionsAnalytics,
  Analytics,
  AutomationFlowStatus,
  BinaryCondition,
  CampaignFlowStatus,
  CharacterCodes,
  Charset,
  Charsets,
  ClonedFlowResponse,
  CompletedOptimizedABSplitTestStatus,
  Condition,
  ConditionCase,
  DetailedFlow,
  DraftFlow,
  DraggableItem,
  Flow,
  FlowAnalytics,
  FlowOrigin,
  FlowStatus,
  FlowTemplate,
  FlowTemplateType,
  FlowType,
  LinkTracking,
  LinkTrackingCustomParams,
  LinkTrackingParam,
  NewLinkTracking,
  OptimizedABSplitTestHistory,
  OptimizedABSplitTestHistoryRecord,
  OptimizedABSplitTestStatus,
  PriceDropAmountCondition,
  PriceDropCategory,
  PriceDropCategoryCondition,
  Product,
  ProductVariant,
  SidebarType,
  StepNode,
  StepNodeData,
  SubscriberEvent,
  TextToBuySidebarType,
  ValueOption,
} from 'components/flowBuilder/types';
import { isResourceTemplate } from 'hooks/useResourceTemplates';
import { isNode } from 'reactflow';
import {
  isAction,
  isOptimizedABSplitActionBackendConfig,
  isOptimizedABSplitActionParams,
} from './actions/typeGuards';
import { isStep } from './steps/typeGuards';

export const isAutomationFlowStatus = (
  status: unknown,
): status is AutomationFlowStatus => {
  const statusValue = status as AutomationFlowStatus;
  return Object.values(AutomationFlowStatuses).includes(statusValue);
};

export const isCampaignFlowStatus = (
  status: unknown,
): status is CampaignFlowStatus => {
  const statusValue = status as CampaignFlowStatus;
  return Object.values(CampaignFlowStatuses).includes(statusValue);
};

export const isFlowStatus = (status: unknown): status is FlowStatus => {
  return isAutomationFlowStatus(status) || isCampaignFlowStatus(status);
};

export const isFlowType = (type: unknown): type is FlowType => {
  const typeValue = type as FlowType;
  return Object.values(FlowTypes).includes(typeValue);
};

export const isFlowOrigin = (origin: unknown): origin is FlowOrigin => {
  const originValue = origin as FlowOrigin;
  return Object.values(FlowOrigins).includes(originValue);
};

export const isSubscriberEvent = (event: unknown): event is SubscriberEvent => {
  const eventValue = event as SubscriberEvent;

  if (!eventValue || typeof eventValue.description !== 'string') return false;
  if (!eventValue.name || typeof eventValue.name !== 'string') return false;
  if (!eventValue.type || typeof eventValue.type !== 'string') return false;
  if (!eventValue.source || typeof eventValue.source !== 'string') return false;

  return true;
};

const isCondition = (condition: unknown): condition is Condition => {
  const conditionValue = condition as Condition;

  if (!conditionValue.variable || typeof conditionValue.variable !== 'string')
    return false;
  if (!conditionValue.operator || typeof conditionValue.operator !== 'string')
    return false;
  if (typeof conditionValue.value !== 'number') return false;

  return true;
};

export const isBinaryCondition = (
  condition: unknown,
): condition is BinaryCondition => {
  const conditionValue = condition as BinaryCondition;
  if (typeof conditionValue.type !== 'string') return false;
  if (!conditionValue.variable || typeof conditionValue.variable !== 'string')
    return false;
  if (!conditionValue.operator || typeof conditionValue.operator !== 'string')
    return false;
  if (
    typeof conditionValue.value !== 'number' &&
    typeof conditionValue.value !== 'string' &&
    typeof conditionValue.value !== 'boolean' &&
    !Array.isArray(conditionValue.value)
  )
    return false;

  return true;
};

export const isElseCondition = (condition: unknown): boolean => {
  const conditionValue = condition as BinaryCondition;
  if (typeof conditionValue.type !== 'string') return false;
  if (typeof conditionValue.variable !== 'string' || !!conditionValue.variable)
    return false;
  if (typeof conditionValue.operator !== 'string' || !!conditionValue.operator)
    return false;
  if (conditionValue.value) return false;

  return true;
};

export const isPriceDropCategory = (
  category: unknown,
): category is PriceDropCategory => {
  const categoryValue = category as PriceDropCategory;
  return Object.values(PriceDropCategories).includes(categoryValue);
};

export const isPriceDropAmountCondition = (
  condition: unknown,
): condition is PriceDropAmountCondition => {
  const conditionValue = condition as PriceDropAmountCondition;
  if (!isBinaryCondition(conditionValue)) return false;
  if (
    !Object.values(PriceDropAmountVariables).includes(conditionValue.variable)
  )
    return false;
  if (conditionValue.operator !== 'gte') return false;
  if (typeof conditionValue.value !== 'number') return false;
  return true;
};

export const isPriceDropCategoryCondition = (
  condition: unknown,
): condition is PriceDropCategoryCondition => {
  const conditionValue = condition as PriceDropCategoryCondition;
  if (!isBinaryCondition(conditionValue)) return false;
  if (conditionValue.variable !== PRICE_DROP_CATEGORY_VARIABLE) return false;
  if (conditionValue.operator !== 'in') return false;
  if (!isPriceDropCategory(conditionValue.value)) return false;
  return true;
};

// TODO: Deprecate once we can use isBranch for A/B Split.
export const isABSplitActionBranch = (
  branch: unknown,
): branch is ABSplitActionBranch => {
  const branchValue = branch as ABSplitActionBranch;

  if (typeof branchValue.action_guid !== 'string') return false;
  if (branchValue.conditions.some((condition) => !isCondition(condition)))
    return false;

  return true;
};

export const isEventSplitBranch = (
  branch: unknown,
): branch is ConditionCase => {
  const branchValue = branch as ConditionCase;
  if (typeof branchValue.action_guid !== 'string') return false;
  if (
    !branchValue.conditions.every(
      (condition) => isBinaryCondition(condition) || isElseCondition(condition),
    )
  )
    return false;

  return true;
};

export const isConditionCase = (branch: unknown): branch is ConditionCase => {
  const branchValue = branch as ConditionCase;

  if (typeof branchValue.action_guid !== 'string') return false;
  if (
    !branchValue.conditions.every(
      (condition) => isCondition(condition) || isBinaryCondition(condition),
    )
  )
    return false;

  return true;
};

export const isDraggableItem = (item: unknown): item is DraggableItem => {
  const itemValue = item as DraggableItem;

  if (!itemValue || typeof itemValue !== 'object') return false;
  if (!itemValue.elementRef || typeof itemValue.elementRef !== 'object')
    return false;
  if (
    itemValue.elementRef.current !== null &&
    !(itemValue.elementRef.current instanceof HTMLDivElement)
  )
    return false;
  if (
    typeof itemValue.guid !== 'undefined' &&
    typeof itemValue.guid !== 'string'
  )
    return false;

  return true;
};

export const isDraftFlow = (flow: unknown): flow is DraftFlow => {
  const flowValue = flow as DraftFlow;

  if (!flowValue || typeof flowValue !== 'object') return false;
  if (!flowValue.name || typeof flowValue.name !== 'string') return false;
  if (typeof flowValue.description !== 'string') return false;
  if (!flowValue.start || typeof flowValue.start !== 'string') return false;
  if (!flowValue.segmentIds || !Array.isArray(flowValue.segmentIds))
    return false;
  if (flowValue.segmentIds.some((segmentId) => typeof segmentId !== 'number'))
    return false;
  if (!isFlowStatus(flowValue.status)) return false;
  if (
    flowValue.templateId !== undefined &&
    flowValue.templateId !== null &&
    typeof flowValue.templateId !== 'number'
  )
    return false;
  if (
    !flowValue.actions ||
    !Array.isArray(flowValue.actions) ||
    flowValue.actions.length === 0
  )
    return false;
  if (flowValue.actions.some((action) => !isAction(action))) return false;
  if (
    flowValue.scheduledFor !== undefined &&
    flowValue.scheduledFor !== null &&
    typeof flowValue.scheduledFor !== 'string'
  )
    return false;
  if (!['boolean', 'undefined'].includes(typeof flowValue.safeSend)) {
    return false;
  }

  return true;
};

export const isFlow = (flow: unknown): flow is Flow => {
  const flowValue = flow as Flow;

  if (!isDraftFlow(flow)) return false;
  if (!flowValue.guid || typeof flowValue.guid !== 'string') return false;

  return true;
};

export const isDetailedFlow = (
  detailedFlow: unknown,
): detailedFlow is DetailedFlow => {
  const detailedFlowValue = detailedFlow as DetailedFlow;

  if (!detailedFlowValue || typeof detailedFlowValue !== 'object') return false;
  if (!isFlowStatus(detailedFlowValue.current_flow_status)) return false;
  if (
    !detailedFlowValue.flow_collection_guid ||
    typeof detailedFlowValue.flow_collection_guid !== 'string'
  )
    return false;
  if (!detailedFlowValue.name || typeof detailedFlowValue.name !== 'string')
    return false;
  if (!isFlowType(detailedFlowValue.type)) return false;

  return true;
};

export const isValueOption = (option: unknown): option is ValueOption => {
  const optionValue = option as ValueOption;

  if (!optionValue || typeof optionValue !== 'object') return false;
  if (typeof optionValue.group !== 'string' && optionValue.group !== undefined)
    return false;
  if (!optionValue.label || typeof optionValue.label !== 'string') return false;
  if (!optionValue.value || typeof optionValue.value !== 'string') return false;

  return true;
};

const isStepNodeData = (data: unknown): data is StepNodeData => {
  const dataValue = data as StepNodeData;

  if (!dataValue || typeof dataValue !== 'object') return false;
  if (!isStep(dataValue.activeStep) && dataValue.activeStep !== null)
    return false;
  if (!isStep(dataValue.step)) return false;

  return true;
};

export const isStepNode = (node: unknown): node is StepNode => {
  const nodeValue = node as StepNode;

  if (!nodeValue || typeof nodeValue !== 'object') return false;
  if (!isNode(nodeValue)) return false;
  if (!isStepNodeData(nodeValue.data)) return false;

  return true;
};

const isCharacterCodes = (
  characterCodes: unknown,
): characterCodes is CharacterCodes => {
  const characterCodesValue = characterCodes as CharacterCodes;

  if (!characterCodesValue || typeof characterCodesValue !== 'object')
    return false;
  if (Array.isArray(characterCodesValue.decimal)) {
    if (
      characterCodesValue.decimal.some(
        (decimalCode) => typeof decimalCode !== 'number',
      )
    )
      return false;
  } else if (typeof characterCodesValue.decimal !== 'number') {
    return false;
  }

  return true;
};

const isCharset = (charset: unknown): charset is Charset => {
  const charsetValue = charset as Charset;

  if (!charsetValue || typeof charsetValue !== 'object') return false;
  if (
    Object.values(charsetValue).some(
      (characterCodes) => !isCharacterCodes(characterCodes),
    )
  )
    return false;

  return true;
};

export const isCharsets = (charsets: unknown): charsets is Charsets => {
  const charsetsValue = charsets as Charsets;

  if (!charsetsValue || typeof charsetsValue !== 'object') return false;
  if (Object.values(charsetsValue).some((charset) => !isCharset(charset)))
    return false;

  return true;
};

export const isAnalytics = (analytics: unknown): analytics is Analytics => {
  const analyticsValue = analytics as Analytics;

  if (!analyticsValue || typeof analyticsValue !== 'object') return false;
  if (
    typeof analyticsValue.conversion_rate !== 'number' &&
    analyticsValue.conversion_rate !== null &&
    typeof analyticsValue.conversion_rate !== 'undefined'
  )
    return false;
  if (typeof analyticsValue.credits_used !== 'number') return false;
  if (
    typeof analyticsValue.ctr !== 'number' &&
    typeof analyticsValue.ctr !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.deliverability !== 'number' &&
    typeof analyticsValue.deliverability !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.epm !== 'number' &&
    typeof analyticsValue.epm !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.last_updated !== 'string' &&
    analyticsValue.last_updated !== null &&
    typeof analyticsValue.last_updated !== 'undefined'
  )
    return false;
  if (typeof analyticsValue.orders !== 'number') return false;
  if (
    typeof analyticsValue.outbound !== 'number' &&
    typeof analyticsValue.outbound !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.queued_subscribers !== 'number' &&
    analyticsValue.queued_subscribers !== null &&
    typeof analyticsValue.queued_subscribers !== 'undefined'
  )
    return false;
  if (typeof analyticsValue.revenue !== 'number') return false;
  if (typeof analyticsValue.total_clicks !== 'number') return false;
  if (
    typeof analyticsValue.total_failed !== 'number' &&
    typeof analyticsValue.total_failed !== 'undefined'
  )
    return false;
  if (typeof analyticsValue.total_sent !== 'number') return false;
  if (typeof analyticsValue.total_subscribers !== 'number') return false;
  if (typeof analyticsValue.total_unsubs !== 'number') return false;
  if (
    typeof analyticsValue.uctr !== 'number' &&
    typeof analyticsValue.uctr !== 'undefined'
  )
    return false;
  if (typeof analyticsValue.unique_clicks !== 'number') return false;
  if (
    typeof analyticsValue.unique_subscribers !== 'number' &&
    typeof analyticsValue.unique_subscribers !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.unsubscribe_rate !== 'number' &&
    typeof analyticsValue.unsubscribe_rate !== 'undefined'
  )
    return false;
  if (
    typeof analyticsValue.valid_message_statistics !== 'boolean' &&
    typeof analyticsValue.valid_message_statistics !== 'undefined'
  )
    return false;

  return true;
};

export const isActionAnalytics = (
  actionAnalytics: unknown,
): actionAnalytics is ActionAnalytics => {
  const actionAnalyticsValue = actionAnalytics as ActionAnalytics;

  if (isAnalytics(actionAnalyticsValue)) return true;
  if (!Array.isArray(actionAnalyticsValue)) return false;
  if (actionAnalyticsValue.some((stat) => stat !== null && !isAnalytics(stat)))
    return false;

  return true;
};

export const isActionsAnalytics = (
  actionsAnalytics: unknown,
): actionsAnalytics is ActionsAnalytics => {
  const actionsAnalyticsValue = actionsAnalytics as ActionsAnalytics;

  if (!actionsAnalyticsValue || typeof actionsAnalyticsValue !== 'object')
    return false;
  if (
    Object.values(actionsAnalyticsValue).some(
      (actionAnalytics) => !isActionAnalytics(actionAnalytics),
    )
  )
    return false;

  return true;
};

export const isFlowAnalytics = (
  analytics: unknown,
): analytics is FlowAnalytics => {
  const analyticsValue = analytics as FlowAnalytics;

  if (!analyticsValue || typeof analyticsValue !== 'object') return false;
  if (
    !isAnalytics(analyticsValue.overall) &&
    analyticsValue.overall !== null &&
    analyticsValue.overall !== undefined
  )
    return false;
  if (
    !isActionsAnalytics(analyticsValue.actions) &&
    analyticsValue.actions !== null &&
    analyticsValue.actions !== undefined
  )
    return false;

  return true;
};

export const isFlowTemplateType = (type: unknown): type is FlowTemplateType => {
  const typeValue = type as FlowTemplateType;
  return Object.values(FlowTemplateTypes).includes(typeValue);
};

export const isFlowTemplate = (template: unknown): template is FlowTemplate => {
  const templateValue = template as FlowTemplate;

  if (!isResourceTemplate(templateValue)) return false;
  if (!isFlow(templateValue.data)) return false;
  if (!isFlowTemplateType(templateValue.type)) return false;

  return true;
};

export const isClonedFlowResponse = (
  response: unknown,
): response is ClonedFlowResponse => {
  const cloned = response as ClonedFlowResponse;

  if (typeof cloned.guid !== 'string') {
    return false;
  }
  return true;
};

export const isProduct = (product: unknown): product is Product => {
  const productValue = product as Product;

  if (!productValue || typeof productValue !== 'object') return false;
  if (typeof productValue.handle !== 'string') return false;
  if (typeof productValue.product_id !== 'number') return false;
  if (typeof productValue.product_type !== 'string') return false;
  if (typeof productValue.title !== 'string') return false;

  return true;
};

export const isProductVariant = (
  variant: unknown,
): variant is ProductVariant => {
  const variantValue = variant as ProductVariant;

  if (!variantValue || typeof variantValue !== 'object') return false;
  if (typeof variantValue.variant_id !== 'number') return false;
  if (typeof variantValue.title !== 'string') return false;
  if (
    !variantValue.shopify_data ||
    typeof variantValue.shopify_data !== 'object'
  )
    return false;
  if (typeof variantValue.shopify_data.price !== 'string') return false;
  if (typeof variantValue.shopify_data.inventory_quantity !== 'number')
    return false;

  return true;
};

export const isTextToBuySidebarType = (
  type: SidebarType | null,
): type is TextToBuySidebarType => {
  return (
    type === TextToBuySidebarNames.T2B_PREVIEW_PURCHASED ||
    type === TextToBuySidebarNames.T2B_PREVIEW_CANCELLED ||
    type === TextToBuySidebarNames.T2B_PREVIEW_DIDNT_PURCHASE ||
    type === TextToBuySidebarNames.T2B_PREVIEW_TIMED_OUT
  );
};

export const isOptimizedABSplitTestStatus = (
  status: unknown,
): status is OptimizedABSplitTestStatus => {
  const statusValue = status as OptimizedABSplitTestStatus;
  return Object.values(OptimizedABSplitTestStatuses).includes(statusValue);
};

export const isCompletedOptimizedABSplitTestStatus = (
  status: unknown,
): status is CompletedOptimizedABSplitTestStatus => {
  const statusValue = status as CompletedOptimizedABSplitTestStatus;
  return Object.values(CompletedOptimizedABSplitTestStatuses).includes(
    statusValue,
  );
};

export const isOptimizedABSplitTestHistoryRecord = (
  record: unknown,
): record is OptimizedABSplitTestHistoryRecord => {
  const recordValue = record as OptimizedABSplitTestHistoryRecord;

  if (!recordValue || typeof recordValue !== 'object') return false;
  if (!isOptimizedABSplitActionParams(recordValue.params)) return false;
  if (!isOptimizedABSplitActionBackendConfig(recordValue.backendConfig))
    return false;
  if (!recordValue.results || typeof recordValue.results !== 'object')
    return false;
  if (
    !recordValue.results.branch_statistics ||
    !Array.isArray(recordValue.results.branch_statistics)
  )
    return false;
  if (
    recordValue.results.branch_statistics.some(
      (branchStatistic) => !isAnalytics(branchStatistic),
    )
  )
    return false;

  return true;
};

export const isOptimizedABSplitTestHistory = (
  history: unknown,
): history is OptimizedABSplitTestHistory => {
  const historyValue = history as OptimizedABSplitTestHistory;

  if (!historyValue || typeof historyValue !== 'object') return false;
  if (!historyValue.records || !Array.isArray(historyValue.records))
    return false;
  if (
    historyValue.records.some(
      (record) => !isOptimizedABSplitTestHistoryRecord(record),
    )
  )
    return false;

  return true;
};

export const isLinkTrackingParam = (
  param: unknown,
): param is LinkTrackingParam => {
  const paramValue = param as LinkTrackingParam;

  if (!paramValue || typeof paramValue !== 'object') return false;
  if (typeof paramValue.enabled !== 'boolean') return false;
  if (typeof paramValue.value !== 'string') return false;

  return true;
};

export const isLinkTrackingCustomParams = (
  params: unknown,
): params is LinkTrackingCustomParams => {
  const paramsValue = params as LinkTrackingCustomParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (
    Object.entries(paramsValue).some(
      ([name, value]) =>
        typeof name !== 'string' || !isLinkTrackingParam(value),
    )
  )
    return false;

  return true;
};

export const isNewLinkTracking = (
  linkTracking: unknown,
): linkTracking is NewLinkTracking => {
  const linkTrackingValue = linkTracking as NewLinkTracking;

  if (!linkTrackingValue || typeof linkTrackingValue !== 'object') return false;
  if (
    !isLinkTrackingParam(linkTrackingValue.utmCampaign) &&
    linkTrackingValue.utmCampaign !== null
  )
    return false;
  if (
    !isLinkTrackingParam(linkTrackingValue.utmMedium) &&
    linkTrackingValue.utmMedium !== null
  )
    return false;
  if (
    !isLinkTrackingParam(linkTrackingValue.utmSource) &&
    linkTrackingValue.utmSource !== null
  )
    return false;
  if (
    !isLinkTrackingCustomParams(linkTrackingValue.customParameters) &&
    linkTrackingValue.customParameters !== null
  )
    return false;

  return true;
};

export const isLinkTracking = (
  linkTracking: unknown,
): linkTracking is LinkTracking => {
  const linkTrackingValue = linkTracking as LinkTracking;

  if (!isNewLinkTracking(linkTrackingValue)) return false;
  if (typeof linkTrackingValue.linkTrackingId !== 'number') return false;

  return true;
};
