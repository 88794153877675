/* eslint-disable no-unused-vars */
// Modal context.
//
// Allows the firing of modals that accept callbacks from anywhere in the app.
//
//
// Example modal
//
// {
//   id: "some_unique_id",
//   header: "Wait! Please confirm before continuing.",
//   text: `
//     This is body copy of the modal.
//     Explain more to the user about
//     what's going on and why they
//     see a warning!!
//   `,
//   // Optional Data
//   callback: () => myCallback("with arguments in a closure"),
//   continueText: "Yes, Delete My Account", // for continue button
//   continueStyle: "primary", // Bootstrap-flavor semantic colors
//   backText: "Go Back", // Text shown on the go back button.
//   backCallback: () => someCleanupFunction(), //
// }

import { createContext } from 'react';

const ModalContext = createContext({
  modals: [],
  addModal: (modal) => {},
  addDialog: (modal) => {},
});

export default ModalContext;
