import { BodyText, Layout } from '@postscript/components';
import { StripeSetupIntentStatuses } from 'components/billing/common/types';
import { BankAccount, Card } from 'components/generic/types';
import React from 'react';
import styled from 'styled-components';
import GenericBankAccount from '../../../../img/billing-bank-account-generic.svg?react';
import GenericCard from '../../../../img/billing-credit-card-generic.svg?react';
import AmericanExpressLogo from '../../../../img/card-brands/billing-american-express.svg?react';
import DiscoverLogo from '../../../../img/card-brands/billing-discover.svg?react';
import MastercardLogo from '../../../../img/card-brands/billing-mastercard.svg?react';
import VisaLogo from '../../../../img/card-brands/billing-visa.svg?react';

const MethodDetails = styled.div`
  margin: auto 0;
  color: var(--text-color);
`;

const MetaText = styled(BodyText).attrs({
  size: 'small',
})`
  margin: 0;
`;

const StyledLogo = styled.div`
  max-width: 68px;
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
`;

const brands = {
  amex: {
    name: 'American Express',
    logo: AmericanExpressLogo,
  },
  visa: {
    name: 'Visa',
    logo: VisaLogo,
  },
  mastercard: {
    name: 'Mastercard',
    logo: MastercardLogo,
  },
  discover: {
    name: 'Discover',
    logo: DiscoverLogo,
  },
};

type KnownBrands = keyof typeof brands;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  card?: Card | null;
  usBankAccount?: BankAccount | null;
  setupIntentStatus?: StripeSetupIntentStatuses;
  isSummary?: boolean;
}

export default function PaymentMethodDetails({
  card,
  usBankAccount,
  setupIntentStatus,
  isSummary,
  ...props
}: Props): JSX.Element {
  const cardBrand = brands[card?.brand as KnownBrands];
  const CardBrandLogo = cardBrand?.logo;
  const cardBrandName = cardBrand?.name || 'Debit/Credit Card';

  if (card)
    return (
      <Layout gap="var(--spacing-3)" {...props}>
        <StyledLogo>
          {CardBrandLogo ? (
            <CardBrandLogo data-testid="card-brand-logo" />
          ) : (
            <GenericCard data-testid="generic-card" />
          )}
        </StyledLogo>
        <MethodDetails data-testid="card-details">
          {`${cardBrandName} ••••${card.last4}`}
          <br />
          <MetaText data-testid="card-expiration">
            Expires {`0${card.expMonth.toString()}`.slice(-2)}/
            {card.expYear.toString().slice(-2)}
          </MetaText>
        </MethodDetails>
      </Layout>
    );

  if (usBankAccount)
    return (
      <Layout gap="var(--spacing-3)" {...props}>
        <StyledLogo>
          <GenericBankAccount data-testid="generic-bank-account" />
        </StyledLogo>
        <MethodDetails data-testid="bank-account-details">
          {`${usBankAccount.bankName} ••••${usBankAccount.last4}`} <br />
          {!isSummary ? (
            <MetaText data-testid="bank-account-status">
              {setupIntentStatus && setupIntentStatus !== 'succeeded'
                ? 'Needs verification'
                : 'Verified'}
            </MetaText>
          ) : null}
        </MethodDetails>
      </Layout>
    );

  return (
    <Layout gap="var(--spacing-3)" {...props}>
      <StyledLogo>
        <GenericCard data-testid="generic-card" />
      </StyledLogo>
      <MethodDetails data-testid="no-payment-method">
        No default payment method
      </MethodDetails>
    </Layout>
  );
}
