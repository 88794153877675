import { Parser } from 'html-to-react';
import Helpers from '../controllers/helpers';

/**
 * Wrapper class for html-to-react Parser.
 *
 * https://github.com/aknuds1/html-to-react
 *
 * This should be used over the Parser class directly as this provides common html
 * santization logic prior to html Parsing.
 *
 */
export default class HtmlParser {
  constructor(react) {
    this.parser = new Parser(react || {});
  }

  parse(body) {
    const sanitizedBody = Helpers.escapeUnsafeHtml(body);
    return this.parser.parse(sanitizedBody);
  }

  parseWithInstructions(body, isValidNode, processingInstructions) {
    const sanitizedBody = Helpers.escapeUnsafeHtml(body);

    return this.parser.parseWithInstructions(
      sanitizedBody,
      isValidNode,
      processingInstructions,
    );
  }
}
