import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryResult } from 'react-query';
import { SegmentSubscriber, SubscriberSegment } from '../types';

interface ListSegmentsResponse {
  segments: {
    segment: SubscriberSegment[];
  };
  totalResult: number;
  totalSegments: number;
}

const listSegments = async (): Promise<ListSegmentsResponse> => {
  const searchParams = new URLSearchParams();
  searchParams.set('is_queue_segment', 'true');
  searchParams.set('recalc_segment', 'true');

  return api.post(`/segments/?${searchParams.toString()}`);
};

export const SEGMENTS_QUERY_KEY = 'segments';

export const useSegments = (): UseQueryResult<SubscriberSegment[]> => {
  return useQuery([SEGMENTS_QUERY_KEY, 'forQueue'], () => listSegments(), {
    select: (data) => data.segments.segment,
  });
};

interface ListSubscribersForSegmentResponseData {
  subscribers: SegmentSubscriber[];
  segment: SubscriberSegment;
  count: number;
}

export interface ListSubscribersForSegmentResponse {
  data: ListSubscribersForSegmentResponseData;
  page?: number;
}

export const listSubscribersForSegment = async (
  id?: number | null,
  { pageLimit = 25, page = 1 }: Record<string, number | string> = {},
): Promise<ListSubscribersForSegmentResponse> => {
  const searchParams = new URLSearchParams();
  searchParams.append('page_size', pageLimit.toString());
  searchParams.set('page', page.toString());
  searchParams.append('return_last_msg', 'true');
  const data = await api.get(
    `/segments/${id}/subscribers?${searchParams.toString()}`,
  );
  return {
    ...data,
    page,
  };
};

export const SUBSCRIBER_FOR_SEGMENT_QUERY_KEY = 'subscriberForSegment';

export const useSubscribersForSegment = (
  id?: number | null,
  { pageLimit = 25, page = 1 }: { pageLimit?: number; page?: number } = {},
): UseQueryResult<ListSubscribersForSegmentResponseData, unknown> => {
  return useQuery(
    [SUBSCRIBER_FOR_SEGMENT_QUERY_KEY, id, pageLimit],
    () => listSubscribersForSegment(id, { pageLimit, page }),
    {
      enabled: !!id,
      select: (data) => data?.data,
    },
  );
};

export default useSegments;
