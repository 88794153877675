import { USAGE_BILLING_ENABLED } from 'components/admin/utils/feature-flags';
import { PaymentProviders } from 'components/billing/common/types';
import {
  useGetInvoicingSettings,
  useGetPaymentMethod,
} from 'components/billing/context/useBilling';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import useGetUsageCreditRemaining from '../../usageCredit/hooks/useGetUsageCreditRemaining';
import { useCanPayInvoice } from './useCanPayInvoice';

export const useHasActivePaymentMethod = (): {
  hasActivePaymentMethod: boolean;
  paymentProvider?: PaymentProviders | null;
} => {
  const { data: paymentMethodData } = useGetPaymentMethod();
  const { paymentProvider } = paymentMethodData ?? {};
  const remainingCredit = useGetUsageCreditRemaining();
  const hasUsageCredit = remainingCredit > 0;
  const canPayInvoice = useCanPayInvoice();
  const { data: invoicingSettings } = useGetInvoicingSettings();
  const { hasFlag }: any = useFeatureFlags();

  const hasActivePaymentMethod =
    canPayInvoice ||
    hasUsageCredit ||
    !!invoicingSettings?.manualPayments ||
    !hasFlag(USAGE_BILLING_ENABLED);

  return {
    hasActivePaymentMethod,
    paymentProvider,
  };
};
