import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface AppBannerListProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

// prettier-ignore
const AppBannerListContainer = styled.div<AppBannerListProps & { fullHeight: number }>`
  z-index: 101;

  & > *:not(:first-child) {
    margin-top: var(--spacing-3);
  }

  & > *:last-child {
    margin-bottom: var(--spacing-6);
  }

  + div .page-header-scroll-marker {
    top: ${({ fullHeight }) => `${fullHeight}px`};
  }
`;

const AppBannerList = ({
  children,
  ...props
}: AppBannerListProps): JSX.Element => {
  const [fullHeight, setFullHeight] = useState(600);
  const appBannerListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setFullHeight(appBannerListRef?.current?.offsetHeight || 0);
  }, [appBannerListRef?.current?.offsetHeight, fullHeight]);

  return (
    <AppBannerListContainer
      fullHeight={fullHeight}
      data-fullheight={fullHeight}
      ref={appBannerListRef}
      {...props}
    >
      {children}
    </AppBannerListContainer>
  );
};

export default AppBannerList;
