import { createContext, useContext } from 'react';
import { VIEW_OPTIONS } from '../constants';

interface UseView {
  view: string;
  setView: (type: string) => void;
}

const ViewContext = createContext<UseView>({
  view: VIEW_OPTIONS.AI_DEBUGGER,
  setView: () => {
    throw new Error('<ViewContext /> not initialized');
  },
});

export const useView = () => useContext(ViewContext);

export default ViewContext;
