/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BodyText, Icon, IconSet } from '@postscript/components';
import parseMessageForHtml from 'components/responses/utils/parseMessageForHtml';
import parseMessagesForPreview from 'components/responses/utils/parseMessageForPreview';
import styled from 'styled-components';
import { SYSTEM_EVENT_SALES_AI_SUBSTRING } from '../constants';
import { useView } from '../hooks/useView';
import { Message } from '../types';
import { canViewSystemMessage } from '../utils/roles';
import ClassificationSystemMessage from './ClassificationSystemMessage';

interface MessageProps {
  message: Message;
}

const MessageFlexWrapper = styled.div`
  display: flex;
  gap: 3px;
  padding: 3px var(--spacing-5);
  width: 100%;
  backdrop-filter: blur(1px);
`;

const parseMessageText = (message: string) =>
  parseMessageForHtml(parseMessagesForPreview(message));

const SystemMessage = ({ message }: MessageProps): JSX.Element => {
  const { view } = useView();
  if (!canViewSystemMessage(message, view)) {
    return <></>;
  }

  const isClassificationEvent = message?.type?.includes(
    SYSTEM_EVENT_SALES_AI_SUBSTRING,
  );

  const getSystemMessageIcon = (text: string) => {
    let icon = IconSet.Bub;

    if (text.includes('Shopify')) {
      icon = IconSet.ShoppingBag;
    }
    if (text.includes('Flow branch split')) {
      icon = IconSet.Split;
    }
    if (text.includes('The subscriber clicked this link')) {
      icon = IconSet.CursorClick;
    }
    if (text.includes('resolved conversation for Subscriber')) {
      icon = IconSet.CheckCircle;
    }
    if (text.includes('was assigned a conversation')) {
      icon = IconSet.UserPlus;
    }
    if (text.includes('Flow message from Flow')) {
      icon = IconSet.Message;
    }
    if (text.includes('routed to a flow')) {
      icon = IconSet.Navigation;
    }
    if (text.includes('Subscriber triggered opt out')) {
      icon = IconSet.Forbid;
    }
    if (text.includes('Subscriber searched for')) {
      icon = IconSet.Search;
    }

    return icon;
  };

  return (
    <MessageFlexWrapper>
      <Icon
        color="var(--gray-5)"
        component={getSystemMessageIcon(message.text || '')}
        size={18}
      />
      <BodyText as="span" size="x-small">
        {isClassificationEvent ? (
          <ClassificationSystemMessage message={message} />
        ) : (
          parseMessageText(message.text)
        )}
      </BodyText>
    </MessageFlexWrapper>
  );
};

export default SystemMessage;
