// Please do not use process.env for environment variables.
//
// There should be very few cases where you use `process.env` (for example,
// NODE_ENV).
//
// In order to keep the application immutable, we should declare the
// environment variables in public/index.html on the `window` object, then set
// them to constants here.
//
// In order to use environment variables, make sure that the environment
// variable is named 'REACT_APP_<ENV_VAR>' so that `create-react-app` can
// interpolate the env var into `public/index.html`.
//
// For more information about immutable webapps, check out:
//
// https://immutablewebapps.org/#-workflow

window.env = window.env ?? {};

const VITE_ENV =
  window.env.VITE_MODE === 'test'
    ? 'test'
    : window.env.VITE_PROD === 'true'
    ? 'production'
    : 'development';

export const ENV = VITE_ENV;

export const API_HOST = window.env.VITE_API_HOST;
export const INTERNAL_API_HOST = window.env.VITE_INTERNAL_API_HOST;
export const SDK_HOST = window.env.VITE_SDK_HOST;
export const SENTRY_RELEASE = window.env.VITE_SENTRY_RELEASE;
export const SENTRY_SAMPLE_RATE = window.env.VITE_SENTRY_SAMPLE_RATE;
export const STRIPE_PUBLIC_KEY = window.env.VITE_STRIPE_PUBLIC_KEY;
export const WEBSOCKET_HOST = window.env.VITE_WEBSOCKET_HOST;
