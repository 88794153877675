import { createContext, FC, useContext, useState } from 'react';

interface UseShopId {
  shopId?: number;
  setShopId: (shopId?: number) => void;
}

const ShopIdContext = createContext<UseShopId>({
  shopId: undefined,
  setShopId: () => null,
});

export const ShopIdProvider: FC = ({ children }) => {
  const [shopId, setShopId] = useState<number | undefined>();
  return (
    <ShopIdContext.Provider
      value={{
        shopId,
        setShopId,
      }}
    >
      {children}
    </ShopIdContext.Provider>
  );
};

export const useSetShopId = (): UseShopId => {
  return useContext(ShopIdContext);
};

export const useShopId = (): number | undefined => {
  return useContext(ShopIdContext)?.shopId;
};
