const breakpoints = {
  small: 768,
  large: 1024,
};

const MediaQueries = {
  desktopOnly: `@media only screen and (min-width: ${breakpoints.large + 1}px)`,
  desktopAndTablet: `@media only screen and (min-width: ${breakpoints.small}px)`,
  tabletOnly: `@media only screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.large}px)`,
  tabletAndPhone: `@media only screen and (max-width: ${breakpoints.large}px)`,
  phoneOnly: `@media only screen and (max-width: ${breakpoints.small - 1}px)`,
};

export default MediaQueries;
