/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
// Hooks to manage feedback loops

import queryString from 'query-string';
import { useState } from 'react';

export const defaultFeedbackPrefix = 'feedback_loop_';
export const mandatoryFeedbackLoopKey = 'mandatory_feedback_loop_redirect';

const feedbackTemplate = {
  loopName: '',
  localUrl: '',
  params: [],
  queryParams: {},
};

const useFeedbackLoop = (
  keys = [],
  queryKeys = [],
  redirectOnly = false,
  allowRedirect = true,
) => {
  keys = Array.sanitize(keys, true);
  queryKeys = Array.sanitize(queryKeys, true);

  // read the current query params from the url
  let queryParams = {};
  if (queryKeys) {
    const currentParams = { ...queryString.parse(window.location.search) };
    queryParams = {
      ...queryParams,
      ...currentParams.filterKV((key, _) => {
        return !queryKeys || !queryKeys.length || queryKeys.includes(key);
      }),
    };
  }

  // get the current feedback loops out of storage
  let storedLoops = {};
  const allAvailableLoops = {
    ...sessionStorage.find(defaultFeedbackPrefix),
    ...localStorage.find(defaultFeedbackPrefix),
  };
  if (keys.length) {
    for (const key of keys) {
      const loopKey = `${defaultFeedbackPrefix}${key}`;
      storedLoops = {
        ...storedLoops,
        ...allAvailableLoops.filterKV((k, _) => k === loopKey),
      };
    }
  }

  // state variables for hook updating
  const [params, _] = useState(queryParams);
  const [feedbackLoops, setFeedbackLoops] = useState(storedLoops);

  // we'll get the mandatory redirect value here and apply it later
  let mandatoryRedirectValue = sessionStorage.getItem(mandatoryFeedbackLoopKey);
  if (!mandatoryRedirectValue)
    mandatoryRedirectValue = localStorage.getItem(mandatoryFeedbackLoopKey);
  if (mandatoryRedirectValue) {
    mandatoryRedirectValue = mandatoryRedirectValue.replace(
      defaultFeedbackPrefix,
      '',
    );
  }

  let feedbackLoopModels = {};
  // if the query params aren't met, we disregard these
  if (Object.keys(queryParams).length === queryKeys.length || redirectOnly) {
    // now we map the feedback loops for return
    feedbackLoopModels = Object.keys(feedbackLoops).reduce((loops, loopKey) => {
      const loop = JSON.parse(storedLoops[loopKey]);
      const parsedLoopKey = loopKey.replace(defaultFeedbackPrefix, '');
      return {
        ...loops,
        [parsedLoopKey]: feedbackTemplate.cloneWith({ ...loop, queryParams }),
      };
    }, {});

    // check if we need to redirect
    if (
      allowRedirect &&
      mandatoryRedirectValue &&
      feedbackLoopModels[mandatoryRedirectValue]
    ) {
      const mandatoryFeedbackLoop = feedbackLoopModels[mandatoryRedirectValue];
      if (mandatoryFeedbackLoop.localUrl !== window.location.pathname) {
        window.location.href = `${
          mandatoryFeedbackLoop.localUrl
        }?${queryString.stringify(queryParams)}`;
      }
    }
  }

  // add a loop
  const addLoop = (
    loopName,
    localUrl,
    params,
    redirectWhenObserved = false,
    persistent = true,
  ) => {
    loopName = `${defaultFeedbackPrefix}${loopName}`;
    const storage = (persistent && sessionStorage) || localStorage;
    const loopObj = {
      localUrl: localUrl || window.location.pathname,
      loopName,
      params,
      redirect: redirectWhenObserved || false,
    };
    // add the feedback loop to the browser storage
    storage.setItem(loopName, JSON.stringify(loopObj));
    // reset the feedback loops
    setFeedbackLoops({ ...feedbackLoops, [loopName]: loopObj });
    // set the mandatory redirect key
    if (redirectWhenObserved)
      storage.setItem(mandatoryFeedbackLoopKey, loopName);
    return loopObj;
  };

  // remove a loop
  const removeLoop = (loopName) => {
    loopName = `${defaultFeedbackPrefix}${loopName}`;
    sessionStorage.removeItem(loopName);
    localStorage.removeItem(loopName);
    const loops = { ...feedbackLoops };
    delete loops[loopName];
    // unset the mandatory redirect key
    if (mandatoryRedirectValue === loopName) {
      sessionStorage.removeItem(mandatoryFeedbackLoopKey);
      localStorage.removeItem(mandatoryFeedbackLoopKey);
    }
    // reset the feedback loops
    setFeedbackLoops({ ...loops });
  };

  // various methods and data to return for use of this hook
  return {
    feedbackLoops: feedbackLoopModels,
    feedbackQueryParams: params,
    addLoop,
    removeLoop,
  };
};

export default useFeedbackLoop;
