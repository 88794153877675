/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

// Container to hold and render modals to act as a provider for them.

import { Button } from '@postscript/components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalContext } from '../../controllers/contexts';
import DialogModal from '../generic/DialogModal';

const ModalRenderer = ({
  // From user data.
  id,
  header,
  text,
  callback,
  continueText,
  continueStyle,
  backText,
  backCallback,
  onError,
  // From the ModalContainer
  remove,
}) => (
  <Modal isOpen>
    <ModalHeader>{header}</ModalHeader>
    <ModalBody>{text}</ModalBody>
    <ModalFooter>
      <Button
        variant="secondary"
        onClick={() => {
          backCallback();
          remove();
        }}
      >
        {backText}
      </Button>
      <Button
        variant={continueStyle}
        onClick={() => {
          try {
            callback();
            remove();
          } catch (err) {
            onError(err);
          }
        }}
      >
        {continueText}
      </Button>
    </ModalFooter>
  </Modal>
);

ModalRenderer.defaultProps = {
  callback: () => {},
  backCallback: () => {},
  onError: (err) => {
    console.error(err);
  },
  continueText: 'Continue',
  continueStyle: 'primary',
  backText: 'Back',
};

const requiredIfNotDialog = (props, propName, componentName) => {
  if (!props.dialog && !props[propName]) {
    throw new Error(
      `The prop ${propName} is marked as required in ${componentName}, but its value is ${props[propName]}.`,
    );
  }
};

ModalRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  header: requiredIfNotDialog,
  text: requiredIfNotDialog,
  callback: PropTypes.func,
  backCallback: PropTypes.func,
  onError: PropTypes.func,
  continueText: PropTypes.string,
  backText: PropTypes.string,
  continueStyle: PropTypes.string,
};

const ModalContainer = ({ children }) => {
  const [modals, changeModals] = useState({});

  const addModal = (modal) => {
    changeModals((m) => ({ ...m, [modal.id]: modal }));
  };

  const addDialog = (modal) => {
    modal.dialog = true;
    addModal(modal);
  };

  const removeModal = (modal) => {
    changeModals((m) => {
      delete m[modal.id];
      return { ...m };
    });
  };

  return (
    <ModalContext.Provider value={{ modals, addModal, addDialog }}>
      {children}
      {Object.entries(modals).map(([modalId, modal]) => {
        if (modal.dialog) {
          const Component = modal.component || DialogModal;
          return (
            <Component
              {...modal}
              startShown
              key={modalId}
              body={null}
              closeCallback={() => removeModal({ ...modal })}
            >
              {modal.body || null}
            </Component>
          );
        }
        return (
          <ModalRenderer
            {...modal}
            key={modalId}
            remove={() => removeModal({ ...modal })}
          />
        );
      })}
    </ModalContext.Provider>
  );
};

export default ModalContainer;
