/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable func-names */
import { Button } from '@postscript/components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DialogModal = function (props) {
  const {
    className = '',
    headerClassName = '',
    bodyClassName = '',
    footerClassName = '',
    children,
    startShown = false,
    title = null,
    yesCallback = () => true,
    yesProps = {},
    yesLabel = 'Yes',
    noProps = {},
    noLabel = 'No',
    noCallback = () => true,
    closeCallback = () => true,
  } = props;

  const [show, setShow] = useState(startShown);
  const toggle = () => setShow(!show);
  const clickBtn = (cb) => {
    const res = cb();
    // resolve the promise if necessary
    if (res instanceof Promise) {
      return res.then((r) => {
        // only toggle if truthy
        if (r || r === undefined) {
          toggle();
        }
        return Promise.resolve(r);
      });
    }
    // truthy or undefined (meaning no return supplied)
    if (res || res === undefined) {
      toggle();
    }
    return res;
  };

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      onClosed={closeCallback}
      className={`modal ${className}`}
    >
      {title ? (
        <ModalHeader
          className={headerClassName}
          toggle={noCallback ? undefined : toggle}
        >
          {title}
        </ModalHeader>
      ) : null}
      <ModalBody className={bodyClassName}>{children}</ModalBody>
      <ModalFooter className={footerClassName}>
        {noCallback ? (
          <Button
            {...noProps}
            variant="text"
            onClick={() => clickBtn(noCallback)}
          >
            {noLabel}
          </Button>
        ) : null}
        <Button
          {...yesProps}
          variant="primary"
          onClick={() => clickBtn(yesCallback)}
        >
          {yesLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DialogModal.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  startShown: PropTypes.bool,
  title: PropTypes.string,
  yesCallback: PropTypes.func,
  yesProps: PropTypes.object,
  yesLabel: PropTypes.string,
  noProps: PropTypes.object,
  noLabel: PropTypes.string,
  noCallback: PropTypes.func,
  closeCallback: PropTypes.func,
};

export default DialogModal;
