import produce from 'immer';
import React, { createContext, useContext, useReducer } from 'react';

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

export interface Modal {
  dismissOnBackdropClick?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

interface ShowModal {
  type: typeof SHOW_MODAL;
  modal: Modal | null;
}

interface HideModal {
  type: typeof HIDE_MODAL;
}

type ReducerAction = ShowModal | HideModal;

interface State {
  modal: Modal | null;
  showModal: (modal: Modal) => void;
  hideModal: () => void;
}

const initialState: State = {
  modal: null,
  showModal: () => undefined,
  hideModal: () => undefined,
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case SHOW_MODAL:
      draft.modal = action.modal;
      break;
    case HIDE_MODAL:
      draft.modal = null;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const GlobalModalContext = createContext(initialState);
export const useGlobalModal = (): State => useContext(GlobalModalContext);

interface Props {
  children: JSX.Element;
}

export const GlobalModalProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);

  const showModal = (modal: Modal) => {
    dispatch({
      type: SHOW_MODAL,
      modal,
    });
  };

  const hideModal = () => {
    dispatch({
      type: HIDE_MODAL,
    });
  };

  return (
    <GlobalModalContext.Provider
      value={{
        ...state,
        showModal,
        hideModal,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  );
};
