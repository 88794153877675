import { FieldLabel } from '@postscript/components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LabelWrapper = styled(FieldLabel).attrs(({ id, name }) => ({
  className: '',
  htmlFor: id || name,
}))``;

const FormLabel = ({ children, id, name }) => (
  <LabelWrapper id={id} name={name}>
    {children}
  </LabelWrapper>
);

FormLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string,
};

FormLabel.defaultProps = {
  children: <></>,
  id: null,
  name: null,
};

export default FormLabel;
