import {
  StaticActionsTypes,
  StaticActionsWithFormTypes,
} from 'components/flowBuilder/constants/actions';

export const StaticStepsWithFormTypes = {
  ...StaticActionsWithFormTypes,
  AUTOMATION_TRIGGER: 'AutomationTrigger',
  CAMPAIGN_TRIGGER: 'CampaignTrigger',
} as const;

export const StaticStepsWithInfoTypes = {
  BRANCH_INFO: 'BranchInfo',
  TEXT_TO_BUY_BRANCH_INFO: 'TextToBuyBranchInfo',
} as const;

export const StaticComputedStepsTypes = {
  ...StaticStepsWithInfoTypes,
  RECONVENING_BRANCHES: 'ReconveningBranches',
} as const;

export const StaticStepsTypes = {
  ...StaticActionsTypes,
  ...StaticStepsWithFormTypes,
  ...StaticComputedStepsTypes,
} as const;
