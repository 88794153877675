import { Button, Input, Layout, toast } from '@postscript/components';
import { USER_NOTIFICATION_PREFERENCES } from 'components/admin/utils/feature-flags';
import FormikField from 'components/billing/common/FormikField';
import FormLayout from 'components/billing/common/FormLayout';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getShopId } from '../helpers';
import {
  useGetUserPhoneNumber,
  useUpdateUserPhoneNumber,
  useUpdateUserProfile,
} from '../hooks/useUsers';
import { User } from '../types';
import Avatar from './Avatar';

interface Props {
  user: User;
}

export default function ProfileForm({ user }: Props): JSX.Element {
  const { mutateAsync: updateProfile } = useUpdateUserProfile();
  const { username, profile, guid = '' } = user;
  const { hasLabsFlag, hasInitialized } = usePSLabs();
  const notificationPreferencesEnabled =
    hasInitialized && hasLabsFlag(USER_NOTIFICATION_PREFERENCES);
  const shopId = getShopId();
  const { data: phoneNumber } = useGetUserPhoneNumber(guid, shopId, {
    enabled: notificationPreferencesEnabled && !!guid,
  });
  const { mutateAsync: updatePhoneNumber } = useUpdateUserPhoneNumber();

  return (
    <Formik
      validationSchema={yup.object().shape({
        username: yup.string(),
        profile: yup.object().shape({
          avatarUrl: yup.string().nullable(),
          firstName: yup.string().required('First name is required').nullable(),
          lastName: yup.string().nullable(),
        }),
        phoneNumber: yup.string().nullable(),
      })}
      initialValues={{
        username,
        profile: {
          avatarUrl: profile?.avatarUrl ?? '',
          firstName: profile?.firstName ?? '',
          lastName: profile?.lastName ?? '',
        },
        phoneNumber: phoneNumber ?? '',
      }}
      onSubmit={async (values, { resetForm }) => {
        await Promise.all([
          updateProfile(values),
          notificationPreferencesEnabled
            ? updatePhoneNumber({
                userUuid: guid,
                phoneNumber: values.phoneNumber,
              })
            : Promise.resolve(),
        ]);
        toast.success('Profile updated.');
        resetForm();
      }}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Layout valign="top" gap="var(--spacing-5)">
            <Avatar />
            <FormLayout>
              <Layout>
                <FormikField
                  name="profile.firstName"
                  label="First name"
                  as={Input}
                />
                <FormikField
                  name="profile.lastName"
                  label="Last name"
                  as={Input}
                />
              </Layout>
              <FormikField
                name="username"
                label="Email"
                as={Input}
                message="Email cannot be changed."
                disabled
              />
              {notificationPreferencesEnabled && (
                <FormikField
                  name="phoneNumber"
                  label="Phone number"
                  as={Input}
                  message="Used for Postscript communications (e.g. SMS notifications)."
                />
              )}
              <Layout align="right">
                <Button
                  type="submit"
                  variant="secondary"
                  disabled={isSubmitting || !dirty}
                >
                  Save Changes
                </Button>
              </Layout>
            </FormLayout>
          </Layout>
        </Form>
      )}
    </Formik>
  );
}
