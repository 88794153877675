import {
  BodyText,
  Button,
  Code,
  IconSet,
  Input,
  Layout,
  LinkText,
  SelectMenu,
} from '@postscript/components';
import { Option } from '@postscript/components/dist/esm/components/form/SelectMenu/types';
import { POPUP_PROGRAMMATIC_TRIGGER } from 'components/admin/utils/feature-flags';
import FormikField from 'components/billing/common/FormikField';
import { nameofFactory } from 'components/flowBuilder/types/util';
import { POPUP_TYPES, TRIGGER_TYPES } from 'components/Popup/constants';
import {
  DESKTOP_POPUP_TRIGGER_OPTIONS,
  MOBILE_POPUP_TRIGGER_OPTIONS,
} from 'components/Popup/constants/PopupSelectMenuOptions';
import { PopupFormValues, PopupTypes } from 'components/Popup/types';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const nameof = nameofFactory<PopupFormValues>();

const CustomTriggerHelpContainer = styled.div`
  background-color: var(--main-bkg-color);
  border: 1px solid var(--border-color-dim);
  border-radius: var(--border-radius-medium);
  grid-column: 1/-1;
  padding: var(--spacing-2);
`;

const StyledCodeBlock = styled(Code)`
  display: block;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-x-small);
  font-size: var(--body-text-font-size-small);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: pre-wrap;
`;

const getEmbedCode = (popupId: number) =>
  `window.postscript.popups.open(${popupId}, {
  activePopupBehavior: 'ALWAYS_DISMISS',
  respectPopupStatus: true
});
`;

const CustomTriggerHelp = ({ id }: { id: number }) => {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasCopied) {
      timeout = setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [hasCopied]);

  return (
    <CustomTriggerHelpContainer>
      <BodyText size="small" style={{ marginBottom: 'var(--spacing-2)' }}>
        Use custom criteria to trigger a popup by embedding code on your site.
        Learn more about setting additional criteria (e.g. popup priority,
        cookies, etc) in the{' '}
        <LinkText
          target="_blank"
          href="https://developers.postscript.io/docs/javascript-sdk-api-reference#windowpostscriptpopupsopen"
        >
          Developer Documentation
        </LinkText>
        .
      </BodyText>

      <Layout align="justify">
        <BodyText bold>Embed code</BodyText>
        <Button
          onClick={async () => {
            await navigator.clipboard.writeText(getEmbedCode(id));
            setHasCopied(true);
          }}
          size="small"
          variant="plain"
          icon={hasCopied ? IconSet.CheckCircle : IconSet.Copy}
          iconPosition="right"
        >
          {hasCopied ? 'Copied' : 'Copy code'}
        </Button>
      </Layout>
      <StyledCodeBlock>{getEmbedCode(id)}</StyledCodeBlock>
    </CustomTriggerHelpContainer>
  );
};

const getTriggerOptions = (
  popupType: PopupTypes,
  hasProgrammaticTriggersEnabled: boolean,
): Option[] => {
  const options =
    popupType === POPUP_TYPES.DESKTOP
      ? DESKTOP_POPUP_TRIGGER_OPTIONS
      : MOBILE_POPUP_TRIGGER_OPTIONS;

  return hasProgrammaticTriggersEnabled
    ? options
    : options.filter((option) => option.value !== TRIGGER_TYPES.CUSTOM);
};

const TriggerSettings = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<PopupFormValues>();
  const { hasLabsFlag } = usePSLabs();
  const { id, popupType, trigger } = values;

  const triggerOptions = getTriggerOptions(
    popupType as PopupTypes,
    hasLabsFlag(POPUP_PROGRAMMATIC_TRIGGER),
  );

  return (
    <>
      {triggerOptions.length > 1 && (
        <FormikField
          name={nameof('trigger')}
          label="Popup trigger"
          as={SelectMenu}
          options={triggerOptions}
          value={triggerOptions.find((o) => o.value === values.trigger)}
          onChange={({ value }: { value: string }) => {
            setFieldValue(`trigger`, value);
          }}
          isClearable={false}
        />
      )}
      {trigger === TRIGGER_TYPES.CUSTOM && <CustomTriggerHelp id={id} />}
      {trigger === TRIGGER_TYPES.DELAY && (
        <FormikField
          name={nameof('delay')}
          label="Popup delay"
          type="number"
          as={Input}
          suffix="seconds"
        />
      )}
    </>
  );
};

export default TriggerSettings;
