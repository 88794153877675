import { BodyText } from '@postscript/components';
import { TypicalCarrierFeesCountryCodes } from 'components/billing/common/types';
import React from 'react';
import styled from 'styled-components';
import {
  MMS_ENABLED_COUNTRY_CODES,
  MULTIPLIER_FRACTION_DIGITS,
  TYPICAL_CARRIER_FEES_BY_COUNTRY,
} from '../../common/constants';
import { formatDollars } from '../../common/utils';
import TypicalCarrierFees from './TypicalCarrierFees';

const StyledBodyText = styled(BodyText).attrs({ color: 'var(--text-color)' })`
  margin-bottom: var(--spacing-1);
`;

const StyledUl = styled.ul`
  &:last-child {
    margin-bottom: var(--spacing-1);
  }
`;

const StyledLi = styled.li`
  list-style-type: none;
  padding-bottom: var(--spacing-1);

  span {
    display: block;
    font: var(--body-text-small);
    color: var(--text-color-dim);
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  countryCode: string;
  smsRate?: number;
  mmsRate?: number;
}

export default function CountryMessageRates({
  countryCode,
  smsRate,
  mmsRate,
  ...props
}: Props): JSX.Element {
  const carrierFees =
    TYPICAL_CARRIER_FEES_BY_COUNTRY[
      countryCode as TypicalCarrierFeesCountryCodes
    ];

  return (
    <div {...props}>
      <StyledBodyText>
        <strong>{countryCode} message rates:</strong>
      </StyledBodyText>
      <StyledUl>
        <StyledLi data-cy={`plan-${countryCode}-sms-multiplier`}>
          {smsRate ? (
            <>
              {formatDollars(smsRate / 100, {
                maximumFractionDigits: MULTIPLIER_FRACTION_DIGITS,
              })}
              /SMS
            </>
          ) : (
            'Custom SMS rate'
          )}
          {carrierFees && <TypicalCarrierFees typicalFee={carrierFees.sms} />}
        </StyledLi>
        {MMS_ENABLED_COUNTRY_CODES.includes(countryCode) && (
          <StyledLi data-cy={`plan-${countryCode}-mms-multiplier`}>
            {mmsRate ? (
              <>
                {formatDollars(mmsRate / 100, {
                  maximumFractionDigits: MULTIPLIER_FRACTION_DIGITS,
                })}
                /MMS
              </>
            ) : (
              'Custom MMS rate'
            )}
            {carrierFees && <TypicalCarrierFees typicalFee={carrierFees.mms} />}
          </StyledLi>
        )}
      </StyledUl>
    </div>
  );
}
