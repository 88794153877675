import { capitalize } from 'lodash';
import { QUESTION_TYPES } from '../constants';
import { PAGE_COLLECTION_ATTRIBUTE_PREFIX } from '../PopupPageTabs';
import { PopupPage } from '../types';
import { hasQuestionOfType } from './questionUtils';

/*
  V1 without flag on do not use these, v1 with data collection ff on use a
  member of collection attributes (see getTabName), and v2 steps map to two
  choices.
*/

export const getTabName = (stepId: string, customPage?: PopupPage): string => {
  if (stepId.startsWith(PAGE_COLLECTION_ATTRIBUTE_PREFIX)) {
    const isPhoneStep = hasQuestionOfType(
      customPage?.questions ?? [],
      QUESTION_TYPES.PHONE,
    );
    return isPhoneStep ? 'Phone Collection Step' : 'Custom Step';
  }

  return capitalize(stepId);
};

export const getSentenceCaseStepName = (
  stepId: string,
  customPage?: PopupPage,
): string => capitalize(getTabName(stepId, customPage).toLowerCase());
