import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { INVOICE_STATUSES } from '../common/constants';
import { useGetUsageBillingEnabled } from '../common/hooks/useGetUsageBillingEnabled';
import {
  AvailablePaymentMethods,
  CreateUsageCreditInput,
  CustomPlanInput,
  Cycle,
  Cycles,
  CycleWithTotals,
  Invoice,
  InvoicesResponse,
  InvoicingSettings,
  InvoicingStatus,
  LedgerRecord,
  LedgerRecordInput,
  LedgerRecordStat,
  ManualPaymentReceiptInput,
  MinimumSpend,
  NewCustomProductPlan,
  Package,
  PaymentMethod,
  Plan,
  ProductPlan,
  ProductPlanType,
  RecurringFee,
  RecurringFeeInput,
  ScheduledPlanChange,
  SearchParams,
  StatsTotalResponse,
  UpdateInvoiceStatus,
  UsageCredit,
} from '../common/types';
import { toastError } from '../common/utils';
import { EstimatedCost } from '../modules/plans/estimatedCampaignCost/types';
import {
  assignProductPlan,
  cancelScheduledPlanChange,
  createCustomProductPlan,
  createLedgerRecord,
  createRecurringFee,
  createUsageCredit,
  deactivateUsageCredit,
  disableRecurringFee,
  forceSetCurrentPlan,
  getAvailablePackages,
  getAvailablePaymentMethods,
  getCurrentAndNextCycles,
  getCurrentPlan,
  getCurrentProductPlans,
  getCustomMarketingPlans,
  getCyclesWithTotals,
  getEstimatedCostsByCountryCode,
  getEstimatedSalesTaxAmount,
  getInvoiceByNumber,
  getInvoices,
  getInvoicesByCycle,
  getInvoicingSettings,
  getInvoicingStatus,
  getLedgerRecords,
  getMarketingPlans,
  getMinimumSpend,
  getNextPlan,
  getPackages,
  getPastCycles,
  getPaymentMethod,
  getProductPlansByType,
  getRecurringFees,
  getScheduledPlanChange,
  getUninvoicedLedgerRecords,
  getUninvoicedUsageAmount,
  getUninvoicedUsageStats,
  getUsageCredits,
  getUsageStats,
  getUsageStatsTotalsByField,
  moveAccountToStripe,
  payInvoice,
  recordManualInvoicePayment,
  removeCustomPlan,
  removeProductPlan,
  schedulePlanChange,
  setAvailablePaymentMethods,
  setCurrentPlan,
  setCustomPlan,
  setNextPlan,
  updateInvoiceStatus,
  updateInvoicingSettings,
  updateMaxFailedInvoices,
  updateRecurringFee,
} from './billingClient';

export const ALL_INVOICES = 'billing_all_invoices';
export const INVOICES = 'billing_invoices';
export const INVOICE = 'billing_invoice';
export const CYCLES_WITH_TOTAL = 'billing_cycles_with_total';
export const UNINVOICED_USAGE = 'billing_uninvoiced_usage';
export const UNINVOICED_LEDGER_RECORDS = 'billing_uninvoiced_ledger_records';
export const USAGE_CREDITS = 'billing_usage_credits';
export const MARKETING_PLANS = 'billing_marketing_plans';
export const CUSTOM_MARKETING_PLANS = 'billing_custom_marketing_plans';
export const PRODUCT_PLANS = 'billing_product_plans';
export const CURRENT_PRODUCT_PLANS = 'billing_current_product_plans';
export const PACKAGES = 'billing_packages';
export const AVAILABLE_PACKAGES = 'billing_available_packages';
export const AVAILABLE_PAYMENT_METHODS = 'billing_available_payment_methods';
export const UNINVOICED_USAGE_STATS = 'billing_uninvoiced_usage_stats';
export const RECURRING_FEES = 'billing_recurring_fees';
export const SCHEDULED_PLAN_CHANGE = 'billing_scheduled_plan_change';
export const CURRENT_PLAN = 'billing_current_plan';
export const NEXT_PLAN = 'billing_next_plan';
export const CURRENT_AND_NEXT_CYCLES = 'billing_current_and_next_cycles';
export const PAST_CYCLES = 'billing_past_cycles';
export const CAMPAIGN_ESTIMATED_COST = 'billing_campaign_estimated_cost';
export const MINIMUM_SPEND_PERIOD = 'billing_minimum_spend_period';
export const USAGE_STATS = 'billing_usage_stats';
export const USAGE_STATS_TOTALS = 'billing_usage_stats_totals';
export const LEDGER_RECORDS = 'billing_ledger_records';
export const INVOICING_STATUS = 'billing_invoicing_status';
export const PAYMENT_METHOD = 'billing_payment_method';
export const FAILED_INVOICES = 'billing_failed_invoices';
export const INVOICING_SETTINGS = 'billing_invoicing_settings';
export const ESTIMATED_SALES_TAX = 'billing_estimated_sales_tax';

const staleTime = 1000 * 60;

export function useGetMinimumSpend(
  cycleId?: number,
  options?: UseQueryOptions<
    MinimumSpend,
    any,
    MinimumSpend,
    [typeof MINIMUM_SPEND_PERIOD, number]
  >,
): { isLoading: boolean; error: unknown; data: MinimumSpend | undefined } {
  const enabled = useGetUsageBillingEnabled();

  const { isLoading, error, data } = useQuery(
    [MINIMUM_SPEND_PERIOD, cycleId ?? 0],
    () => getMinimumSpend(cycleId ?? 0),
    {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    },
  );
  return { isLoading, error, data };
}

export function useRecordManualInvoicePayment(): UseMutationResult<
  void,
  any,
  ManualPaymentReceiptInput
> {
  const queryClient = useQueryClient();

  return useMutation(
    (args: ManualPaymentReceiptInput) => recordManualInvoicePayment(args),
    {
      onSuccess: () => queryClient.refetchQueries(ALL_INVOICES),
      onError: toastError,
    },
  );
}

export function useGetInvoices(
  search?: SearchParams,
  options?: UseQueryOptions<
    InvoicesResponse,
    any,
    InvoicesResponse,
    [typeof ALL_INVOICES, SearchParams | undefined]
  >,
): UseQueryResult<InvoicesResponse> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery([ALL_INVOICES, search], () => getInvoices(search), {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetUsageStats(
  { startDate, endDate }: { startDate: string; endDate: string },
  options?: UseQueryOptions<
    LedgerRecordStat[],
    any,
    LedgerRecordStat[],
    [typeof USAGE_STATS, SearchParams | undefined]
  >,
): UseQueryResult<LedgerRecordStat[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(
    [USAGE_STATS, { startDate, endDate }],
    () => getUsageStats({ startDate, endDate }),
    {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    },
  );
}

export function useGetInvoicesByCycle(
  cycleId?: number,
  options?: UseQueryOptions<
    Invoice[],
    any,
    Invoice[],
    [typeof INVOICES, number]
  >,
): UseQueryResult<Invoice[]> {
  const enabled = useGetUsageBillingEnabled();

  if (!cycleId) {
    return useQuery([INVOICES, 0], () => [], {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    });
  }

  return useQuery([INVOICES, cycleId], () => getInvoicesByCycle(cycleId), {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetInvoiceByNumber(
  invoiceNumber: string,
  options?: UseQueryOptions<Invoice, any, Invoice, [typeof INVOICE, string]>,
): UseQueryResult<Invoice> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(
    [INVOICE, invoiceNumber],
    () => getInvoiceByNumber(invoiceNumber),
    {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    },
  );
}

export function useGetCyclesWithTotals(
  options?: UseQueryOptions<
    CycleWithTotals[],
    any,
    CycleWithTotals[],
    typeof CYCLES_WITH_TOTAL
  >,
): UseQueryResult<CycleWithTotals[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(CYCLES_WITH_TOTAL, getCyclesWithTotals, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetUninvoicedUsageAmount(
  options?: UseQueryOptions<number, any, number, typeof UNINVOICED_USAGE>,
): UseQueryResult<number> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(UNINVOICED_USAGE, getUninvoicedUsageAmount, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetUninvoicedLedgerRecords(
  options?: UseQueryOptions<
    LedgerRecord[],
    any,
    LedgerRecord[],
    typeof UNINVOICED_LEDGER_RECORDS
  >,
): UseQueryResult<LedgerRecord[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(UNINVOICED_LEDGER_RECORDS, getUninvoicedLedgerRecords, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetUsageCredits(
  options?: UseQueryOptions<
    UsageCredit[],
    any,
    UsageCredit[],
    typeof USAGE_CREDITS
  >,
): UseQueryResult<UsageCredit[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(USAGE_CREDITS, getUsageCredits, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useDeactivateUsageCredit(): UseMutationResult<
  void,
  any,
  number
> {
  const queryClient = useQueryClient();

  return useMutation((id: number) => deactivateUsageCredit(id), {
    onSuccess: () => queryClient.refetchQueries(USAGE_CREDITS),
    onError: toastError,
  });
}

export function useCreateUsageCredit(): UseMutationResult<
  void,
  any,
  CreateUsageCreditInput
> {
  const queryClient = useQueryClient();

  return useMutation(
    (args: CreateUsageCreditInput) => createUsageCredit(args),
    {
      onSuccess: () => queryClient.refetchQueries(USAGE_CREDITS),
      onError: toastError,
    },
  );
}

export function useGetMarketingPlans(
  options?: UseQueryOptions<Plan[], any, Plan[], typeof MARKETING_PLANS>,
): UseQueryResult<Plan[]> {
  return useQuery(MARKETING_PLANS, getMarketingPlans, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetProductPlansByType(
  type: ProductPlanType,
  options?: UseQueryOptions<
    ProductPlan[],
    any,
    ProductPlan[],
    [typeof PRODUCT_PLANS, ProductPlanType]
  >,
): UseQueryResult<ProductPlan[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery([PRODUCT_PLANS, type], () => getProductPlansByType(type), {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetCurrentProductPlansByType(
  type: ProductPlanType,
  options?: UseQueryOptions<
    ProductPlan[],
    any,
    ProductPlan[],
    [typeof CURRENT_PRODUCT_PLANS, ProductPlanType]
  >,
): UseQueryResult<ProductPlan[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(
    [CURRENT_PRODUCT_PLANS, type],
    () => getCurrentProductPlans(type),
    {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    },
  );
}

export function useAssignProductPlan(): UseMutationResult<
  void,
  any,
  { id: number; productType: ProductPlanType }
> {
  return useMutation(
    ({ id, productType }: { id: number; productType: ProductPlanType }) =>
      assignProductPlan(id, productType),
    {
      onError: toastError,
    },
  );
}

export function useRemoveProductPlan(): UseMutationResult<void, any, number> {
  return useMutation((id: number) => removeProductPlan(id), {
    onError: toastError,
  });
}

export function useGetCustomMarketingPlans(
  options?: UseQueryOptions<Plan[], any, Plan[], typeof CUSTOM_MARKETING_PLANS>,
): UseQueryResult<Plan[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(CUSTOM_MARKETING_PLANS, getCustomMarketingPlans, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetAvailablePackages(
  options?: UseQueryOptions<
    Package[],
    any,
    Package[],
    typeof AVAILABLE_PACKAGES
  >,
): UseQueryResult<Package[]> {
  return useQuery(AVAILABLE_PACKAGES, getAvailablePackages, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetPackages(
  options?: UseQueryOptions<Package[], any, Package[], typeof PACKAGES>,
): UseQueryResult<Package[]> {
  return useQuery(PACKAGES, getPackages, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetAvailablePaymentMethods(
  options?: UseQueryOptions<
    AvailablePaymentMethods,
    any,
    AvailablePaymentMethods,
    typeof AVAILABLE_PAYMENT_METHODS
  >,
): UseQueryResult<AvailablePaymentMethods> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethods, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useSetAvailablePaymentMethods(): UseMutationResult<
  void,
  any,
  AvailablePaymentMethods
> {
  const queryClient = useQueryClient();

  return useMutation(
    (values: AvailablePaymentMethods) => setAvailablePaymentMethods(values),
    {
      onSuccess: () => queryClient.refetchQueries(AVAILABLE_PAYMENT_METHODS),
      onError: toastError,
    },
  );
}

export function useGetUninvoicedUsageStats(
  options?: UseQueryOptions<
    LedgerRecordStat[],
    any,
    LedgerRecordStat[],
    typeof UNINVOICED_USAGE_STATS
  >,
): UseQueryResult<LedgerRecordStat[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(UNINVOICED_USAGE_STATS, getUninvoicedUsageStats, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetLedgerRecords(
  search?: SearchParams,
  options?: UseQueryOptions<
    LedgerRecord[],
    any,
    LedgerRecord[],
    [typeof LEDGER_RECORDS, SearchParams | undefined]
  >,
): UseQueryResult<LedgerRecord[]> {
  return useQuery([LEDGER_RECORDS, search], () => getLedgerRecords(search), {
    ...options,
    staleTime,
  });
}

export function useGetRecurringFees(
  search?: SearchParams,
  options?: UseQueryOptions<
    RecurringFee[],
    any,
    RecurringFee[],
    [typeof RECURRING_FEES, SearchParams | undefined]
  >,
): UseQueryResult<RecurringFee[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery([RECURRING_FEES, search], () => getRecurringFees(search), {
    enabled,
    onError: toastError,
    ...options,
    staleTime,
  });
}

export function useCreateRecurringFee(): UseMutationResult<
  void,
  any,
  RecurringFeeInput
> {
  const queryClient = useQueryClient();

  return useMutation(
    (values: RecurringFeeInput) => createRecurringFee(values),
    {
      onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
      onError: toastError,
    },
  );
}

interface UpdateRecurringFeeInput {
  id: number;
  recurringFee: RecurringFeeInput;
}

export function useUpdateRecurringFee(): UseMutationResult<
  void,
  any,
  UpdateRecurringFeeInput
> {
  const queryClient = useQueryClient();

  return useMutation(
    (values: UpdateRecurringFeeInput) => updateRecurringFee(values),
    {
      onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
      onError: toastError,
    },
  );
}

export function useDisableRecurringFee(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  return useMutation((values: number) => disableRecurringFee(values), {
    onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
    onError: toastError,
  });
}

export function useGetScheduledPlanChange(
  options?: UseQueryOptions<
    ScheduledPlanChange | null,
    any,
    ScheduledPlanChange | null,
    typeof SCHEDULED_PLAN_CHANGE
  >,
): UseQueryResult<ScheduledPlanChange | null> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(SCHEDULED_PLAN_CHANGE, getScheduledPlanChange, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useSchedulePlanChange(): UseMutationResult<
  void,
  any,
  ScheduledPlanChange
> {
  const queryClient = useQueryClient();

  return useMutation(
    (values: ScheduledPlanChange) => schedulePlanChange(values),
    {
      onSuccess: () => {
        queryClient.refetchQueries(SCHEDULED_PLAN_CHANGE);
        queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
        queryClient.refetchQueries(CURRENT_PLAN);
        queryClient.refetchQueries(NEXT_PLAN);
      },
      onError: toastError,
    },
  );
}

export function useCancelScheduledPlanChange(): UseMutationResult<
  void,
  any,
  void
> {
  const queryClient = useQueryClient();

  return useMutation(cancelScheduledPlanChange, {
    onSuccess: () => {
      queryClient.refetchQueries(SCHEDULED_PLAN_CHANGE);
      queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
    },
    onError: toastError,
  });
}

export function useGetCurrentPlan(
  options?: UseQueryOptions<Plan, any, Plan, typeof CURRENT_PLAN>,
): UseQueryResult<Plan> {
  return useQuery(CURRENT_PLAN, getCurrentPlan, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetCampaignEstimatedCost(
  hasMediaUrl: boolean,
  segmentId: number,
  textBody: string,
  enabled: boolean,
): { isLoading: boolean; error: unknown; data: EstimatedCost[] | undefined } {
  const { isLoading, error, data } = useQuery(
    CAMPAIGN_ESTIMATED_COST,
    () => getEstimatedCostsByCountryCode(hasMediaUrl, segmentId, textBody),
    { enabled },
  );

  return { isLoading, error, data };
}

export function useSetCurrentPlan(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  return useMutation((id: number) => setCurrentPlan(id), {
    onSuccess: () => {
      queryClient.refetchQueries(CURRENT_PLAN);
      queryClient.refetchQueries(NEXT_PLAN);
      queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
    },
    onError: toastError,
  });
}

export function useForceSetCurrentPlan(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  return useMutation((planId: number) => forceSetCurrentPlan(planId), {
    onSuccess: () => {
      queryClient.refetchQueries(CURRENT_PLAN);
      queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
    },
    onError: toastError,
  });
}

export function useGetNextPlan(
  options?: UseQueryOptions<Plan, any, Plan, typeof NEXT_PLAN>,
): UseQueryResult<Plan> {
  return useQuery(NEXT_PLAN, getNextPlan, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useSetNextPlan(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  return useMutation((id: number) => setNextPlan(id), {
    onSuccess: () => {
      queryClient.refetchQueries(NEXT_PLAN);
      queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
    },
    onError: toastError,
  });
}

export function useGetCurrentAndNextCycles(
  options?: UseQueryOptions<
    Cycles,
    any,
    Cycles,
    typeof CURRENT_AND_NEXT_CYCLES
  >,
): UseQueryResult<Cycles> {
  return useQuery(CURRENT_AND_NEXT_CYCLES, getCurrentAndNextCycles, {
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetPastCycles(
  options?: UseQueryOptions<Cycle[], any, Cycle[], typeof PAST_CYCLES>,
): UseQueryResult<Cycle[]> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(PAST_CYCLES, getPastCycles, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useUpdateMaxFailedInvoices(): UseMutationResult<
  { failedInvoicesForPastDue: number },
  any,
  number
> {
  return useMutation(
    (numOfInvoices: number) => updateMaxFailedInvoices(numOfInvoices),
    {
      onError: toastError,
    },
  );
}

export function useGetInvoicingStatus(
  options?: UseQueryOptions<
    InvoicingStatus,
    unknown,
    InvoicingStatus,
    typeof INVOICING_STATUS
  >,
): UseQueryResult<InvoicingStatus> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(INVOICING_STATUS, getInvoicingStatus, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetPaymentMethod(
  options?: UseQueryOptions<
    PaymentMethod,
    unknown,
    PaymentMethod,
    typeof PAYMENT_METHOD
  >,
): UseQueryResult<PaymentMethod> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(PAYMENT_METHOD, getPaymentMethod, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useMoveAccountToStripe(): UseMutationResult<
  PaymentMethod,
  unknown,
  void
> {
  const queryClient = useQueryClient();

  return useMutation(moveAccountToStripe, {
    onSuccess: () => queryClient.refetchQueries(PAYMENT_METHOD),
  });
}

export function useSetCustomPlan(): UseMutationResult<
  Plan,
  unknown,
  CustomPlanInput
> {
  return useMutation((plan: CustomPlanInput) => setCustomPlan(plan), {
    onError: toastError,
  });
}

export function useCreateLedgerRecord(): UseMutationResult<
  void,
  any,
  LedgerRecordInput
> {
  const queryClient = useQueryClient();

  return useMutation((args: LedgerRecordInput) => createLedgerRecord(args), {
    onSuccess: () => queryClient.refetchQueries(LEDGER_RECORDS),
    onError: toastError,
  });
}

export function useRemoveCustomPlan(): UseMutationResult<
  void,
  unknown,
  number
> {
  return useMutation((planId: number) => removeCustomPlan(planId), {
    onError: toastError,
  });
}

export function useGetFailedInvoices(
  options?: UseQueryOptions<
    InvoicesResponse,
    any,
    InvoicesResponse,
    typeof FAILED_INVOICES
  >,
): UseQueryResult<InvoicesResponse> {
  const enabled = useGetUsageBillingEnabled();

  const queryParams = { status__eq: INVOICE_STATUSES.FAILED };

  return useQuery(FAILED_INVOICES, () => getInvoices(queryParams), {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useGetInvoicingSettings(
  options?: UseQueryOptions<
    InvoicingSettings,
    any,
    InvoicingSettings,
    typeof INVOICING_SETTINGS
  >,
): UseQueryResult<InvoicingSettings> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(INVOICING_SETTINGS, getInvoicingSettings, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useUpdateInvoicingSettings(): UseMutationResult<
  InvoicingSettings,
  unknown,
  InvoicingSettings
> {
  return useMutation(
    (args: InvoicingSettings) => updateInvoicingSettings(args),
    {
      onError: toastError,
    },
  );
}

export function useGetUsageStatsTotalsByField(
  {
    fieldName,
    startDate,
    endDate,
  }: { fieldName: string; startDate: string; endDate: string },
  options?: UseQueryOptions<
    StatsTotalResponse,
    any,
    StatsTotalResponse,
    [typeof USAGE_STATS, SearchParams | undefined]
  >,
): UseQueryResult<StatsTotalResponse> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(
    [USAGE_STATS, { fieldName, startDate, endDate }],
    () => getUsageStatsTotalsByField({ fieldName, startDate, endDate }),
    {
      enabled,
      onError: toastError,
      staleTime,
      ...options,
    },
  );
}

export function usePayInvoice(): UseMutationResult<Invoice, any, number> {
  const queryClient = useQueryClient();

  return useMutation((invoiceId: number) => payInvoice(invoiceId), {
    onSuccess: () => queryClient.refetchQueries(INVOICE),
    onError: toastError,
  });
}

export function useUpdateInvoiceStatus(): UseMutationResult<
  void,
  unknown,
  UpdateInvoiceStatus
> {
  const queryClient = useQueryClient();

  return useMutation(
    (args: UpdateInvoiceStatus) => updateInvoiceStatus({ ...args }),
    {
      onSuccess: () => queryClient.refetchQueries(INVOICING_STATUS),
      onError: toastError,
    },
  );
}

export function useGetEstimatedSalesTaxAmount(
  options?: UseQueryOptions<number, any, number, typeof ESTIMATED_SALES_TAX>,
): UseQueryResult<number> {
  const enabled = useGetUsageBillingEnabled();

  return useQuery(ESTIMATED_SALES_TAX, getEstimatedSalesTaxAmount, {
    enabled,
    onError: toastError,
    staleTime,
    ...options,
  });
}

export function useCreateCustomProductPlan(): UseMutationResult<
  ProductPlan,
  any,
  NewCustomProductPlan
> {
  const queryClient = useQueryClient();

  return useMutation(
    (customPlan: NewCustomProductPlan) => createCustomProductPlan(customPlan),
    {
      onSuccess: () => queryClient.refetchQueries(PRODUCT_PLANS),
      onError: toastError,
    },
  );
}
