// the provider for the feedbackLoop object

import { useFeedbackLoop } from '../hooks';
import {
  defaultFeedbackPrefix,
  mandatoryFeedbackLoopKey,
} from '../hooks/feedbackLoop';

export const feedbackLoops = (
  Component,
  keys = [],
  queryKeys = [],
  allowRedirect = true,
) => {
  return (props) => {
    const { feedbackLoops, feedbackQueryParams, addLoop, removeLoop } =
      useFeedbackLoop(keys, queryKeys, false, allowRedirect);
    return (
      <Component
        {...props}
        feedbackLoops={feedbackLoops}
        feedbackQueryParams={feedbackQueryParams}
        addFeedbackLoop={addLoop}
        removeFeedbackLoop={removeLoop}
      />
    );
  };
};

export const feedbackLoopRedirect = (Component) => {
  return (props) => {
    // we'll get the mandatory redirect value here and apply it later
    let mandatoryRedirectValue = sessionStorage.getItem(
      mandatoryFeedbackLoopKey,
    );
    if (!mandatoryRedirectValue)
      mandatoryRedirectValue = localStorage.getItem(mandatoryFeedbackLoopKey);

    if (mandatoryRedirectValue) {
      mandatoryRedirectValue = mandatoryRedirectValue.replace(
        defaultFeedbackPrefix,
        '',
      );
      const { feedbackLoops } = useFeedbackLoop(
        mandatoryRedirectValue,
        [],
        true,
      );
      // check if we need to redirect
      if (mandatoryRedirectValue && feedbackLoops[mandatoryRedirectValue]) {
        const mandatoryFeedbackLoop = feedbackLoops[mandatoryRedirectValue];
        if (mandatoryFeedbackLoop.localUrl !== window.location.pathname) {
          return null;
        }
      }
    }
    return <Component {...props} />;
  };
};
