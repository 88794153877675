import { Heading } from '@postscript/components';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import Bub from '../../img/bub.svg?react';

const StyledLoadingPageWrapper = styled.main`
  background: var(--surface-bkg-color);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-4);
  padding-bottom: 10vh;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-top: var(--spacing-5);
  margin-bottom: var(--spacing-8);
`;

const Loading = (): JSX.Element => (
  <StyledLoadingPageWrapper>
    <Bub />
    <StyledLogoWrapper>
      <FontAwesome name="circle-o-notch" spin />
      <Heading
        style={{
          margin: 0,
          marginTop: 'var(--spacing-1)',
          marginLeft: 'var(--spacing-2)',
        }}
        size="medium"
        as="h2"
      >
        Loading Postscript
      </Heading>
    </StyledLogoWrapper>
  </StyledLoadingPageWrapper>
);

export default Loading;
