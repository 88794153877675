/* eslint-disable no-underscore-dangle */
// Tools for working with VWO

// Events are special IDs that kick information back to VWO
// this object lets you refer to them by name, ex. `vwoEvent.qualifiedInstall`
//
// Please add a short comment before each name with a description of what
// the event is for, and the test name for the event if that's on hand.
//
// Names here also link up with calls to the Events tool.
const vwoEvent = {
  // Shops with >5000k customers.
  'Qualified Install': 201,
  'Account Activated - 30 Days': 203,
};

// Combine the `trackEvent` function with a vwoEventName to fire an event in
// VWO. Ex. `trackEvent(vwoEvent.qualifiedInstall);`
const trackEvent = (id) => {
  window._vis_opt_queue = window._vis_opt_queue || [];
  window._vis_opt_queue.push(() => window._vis_opt_goal_conversion(id));
};

export { trackEvent, vwoEvent };
