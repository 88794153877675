import { Checkbox, FieldError } from '@postscript/components';
import { DynamicAction } from 'components/flowBuilder/types/actions';
import { CheckboxDynamicField } from 'components/flowBuilder/types/dynamicFields';
import { formatErrorMessage } from 'components/flowBuilder/utils/errors';
import { useField } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

export const checkboxFieldSchema = Yup.boolean().required();

interface CheckboxFieldProps {
  action: DynamicAction;
  field: CheckboxDynamicField;
}

const CheckboxField = ({ action, field }: CheckboxFieldProps): JSX.Element => {
  const [formikField, meta, helpers] = useField<boolean>({
    name: field.options.paramName,
    validate: (value) => {
      try {
        checkboxFieldSchema.validateSync(value);
      } catch (e) {
        return formatErrorMessage(e);
      }
    },
  });

  const emptyValue = false;

  useEffect(() => {
    const initialCheckboxParamValue = action.params[field.options.paramName];

    if (checkboxFieldSchema.isValidSync(initialCheckboxParamValue))
      helpers.setValue(initialCheckboxParamValue);
    else helpers.setValue(emptyValue);
  }, []);

  return (
    <div>
      <Checkbox
        checked={formikField.value ?? emptyValue}
        error={!!meta.error && meta.touched}
        fieldSize="small"
        id={formikField.name}
        label={field.options.label}
        onChange={formikField.onChange}
      />
      {!!meta.error && meta.touched && (
        <FieldError htmlFor={formikField.name}>{meta.error}</FieldError>
      )}
    </div>
  );
};

export default CheckboxField;
