import { LabsFeature, usePSLabs } from 'controllers/contexts/labsFeatures';
import { useMemo } from 'react';
import { Dashboard } from '../OmniDashboards';

export default function useParseLabsFeature(featureKey: string): {
  feature?: LabsFeature;
  dashboards: Dashboard[];
} {
  const { features, hasInitialized } = usePSLabs();

  return useMemo(() => {
    if (!hasInitialized || !features) {
      return {
        dashboards: [],
      };
    }

    const feature = features.find(({ key }) => key === featureKey);

    return {
      feature,
      dashboards: JSON.parse(feature?.documentationUrl || '[]'),
    };
  }, [features, hasInitialized, featureKey]);
}
