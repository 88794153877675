import React from 'react';
import {
  COMMON_EVENT_NAMES,
  logButtonClickEvent,
  logEvent,
  PRODUCT_AREAS,
} from 'utils/events';

interface LogAnnouncementCTAClickedProps {
  event: React.MouseEvent<HTMLButtonElement>;
  id: string;
  bannerContent?: string;
}

export const logAnnouncementCTAClicked = ({
  bannerContent,
  event,
  id,
}: LogAnnouncementCTAClickedProps): void => {
  logButtonClickEvent(event, {
    banner_id: id,
    banner_content: bannerContent,
    location: 'legacy announcement banner CTA',
    product_area: PRODUCT_AREAS.GLOBAL,
  });
};

type LogAnnouncementDismissedProps = Omit<
  LogAnnouncementCTAClickedProps,
  'event'
>;

export const logAnnouncementDismissed = ({
  bannerContent,
  id,
}: LogAnnouncementDismissedProps): void => {
  /* This uses logEvent() rather than logButtonClickEvent(), even though
  they're both buttons, because logButtonClickEvent requires an event arg, but
  the component the announcement banners are based on do not include a synthetic
  event in its onDismiss prop type (currently it is () => void) */
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    banner_content: bannerContent,
    banner_id: id,
    location: 'legacy announcement banner dismiss',
    product_area: PRODUCT_AREAS.GLOBAL,
    text: undefined,
  });
};
