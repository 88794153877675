/* eslint-disable react/prop-types */

import {
  BodyText,
  Button,
  EditableText,
  Icon,
  IconSet,
  LinkText,
} from '@postscript/components';
import Helpers from 'controllers/helpers';
import {
  Categories,
  SearchResult as SearchResultType,
  useGlobalSearch,
} from 'hooks/globalSearch';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { logNavItemClicked } from './eventUtils';

const StyledSubnavMenuSearchList = styled.menu`
  height: 100%;
  padding-left: var(--spacing-3);
  position: relative;
  z-index: 2;
`;

const SearchBar = styled.div`
  display: flex;
  width: 202px;
  margin: -42px 0 var(--spacing-4) var(--spacing-4);
`;

const StyledEditableTextWrapper = styled.div`
  flex: 1 0 calc(100% - 24px);
  padding-right: var(--spacing-2);
`;

const StyledEditableText = styled(EditableText)`
  max-height: 24px;
  flex: 1 0 calc(100% - 36px);
  padding-right: 12px;
`;

const CloseSearchButton = styled(Button)`
  flex: 1 0 24px;
`;

const SearchResults = styled.div`
  height: calc(100vh - 174px);
`;

const SearchResultsTable = styled.ul`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0;
`;

const SearchResultsRow = styled.div<{ hide: boolean }>(
  ({ hide }) => `
  display: ${hide ? 'none' : 'flex'};
  flex-direction: column;
  padding-bottom: var(--spacing-5);
`,
);

const SearchResultsHeaderRow = styled.div`
  margin-bottom: var(--spacing-2);
`;

const SearchResultsHeader = styled(BodyText)`
  color: var(--text-color);
  font-weight: var(--body-text-bold-font-weight);
  margin: 0; /* because _legacy.scss */
`;

const SearchResult = styled(Link)`
  color: var(--text-color-dim);
  text-decoration: none;
  flex: 1 0 auto;
  font: var(--body-text-small);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-left: 1px solid var(--border-color-dim);
  padding-left: var(--spacing-3);
  outline: none !important;

  &:not(:last-of-type) {
    padding-bottom: var(--spacing-2);
  }

  &:hover,
  &:focus {
    color: var(--link-color);
  }
  &:active {
    color: var(--link-color-hover);
  }
  &:not(:hover):focus {
    text-decoration: underline;
    text-decoration-color: var(--link-color);
  }
`;

const SearchResultName = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ViewMoreRow = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  margin: var(--spacing-3) 0 0;
  padding-left: calc(var(--spacing-3) + 1px);
  color: var(--text-color-dim);
  font: var(--body-text-x-small);
  min-width: fit-content;
  outline: none !important;

  &:hover,
  &:focus,
  span:hover,
  span:focus {
    color: var(--link-color);
    text-decoration: none;
  }
  &:active,
  span:active {
    color: var(--link-color-hover);
    text-decoration: none;
  }
  &:not(:hover):focus,
  span:not(:hover):focus {
    text-decoration: underline;
    text-decoration-color: var(--link-color);
  }
`;

const StyledSearchIcon = styled(Icon)`
  margin-right: var(--spacing-1);
  animation: spin 1s linear infinite;
  animation-direction: reverse;
`;

const SearchingText = styled(BodyText)`
  display: flex;

  a:focus {
    outline: none;
    color: var(--link-color-hover);
  }
`;

const StyledNoResultsIcon = styled(Icon)`
  margin-right: var(--spacing-1);
`;

const ClearRecent = styled(LinkText)`
  display: block;
  font: var(--body-text-small);
  font-weight: var(--body-text-bold-font-weight);
  padding-left: var(--spacing-3);
  outline: none !important;

  &:not(:hover):focus {
    color: var(--link-color-hover);
  }
`;

const getNameBold = (name: string, searchValue: string) => {
  if (searchValue) {
    const nameIndex = name.toLowerCase().indexOf(searchValue.toLowerCase());
    if (nameIndex !== -1) {
      const before = <span>{name.substring(0, nameIndex)}</span>;
      const bold = (
        <strong>
          {name.substring(nameIndex, nameIndex + searchValue.length)}
        </strong>
      );
      const after = (
        <span>{name.substring(nameIndex + searchValue.length)}</span>
      );
      return (
        <SearchResultName>
          {before}
          {bold}
          {after}
        </SearchResultName>
      );
    }
  }
  return name;
};

const createRow = (
  name: string,
  path: string,
  searchValue: string,
  category: string,
  state?: any,
) => {
  const fullName = getNameBold(name, searchValue);
  return (
    <SearchResult
      to={{
        pathname: path,
        state: { ...state },
      }}
      onClick={(event) => logNavItemClicked(event, 'Search result')}
    >
      {fullName}
    </SearchResult>
  );
};

const createSubscriberRow = (result: any, searchValue: string) => {
  let name = result.search_term;
  let path = '#';
  let state = {};

  if (result?.customer) {
    path = `/customers/${result.customer.customer_id}`;
    state = {
      contactId: result.customer.customer_id,
      contactType: 'customer',
      contactLabel: name,
    };
  }

  if (result?.subscriber) {
    path = `/subscribers/${result.subscriber.id}`;
    state = {
      contactId: result.subscriber.id,
      contactType: 'subscriber',
      contactLabel: Helpers.formatPhoneNumber(result.subscriber.phone_number),
    };
  }

  if (
    name === result?.subscriber?.phone_number ||
    name === result?.customer_fact?.phone_number
  ) {
    name = Helpers.formatPhoneNumber(name);
  }
  if (path !== '#')
    return createRow(name, path, searchValue, 'customer', state);

  return null;
};

const createCampaignRow = (
  { name, path }: SearchResultType,
  searchValue: string,
) => createRow(name, path, searchValue, 'campaign');

const createAutomationRow = (
  { name, path }: SearchResultType,
  searchValue: string,
) => createRow(name, path, searchValue, 'automation');

const createSegmentRow = (result: any, searchValue: string) => {
  if (result?.segment_id) {
    const { name } = result;
    const path = `/segments/${result.segment_id}`;
    return createRow(name, path, searchValue, 'segment');
  }

  return null;
};

const createKeywordRow = (result: any, searchValue: string) => {
  if (result?.keyword_id) {
    const { keyword } = result;
    const path = `/keywords/${result.keyword_id}`;
    return createRow(keyword, path, searchValue, 'keyword');
  }

  return null;
};

const createAppRow = (result: any, searchValue: string) => {
  if (result?.type) {
    const { type } = result;
    const path = `/integrations/${Helpers.formatIntegration(result.type)}`;
    return createRow(type, path, searchValue, 'app');
  }

  return null;
};

const createFeatureRow = (result: any, searchValue: string) => {
  if (result?.label && result?.path) {
    const { label } = result;
    return createRow(label, result.path, searchValue, 'feature');
  }

  return null;
};

const createResultRow = (
  result: any,
  searchValue: string,
  category: string,
) => {
  if (!result && !searchValue) return;

  if (category === 'Subscribers')
    return createSubscriberRow(result, searchValue);

  if (category === 'Campaigns') return createCampaignRow(result, searchValue);

  if (category === 'Automations')
    return createAutomationRow(result, searchValue);

  if (category === 'Segments') return createSegmentRow(result, searchValue);

  if (category === 'Keywords') return createKeywordRow(result, searchValue);

  if (category === 'Apps') return createAppRow(result, searchValue);

  if (category === 'Features') return createFeatureRow(result, searchValue);

  return null;
};

const RECENT_SEARCHES_KEY = 'ps-recent-searches';

type NavMenuSearchProps = {
  closeSearch: () => void;
};

const NavMenuSearch = ({ closeSearch }: NavMenuSearchProps): JSX.Element => {
  const [viewMore, setViewMore] = useState<string>();
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [recentSearches, setRecentSearches] = useState<string[]>([]);

  const { searchResults, hasSearchResults, loading } = useGlobalSearch(
    searchFocus,
    searchValue,
  );

  const addRecentSearchTerm = () => {
    if (searchValue && !recentSearches.includes(searchValue)) {
      setRecentSearches([...recentSearches, searchValue]);
      localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches));
    }
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem(RECENT_SEARCHES_KEY);
  };

  const setRecentSearchTerm = (term: string) => {
    setSearchValue?.(term);
  };

  const typedSearchValue = (value: any) => {
    if (value && value.length > 0) {
      setSearchValue?.(value);
    } else {
      setSearchValue?.(undefined);
    }
  };

  const onSearchFocus = () => {
    setSearchFocus?.(true);
  };

  const onSearchClear = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) logNavItemClicked(event, 'Close search');
    setSearchFocus?.(false);
    typedSearchValue(undefined);
    closeSearch();
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onSearchClear();
    }
  }, []);

  useEffect(() => {
    if (recentSearches.length === 0) {
      const storedSearches = localStorage.getItem(RECENT_SEARCHES_KEY);
      if (storedSearches) {
        setRecentSearches(JSON.parse(storedSearches));
      }
    }
  }, [searchFocus]);

  useEffect(() => {
    setViewMore(undefined);
  }, [searchValue]);

  useEffect(() => {
    document.getElementById('nav-global-search-input')?.focus();
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const ViewMore = ({ category, numItems }: any) => {
    return (
      <ViewMoreRow
        onClick={() =>
          setViewMore(viewMore === category ? undefined : category)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setViewMore(viewMore === category ? undefined : category);
          }
        }}
        tabIndex={0}
      >
        {viewMore !== category ? (
          <>
            View {numItems} More{' '}
            <Icon component={IconSet.ChevronDown} size={18} />
          </>
        ) : (
          <>
            Close <Icon component={IconSet.ChevronUp} size={18} />
          </>
        )}
      </ViewMoreRow>
    );
  };

  type SearchCategoryProps = {
    searchResults: any;
    category: string;
  };

  const SearchCategory = ({ searchResults, category }: SearchCategoryProps) => {
    if (searchResults?.length > 0 && searchValue) {
      return (
        <>
          <SearchResultsRow
            hide={!(viewMore === undefined || viewMore === category)}
            onClick={addRecentSearchTerm}
            data-testid={`${category.toLowerCase()}-search-results`}
          >
            <SearchResultsHeaderRow>
              <SearchResultsHeader>{Categories[category]}</SearchResultsHeader>
            </SearchResultsHeaderRow>
            {viewMore === category
              ? searchResults?.map((result: any) =>
                  createResultRow(result, searchValue, category),
                )
              : searchResults
                  ?.slice(0, 3)
                  ?.map((result: any) =>
                    createResultRow(result, searchValue, category),
                  )}
            {(viewMore === undefined || viewMore === category) &&
              searchResults?.length > 3 && (
                <ViewMore
                  category={category}
                  numItems={searchResults.length - 3}
                />
              )}
          </SearchResultsRow>
        </>
      );
    }
    return null;
  };

  return (
    <StyledSubnavMenuSearchList>
      <SearchBar>
        <StyledEditableTextWrapper>
          <StyledEditableText
            id="nav-global-search-input"
            value={searchValue ?? ''}
            onChange={(e: any) => {
              typedSearchValue(e.target.value);
            }}
            onFocus={onSearchFocus}
            placeholder="Search Postscript"
          />
        </StyledEditableTextWrapper>
        <CloseSearchButton
          icon={IconSet.Close}
          variant="secondary"
          size="small"
          onClick={(event) => onSearchClear(event)}
        />
      </SearchBar>
      <SearchResults>
        <SearchResultsTable>
          {!searchValue &&
            (!searchFocus || (searchFocus && recentSearches.length === 0)) && (
              <BodyText size="x-small">
                Quickly find Subscribers, Campaigns, Automations, and other
                Postscript features.
              </BodyText>
            )}
          {!searchValue && searchFocus && recentSearches.length > 0 && (
            <>
              <SearchResultsRow
                hide={false}
                style={{ paddingBottom: 'var(--spacing-2)' }}
              >
                <SearchResultsHeaderRow>
                  <SearchResultsHeader>Recent</SearchResultsHeader>
                </SearchResultsHeaderRow>
                {recentSearches.map((result: string) => (
                  <SearchResult
                    to="#"
                    onClick={(event) => {
                      logNavItemClicked(event, 'Recent search result');
                      setRecentSearchTerm(result);
                    }}
                  >
                    {result}
                  </SearchResult>
                ))}
              </SearchResultsRow>

              <ClearRecent
                onClick={(event) => {
                  logNavItemClicked(event, 'Clear recent search results');
                  clearRecentSearches();
                }}
                href="#"
                role="button"
                aria-label="clear recent searches"
              >
                Clear Recent
              </ClearRecent>
            </>
          )}
          {searchValue &&
            searchFocus &&
            (!hasSearchResults ? (
              loading ? (
                <SearchingText size="x-small">
                  <StyledSearchIcon component={IconSet.Refresh} size={18} />
                  Searching for &quot;{searchValue}&quot;...
                </SearchingText>
              ) : (
                <SearchingText size="x-small">
                  <StyledNoResultsIcon component={IconSet.EmojiSad} size={18} />
                  <span>
                    No results found for “{searchValue}”. Try searching the{' '}
                    <LinkText
                      href={`https://help.postscript.io/hc/en-us/search?utf8=%E2%9C%93&query=${searchValue}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help Center
                    </LinkText>
                    .
                  </span>
                </SearchingText>
              )
            ) : (
              Object.keys(searchResults).map((value) => (
                <SearchCategory
                  searchResults={searchResults[value]}
                  category={value}
                />
              ))
            ))}
        </SearchResultsTable>
      </SearchResults>
    </StyledSubnavMenuSearchList>
  );
};

export default NavMenuSearch;
