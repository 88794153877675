import { Button, IconSet, Layout, ModalBody } from '@postscript/components';
import {
  PaymentMethodSteps,
  usePaymentMethods,
} from 'components/billing/context/paymentMethods';
import { useGetAvailablePaymentMethods } from 'components/billing/context/useBilling';
import { useEffect } from 'react';
import { logButtonClickEvent, MODAL_TYPES } from 'utils/events';

export default function PaymentMethodTypeChoice(): JSX.Element {
  const { updateStep } = usePaymentMethods();
  const {
    data: availablePaymentMethods,
    isLoading: loadingAvailablePaymentMethods,
  } = useGetAvailablePaymentMethods();
  const multiplePaymentMethodsAvailable =
    Object.values(availablePaymentMethods || {}).filter((v) => v).length > 1;

  // If only one payment method is available, skip directly to it
  useEffect(() => {
    if (loadingAvailablePaymentMethods || multiplePaymentMethodsAvailable) {
      return;
    }

    // Get the key from the only available payment method and use it as the step value
    Object.entries(availablePaymentMethods || {}).forEach(
      ([methodName, isAvailable]) => {
        if (!isAvailable) return;
        updateStep(methodName as PaymentMethodSteps);
      },
    );
  }, [availablePaymentMethods, loadingAvailablePaymentMethods]);

  return (
    <ModalBody>
      <Layout vertical>
        <Button
          variant="text"
          icon={IconSet.CreditCard}
          onClick={(event) => {
            logButtonClickEvent(event, {
              modal_type: MODAL_TYPES.BILLING_PAYMENT_METHODS,
            });
            updateStep('stripeCard');
          }}
          data-cy="add-credit-card-button"
        >
          Credit/Debit Card
        </Button>
        <Button
          variant="text"
          icon={IconSet.Bank}
          onClick={(event) => {
            logButtonClickEvent(event, {
              modal_type: MODAL_TYPES.BILLING_PAYMENT_METHODS,
            });
            updateStep('stripeAch');
          }}
          data-cy="add-bank-account-button"
        >
          Bank Account
        </Button>
      </Layout>
    </ModalBody>
  );
}
