import produce from 'immer';
import React, { createContext, useContext, useReducer } from 'react';

const ADD_BANNER = 'ADD_BANNER';
const REMOVE_BANNER = 'REMOVE_BANNER';

export interface PrimaryAction
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

interface BannerData {
  content: React.ReactNode;
  closeable?: boolean;
  color?: string;
  primaryAction?: PrimaryAction;
  message?: string;
  type: BannerTypes;
  announcementType?: string;
}

export interface Banner {
  id: string;
  key?: string;
  data: BannerData;
  width?: string;
  primaryAction?: PrimaryAction;
  message?: string;
}

interface AddBanner {
  type: typeof ADD_BANNER;
  id: string;
  data: BannerData;
}

interface RemoveBanner {
  type: typeof REMOVE_BANNER;
  id: string;
}

type ReducerAction = AddBanner | RemoveBanner;

export type BannerTypes = 'success' | 'warning' | 'danger' | 'custom';

interface State {
  banners: Banner[];
  addBanner: (banner: Banner) => void;
  removeBanner: (id: string) => void;
}

const initialState: State = {
  banners: [],
  addBanner: () => undefined,
  removeBanner: () => undefined,
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case ADD_BANNER:
      draft.banners = [
        ...new Set([{ id: action.id, data: action.data }, ...draft.banners]),
      ];
      break;
    case REMOVE_BANNER:
      draft.banners = draft.banners.filter(({ id }) => id !== action.id);
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const LegacyBannersContext = createContext(initialState);
export const useLegacyBanners = (): State => useContext(LegacyBannersContext);

interface Props {
  children: JSX.Element;
}

export const LegacyGlobalBannersProvider = ({
  children,
}: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);

  const removeBanner = (id: string) => {
    dispatch({
      type: REMOVE_BANNER,
      id,
    });
  };

  const addBanner = ({ id, data }: Banner) => {
    if (!id) {
      throw new Error('A unique banner ID is required.');
    }

    if (state.banners.some((banner) => banner.id === id)) {
      removeBanner(id);
    }

    dispatch({
      type: ADD_BANNER,
      id,
      data,
    });
  };

  return (
    <LegacyBannersContext.Provider
      value={{
        ...state,
        addBanner,
        removeBanner,
      }}
    >
      {children}
    </LegacyBannersContext.Provider>
  );
};
