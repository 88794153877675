import { Emoji, EmojiSet, Icon, IconSet } from '@postscript/components';
import React from 'react';
import styled from 'styled-components';
import MediaQueries from 'utils/mediaQueries';

interface NavMenuButtonInterface
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  easterEggEmoji?: boolean;
  isSidebarNavigationOpen: boolean;
}

const NavMenuIcon = styled(Icon)`
  transition: color var(--hover-transition);
`;

const StyledNavMenuButton = styled.button<NavMenuButtonInterface>`
  background: transparent;
  display: none;
  align-items: center;
  border: none;
  height: 48px;
  width: 48px;
  padding: var(--spacing-2);
  cursor: pointer;
  transition: color var(--hover-transition),
    transform var(--nav-menu-transition-speed);

  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;

  /* DEFAULT */

  ${NavMenuIcon} {
    color: var(--black);
  }

  [data-theme='dark'] & {
    ${NavMenuIcon} {
      color: var(--white);
    }
  }

  /* HOVER + FOCUS */

  &:hover,
  &:focus-visible {
    ${NavMenuIcon} {
      color: var(--purple-core);
    }
  }

  [data-theme='dark'] & {
    &:hover,
    &:focus-visible {
      ${NavMenuIcon} {
        color: var(--mint-core);
      }
    }
  }

  /* ACTIVE */

  &:active {
    ${NavMenuIcon} {
      color: var(--purple-7);
    }
  }

  [data-theme='dark'] &:active {
    ${NavMenuIcon} {
      color: var(--mint-2);
    }
  }

  /* browser patches */

  appearance: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin-top: -2px;
    margin-bottom: -2px;
  }

  /* focus states */

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  ${MediaQueries.desktopOnly} {
    .full-screen-editor & {
      display: flex;
      top: 0;
      left: 0;
      transform: translate(0, 0);
    }

    .full-screen-editor.sidebar-navigation-open & {
      transform: translate(261px, 51px);
    }
  }

  ${MediaQueries.tabletOnly} {
    display: flex;

    .sidebar-navigation-open & {
      transform: translate(-63px, 39px);
    }
  }

  ${MediaQueries.phoneOnly} {
    display: flex;

    .sidebar-navigation-open & {
      transform: translate(-63px, 15px);
    }
  }
`;

const NavMenuButton = ({
  easterEggEmoji = false,
  isSidebarNavigationOpen,
  onClick,
  ...props
}: NavMenuButtonInterface): JSX.Element => (
  <StyledNavMenuButton
    aria-label="Toggle Navigation Menu"
    role="button"
    isSidebarNavigationOpen={isSidebarNavigationOpen}
    onClick={onClick}
    tabIndex={0}
    type="button"
    {...props}
  >
    {easterEggEmoji && !isSidebarNavigationOpen ? (
      <Emoji component={EmojiSet.Hamburger} size={24} />
    ) : (
      <NavMenuIcon
        color="currentColor"
        component={isSidebarNavigationOpen ? IconSet.Close : IconSet.Menu}
        size={24}
      />
    )}
  </StyledNavMenuButton>
);

export default NavMenuButton;
