import useWebSocket from './useWebSocket';

type BroadcastDataTypes = 'user_typing' | 'user_sent_message';

interface BroadcastData {
  type: BroadcastDataTypes;
  [key: string]: any;
}

interface UseBroadcastToSubscriberChannel {
  broadcast: (data: BroadcastData) => void;
}

const useBroadcastToChannel = (
  channel: string,
): UseBroadcastToSubscriberChannel => {
  const { send } = useWebSocket();

  return {
    broadcast: (data: BroadcastData) => {
      if (channel) {
        send({
          routeKey: 'broadcast_to_channel',
          channel,
          message: JSON.stringify(data),
        });
      }
    },
  };
};

export default useBroadcastToChannel;
