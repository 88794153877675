import {
  BodyText,
  Button,
  Layout,
  ModalBody,
  ModalFooter,
  toast,
} from '@postscript/components';
import { useStripe } from '@stripe/react-stripe-js';
import {
  LONG_DATE_FORMAT,
  PAYMENT_METHOD_BANNER_ID,
} from 'components/billing/common/constants';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useGetPaymentMethod } from 'components/billing/context/useBilling';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { logEvent } from 'utils/events';

const AccountDetail = styled(BodyText).attrs({ color: 'var(--text-color)' })`
  span {
    display: block;
    color: var(--text-color-dim);
    font: var(--body-text-small);
  }
`;

export default function PaymentMethodACHMandate(): JSX.Element | null {
  const stripe = useStripe();
  const {
    stepData: { achDetails },
    updateStep,
    getPaymentMethods,
    getUnverifiedPaymentMethods,
    setDefaultPaymentMethod,
  } = usePaymentMethods();
  const { removeBanner } = useBanners();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch: refetchPaymentMethod } = useGetPaymentMethod();

  if (!achDetails) return null;

  const acceptMandate = async () => {
    setIsLoading(true);

    const { clientSecret } = achDetails;

    if (!stripe || !clientSecret) return;

    const { setupIntent, error } = await stripe.confirmUsBankAccountSetup(
      clientSecret,
    );

    if (error || !setupIntent) {
      toast.error(error || 'Please try again.');
      return updateStep('typeChoice');
    }

    logEvent('payment method added', {
      payment_method_type: 'ach',
    });

    await Promise.all([
      getPaymentMethods(),
      getUnverifiedPaymentMethods(),
      refetchPaymentMethod(),
    ]);

    if (setupIntent.payment_method) {
      try {
        await setDefaultPaymentMethod(setupIntent.payment_method);
        await refetchPaymentMethod();
        removeBanner(PAYMENT_METHOD_BANNER_ID);
      } catch {
        toast.error('Unable to set new payment method as default.');
      }
    }

    toast.success('Payment method added.');
    updateStep('management');
  };

  const { name, email, bankName, last4, routingNumber } = achDetails;

  return (
    <>
      <ModalBody>
        <Layout vertical>
          <AccountDetail>
            Agreement date
            <span>{moment().format(LONG_DATE_FORMAT)}</span>
          </AccountDetail>
          <AccountDetail>
            Accountholder name<span>{name}</span>
          </AccountDetail>
          <AccountDetail>
            Accountholder email address<span>{email}</span>
          </AccountDetail>
          <AccountDetail>
            Bank name<span>{bankName}</span>
          </AccountDetail>
          <AccountDetail>
            Routing number<span>{routingNumber}</span>
          </AccountDetail>
          <AccountDetail>
            Account number<span>Ending in {last4}</span>
          </AccountDetail>
          <div>
            <hr />
            <BodyText size="small">
              By clicking Agree &amp; Add Payment Method, you authorize
              Postscript to debit the bank account specified above for any
              amount owed for charges arising from your use of Postscript
              services and/or purchase of products from Postscript, pursuant to
              Postscript&apos;s website and terms, until this authorization is
              revoked. You may amend or cancel this authorization at any time by
              providing notice to Postscript with 30 (thirty) days notice.
            </BodyText>
            <BodyText size="small">
              If you use Postscript services or purchase additional products
              periodically pursuant to Postscript&apos;s terms, you authorize
              Postscript to debit your bank account periodically. Payments that
              fall outside of the regular debits authorized above will only be
              debited after your authorization is obtained.
            </BodyText>
          </div>
        </Layout>
      </ModalBody>
      <ModalFooter>
        <Button onClick={acceptMandate} disabled={isLoading}>
          Agree &amp; Add Payment Method
        </Button>
        <Button
          variant="text"
          onClick={() => updateStep('management')}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
}
