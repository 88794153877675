import { api } from 'controllers/network/apiClient';
import {
  NotificationSubscription,
  RemoveNotificationSubscriptionPayload,
  ShopRole,
  User,
  UserNotification,
  UserNotificationsPreference,
  UserProfile,
} from './types';

const usersUrl = '/v2/users/';
const userProfileUrl = '/v2/users/profile';
const notificationsUrl = '/v2/notifications';

export async function getUsers(): Promise<User[]> {
  const { users } = await api.get(usersUrl);
  return users;
}

export interface AddShopUserPayload {
  username: string;
  roles: number[];
}

export async function addUser({
  username,
  roles,
}: AddShopUserPayload): Promise<void> {
  await api.post(usersUrl, { username, roles });
}

export async function updateUser(user: User): Promise<User> {
  await api.put(usersUrl, user);
  return user;
}

export async function deleteUser(guid: string): Promise<void> {
  await api.delete(`${usersUrl}${guid}`);
}

export async function inviteUsers(users: AddShopUserPayload[]): Promise<void> {
  await Promise.all(users.map((user) => api.post(usersUrl, user)));
}

export async function getUserProfile(): Promise<UserProfile> {
  const profile = await api.get(userProfileUrl);
  return profile;
}

export async function updateUserProfile(user: User): Promise<User> {
  await api.put(userProfileUrl, {
    ...user.profile,
    // We cannot send an empty string as the value of avatarUrl
    avatarUrl: user.profile?.avatarUrl || undefined,
  });
  return user;
}

export async function getShopRoles(): Promise<ShopRole[]> {
  const { roles } = await api.get('/v2/users/shop_roles');
  return roles;
}

export interface UpdateUserShopRolesPayload {
  userId: string;
  shopId: number;
  roles: number[];
}

export async function updateUserShopRoles({
  userId,
  shopId,
  roles,
}: UpdateUserShopRolesPayload): Promise<void> {
  await api.put(`/v2/users/${userId}/shop/${shopId}/roles`, {
    roles,
  });
}

export async function getUserPhoneNumber(
  userUuid: string,
  shopId: number,
): Promise<string> {
  const { phones } = await api.get(`${usersUrl}${userUuid}/shop/${shopId}`);
  const { number } = phones[0] ?? '';
  return number;
}

export interface UpdateUserPhoneNumberPayload {
  userUuid: string;
  phoneNumber: string;
}

export async function updateUserPhoneNumber({
  userUuid,
  phoneNumber,
}: UpdateUserPhoneNumberPayload): Promise<void> {
  await api.put(`${usersUrl}${userUuid}/phone`, { number: phoneNumber });
}

export async function getUserNotificationPreferences(): Promise<UserNotificationsPreference> {
  return api.get(`${notificationsUrl}/preferences`);
}

export async function addNotificationSubscription({
  userUuid,
  shopId,
  notificationName,
  notificationChannelType,
}: NotificationSubscription): Promise<UserNotification> {
  return api.put(
    `${notificationsUrl}/${userUuid}/${shopId}/${notificationName}/${notificationChannelType}`,
  );
}

export async function removeNotificationSubscription({
  userUuid,
  shopId,
  notificationName,
  notificationChannelType,
}: NotificationSubscription): Promise<RemoveNotificationSubscriptionPayload> {
  return api.delete(
    `${notificationsUrl}/${userUuid}/${shopId}/${notificationName}/${notificationChannelType}`,
  );
}
