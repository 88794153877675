import { toast } from '@postscript/components';
import produce from 'immer';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';
import Requests from '../network/network_requests';

const LOADING = 'LOADING';
const SET_REFERRALS = 'SET_REFERRALS';
const REFERRALS = 'referrals';
const SET_SLUG = 'SET_SLUG';
const SLUG = 'slug';
const SET_AMOUNT = 'SET_AMOUNT';
const AMOUNT = 'amount';

const defaultInitialState = {
  loading: false,
  [REFERRALS]: {},
  [SLUG]: '',
  [AMOUNT]: 0,
};

export const ReferralsContext = createContext(defaultInitialState);
export const useReferrals = () => useContext(ReferralsContext);

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_REFERRALS:
      draft[REFERRALS] = action.data;
      break;
    case SET_SLUG:
      draft[SLUG] = action.data;
      break;
    case SET_AMOUNT:
      draft[AMOUNT] = action.data;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const ReferralsProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setReferrals = (id, data) => {
    dispatch({
      type: SET_REFERRALS,
      id,
      data,
    });
  };

  const setSlug = (id, data) => {
    dispatch({
      type: SET_SLUG,
      id,
      data,
    });
  };

  const setReferralAmount = (id, data) => {
    dispatch({
      type: SET_AMOUNT,
      id,
      data,
    });
  };

  const getShopReferrals = async () => {
    const { referrals } = await Requests.get(`/v2/referrals/`);
    setReferrals(REFERRALS, referrals);
  };

  const getSlug = async () => {
    const { slug } = await Requests.get(`/v2/referrals/slug`);
    setSlug(SLUG, slug);
  };

  const updateShopSlug = async (shopSlug) => {
    try {
      const { error, slug } = await Requests.post('/v2/referrals/slug', {
        slug: shopSlug,
      });

      if (error) throw error;

      setSlug(SLUG, slug);
    } catch (err) {
      toast.error(err);
    }
  };

  const getReferralAmount = async () => {
    try {
      const { error, amount } = await Requests.get(`/v2/referrals/amount`);
      if (error) throw error;

      setReferralAmount(AMOUNT, amount);
    } catch (err) {
      toast.error(err);
    }
  };

  const updateReferralAmount = async (reward) => {
    try {
      const { error, amount } = await Requests.post('/v2/referrals/amount', {
        amount: reward,
      });

      if (error) throw error;
      setReferralAmount(AMOUNT, amount);
      toast.success(`Referral Amount Updated to $${amount}`);
    } catch (err) {
      toast.error(err);
    }
  };

  const search = async () => {
    setLoading(true);
    getShopReferrals();
    getSlug();
    getReferralAmount();
    setLoading(false);
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <ReferralsContext.Provider
      value={{
        ...state,
        updateShopSlug,
        updateReferralAmount,
        search,
      }}
    >
      {children}
    </ReferralsContext.Provider>
  );
};

ReferralsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

ReferralsProvider.defaultProps = {
  initialState: defaultInitialState,
};
