import {
  EMAIL_ATTRIBUTE_KEY,
  PHONE_ATTRIBUTE_KEY,
  QUESTION_TYPES,
  QUESTION_TYPE_LABELS,
} from '../constants';
import { PAGE_COLLECTION_ATTRIBUTE_PREFIX } from '../PopupPageTabs';
import {
  CustomAttribute,
  PopupFormValues,
  PopupPage,
  Question,
  QuestionTypes,
} from '../types';

export const hasQuestionOfType = (
  questions: Question[],
  type: QuestionTypes,
): boolean => questions.some((q) => q.type === type);

export const filterAlreadyUsedAttributes = (
  formValues: PopupFormValues,
  attributes?: CustomAttribute[],
): CustomAttribute[] | undefined => {
  const allUsedAttributes = formValues.pages?.flatMap((p) =>
    p.questions.map((q) => q.attributeKey),
  );
  return attributes?.filter((a) => !allUsedAttributes?.includes(a.key));
};

export const getDefaultQuestion = (questionType: QuestionTypes): Question => {
  return {
    attributeKey:
      questionType === QUESTION_TYPES.EMAIL
        ? EMAIL_ATTRIBUTE_KEY
        : questionType === QUESTION_TYPES.PHONE
        ? PHONE_ATTRIBUTE_KEY
        : '',
    optInMethod: null,
    text: `Untitled ${QUESTION_TYPE_LABELS[
      questionType
    ].toLowerCase()} question`,
    type: questionType,
  };
};

export const hasLegacyPhoneCollection = (
  collectionAttributes: string,
): boolean => collectionAttributes.includes('phone');

export const hasLegacyEmailCollection = (
  collectionAttributes: string,
): boolean => collectionAttributes.includes('email');

export const getPhoneStepId = (
  collectionAttributes: string,
  pages: PopupPage[],
): string => {
  let phoneStepId = 'phone';
  const collectionAttributesArray = collectionAttributes.split(',');
  collectionAttributesArray.forEach((collectionAttribute) => {
    const matchingPage = pages.find((page) =>
      collectionAttribute.includes(page.id.toString()),
    );
    if (
      matchingPage?.questions?.some(
        (question) => question.type === QUESTION_TYPES.PHONE,
      )
    ) {
      phoneStepId = collectionAttribute;
    }
  });

  return phoneStepId;
};

export const hasAnyDataCollectionStep = (
  collectionAttributes?: string,
): boolean =>
  (collectionAttributes ?? '').includes(PAGE_COLLECTION_ATTRIBUTE_PREFIX);
