import { ModalHeader, toast } from '@postscript/components';
import HubSpotForm from 'components/forms/HubSpotForm';
import { useEffect } from 'react';
import { COMMON_EVENT_NAMES, logEvent, MODAL_TYPES } from 'utils/events';

interface Props {
  close: () => void;
  formId: string;
  packageName: string;
}

export default function ContactSalesModal({
  close,
  formId,
  packageName,
}: Props): JSX.Element {
  const handleAfterSubmit = () => {
    logEvent('contact sales form submitted', {
      package_name: packageName,
    });
    toast.success('Thanks! Our sales team will get in touch with you soon!');
  };

  useEffect(() => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: MODAL_TYPES.BILLING_CONTACT_SALES,
      package_name: packageName,
    });
  }, []);

  return (
    <>
      <ModalHeader onCancel={close}>Contact sales</ModalHeader>
      <HubSpotForm
        target="this-must-be-a-unique-id"
        isSingleField={false}
        hubspotFormId={formId}
        onFormSubmitted={() => {
          close();
          handleAfterSubmit();
        }}
      />
    </>
  );
}
