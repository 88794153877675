import useGetUsageStatsTodaySum from 'components/billing/common/hooks/useGetUsageStatsTodaySum';
import {
  Invoice,
  InvoiceStatuses,
  LedgerRecord,
} from 'components/billing/common/types';
import { sumLedgerRecords } from 'components/billing/common/utils';
import {
  useGetCurrentAndNextCycles,
  useGetEstimatedSalesTaxAmount,
  useGetInvoices,
  useGetInvoicingSettings,
  useGetUninvoicedLedgerRecords,
} from 'components/billing/context/useBilling';
import { useUser } from 'controllers/contexts/user';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import useGetUsageCreditRemaining from '../../usageCredit/hooks/useGetUsageCreditRemaining';
import { getOpenInvoiceEffectiveStartDate } from '../helpers';

export const openInvoiceStatus = 'OPEN' as InvoiceStatuses;

const defaultValues = {
  id: -1,
  createdAt: '',
  invoiceDate: '',
  invoiceNumber: '',
  ledgerRecords: [],
  shopId: -1,
  status: openInvoiceStatus,
  updatedAt: '',
  transactions: [],
  effectiveStartDate: '',
  effectiveEndDate: '',
  amount: 0,
};

export default function useGenerateOpenInvoice(): {
  invoice: Invoice | null;
  isLoading: boolean;
} {
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const { data: invoices, isLoading: invoicesLoading } = useGetInvoices();
  const { shopInvoices = [] } = invoices ?? {};
  const {
    data: uninvoicedLedgerRecords,
    isLoading: uninvoicedLedgerRecordsLoading,
  } = useGetUninvoicedLedgerRecords();
  const {
    user: { shop_id: shopId },
  } = useUser();
  const messagingFeesToday = useGetUsageStatsTodaySum();
  const usageCreditRemaining = useGetUsageCreditRemaining();
  const {
    data: estimatedSalesTaxAmount,
    isLoading: estimatedSalesTaxAmountLoading,
  } = useGetEstimatedSalesTaxAmount();
  const { data: invoicingSettings, isLoading: invoicingSettingsLoading } =
    useGetInvoicingSettings();
  const { frequency } = invoicingSettings ?? {};
  const latestInvoice: Invoice | undefined = shopInvoices[0];
  const oldestUninvoicedLedgerRecord: LedgerRecord | undefined =
    (uninvoicedLedgerRecords ?? [])[(uninvoicedLedgerRecords ?? []).length - 1];
  const { data: cycles, isLoading: cyclesLoading } =
    useGetCurrentAndNextCycles();

  const isLoading =
    invoicesLoading ||
    uninvoicedLedgerRecordsLoading ||
    invoicingSettingsLoading ||
    estimatedSalesTaxAmountLoading ||
    cyclesLoading;

  useEffect(() => {
    if (isLoading || !frequency || !cycles?.currentCycle) return;

    setInvoice({
      ...defaultValues,
      ledgerRecords: uninvoicedLedgerRecords ?? [],
      shopId,
      status: openInvoiceStatus,
      updatedAt: DateTime.local().toISODate(),
      effectiveStartDate: getOpenInvoiceEffectiveStartDate({
        frequency,
        latestInvoice,
        oldestUninvoicedLedgerRecord,
      }),
      effectiveEndDate: DateTime.local().endOf('month').toISODate(),
      amount:
        sumLedgerRecords(uninvoicedLedgerRecords ?? []) +
        messagingFeesToday -
        Math.min(messagingFeesToday, usageCreditRemaining) +
        (estimatedSalesTaxAmount ?? 0),
    });
  }, [
    uninvoicedLedgerRecords,
    shopId,
    messagingFeesToday,
    usageCreditRemaining,
    estimatedSalesTaxAmount,
    isLoading,
    cycles,
  ]);

  return { invoice, isLoading };
}
