import { Button, IconSet } from '@postscript/components';
import {
  HUBSPOT_FORM_IDS,
  LONG_DATE_FORMAT,
} from 'components/billing/common/constants';
import {
  useGetCurrentAndNextCycles,
  useGetMarketingPlans,
} from 'components/billing/context/useBilling';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import moment from 'moment';
import useAccountStatus from '../../../account/useAccountStatus';
import ChangePlanModal from './ChangePlanModal';
import ContactSalesModal from './ContactSalesModal';

interface Props {
  planId?: number;
  isCurrentPlan: boolean;
  isNextPlan: boolean;
  isDowngrade: boolean;
  isUpgrade: boolean;
  className?: string;
}

export default function PlanCTA({
  planId,
  isCurrentPlan,
  isNextPlan,
  isDowngrade,
  isUpgrade,
  className,
}: Props): JSX.Element {
  const { data: { nextCycle } = {} } = useGetCurrentAndNextCycles();
  const { data: plans = [] } = useGetMarketingPlans();
  const { showModal, hideModal } = useGlobalModal();
  const { installed } = useAccountStatus() ?? {};

  const toPlan = plans.find(({ id }) => id === planId);
  const isPlanChangeNextCycle = isNextPlan && !isCurrentPlan;
  const contactSalesCta = 'Contact Sales';

  function handleChangePlan() {
    if (!toPlan) return;

    showModal({
      dismissOnBackdropClick: false,
      children: (
        <ChangePlanModal
          toPlan={toPlan}
          isUpgrade={isUpgrade}
          close={hideModal}
        />
      ),
    });
  }

  function handleContactSales() {
    showModal({
      dismissOnBackdropClick: false,
      children: (
        <ContactSalesModal
          formId={HUBSPOT_FORM_IDS.ENTERPRISE}
          packageName="Enterprise"
          close={hideModal}
        />
      ),
    });
  }

  function getButtonTextAndClickHandler(): [string, (() => void)?] {
    if (isCurrentPlan) {
      return ['Current Plan'];
    }

    if (isUpgrade) {
      return ['Select Plan', handleChangePlan];
    }

    if (isDowngrade) {
      return ['Downgrade Plan', handleChangePlan];
    }

    return [contactSalesCta, handleContactSales];
  }

  const [ctaText, onClickHandler] = getButtonTextAndClickHandler();
  const isContactCta = ctaText === contactSalesCta;

  return (
    <Button
      onClick={onClickHandler}
      disabled={
        (!installed && !isContactCta) ||
        isCurrentPlan ||
        isPlanChangeNextCycle ||
        (!toPlan && !isContactCta)
      }
      data-cy={`plan-cta-button-${planId}`}
      icon={
        isCurrentPlan
          ? IconSet.CheckCircle
          : isPlanChangeNextCycle
          ? IconSet.Clock
          : undefined
      }
      iconPosition="right"
      className={className}
    >
      {isPlanChangeNextCycle
        ? `Begins ${moment.utc(nextCycle?.startDate).format(LONG_DATE_FORMAT)}`
        : ctaText}
    </Button>
  );
}
