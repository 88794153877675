/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { toast } from '@postscript/components';
import { camelCasePage } from 'components/Popup/hooks/usePopupPages';
import produce from 'immer';
import { camelCase, capitalize, isNil, mapKeys, snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';
import {
  EXISTING_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT,
  NEW_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT,
} from '../../components/Popup/shared/globalSettings/BehaviorTabPanel';
import Events from '../events';
import Requests from '../network/network_requests';

export const PopupsContext = createContext();
export const usePopups = () => useContext(PopupsContext);

const DEFAULT_API_VALUES = {
  collection_attributes: ['phone'],
  two_touch_enabled: true,
  font: 'Open Sans Condensed:300',
  background_style: 'Color',
  email_pre_headline: 'SUBSCRIBE AND GET',
  email_headline: '10% OFF',
  email_post_headline: 'YOUR PURCHASE',
  email_subscribe_text: 'Sign Up',
  email_close_text: 'No Thanks',
  button_radius: 10,
  headline_font_size: 14,
  post_headline_font_size: 5,
  keywordLabel: '',
  trigger: 'Delay',
};

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const UPDATE_PREVIEW_SCREEN_STATE = 'UPDATE_PREVIEW_SCREEN_STATE';
const UPDATE_IS_COLLAPSED = 'UPDATE_IS_COLLAPSED';

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_ITEMS:
      draft.popups = action.data;
      break;
    case ADD_ITEM:
      draft.popups[action.id] = action.data;
      break;
    case UPDATE_ITEM:
      const item = draft.popups[action.id];

      draft.popups[action.id] = {
        ...item,
        ...action.data,
      };
      break;
    case REMOVE_ITEM:
      delete draft[action.id];
      break;
    case UPDATE_PREVIEW_SCREEN_STATE:
      draft.previewScreenState = action.screen;
      break;
    case UPDATE_IS_COLLAPSED:
      draft.isPreviewCollapsed = action.isCollapsed;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const PopupsProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setItems = (id, data) => {
    dispatch({
      type: SET_ITEMS,
      id,
      data,
    });
  };

  const addItem = (id, data) => {
    dispatch({
      type: ADD_ITEM,
      id,
      data,
    });
  };

  const updateItem = (id, data) => {
    dispatch({
      type: UPDATE_ITEM,
      id,
      data,
    });
  };

  const removeItem = (id) => {
    dispatch({
      type: REMOVE_ITEM,
      id,
    });
  };

  const updatePreviewScreenState = (screen) => {
    dispatch({
      type: UPDATE_PREVIEW_SCREEN_STATE,
      screen,
    });
  };

  const updateIsPreviewCollapsed = (isCollapsed) => {
    dispatch({
      type: UPDATE_IS_COLLAPSED,
      isCollapsed,
    });
  };

  // handle re-structuring payload, casing, and converting values
  const convertLocalValuesForServer = (values) => {
    const formattedValues = mapKeys(values, (v, key) => snakeCase(key));
    const includedPages = values.includedPages?.map(({ value }) => value);
    const excludedPages = values.excludedPages?.map(({ value }) => value);

    return {
      enabled: values.enabled,
      ...formattedValues,
      collection_attributes: values.collectionAttributes.split(','),
      delay: values.delay * 1000,
      // remove whitespace, leading commas, and trailing commas
      included_pages: includedPages.toString(),
      excluded_pages: excludedPages.toString(),
    };
  };

  const convertServerValuesForLocal = (values) => {
    const hasCollectionAttributes = !!values?.collection_attributes.length;

    const includedPages =
      values.included_pages
        ?.split(',')
        .filter(Boolean)
        .map((i) => ({ value: i, label: i })) ?? [];
    const excludedPages =
      values.excluded_pages
        ?.split(',')
        .filter(Boolean)
        .map((i) => ({ value: i, label: i })) ?? [];
    const customPages = values.pages ?? [];

    const valuesWithDefaults = {
      ...DEFAULT_API_VALUES,
      ...values,
      // values.font undefined represents a popup that hasn't been "converted" to new format yet
      backgroundStyle:
        values.background_style || DEFAULT_API_VALUES.background_style,
      buttonRadius: values.font
        ? values.button_radius
        : DEFAULT_API_VALUES.button_radius,
      /* attributes->collection_attributes can be the following irregular values
      on the BE: non-existent, [], and [""]. In the response, if non-existent we
      receive [], and for the others we receive ["phone"] */
      collection_attributes: hasCollectionAttributes
        ? values.collection_attributes
        : [...DEFAULT_API_VALUES.collection_attributes],
      font: values.font || DEFAULT_API_VALUES.font,
      includedPages,
      excludedPages,
      trigger: values.trigger || DEFAULT_API_VALUES.trigger,
      pages: customPages.map((page) =>
        camelCasePage({
          ...page,
          secondary_submit_button_text: page.secondary_submit_button_text ?? '',
        }),
      ),
      newSubscriberSuccessMessage:
        values.new_subscriber_success_message ??
        NEW_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT,
      existingSubscriberSuccessMessage:
        values.existing_subscriber_success_message ??
        EXISTING_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT,
    };

    const flattenedValues = {
      ...valuesWithDefaults,
      disclaimer_text_color: values.disclaimer_text_color || values.text_color,
      delay: valuesWithDefaults.delay / 1000,
      buttonRadiusStyle:
        valuesWithDefaults.button_radius === 0 ? 'Square' : 'Rounded',
      cornerRadiusStyle:
        valuesWithDefaults.border_radius === 0 ? 'Square' : 'Rounded',
      collection_attributes: valuesWithDefaults.collection_attributes.join(),
    };

    return mapKeys(flattenedValues, (v, key) => camelCase(key));
  };

  const search = async (id) => {
    try {
      setLoading(true);

      const { data } = await Requests.get(`/popups/${id}`);

      if (isNil(data)) throw new Error('Popup does not exist');

      addItem(id, data);
    } catch (err) {
      console.log('Error getting popup: ', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    search('desktop');
    search('mobile');
  }, []);

  const enable = async (type, enabled) => {
    try {
      updateItem(type, { enabled });

      const response = await Requests.put(`/popups/${type}`, {
        enabled,
      });
      Events.track(
        `[${capitalize(type)} Popup] Popup Active ${enabled} Clicked`,
      );

      if (!response) throw new Error(response);
    } catch (err) {
      updateItem(type, { enabled: !enabled });
      toast.error('Error enabling popup');
    }
  };

  return (
    <PopupsContext.Provider
      value={{
        ...state,
        setItems,
        addItem,
        updateItem,
        removeItem,
        search,
        convertLocalValuesForServer,
        convertServerValuesForLocal,
        updateIsPreviewCollapsed,
        updatePreviewScreenState,
        enable,
      }}
    >
      {children}
    </PopupsContext.Provider>
  );
};

PopupsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialState: PropTypes.object,
};

PopupsProvider.defaultProps = {
  initialState: {
    loading: false,
    popups: {},
    previewScreenState: 'phone',
    isPreviewCollapsed: false,
  },
};
