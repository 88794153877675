/* eslint-disable no-param-reassign */
export const PRODUCT_COUNT_FOR_LOW_STOCK = 5;

export const REFRESH_POLL_INTERVAL_FOR_NEW_RESPONSE = 30_000;

export const RESPONSES_QUEUE_LIMIT = 50;

export const DEFAULT_PERSONA = 'Charlotte';

export const CONVERT_EVENTS = {
  TAG_ADDED: 'tag added',
  TAG_REMOVED: 'tag removed',
  REPLY_SENT: 'reply sent',
  RESPONSE_QUEUE_FILTERED: 'response queue filtered',
  SUBSCRIBER_ROUTED_TO_FLOW: 'subscriber routed to flow',
  SUBSCRIBER_OPT_OUT_CLICKED: 'subscriber opt out clicked',
  TOGGLE_NEW_UI_CLICKED: 'toggle new ui clicked',
  UPDATE_PERMISSIONS_CLICKED: 'update permissions clicked',
  CART_LINK_INSERTED: 'cart link inserted',
  CART_UPDATED: 'cart updated',
  CART_BUILDER_TOGGLED: 'cart builder toggled',
  RESPONSE_ROUTED: 'response routed',
  RESPONSE_TEMPLATE_SELECTED: 'response template selected',
  RESPONSE_TEMPLATE_OPENED: 'response template opened',
  RESPONSE_TEMPLATE_CLOSED: 'response template closed',
  RESPONSE_TEMPLATE_FILTERED: 'response template filtered',
  SUBSCRIBER_RESOLVED: 'subscriber resolved',
  SUBSCRIBER_UNRESOLVED: 'subscriber unresolved',
  ROUTE_TO_SUPPORT: 'route to support',
};

const excludeEvents: (keyof typeof CONVERT_EVENTS)[] = [
  'RESPONSE_QUEUE_FILTERED',
  'TOGGLE_NEW_UI_CLICKED',
  'CART_BUILDER_TOGGLED',
  'RESPONSE_TEMPLATE_OPENED',
  'RESPONSE_TEMPLATE_CLOSED',
  'RESPONSE_TEMPLATE_FILTERED',
  'SUBSCRIBER_UNRESOLVED',
];

export const AGENT_EVENTS_TO_RECORD = Object.keys(CONVERT_EVENTS)
  .filter(
    (key): key is keyof typeof CONVERT_EVENTS =>
      !excludeEvents.includes(key as keyof typeof CONVERT_EVENTS),
  )
  .reduce((obj, key) => {
    obj[key] = CONVERT_EVENTS[key];
    return obj;
  }, {} as { [K in keyof typeof CONVERT_EVENTS]?: string });

export const SUBSCRIBER_CHAT_CHANNEL_PREFIX = 'subscriber-chat';

export const DEFAULT_MESSAGE_TEXT = '{shop_name}: ';
// 90px is the height of the header and footer
export const MAX_HEIGHT = 'calc(100vh - 90px)';
