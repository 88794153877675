/* eslint-disable no-case-declarations */
import { Props as BannerProps } from '@postscript/components/dist/esm/components/basic/Banner/Banner';
import produce from 'immer';
import { createContext, useContext, useReducer } from 'react';
import { BANNER_TYPES } from 'utils/events';

const ADD_BANNER = 'ADD_BANNER';
const REMOVE_BANNER = 'REMOVE_BANNER';
const UPDATE_BANNER = 'UPDATE_BANNER';

type BannerTypeKeys = keyof typeof BANNER_TYPES;
export type EventTrackingBannerTypes = typeof BANNER_TYPES[BannerTypeKeys];

interface EventTrackingOptions {
  bannerType: EventTrackingBannerTypes;
}

export interface GlobalBanner {
  id: string;
  eventTrackingOptions: EventTrackingOptions;
  isTransient?: boolean;
  isDismissable?: boolean;
  shouldPersistDismiss?: boolean;
  data: BannerProps;
}

interface AddBanner extends GlobalBanner {
  type: typeof ADD_BANNER;
}

interface RemoveBanner {
  type: typeof REMOVE_BANNER;
  id: string;
}

interface UpdateBanner extends Partial<GlobalBanner> {
  type: typeof UPDATE_BANNER;
}

type ReducerAction = AddBanner | RemoveBanner | UpdateBanner;

interface State {
  banners: GlobalBanner[];
  addBanner: (banner: GlobalBanner) => void;
  removeBanner: (id: string) => void;
  updateBanner: (banner: Partial<GlobalBanner>) => void;
}

const initialState: State = {
  banners: [],
  addBanner: () => undefined,
  removeBanner: () => undefined,
  updateBanner: () => undefined,
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case ADD_BANNER:
      draft.banners = [
        {
          id: action.id,
          eventTrackingOptions: action.eventTrackingOptions,
          isTransient: action.isTransient,
          isDismissable: action.isDismissable,
          shouldPersistDismiss: action.shouldPersistDismiss,
          data: action.data,
        },
        ...draft.banners,
      ];
      break;
    case REMOVE_BANNER:
      draft.banners = [...draft.banners.filter(({ id }) => id !== action.id)];
      break;
    case UPDATE_BANNER:
      const banners = [...draft.banners];
      const index = banners.map(({ id }) => id).indexOf(action.id || '');
      if (index === -1) break;
      banners[index] = {
        ...banners[index],
        ...action,
        data: { ...banners[index].data, ...action.data },
      };
      draft.banners = banners;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const BannersContext = createContext(initialState);
export const useBanners = (): State => useContext(BannersContext);

interface Props {
  children: JSX.Element;
}

export const GlobalBannersProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateBanner = (args: Partial<GlobalBanner>) => {
    dispatch({
      type: UPDATE_BANNER,
      ...args,
    });
  };

  const removeBanner = (id: string) => {
    dispatch({
      type: REMOVE_BANNER,
      id,
    });
  };

  const addBanner = ({
    id,
    eventTrackingOptions,
    isTransient = false,
    isDismissable = false,
    shouldPersistDismiss = false,
    data,
  }: GlobalBanner) => {
    if (!id) {
      throw new Error(
        '[Global Banners Context] A unique banner ID is required.',
      );
    }

    // Allows us to skip a previously-dismissed banner
    if (
      shouldPersistDismiss &&
      localStorage.getItem(`${id}-banner-dismissed`)
    ) {
      return;
    }

    dispatch({
      type: ADD_BANNER,
      id,
      eventTrackingOptions,
      isTransient,
      isDismissable,
      shouldPersistDismiss,
      data: { ...data },
    });
  };

  return (
    <BannersContext.Provider
      value={{
        ...state,
        addBanner,
        removeBanner,
        updateBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};
