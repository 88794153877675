import produce from 'immer';
import { has, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import Requests from '../network/network_requests';

export const NotesContext = createContext();
export const useNotes = () => useContext(NotesContext);

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';

const initialState = {
  loading: false,
  keywords: {},
};

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_ITEMS:
      draft[action.dataType][action.dataTypeId] = action.data;
      break;
    case ADD_ITEM:
      if (isNil(draft[action.dataType][action.data.type_id])) {
        draft[action.dataType][action.data.type_id] = [];
      }

      draft[action.dataType][action.data.type_id].push(action.data);
      break;
    case UPDATE_ITEM: {
      const index = draft[action.dataType][action.data.type_id].findIndex(
        ({ id }) => id === parseInt(action.data.id, 10),
      );

      const item = draft[action.dataType][action.data.type_id][index];

      draft[action.dataType][action.data.type_id][index] = {
        ...item,
        ...action.data,
      };
      break;
    }
    case REMOVE_ITEM:
      draft[action.dataType][action.dataTypeId] = draft[action.dataType][
        action.dataTypeId
      ].filter(({ id }) => id !== action.id);
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const NotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (loading) => {
    dispatch({
      type: 'loading',
      loading,
    });
  };

  const setItems = (dataType, dataTypeId, data) => {
    dispatch({
      type: SET_ITEMS,
      dataType,
      dataTypeId,
      data,
    });
  };

  const addItem = (dataType, data) => {
    dispatch({
      type: ADD_ITEM,
      dataType,
      data,
    });
  };

  const updateItem = (dataType, data) => {
    dispatch({
      type: UPDATE_ITEM,
      dataType,
      data,
    });
  };

  const removeItem = (dataType, dataTypeId, id) => {
    dispatch({
      type: REMOVE_ITEM,
      dataType,
      dataTypeId,
      id: parseInt(id, 10),
    });
  };

  const getItemById = (type, listId, itemId, key = 'id') => {
    if (!has(state, '[type][listId]')) return undefined;

    const list = state[type][listId];

    return list.find((dataItem) => dataItem[key] === parseInt(itemId, 10));
  };

  const getListById = (type, listId) => {
    if (isNil(state[type])) return undefined;

    return state[type][listId];
  };

  const search = async (type, typeId) => {
    try {
      setLoading(true);

      const {
        data: { notes },
      } = await Requests.get(`/notes/${type}/${typeId}`);

      setItems(type, typeId, notes);

      setLoading(false);

      return notes;
    } catch (err) {
      return console.log(`Error fetching /notes/${type}/${typeId} : `, err);
    }
  };

  return (
    <NotesContext.Provider
      value={{
        ...state,
        setItems,
        addItem,
        updateItem,
        removeItem,
        search,
        getItemById,
        getListById,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

NotesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
