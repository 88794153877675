import { Input, Layout, toast } from '@postscript/components';
import { Form, Formik } from 'formik';
import qs from 'qs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Requests from '../../controllers/network/network_requests';
import AuthPage, { AuthButton } from './AuthPage';

const StyledLayout = styled(Layout)`
  text-align: left;

  > div {
    width: 100%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ResetForgottenPassword = (): JSX.Element => {
  const [submitToken, setSubmitToken] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const verifyToken = async (tokenToVerify: string) => {
    const path = '/v2/auth/reset_password/verify_token_load/';
    const payload = {
      token: tokenToVerify,
    };

    try {
      const result = await Requests.put(path, payload);
      if (!result.token) window.location.replace('/login_email?badreset');
      else {
        setIsVerified(true);
        setSubmitToken(result.token);
      }
    } catch (error) {
      toast.error('Error verifying identity');
    }
  };

  const verifyPasswordSecure = (checkedPassword: string) => {
    // This regex has a positive lookahead for any special character - (?=.*[])
    // then ensures that the password matches our char set
    /* eslint-disable no-useless-escape */
    const matcher =
      /^(?=.*[!@#$%^&*()_+=\-\[\]\\'";`:/?.>,<])([a-zA-Z\d!@#$%^&*()_+=\-\[\]\\'";`:/?.>,<]*)$/;
    if (
      checkedPassword.length < 8 ||
      checkedPassword.length > 25 ||
      !checkedPassword.match(matcher)
    ) {
      setPasswordInvalid(true);
      return false;
    }
    return true;
  };

  const formSubmit = async () => {
    if (password !== password2) {
      toast.error("Passwords don't match.");
      return;
    }

    if (!verifyPasswordSecure(password)) {
      return;
    }

    if (!isVerified) {
      window.location.replace('/login_email?badreset');
      return;
    }

    setPasswordInvalid(false);

    const path = '/v2/auth/reset_password/';
    const payload = {
      token: submitToken,
      password,
    };

    try {
      const result = await Requests.put(path, payload);
      if (result.message === 'Password updated!') {
        window.location.replace('/login_email?reset');
      }
    } catch (error) {
      toast.error('Error verifying identity');
    }
  };

  useEffect(() => {
    const parsedUrl = qs.parse(window.location.search.substring(1));
    const urlToken: string = parsedUrl.token ? parsedUrl.token.toString() : '';
    if (!urlToken) window.location.replace('/login_email?badreset');
    verifyToken(urlToken);
  }, []);

  return (
    <AuthPage headingText="Password Reset">
      <Formik initialValues={{}} onSubmit={formSubmit}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <StyledLayout gap="var(--spacing-6)" vertical>
              <StyledInput
                type="password"
                id="password"
                fieldWidth="full"
                name="password-input"
                label="Enter password"
                fieldSize="large"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                data-cy="password"
              />
              <StyledInput
                type="password"
                id="password2"
                fieldWidth="full"
                name="password2-input"
                fieldSize="large"
                error={passwordInvalid}
                errorMessage="Make sure your password is 8-25 characters long and includes
                      at least 1 special character."
                message="8-25 characters long with 1 special character"
                value={password2}
                onChange={(e: any) => setPassword2(e.target.value)}
                data-cy="password2"
                label="Re-enter password"
              />
              <AuthButton
                size="large"
                variant="primary"
                type="submit"
                data-cy="reset-password-button"
              >
                Change Password
              </AuthButton>
            </StyledLayout>
          </Form>
        )}
      </Formik>
    </AuthPage>
  );
};

export default ResetForgottenPassword;
