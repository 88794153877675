export const AAE_DEV_ONLY = 'aae_dev_only';
export const DYNAMIC_TERMS = 'dynamic_terms';
export const PS_PAY = 'pspay';
export const TEXT_TO_BUY_VARIANTS = 'text_to_buy_variants';
export const POPUP_INCREASE_AB_TEST_LIMIT = 'popup_increase_ab_test_limit';
export const POPUP_BLOCK_EDITOR_DEFAULT_POPUP =
  'popup_block_editor_default_popup';
export const POPUP_DOUBLE_OPT_IN_OPTION = 'popup_double_opt_in_option';
export const POPUP_SINGLE_OPT_IN = 'allow_acquisition_single_opt_in';
export const POPUP_LINK_CLICK_OPT_IN = 'popup_link_click_opt_in';
export const POPUP_MM_DD_COLLECTION = 'popup_mm_dd_collection';
export const POPUP_NEW_COMPLIANCE_TEXT = 'popup_new_compliance_text';
export const POPUP_PROGRAMMATIC_TRIGGER = 'popup_programmatic_trigger';
export const POPUP_SPECIFIC_ESP_LISTS = 'popup_specific_esp_lists';
export const POPUP_SPIN_TO_WIN = 'popup_spin_to_win';
export const POPUP_SPLIT_TEST = 'popup_split_test';
export const POPUP_SUBMIT_TWO_LINES = 'popup_submit_two_lines';
export const POPUP_SUBSCRIBER_TARGETING = 'popup_subscriber_targeting';
export const POPUP_TWO_TOUCH_OPT_IN_OPTION =
  'popup_two_touch_option_but_for_realsies_maybe';
export const FB_FLOW_TEMPLATES = 'fb_flow_templates';
export const SLTV_DASHBOARD_DISABLED = 'sltv_dashboard_disabled';
export const FB_WAIT_SUBSCRIBER_TZ = 'fb_wait_subscriber_tz';
export const CONVERT = 'convert';
export const SSAI = 'ssai';
export const SSAI_NAV = 'ssai_nav';
export const USAGE_BILLING_L30_REPORT = 'billing_usage_report';
export const SDK_THEME_APP_EXTENSION = 'sdk_theme_app_extension';
export const SELF_MIGRATE_TO_USAGE_BILLING = 'self_migrate_to_usage_billing';
export const DELIVERABILITY_STATS = 'deliverability_stats';
export const USER_NOTIFICATION_PREFERENCES = 'user_notification_preferences';
export const COMPLIANCE_BUSINESS_LINKS = 'compliance_business_links';
export const COMPLIANCE_BUSINESS_LINKS_OPT_OUT =
  'compliance_business_links_opt_out';
export const LANDING_PAGES_USE_DEFAULT_TEMPLATE =
  'landing_pages_use_default_template';
export const SUBSCRIBER_ANALYTICS_ENABLED = 'subscriber-analytics';
export const USAGE_BILLING_ENABLED = 'usage_billing';
export const DARK_MODE = 'dark_mode';
export const EXEMPT_FROM_SHOP_TOS_GATE =
  'exempt_shop_from_terms_of_service_gate';
export const TRANSACTIONAL_MESSAGES_ENABLED = 'transactional_messages_enabled';
export const PS_LABS = 'ps_labs';
export const FLOW_BUILDER_TRANSACTIONAL_AUTOMATIONS =
  'flow_builder_transactional_automations';
export const GORGIAS_V2 = 'gorgias_v2';
export const YOTPO_LOYALTY_V2 = 'yotpo_loyalty_v2';
export const ITERABLE_INTEGRATION = 'iterable_integration';
export const LISTRAK_INTEGRATION = 'listrak_integration';
export const MAILCHIMP_INTEGRATION = 'mailchimp_integration';
export const CORDIAL_INTEGRATION = 'cordial_integration';
export const OMNISEND_INTEGRATION = 'omnisend_integration';
export const CARRIER_SEGMENTS_ENABLED = 'carrier_segments';
export const FB_OPTIMIZED_AB_SPLIT_ACTION = 'fb_optimized_ab_split_action';
export const FB_SCHEDULE_AUTOMATION_DEACTIVATION =
  'fb_schedule_automation_deactivation';
export const FB_ASYNC_CAMPAIGN_SUBSCRIBER_COUNT =
  'fb_async_campaign_subscriber_count';
export const FB_MULTI_SEGMENT_CAMPAIGNS = 'fb_multi_segment_campaigns';
export const FB_RECOMMENDED_SEND_TIME = 'fb_recommended_send_time';
export const FB_ENHANCED_LINK_TRACKING = 'fb_enhanced_link_tracking';
export const FB_RANDOM_WAIT_ACTION_CATEGORY = 'fb_random_wait_action_category';
export const FB_PRICE_DROP_AUTOMATIONS = 'fb_price_drop_automations';
export const FB_DATE_TRIGGERED_AUTOMATIONS = 'fb_date_triggered_automations';
export const FB_HIDE_SEGMENT_COUNT_IN_CAMPAIGN_PROPS =
  'fb_hide_segment_count_in_campaign_props';
export const MANUAL_ROUTE_TO_EMAIL = 'manual_route_to_email';
export const BILLING_SEGMENT_COUNTRY_COUNT = 'billing_segment_country_count';
export const DATA_LABS = 'data_labs';
export const ACTION_SEGMENT_FILTERS = 'action_segment_filters';
export const SMS_SALES_NAV = 'sms_sales_nav';
export const SMS_SALES_CONVERSATIONS = 'sms_sales_conversations';
export const SMS_SALES_CONVERSATION_INTELLIGENCE =
  'sms_sales_conversation_intelligence';
export const REDSHIFT_SEGMENTS = 'redshift_segments';
export const USE_KLAVIYO_EVENTS_V2 = 'use_klaviyo_events_v2';
export const BCC = 'brand_control_center';
export const BCCFAI = 'brand_control_center_file_ai_generated';
export const COUPON_PREFIXES = 'coupon_prefixes';
export const OMNI_SANDBOX = 'omni_sandbox';
export const OMNI_TRENDS = 'omni_trends';

const featureFlags = [
  {
    label: 'Analytics',
    value: SUBSCRIBER_ANALYTICS_ENABLED,
  },
  {
    label: 'Usage Billing',
    value: USAGE_BILLING_ENABLED,
    disabled: true,
  },
  {
    label: 'Enable dark mode beta option',
    value: DARK_MODE,
  },
  {
    label: '(Legal Only) Exempt Shop from Terms of Service Gate',
    value: EXEMPT_FROM_SHOP_TOS_GATE,
  },
  {
    label: 'Postscript hosts dynamic Terms of Service and Privacy Policy pages',
    value: DYNAMIC_TERMS,
  },
  {
    label: 'Transactional Messages',
    value: TRANSACTIONAL_MESSAGES_ENABLED,
  },
  {
    label: 'Postscript Labs',
    value: PS_LABS,
  },
];

export default featureFlags;
