import { Button, Modal, Table } from '@postscript/components';
import styled from 'styled-components';

export const StyledPayNowModal = styled(Modal)`
  min-width: 650px;
  overflow: unset;
`;

export const StyledBoldFont = styled.span`
  font-weight: var(--body-text-bold-font-weight);
`;

export const StyledTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: solid 1px var(--border-color-dim);
  padding: 10px 10px 10px 0;
`;

export const StyledAmountDue = styled(StyledBoldFont)`
  padding-right: 40px;
`;

export const StyledContinueBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const StyledDivWithBottomMargin = styled.div`
  margin-bottom: 24px;
`;

export const StyledRetryButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledTableContainer = styled.div`
  margin-top: 24px;
  border: solid 1px var(--border-color-dim);
  border-radius: 8px;
  padding: 0 0 10px 0;
`;

export const StyledTable = styled(Table)`
  max-height: 400px;
  position: relative;
  > div {
    border-radius: 8px;
  }
  thead {
    position: sticky;
    top: 0;
    background-color: var(--modal-bkg-color);
    z-index: 1;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);
  }
`;

export const StyledRetryBtn = styled(Button)`
  margin-left: var(--spacing-3);
`;
