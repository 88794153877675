import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryResult } from 'react-query';

export interface ClassificationOption {
  name: string;
  label: string;
}

export const CLASSIFICATIONS_CACHE_KEY = 'classifications';
export const CLASSIFICATIONS_BASE_URL = '/v2/responses/classification';
export const CLASSIFICATIONS_CATEGORIES_URL = `${CLASSIFICATIONS_BASE_URL}/categories`;

const getClassifications = async (): Promise<ClassificationOption[]> => {
  const response = await api.get(CLASSIFICATIONS_CATEGORIES_URL);
  return response.categories;
};

export const useClassifications = (): UseQueryResult<ClassificationOption[]> =>
  useQuery([CLASSIFICATIONS_CACHE_KEY], getClassifications, {
    staleTime: Infinity,
  });
