import { Image } from '@postscript/components';
import styled from 'styled-components';

export const StyledLogoImg = styled(Image)`
  display: inline-block;
  height: var(--spacing-6);
  width: auto;
  margin-right: var(--spacing-2);
  vertical-align: middle;
`;
