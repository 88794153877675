import moment from 'moment/moment';
import { INVOICE_STATUSES } from '../constants';
import { Invoice } from '../types';

export const getOverdueInvoicesDates = (
  invoices: Invoice[] | undefined,
): string => {
  if (!invoices || invoices?.length === 0) return '';

  let dates: string[] = [];

  invoices.forEach((invoice) => {
    if (invoice.status === INVOICE_STATUSES.FAILED) {
      const { effectiveEndDate } = invoice;
      const effectiveEndDateStr = moment(effectiveEndDate).format('MMM YYYY');
      dates.push(effectiveEndDateStr);
    }
  });

  // remove duplicates
  dates = [...new Set(dates)];

  if (dates.length === 1) return dates.join();
  if (dates.length === 2) return dates.join(' and ');

  const lastDate = dates.slice(-1);
  const otherDates = dates.slice(0, -1);

  return `${otherDates.join(', ')}, and ${lastDate}`;
};

export const convertSingleDigitToWord = (number: number): string => {
  if (number < 0) return '';
  if (number < 10) {
    const words: string[] = [
      '',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
    ];
    return words[number];
  }
  return number.toString();
};
