/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
// Billing context object.
/* eslint-disable chai-friendly/no-unused-expressions */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable block-scoped-var */
/* eslint-disable eqeqeq */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-loop-func */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-continue */
/* eslint-disable no-throw-literal */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable default-case */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-absolute-path */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable func-names */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-alert */
// Button to let admins turn on extra keywords for stores.
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */
import { captureException } from '@sentry/browser';
import { get } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import LoadingSpinner from '../../components/generic/Loading/LoadingSpinner';
import Requests from '../network/network_requests';

const BillingContext = React.createContext(undefined);

class BillingProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...(props.billing || {}),
      loading: false,
    };

    this.getBillingData = this.getBillingData.bind(this);
    this.checkForShopifyCharge = this.checkForShopifyCharge.bind(this);

    this.nextBillingDate = this.nextBillingDate.bind(this);
    this.isPastDue = this.isPastDue.bind(this);
    this.cyclesPastDue = this.cyclesPastDue.bind(this);

    this.inFreeTrial = this.inFreeTrial.bind(this);
    this.verificationUrl = this.verificationUrl.bind(this);
    this.isPendingPaymentProvider = this.isPendingPaymentProvider.bind(this);

    this.paymentSources = this.paymentSources.bind(this);
    this.isActiveSource = this.isActiveSource.bind(this);
    this.isExpiredSource = this.isExpiredSource.bind(this);
    this.needsVerifications = this.needsVerifications.bind(this);
    this.paymentSourceNeededInNDays =
      this.paymentSourceNeededInNDays.bind(this);
    this.paymentSourceNeededCurrently =
      this.paymentSourceNeededCurrently.bind(this);

    this.willBeDowngraded = this.willBeDowngraded.bind(this);

    this.createStripeToken = this.createStripeToken.bind(this);
  }

  // MARK - DATA UPDATING
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.getBillingData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;

    if (
      !this.state.loading &&
      ((!Object.keys(prevState).length && !Object.keys(this.state).length) ||
        (!prevUser && user) ||
        (user && prevUser && prevUser.id != user.id))
    ) {
      this.getBillingData();
    }
  }

  getBillingData() {
    this.setState(
      (state) => ({ ...state, loading: true }),
      async () => {
        await this.checkForShopifyCharge();
        // this may have been a no-op, so don't waste the overhead on a `null`
        const result = await Requests.get('/shops/billings');
        const cyclesResult = await Requests.get('/v2/billing/cycles');
        const cycles = cyclesResult?.cycles ?? [];

        if (result?.data) {
          this.setState(() => ({ ...result.data, cycles, loading: false }));
        }
      },
    );
  }

  async checkForShopifyCharge() {
    const params = new URL(document.location).searchParams;
    // this means we're attempting an upgrade
    if (params.get('charge_id') && params.get('plan_id')) {
      const charge_id = params.get('charge_id');
      const plan_id = params.get('plan_id');
      try {
        const result = await Requests.put(`/shops/plans/${plan_id}`, {
          next_cycle: false,
          return_url: window.location.host,
        });
        const { user } = this.props;
        // this means we haven't met the necessary amount needed to continue
        const verification_url = get(result, 'data.verification_url');
        if (!verification_url) {
          return null;
        }
        // since this is a Shopify redirect, we shouldn't ever get here and
        // we can safely assume it's an error or something that needs manual attention
        captureException(
          new Error(
            `Shopify verification url received for shop ${user.shop_id} after already approving the charge`,
            {
              verification_url,
              charge_id,
              plan_id,
              shop_id: user.shop_id,
            },
          ),
        );
      } catch (e) {}
    }
    return null;
  }
  // EMARK - DATA UPDATING

  // MARK -- DYNAMIC GETTERS
  get billing() {
    return this.state;
  }

  get isOnFreePlan() {
    return !(get(this.billing, 'current_plan.price', 0) > 0);
  }

  get isOnFreeTrial() {
    return get(this.billing, 'trial.in_free_trial', false);
  }

  get isLegacyShopify() {
    return get(this.billing, 'payment_provider', null) === null;
  }

  get isShopify() {
    return get(this.billing, 'payment_provider', null) === 'shopify';
  }

  get isStripe() {
    return get(this.billing, 'payment_provider', null) === 'stripe';
  }
  // EMARK -- DYNAMIC GETTERS

  nextBillingDate(format = null) {
    let billingDate = (this.billing && this.billing.next_billing_date) || null;
    if (billingDate) {
      billingDate = new Date(billingDate);
      if (format) {
        return moment.utc(billingDate).format(format);
      }
      return billingDate;
    }
    return null;
  }

  isPastDue() {
    if (this.billing && !this.isOnFreePlan && !this.isOnFreeTrial) {
      return (
        get(this.billing, 'billing_cycle.is_past_due') ||
        (this.billing.shopify && this.billing.shopify.verification_url)
      );
    }
    return false;
  }

  cyclesPastDue() {
    if (this.billing && !this.isOnFreePlan && !this.isOnFreeTrial) {
      return get(this.billing, 'billing_cycle.past_due_cycles', 0);
    }
    return 0;
  }

  inFreeTrial() {
    return this.isOnFreeTrial;
  }

  verificationUrl() {
    // verification urls will always be in the payment provider
    return get(
      this.billing,
      `${this.billing.payment_provider}.verification_url`,
      null,
    );
  }

  isPendingPaymentProvider() {
    return this.billing && this.billing.is_pending_payment_provider;
  }

  paymentSources() {
    // sources will always be in the payment provider
    return get(this.billing, `${this.billing.payment_provider}.sources`, null);
  }

  isActiveSource(source) {
    return !!(
      this.paymentSources() === null ||
      (!source && this.paymentSources().find((s) => s.active)) ||
      (source && source.active)
    );
  }

  isExpiredSource(source) {
    if (this.paymentSources() === null) {
      return false;
    }
    source = source || this.paymentSources().find((s) => s.active);
    if (!source) {
      return false;
    }
    const expDate = new Date(source.exp_year, source.exp_month, 1);
    const now = new Date();
    return !!(expDate < now);
  }

  needsVerifications(source) {
    if (this.paymentSources() === null) {
      return false;
    }
    source = source || this.paymentSources().find((s) => s.active);
    if (!source) {
      return false;
    }
    return source.verification_needed;
  }

  paymentSourceNeededInNDays() {
    if (
      !this.isStripe ||
      !this.billing.can_update_billing ||
      (this.paymentSources() !== null && this.isActiveSource())
    )
      return 0;

    const now = moment.utc(new Date());
    const billing_date = moment.utc(new Date(this.billing.next_billing_date));

    // we need payment info in the next N days
    const daysBetween = moment.duration(billing_date.diff(now)).asDays();

    if (!now.isAfter(billing_date) && daysBetween <= 5 && daysBetween > 0) {
      return daysBetween;
    }
    return 0;
  }

  paymentSourceNeededCurrently() {
    return (
      !this.isOnFreeTrial &&
      !this.isOnFreePlan &&
      this.isStripe &&
      !this.paymentSources()
    );
  }

  willBeDowngraded() {
    // we only downgrade after 2 missed payments
    if (!this.isPastDue()) {
      return null;
    }
    // get the number of days until the downgrade
    if (get(this.billing, 'billing_cycle.downgrade_on', null)) {
      const timeDelta =
        new Date(this.billing.billing_cycle.downgrade_on).getTime() -
        new Date().getTime();
      return Math.floor(timeDelta / (1000 * 3600 * 24));
    }
    // this means we're already over the number of days before a downgrade
    return null;
  }

  createStripeToken(data = {}, stripe) {
    const payload = { type: 'card' };
    return stripe.createToken(payload, data);
  }

  get contextValues() {
    const fns = {
      getBillingData: this.getBillingData,

      nextBillingDate: this.nextBillingDate,
      isPastDue: this.isPastDue,
      cyclesPastDue: this.cyclesPastDue,
      inFreeTrial: this.inFreeTrial,
      verificationUrl: this.verificationUrl,
      isPendingPaymentProvider: this.isPendingPaymentProvider,

      paymentSources: this.paymentSources,
      isActiveSource: this.isActiveSource,
      isExpiredSource: this.isExpiredSource,
      needsVerifications: this.needsVerifications,
      paymentSourceNeededInNDays: this.paymentSourceNeededInNDays,
      paymentSourceNeededCurrently: this.paymentSourceNeededCurrently,

      willBeDowngraded: this.willBeDowngraded,

      createStripeToken: this.createStripeToken,
    };

    return { billing: { ...this.billing, ...fns }, ...fns };
  }

  render() {
    return (
      <BillingContext.Provider value={this.contextValues}>
        {this.props.user && this.billing && Object.keys(this.billing).length ? (
          this.props.children
        ) : (
          <div className="page-load-spinner">
            <LoadingSpinner />
          </div>
        )}
      </BillingContext.Provider>
    );
  }
}

const BillingConsumer = (props) => {
  const ctx = useBilling();

  return props.children(ctx);
};

const useBilling = () => {
  const context = useContext(BillingContext);
  if (context === undefined) {
    throw new Error('useBilling can only be used inside BillingProvider');
  }
  return context;
};

export { BillingConsumer, BillingContext, BillingProvider, useBilling };
