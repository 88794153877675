import { Tag } from '../types/tags';

export const TagDelimiters = {
  START: '{{',
  END: '}}',
} as const;

export const LegacyTagDelimiters = {
  START: '{',
  END: '}',
} as const;

export const LegacyTagsDict = {
  [`${LegacyTagDelimiters.START}shop_name${LegacyTagDelimiters.END}`]: `${TagDelimiters.START}shop.name${TagDelimiters.END}`,
  [`${LegacyTagDelimiters.START}shop_link${LegacyTagDelimiters.END}`]: `${TagDelimiters.START}shop.link${TagDelimiters.END}`,
  [`${LegacyTagDelimiters.START}first_name${LegacyTagDelimiters.END}`]: `${TagDelimiters.START}subscriber.first_name${TagDelimiters.END}`,
  [`${LegacyTagDelimiters.START}last_name${LegacyTagDelimiters.END}`]: `${TagDelimiters.START}subscriber.last_name${TagDelimiters.END}`,
};

// Legacy tags we need to support on the new MessageBuilder component along with the new Jinja tags
export const LEGACY_TAGS = [
  {
    key: `${LegacyTagDelimiters.START}shop_name${LegacyTagDelimiters.END}`,
    label: 'Shop Name',
    title: 'Shop Name',
    category: 'Legacy',
  },
];

export const TAG_REGEX_PATTERN = `${TagDelimiters.START}.+?${TagDelimiters.END}`;
export const LEGACY_TAG_REGEX_PATTERN = `${LegacyTagDelimiters.START}[\\w.]+?${LegacyTagDelimiters.END}(?!})`; // Includes lookahead assertion to make sure we don't match new tags (we don't include lookbehind as well since Safari doesn't support it)
const TAG_AND_LEGACY_TAG_REGEX_PATTERN = `(${TAG_REGEX_PATTERN}|${LEGACY_TAG_REGEX_PATTERN})`;

export const TAG_REGEX = new RegExp(TAG_REGEX_PATTERN, 'g');
export const LEGACY_TAG_REGEX = new RegExp(LEGACY_TAG_REGEX_PATTERN, 'g');
export const TAG_AND_LEGACY_TAG_REGEX = new RegExp(
  TAG_AND_LEGACY_TAG_REGEX_PATTERN,
  'g',
);

// Temporary constant to be able to develop dynamic images functionality while this becomes available in the API
export const dynamicImageTagsDict: Record<string, Tag[] | undefined> = {
  ShopifyCheckoutStarted: [
    {
      key: `${TagDelimiters.START}func.dynamic_photo(initial_event)${TagDelimiters.END}`,
      label: 'Dynamic Product Photo',
      title: 'Dynamic Product Photo',
      category: 'Dynamic',
    },
  ],
  ProductAddedToCart: [
    {
      key: `${TagDelimiters.START}func.dynamic_photo(initial_event)${TagDelimiters.END}`,
      label: 'Dynamic Product Photo',
      title: 'Dynamic Product Photo',
      category: 'Dynamic',
    },
  ],
  SubscriberViewedProduct: [
    {
      key: `${TagDelimiters.START}func.first_product_photo_viewed(initial_event)${TagDelimiters.END}`,
      label: 'First Product the Subscriber Viewed',
      title: 'First Product the Subscriber Viewed',
      category: 'Dynamic',
    },
    {
      key: `${TagDelimiters.START}func.last_product_photo_viewed(initial_event)${TagDelimiters.END}`,
      label: 'Last Product the Subscriber Viewed',
      title: 'Last Product the Subscriber Viewed',
      category: 'Dynamic',
    },
  ],
};

export const DISCOUNT_LINK_LABEL = 'Discount Link';
export const PRODUCT_LINK_LABEL = 'Product Link';
