import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: 'd-flex flex-wrap mt-2 w-100',
})``;

const FormRow = ({ children }) => <Wrapper>{children}</Wrapper>;

FormRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

FormRow.defaultProps = {
  children: <></>,
};

export default FormRow;
