import { api } from 'controllers/network/apiClient';
import { useMutation, UseMutationResult } from 'react-query';
import { camelCaseKeys, snakeCaseKeys } from 'utils/formatters';
import { PopupPage } from '../types';

const snakeCasePage = (page: PopupPage): any => ({
  ...snakeCaseKeys(page),
  questions: page.questions.map((q) => snakeCaseKeys(q)),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const camelCasePage = (page: any): PopupPage =>
  ({
    ...camelCaseKeys(page),
    questions: page.questions.map((q: any) => camelCaseKeys(q)),
  } as PopupPage);

const createPopupPage = async (popupId: number, page: PopupPage) => {
  const newPage = await api.post<PopupPage>(
    `/v2/popups/${popupId}/page`,
    snakeCasePage(page),
  );
  return camelCasePage(newPage);
};

const updatePopupPage = async (popupId: number, page: PopupPage) => {
  const updatedPage = await api.put<PopupPage>(
    `/v2/popups/${popupId}/page/${page.id}`,
    snakeCasePage(page),
  );
  return camelCasePage(updatedPage);
};

const deletePopupPage = (popupId: number, pageId: string | number) =>
  api.delete(`/v2/popups/${popupId}/page/${pageId}`);

export const useCreatePopupPage = (
  popupId: number,
): UseMutationResult<PopupPage, unknown, PopupPage> =>
  useMutation((page: PopupPage) => createPopupPage(popupId, page));

export const useUpdatePopupPage = (
  popupId: number,
): UseMutationResult<PopupPage, unknown, PopupPage> =>
  useMutation((page: PopupPage) => updatePopupPage(popupId, page));

export const useDeletePopupPage = (
  popupId: number,
): UseMutationResult<any, unknown, string | number> =>
  useMutation((pageId: string | number) => deletePopupPage(popupId, pageId));
