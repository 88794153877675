import { toast } from '@postscript/components';
import cookie from 'react-cookies';
import Events from './events';
import Requests from './network/network_requests';

const YC = {
  login() {
    const path = '/login';
    const payload = {
      username: 'test@test.com',
      password: 'test',
    };
    Events.track('YC Login');
    Requests.post(path, payload).then(function (result) {
      if (result.access_token) {
        cookie.save('access_token', result.access_token, { path: '/' });
        cookie.save('refresh_token', result.refresh_token, { path: '/' });
        window.location.replace('/dashboard');
      } else {
        toast.error('Invalid email address and/or password. Please try again.');
      }
    });
  },
};

export default YC;
