import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

const VcardPhoneMessage = ({ messageContent }) => {
  return (
    <div className="phone-content-contact">
      <FontAwesome name="address-card-o" className="mr-1" />
      {messageContent}
      <FontAwesome name="angle-right" className="ml-2" />
    </div>
  );
};

VcardPhoneMessage.propTypes = {
  messageContent: PropTypes.string,
};

VcardPhoneMessage.defaultProps = {
  messageContent: 'Contact Card',
};

export default VcardPhoneMessage;
