import { Layout, Switch } from '@postscript/components';
import { useState } from 'react';
import { LEGACY_ROLES } from '../constants';
import { userHasLegacyRole } from '../helpers';

export default function UserSettingsForm(): JSX.Element | null {
  const isAiAlphaTester = userHasLegacyRole('AI_ALPHA_TESTER');

  const [ccsAIAssistant, setCcsAIAssistant] = useState(
    localStorage.getItem(LEGACY_ROLES.AI_ALPHA_TESTER) === 'true',
  );

  if (!isAiAlphaTester) {
    return null;
  }

  return (
    <Layout>
      <Switch
        id="CCSAIAssistant"
        label="CCS AI assistant"
        labelPosition="left"
        checked={ccsAIAssistant}
        onChange={() => {
          localStorage.setItem(
            LEGACY_ROLES.AI_ALPHA_TESTER,
            (!ccsAIAssistant).toString(),
          );
          setCcsAIAssistant(!ccsAIAssistant);
        }}
      />
    </Layout>
  );
}
