export const generateSubscribeText = (
  storeName: string,
  keywordName: string,
  useOldText?: boolean,
) =>
  useOldText
    ? `Send this text to subscribe to ${storeName}! (ref: ${keywordName} )`
    : `Send this text to subscribe to recurring automated marketing text messages (e.g. cart reminders) from ${storeName}. Consent is not a condition to obtain goods or services. Msg and data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. You also agree to our Terms of Service. (ref: ${keywordName} )`;

export const textIncludesKeyword = (text: string, keywordName: string) => {
  if (!text || !keywordName) return false;
  const keywordTextTrigger = `ref: ${keywordName.toUpperCase()}`;

  // don't enforce trailing space if at the end of the text
  if (text.endsWith(keywordTextTrigger)) return true;

  return text.includes(`${keywordTextTrigger} `);
};
