import React, { createContext, useContext, useEffect, useState } from 'react';

// Define the type for your context state
type SystemEventVisibilityContextType = {
  isVisible: boolean;
  enableVisibility: () => void;
  disableVisibility: () => void;
};

// Create the context
const SystemEventVisibilityContext = createContext<
  SystemEventVisibilityContextType | undefined
>(undefined);

// Define a key for local storage
const LOCAL_STORAGE_KEY = 'systemEventVisibility';

// Create the provider component
export const SystemEventVisibilityProvider: React.FC = ({ children }) => {
  // Initialize state from local storage
  const [isVisible, setIsVisible] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedValue !== null ? JSON.parse(storedValue) : false;
  });

  // Update local storage when state changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(isVisible));
  }, [isVisible]);

  // Handlers to change visibility
  const enableVisibility = () => setIsVisible(true);
  const disableVisibility = () => setIsVisible(false);

  return (
    <SystemEventVisibilityContext.Provider
      value={{ isVisible, enableVisibility, disableVisibility }}
    >
      {children}
    </SystemEventVisibilityContext.Provider>
  );
};

// Hook to use the context
export const useSystemEventVisibility = () => {
  const context = useContext(SystemEventVisibilityContext);
  if (context === undefined) {
    throw new Error(
      'useSystemEventVisibility must be used within a SystemEventVisibilityProvider',
    );
  }
  return context;
};
