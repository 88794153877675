import cookie from 'react-cookies';

const HeaderUtils = {
  createHeadersAccess() {
    const accessToken = cookie.load('access_token');
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  },
  createHeadersRefresh() {
    const refreshToken = cookie.load('refresh_token');
    return {
      Authorization: `Bearer ${refreshToken}`,
    };
  },
};

export default HeaderUtils;
