import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { toastError } from '../../../../billing/common/utils';
import {
  NotificationSubscription,
  RemoveNotificationSubscriptionPayload,
  ShopRole,
  User,
  UserNotification,
  UserNotificationsPreference,
  UserProfile,
} from '../types';
import {
  addNotificationSubscription,
  AddShopUserPayload,
  addUser,
  deleteUser,
  getShopRoles,
  getUserNotificationPreferences,
  getUserPhoneNumber,
  getUserProfile,
  getUsers,
  inviteUsers,
  removeNotificationSubscription,
  updateUserPhoneNumber,
  UpdateUserPhoneNumberPayload,
  updateUserProfile,
  updateUserShopRoles,
  UpdateUserShopRolesPayload,
} from '../usersClient';

export const USERS_QUERY_KEY = 'shop_users';
export const PROFILE_QUERY_KEY = 'user_profile';
export const ROLES_QUERY_KEY = 'shop_roles';
export const USER_PHONE_NUMBER = 'user_phone_number';
export const USER_NOTIFICATION_PREFERENCES = 'user_notification_preferences';

const staleTime = 1000 * 60;

export const useGetShopUsers = (
  options?: UseQueryOptions<User[], any, User[], typeof USERS_QUERY_KEY>,
): UseQueryResult<User[]> => {
  return useQuery(USERS_QUERY_KEY, getUsers, {
    onError: toastError,
    staleTime,
    ...options,
  });
};

export const useAddUser = (): UseMutationResult<
  void,
  any,
  AddShopUserPayload
> => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: AddShopUserPayload) => addUser(values),
    {
      onSuccess: () => queryClient.refetchQueries(USERS_QUERY_KEY),
      onError: toastError,
    },
  );

  return mutation;
};

export const useDeleteUser = (): UseMutationResult<void, any, string> => {
  const queryClient = useQueryClient();

  const mutation = useMutation((guid: string) => deleteUser(guid), {
    onSuccess: () => queryClient.refetchQueries(USERS_QUERY_KEY),
    onError: toastError,
  });

  return mutation;
};

export const useInviteUsers = (): UseMutationResult<
  void,
  any,
  AddShopUserPayload[]
> => {
  return useMutation((users: AddShopUserPayload[]) => inviteUsers(users));
};

export const useGetUserProfile = (
  options?: UseQueryOptions<
    UserProfile,
    any,
    UserProfile,
    typeof PROFILE_QUERY_KEY
  >,
): UseQueryResult<UserProfile> => {
  return useQuery(PROFILE_QUERY_KEY, getUserProfile, {
    onError: toastError,
    staleTime,
    ...options,
  });
};

export const useUpdateUserProfile = (): UseMutationResult<User, any, User> => {
  const queryClient = useQueryClient();

  const mutation = useMutation((user: User) => updateUserProfile(user), {
    onSuccess: () => queryClient.refetchQueries(PROFILE_QUERY_KEY),
    onError: toastError,
  });

  return mutation;
};

export const useGetShopRoles = (
  options?: UseQueryOptions<
    ShopRole[],
    any,
    ShopRole[],
    typeof ROLES_QUERY_KEY
  >,
): UseQueryResult<ShopRole[]> => {
  return useQuery(ROLES_QUERY_KEY, getShopRoles, {
    onError: toastError,
    staleTime,
    ...options,
  });
};

export const useUpdateUserShopRoles = (): UseMutationResult<
  void,
  any,
  UpdateUserShopRolesPayload
> => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ userId, shopId, roles }: UpdateUserShopRolesPayload) =>
      updateUserShopRoles({ userId, shopId, roles }),
    {
      onSuccess: () => queryClient.refetchQueries(USERS_QUERY_KEY),
      onError: toastError,
    },
  );

  return mutation;
};

export const useGetUserPhoneNumber = (
  userUuid: string,
  shopId: number,
  options?: UseQueryOptions<string, any, string, typeof USER_PHONE_NUMBER>,
): UseQueryResult<string> => {
  return useQuery(
    USER_PHONE_NUMBER,
    () => getUserPhoneNumber(userUuid, shopId),
    {
      onError: toastError,
      staleTime,
      ...options,
    },
  );
};

export const useUpdateUserPhoneNumber = (): UseMutationResult<
  void,
  any,
  UpdateUserPhoneNumberPayload
> => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: UpdateUserPhoneNumberPayload) => updateUserPhoneNumber(payload),
    {
      onSuccess: () => queryClient.refetchQueries(USER_PHONE_NUMBER),
      onError: toastError,
    },
  );

  return mutation;
};

export const useGetUserNotificationPreferences = (
  options?: UseQueryOptions<
    UserNotificationsPreference,
    any,
    UserNotificationsPreference,
    typeof USER_NOTIFICATION_PREFERENCES
  >,
): UseQueryResult<UserNotificationsPreference> => {
  return useQuery(
    USER_NOTIFICATION_PREFERENCES,
    getUserNotificationPreferences,
    {
      onError: toastError,
      staleTime,
      ...options,
    },
  );
};

export const useAddUserNotificationPreference = (): UseMutationResult<
  UserNotification,
  any,
  NotificationSubscription
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: NotificationSubscription) => addNotificationSubscription(payload),
    {
      onSuccess: () =>
        queryClient.refetchQueries(USER_NOTIFICATION_PREFERENCES),
      onError: toastError,
    },
  );
};

export const useRemoveUserNotificationPreference = (): UseMutationResult<
  RemoveNotificationSubscriptionPayload,
  any,
  NotificationSubscription
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: NotificationSubscription) =>
      removeNotificationSubscription(payload),
    {
      onSuccess: () =>
        queryClient.refetchQueries(USER_NOTIFICATION_PREFERENCES),
      onError: toastError,
    },
  );
};
