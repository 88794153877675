/* eslint-disable camelcase */
import { CONVERT_EVENTS } from 'components/responses/constants';
import { UUID } from 'crypto';
import LogRocket from 'logrocket';
import {
  COMMON_EVENT_NAMES,
  logButtonClickEvent,
  logEvent as basicLogEvent,
  PRODUCT_AREAS,
} from 'utils/events';
import { RoutingIntegration } from '../types';

const SALES_WORKSPACE = 'sales workspace';

interface Data {
  location: string;
  product_area: string;
  user_id: number;
  subscriber_id?: number;
  action?: string;
  [key: string]: any;
}

const logEvent = (eventName: string, eventProperties: Data) => {
  return basicLogEvent(eventName, eventProperties);
};

export const logChatListConnectionErrorRender = (userId: number) => {
  logEvent(COMMON_EVENT_NAMES.COMPONENT_RENDERED, {
    location: 'chat list',
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
  });
};

interface LogChatListConnectionErrorRefresh {
  userId: number;
}

export const logChatListConnectionErrorRefresh = (
  event: React.MouseEvent<HTMLButtonElement>,
  options: LogChatListConnectionErrorRefresh,
) => {
  const { userId } = options;
  logButtonClickEvent(event, {
    location: 'chat list error connection banner',
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
  });
};

export const logMaxRetriesAttempted = (userId: number) => {
  logEvent('websocket reconnect attempts maxed out', {
    location: 'useWebSocket context (can be anywhere)',
    product_area: PRODUCT_AREAS.GLOBAL,
    user_id: userId,
  });
};

export interface RecommendationContext {
  recommendation_id?: UUID;
  applied_action_set_name?: string;
  recommended_flow_name?: string;
  recommended_response_template?: string;
  recommended_response_text?: string;
}

export interface RecommendedActualContext extends RecommendationContext {
  actual_flow_name?: string;
  actual_response_template?: string;
  actual_response_text?: string;
}
interface LogSubscriberActionOptions {
  subscriberId: number;
  userId: number;
  message?: any;
  recommendationActualContext?: RecommendedActualContext[];
}

export const logMessageSent = ({
  subscriberId,
  userId,
  ...data
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: CONVERT_EVENTS.REPLY_SENT,
    ...data,
  });
};

export const logSubscriberResolved = ({
  subscriberId,
  userId,
  recommendationActualContext,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: CONVERT_EVENTS.SUBSCRIBER_RESOLVED,
    recommendation_actual_context: recommendationActualContext,
  });
};

export const logSubscriberUnresolved = ({
  subscriberId,
  userId,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: CONVERT_EVENTS.SUBSCRIBER_UNRESOLVED,
  });
};

export const logSubscriberOptOut = ({
  subscriberId,
  userId,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: 'subscriber opt out',
  });
};

export const logSubscriberRoutedToSupport = (
  destination: RoutingIntegration,
  { subscriberId, userId }: LogSubscriberActionOptions,
) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: `subscriber routed to ${destination}`,
  });
};

type LogTemplateOptions = {
  templateId: number;
  template: any;
  hasMedia: boolean;
} & LogSubscriberActionOptions;

export const logTemplateSelected = ({
  userId,
  subscriberId,
  templateId,
  template,
  hasMedia,
}: LogTemplateOptions) => {
  logEvent(CONVERT_EVENTS.RESPONSE_TEMPLATE_SELECTED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    template,
    template_id: templateId,
    has_media: hasMedia,
    action: CONVERT_EVENTS.RESPONSE_TEMPLATE_SELECTED,
  });
};

export const logTemplatePickerOpened = ({
  userId,
  subscriberId,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: `template picker opened`,
  });
};

export const logTemplatePickerClosed = ({
  userId,
  subscriberId,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    action: `template picker closed`,
  });
};

export const logTemplatePickerSearched = (
  search: string,
  { userId, subscriberId }: LogSubscriberActionOptions,
) => {
  logEvent(COMMON_EVENT_NAMES.INPUT_CHANGED, {
    location: 'template picker search field',
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    search,
    action: 'template picker searched',
  });
};

export const logAddSubscriberTag = (
  tag: string,
  { userId, subscriberId }: LogSubscriberActionOptions,
) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    tag,
    action: CONVERT_EVENTS.TAG_ADDED,
  });
};

export const logRemoveSubscriberTag = (
  tag: string,
  { userId, subscriberId }: LogSubscriberActionOptions,
) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    tag,
    action: CONVERT_EVENTS.TAG_REMOVED,
  });
};

export const logSubscriberRoutedToFlow = (
  triggerName: string,
  { userId, subscriberId }: LogSubscriberActionOptions,
) => {
  logEvent(COMMON_EVENT_NAMES.BUTTON_CLICKED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
    trigger_name: triggerName,
    action: CONVERT_EVENTS.SUBSCRIBER_ROUTED_TO_FLOW,
  });
};

export const logOnlineAgentUnloadTriggered = ({
  userId,
  subscriberId,
}: LogSubscriberActionOptions) => {
  logEvent(COMMON_EVENT_NAMES.UNLOAD_TRIGGERED, {
    location: SALES_WORKSPACE,
    product_area: PRODUCT_AREAS.SMS_SALES,
    user_id: userId,
    subscriber_id: subscriberId,
  });
  LogRocket.track(COMMON_EVENT_NAMES.UNLOAD_TRIGGERED);
};
