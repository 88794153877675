import {
  Button,
  IconSet,
  Layout,
  ModalHeader,
  toast,
} from '@postscript/components';
import FormikFieldText from 'components/billing/common/FormikFieldText';
import FormLayout from 'components/billing/common/FormLayout';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { FieldArray, Form, Formik } from 'formik';
import { ModalBody, ModalFooter } from 'reactstrap';
import * as yup from 'yup';
import useParseLabsFeature from './hooks/useParseLabsFeature';
import { FeatureKey } from './OmniDashboards';

const validationSchema = yup.object().shape({
  dashboards: yup.array().of(
    yup.object().shape({
      label: yup.string().required('Required'),
      value: yup.string().required('Required'),
    }),
  ),
});

interface Props {
  featureKey: FeatureKey;
}

export default function OmniDashboardsSettingsModal({
  featureKey,
}: Props): JSX.Element {
  const { hideModal } = useGlobalModal();
  const { addFeature: updateFeature } = usePSLabs();
  const { feature, dashboards } = useParseLabsFeature(featureKey);

  return (
    <>
      <ModalHeader onCancel={hideModal}>Settings</ModalHeader>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ dashboards }}
        onSubmit={async (values) => {
          if (!feature) return;
          await updateFeature(featureKey, {
            ...feature,
            documentationUrl: JSON.stringify(values.dashboards),
          });
          hideModal();
          toast.success('Settings saved.');
        }}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, isSubmitting, dirty }) => (
          <Form>
            <ModalBody>
              <FormLayout>
                <FieldArray name="dashboards">
                  {({ push, remove }) => {
                    return (
                      <>
                        {values.dashboards.map((_, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Layout key={index} valign="top">
                            <FormikFieldText
                              name={`dashboards.${index}.label`}
                              label={index === 0 && 'Display name'}
                              fieldSize="small"
                            />
                            <FormikFieldText
                              name={`dashboards.${index}.value`}
                              label={index === 0 && 'Omni dashboard ID'}
                              fieldSize="small"
                            />
                            <Button
                              variant="text"
                              size="small"
                              monochrome
                              icon={IconSet.Trash2}
                              onClick={() => remove(index)}
                              style={
                                index === 0 ? { visibility: 'hidden' } : {}
                              }
                            />
                          </Layout>
                        ))}
                        <Button
                          variant="secondary"
                          size="small"
                          monochrome
                          icon={IconSet.Plus}
                          onClick={() => {
                            push({ label: '', value: '' });
                          }}
                        >
                          Add Dashboard
                        </Button>
                      </>
                    );
                  }}
                </FieldArray>
              </FormLayout>
            </ModalBody>
            <ModalFooter>
              <Button variant="text" monochrome onClick={hideModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                monochrome
                disabled={isSubmitting || !dirty}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  );
}
