import type { FlowContext } from 'components/flowBuilder/types/tags';

export const isFlowContext = (context: unknown): context is FlowContext => {
  const contextValue = context as FlowContext;

  if (!contextValue || typeof contextValue !== 'object') return false;
  if (!contextValue.key || typeof contextValue.key !== 'string') return false;
  if (!contextValue.title || typeof contextValue.title !== 'string')
    return false;

  return true;
};
