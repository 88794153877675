import { api } from 'controllers/network/apiClient';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Subscriber, SubscriberResponse } from '../types';
import { CHAT_QUERY_KEYS } from './useChatQueue';
import { RESPONSES_CACHE_KEY_PREFIX } from './useResponses';

export const SUBSCRIBER_QUERY_KEY = 'subscriber';

interface Options {
  refetchInterval?: number;
  onSuccess?: (data: Subscriber) => void;
}

export const useSubscriber = (
  subscriberId?: number,
  { refetchInterval, onSuccess }: Options = {},
): UseQueryResult<Subscriber> => {
  return useQuery(
    [SUBSCRIBER_QUERY_KEY, subscriberId],
    () => {
      return api.get<SubscriberResponse>(`/subscribers/${subscriberId}`);
    },
    {
      select: ({ data }) => ({
        ...data,
        customer: data.customer === 'Subscriber' ? null : data.customer,
      }),
      enabled: !!subscriberId,
      ...(refetchInterval ? { refetchInterval } : {}),
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    },
  );
};

type UseSubscriberMutationResult<TVariables = unknown> = UseMutationResult<
  void,
  unknown,
  TVariables,
  unknown
>;

interface UseSendMessageOptions {
  autoResolve?: boolean;
}

export const useSendMessage = (
  subscriberId?: number,
  options?: UseSendMessageOptions,
): UseSubscriberMutationResult<any> => {
  const client = useQueryClient();
  return useMutation(
    (payload) => {
      if (!subscriberId) throw new Error('No subscriberId provided');
      const params = new URLSearchParams();
      if (options?.autoResolve !== undefined) {
        params.append('auto_resolve', options.autoResolve ? 'true' : 'false');
      }
      return api.post(`/message?${params.toString()}`, payload);
    },
    {
      onMutate: (payload: any) => {
        const previousMessages = client.getQueryData([
          SUBSCRIBER_QUERY_KEY,
          subscriberId,
        ]);

        // optimistic update
        client.setQueryData(
          [SUBSCRIBER_QUERY_KEY, subscriberId],
          (old: any) => ({
            ...old,
            chat_history: [
              ...old.data.chat_history,
              {
                body: payload.message.text,
              },
            ],
          }),
        );
        return { previousMessages };
      },
      onError: (err, newTodo, context: any) => {
        client.setQueryData(
          [SUBSCRIBER_QUERY_KEY, subscriberId],
          context.previousMessages,
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        client.invalidateQueries([SUBSCRIBER_QUERY_KEY, subscriberId]);
        client.invalidateQueries([RESPONSES_CACHE_KEY_PREFIX]);
        client.invalidateQueries(['segments']);
      },
    },
  );
};

export const useOptOutSubscriber = (
  subscriberId?: number,
): UseSubscriberMutationResult<void> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      if (!subscriberId) throw new Error('No subscriberId provided');
      return api.post('/optout', {
        subscriber_id: subscriberId,
      });
    },
    {
      onSuccess: () => {
        client.invalidateQueries([SUBSCRIBER_QUERY_KEY, subscriberId]);
        client.invalidateQueries([CHAT_QUERY_KEYS.UNRESOLVED]);
      },
    },
  );
};

export const useResolveSubscriber = (
  subscriberId?: number,
): UseSubscriberMutationResult<'resolve' | 'unresolve'> => {
  const client = useQueryClient();
  return useMutation(
    (resolveType) => {
      if (!subscriberId) throw new Error('No subscriberId provided');
      return api.post(`/responses/${resolveType}`, {
        subscriber_id: subscriberId,
      });
    },
    {
      onMutate: () => {
        const previousSubscriber = client.getQueryData([
          SUBSCRIBER_QUERY_KEY,
          subscriberId,
        ]);

        // optimistic update
        client.setQueryData(
          [SUBSCRIBER_QUERY_KEY, subscriberId],
          (old: any) => ({
            ...old,
            data: {
              ...old.data,
              subscriber: {
                ...old.data.subscriber,
                is_unresolved: !old.data.subscriber.is_unresolved,
              },
            },
          }),
        );
        return { previousSubscriber };
      },
      onError: (err, resolveType, context: any) => {
        client.setQueryData(
          [SUBSCRIBER_QUERY_KEY, subscriberId],
          context.previousSubscriber,
        );
      },
      onSuccess: () => {
        client.invalidateQueries([SUBSCRIBER_QUERY_KEY, subscriberId]);
        client.invalidateQueries([CHAT_QUERY_KEYS.UNRESOLVED]);
      },
    },
  );
};

export default useSubscriber;
