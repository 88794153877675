import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryResult } from 'react-query';
import { SalesShopsData } from '../types';

export const SHOP_DATA_QUERY_KEY = 'shopData';

export const useSalesShopData = (): UseQueryResult<SalesShopsData> => {
  return useQuery([SHOP_DATA_QUERY_KEY], async (): Promise<SalesShopsData> => {
    return api.get('/v2/convert/shop/data');
  });
};
