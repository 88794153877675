import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useBilling } from 'controllers/contexts';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BANNER_TYPES } from 'utils/events';
import { USAGE_BILLING_ENABLED } from '../../../admin/utils/feature-flags';
import { LEGACY_BILLING_PAST_DUE_BANNER_ID } from '../constants';

export default function LegacyPastDueStatusBanner(): null {
  const { addBanner, removeBanner } = useBanners();
  const { push } = useHistory();
  const { billing }: any = useBilling();
  const { hasFlag }: any = useFeatureFlags();

  useEffect(() => {
    if (!hasFlag(USAGE_BILLING_ENABLED) && billing && billing.isPastDue()) {
      addBanner({
        id: LEGACY_BILLING_PAST_DUE_BANNER_ID,
        isDismissable: true,
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.BILLING_LEGACY_PAST_DUE,
        },
        data: {
          variant: 'critical',
          heading: 'A billing issue was detected',
          bodyText:
            'Please address the issue with your billing information to ensure you can continue sending messages.',
          primaryAction: {
            text: 'Verify Billing Details',
            onClick: () => {
              removeBanner(LEGACY_BILLING_PAST_DUE_BANNER_ID);
              push('/account/billing');
            },
          },
        },
      });
    }
  }, [billing]);

  return null;
}
