import { BodyText } from '@postscript/components';

interface Props {
  children: string;
  className?: string;
}

export default function Description({
  children,
  className,
}: Props): JSX.Element {
  return (
    <BodyText size="small" color="var(--text-color)" className={className}>
      {children}
    </BodyText>
  );
}
