import { Badge, Table, toast } from '@postscript/components';
import { TableItemProps } from '@postscript/components/dist/esm/components/advanced/Table/types';
import { INVOICE_STATUSES } from 'components/billing/common/constants';
import { Invoice, InvoicesResponse } from 'components/billing/common/types';
import { formatDollars } from 'components/billing/common/utils';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import { api } from 'controllers/network/apiClient';
import { getInvoiceServicePeriod } from '../../invoices/helpers';
import {
  CreatePayNowTableRowsProps,
  CreatePayNowTableRowsReturn,
  HandleApproveOnShopifyProps,
  HandlePayInvoicesProps,
} from './types';

export const sortInvoicesByDate = (
  unpaidInvoices: InvoicesResponse,
): InvoicesResponse => {
  const customSort = (a: Invoice, b: Invoice): number => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA - dateB;
  };
  return {
    pageInfo: unpaidInvoices.pageInfo,
    shopInvoices: [...unpaidInvoices.shopInvoices].sort(customSort),
  };
};

export const getInvoiceStatusBadge = (status: string): JSX.Element => {
  return (
    <Badge
      size="small"
      variant={
        (status === INVOICE_STATUSES.PAID && 'success') ||
        (status === INVOICE_STATUSES.FORGIVEN && 'success') ||
        'error'
      }
    >
      {(status === INVOICE_STATUSES.PAID && 'Paid') ||
        (status === INVOICE_STATUSES.FORGIVEN && 'Paid') ||
        'Overdue'}
    </Badge>
  );
};

export const createPayNowTableRows = ({
  paymentsProcessing,
  shopInvoices,
}: CreatePayNowTableRowsProps): CreatePayNowTableRowsReturn => {
  if (!shopInvoices || shopInvoices.length === 0)
    return { tableRows: [], totalAmount: 0 };

  let totalAmount = 0;
  const tableRows: TableItemProps[] = [];

  shopInvoices?.forEach((invoice) => {
    const dateString = getInvoiceServicePeriod(invoice);

    totalAmount +=
      invoice.status !== INVOICE_STATUSES.PAID ? invoice?.amount ?? 0 : 0;
    tableRows.push({
      nameColumn: {
        text: `#${invoice.invoiceNumber}`,
      },
      dataColumns: [
        <Table.ItemNumber
          key={`${invoice.id}-date`}
          primary={dateString}
          size="small"
        />,
        <Table.ItemNumber
          key={`${invoice.id}-status`}
          primary={
            paymentsProcessing && invoice.status !== INVOICE_STATUSES.PAID ? (
              <LoadingSpinner>Processing...</LoadingSpinner>
            ) : (
              getInvoiceStatusBadge(invoice.status)
            )
          }
          size="small"
        />,
        <Table.ItemNumber
          key={`${invoice.id}-amount`}
          primary={formatDollars((invoice?.amount ?? 0) / 100)}
          size="small"
        />,
      ],
    });
  });
  return { tableRows, totalAmount };
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const handlePayInvoices = async ({
  invoices,
  setPaymentsProcessing,
  setRetryPayment,
  setPaymentProcessingResult,
  setInvoices,
}: HandlePayInvoicesProps) => {
  if (!invoices?.shopInvoices) return;

  setPaymentsProcessing(true);

  const chargePromisesWithDelay = invoices?.shopInvoices.map(
    async (invoice, index) => {
      if (invoice.status === INVOICE_STATUSES.PAID) return invoice;
      const { id } = invoice;
      await delay(1000 * index); // incremental 1-second delay between charge calls
      return api.post(`/v2/billing/invoices/shop_invoices/${id}/charge`);
    },
  );

  await Promise.all(chargePromisesWithDelay)
    .then((result: Invoice[]) => {
      let numberOfPaidInvoices = 0;
      result.forEach((invoice) => {
        if (invoice.status === INVOICE_STATUSES.PAID) numberOfPaidInvoices += 1;
      });
      setRetryPayment(numberOfPaidInvoices !== result.length);
      setPaymentProcessingResult({
        paymentProcessingFinished: true,
        totalInvoices: result.length,
        paidInvoices: numberOfPaidInvoices,
      });
      setPaymentsProcessing(false);

      const updatedInvoices: InvoicesResponse = {
        pageInfo: invoices.pageInfo,
        shopInvoices: result,
      };

      setInvoices(sortInvoicesByDate(updatedInvoices));
    })
    .catch((error) => {
      toast.error(error as string);
    });
};

export const handleApproveOnShopify = async ({
  setApproveOnShopifyProcessing,
  generateRac,
}: HandleApproveOnShopifyProps) => {
  setApproveOnShopifyProcessing(true);
  try {
    const rac = await generateRac('/billing/overview?rac_approved=true');
    if (!rac) {
      setApproveOnShopifyProcessing(false);
      return toast.error('Please try again.');
    }
    window.location.href = rac.confirmationUrl;
  } catch (error) {
    setApproveOnShopifyProcessing(false);
    toast.error(error as string);
  }
};
