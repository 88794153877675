import {
  ADD_TYPE,
  AND_JOIN_TYPE,
  MESSAGE_INITIAL_VALUE,
  OptimizationMetricOptions,
  OptimizedABSplitTestStatuses,
  TextToBuySidebarNames,
} from 'components/flowBuilder/constants';
import { StaticActionsTypes } from 'components/flowBuilder/constants/actions';
import { StaticStepsTypes } from 'components/flowBuilder/constants/steps';
import {
  BinaryCondition,
  Condition,
  ConditionCase,
  ConditionGroup,
  SubscriberGroup,
  TextToBuyProduct,
  TextToBuySidebarType,
  WaitActionCategory,
} from 'components/flowBuilder/types';
import {
  ABSplitAction,
  Action,
  AIManagedMessageAction,
  DynamicAction,
  DynamicActionType,
  EndAction,
  OptimizedABSplitAction,
  SendMessageAction,
  SubscriberAttributeSplitAction,
  TextToBuyAction,
  TriggerEventSplitAction,
  UpdateSubscriberAction,
  WaitAction,
  WaitForEventSplitAction,
} from 'components/flowBuilder/types/actions';
import {
  isABSplitAction,
  isABSplitActionParams,
  isAIManagedMessageAction,
  isAIManagedMessageActionParams,
  isDynamicAction,
  isDynamicActionParams,
  isDynamicSplitAction,
  isOptimizedABSplitAction,
  isOptimizedABSplitActionBackendConfig,
  isOptimizedABSplitActionParams,
  isSendMessageAction,
  isSendMessageActionParams,
  isSplitAction,
  isSubscriberAttributeSplitAction,
  isSubscriberAttributeSplitActionParams,
  isTextToBuyAction,
  isTextToBuyActionParams,
  isTriggerEventSplitAction,
  isTriggerEventSplitActionParams,
  isUpdateSubscriberAction,
  isUpdateSubscriberActionParams,
  isWaitAction,
  isWaitActionParams,
  isWaitForEventSplitAction,
  isWaitForEventSplitActionParams,
} from 'components/flowBuilder/types/actions/typeGuards';
import {
  AutomationTriggerStep,
  CampaignTriggerStep,
} from 'components/flowBuilder/types/steps';
import { isConditionCase } from 'components/flowBuilder/types/typeGuards';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getDynamicActionSplitField } from './dynamicActions';

export const createAutomationTriggerStep = (): AutomationTriggerStep => ({
  guid: uuidv4(),
  type: StaticStepsTypes.AUTOMATION_TRIGGER,
  next: [],
  params: {
    triggerEvents: [],
    cancelEvents: [],
    userFilterCriteria: [],
    triggerFilters: [],
    templatedUserFilterCriteria: [],
    transactional: false,
  },
});

export const createCampaignTriggerStep = (): CampaignTriggerStep => ({
  guid: uuidv4(),
  type: StaticStepsTypes.CAMPAIGN_TRIGGER,
  next: [],
  params: {
    safeSend: false,
    segmentIds: [],
    excludeSegmentIds: [],
    cancelEvents: [],
  },
});

export const createEmptyTextToBuyProduct = (): TextToBuyProduct => {
  return {
    shopify_product_id: null,
    shopify_product_name: null,
    shopify_variant_id: null,
    shopify_variant_name: null,
    price: null,
    min_price: null,
    max_price: null,
    quantity: 1,
    guid: uuidv4(),
  };
};

export const createTextToBuyAction = (): TextToBuyAction => ({
  guid: uuidv4(),
  type: StaticStepsTypes.TEXT_TO_BUY,
  next: [],
  params: {
    cases: [
      {
        action_guid: '',
        event: 'PayOrderConfirmed',
      },
      {
        action_guid: '',
        event: 'PayOrderCancelled',
      },
      {
        action_guid: '',
        event: 'PayOrderExpired',
      },
      {
        action_guid: '',
        event: 'FlowScheduledEvent',
      },
    ],
    content: MESSAGE_INITIAL_VALUE,
    mediaUrl: null,
    products: [createEmptyTextToBuyProduct()],
  },
});

const createWaitAction = (): WaitAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.WAIT,
  params: {
    category: null,
    useSubscriberTz: false,
    clientTzOffset: '+00:00',
  },
  next: [],
});

export const createEmptyBinaryCondition = (): BinaryCondition => {
  return {
    operator: '',
    type: 'Binary',
    value: '',
    variable: '',
    allow_approximate_matches: false,
  };
};

export const createConditionGroup = (): ConditionGroup => {
  return {
    type: AND_JOIN_TYPE,
    value: [createEmptyBinaryCondition()],
  };
};

export const createSubscriberTag = (): SubscriberGroup => {
  return {
    type: ADD_TYPE,
    value: '',
  };
};

export const createEmptyBranchConditions = (): BinaryCondition[] => {
  return [createEmptyBinaryCondition()];
};

export const createEmptyBranch = (): ConditionCase => {
  return {
    action_guid: '',
    conditions: createEmptyBranchConditions(),
  };
};

const createElseBranch = (): ConditionCase => {
  return {
    action_guid: '',
    conditions: [],
  };
};

const createEmptyCases = (): ConditionCase[] => {
  return [createEmptyBranch(), createElseBranch()];
};

export const createSendMessageAction = (): SendMessageAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.SEND_MESSAGE,
  params: {
    content: MESSAGE_INITIAL_VALUE,
    mediaUrl: null,
  },
  next: [],
});

export const createAIManagedMessageAction = (): AIManagedMessageAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.AI_MANAGED_MESSAGE,
  params: {},
  next: [],
});

export const createABSplitCondition = (value: number): Condition => {
  return {
    variable: '_proportional_split',
    operator: '<',
    value,
    type: 'Binary',
    allow_approximate_matches: false,
  };
};

const createABSplitAction = (): ABSplitAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.AB_SPLIT,
  params: {
    cases: [
      {
        // action_guid must be replaced with the first action that follows.
        action_guid: '',
        conditions: [createABSplitCondition(0.5)],
      },
      {
        action_guid: '',
        conditions: [],
      },
    ],
  },
  next: [],
});

export const createOptimizedABSplitAction = (): OptimizedABSplitAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.OPTIMIZED_AB_SPLIT,
  params: {
    cases: [
      {
        // action_guid must be replaced with the first action that follows.
        action_guid: '',
        conditions: [createABSplitCondition(0.5)],
      },
      {
        action_guid: '',
        conditions: [],
      },
    ],
    maxDuration: null,
    metric: OptimizationMetricOptions[0].value,
  },
  backendConfig: {
    test_status: OptimizedABSplitTestStatuses.DRAFT,
    status_updated_at: null,
  },
  next: [],
});

const createWaitForEventSplitAction = (): WaitForEventSplitAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.WAIT_FOR_EVENT_SPLIT,
  params: {
    duration: 'PT24H',
    event_type: '',
    cases: createEmptyCases(),
  },
  next: [],
});

const createTriggerEventSplitAction = (): TriggerEventSplitAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.TRIGGER_EVENT_SPLIT,
  params: {
    cases: createEmptyCases(),
  },
  next: [],
});

const createSubscriberAttributeSplitAction =
  (): SubscriberAttributeSplitAction => ({
    guid: uuidv4(),
    type: StaticActionsTypes.SUBSCRIBER_ATTRIBUTE_SPLIT,
    params: {
      cases: createEmptyCases(),
    },
    next: [],
  });

const createUpdateSubscriberAction = (): UpdateSubscriberAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.UPDATE_SUBSCRIBER,
  params: {
    addTags: [],
    removeTags: [],
  },
  next: [],
});

export const createEndAction = (): EndAction => ({
  guid: uuidv4(),
  type: StaticActionsTypes.END,
  params: {},
  next: [],
});

const createDynamicAction = (
  dynamicActionType: DynamicActionType,
): DynamicAction => {
  const params: Record<string, any> = {};

  dynamicActionType.fields.forEach((field) => {
    params[field.options.paramName] = field.options.defaultParamValue;
  });

  return {
    guid: uuidv4(),
    type: dynamicActionType.type,
    params,
    next: [],
  };
};

export interface CreateNewEdgeMeta {
  defaultWaitCategory?: WaitActionCategory;
}

export const createAction = (
  selectedActionType: string,
  dynamicActionTypes: DynamicActionType[],
): Action | null => {
  switch (selectedActionType) {
    case StaticActionsTypes.WAIT:
      return createWaitAction();
    case StaticActionsTypes.SEND_MESSAGE:
      return createSendMessageAction();
    case StaticActionsTypes.AB_SPLIT:
      return createABSplitAction();
    case StaticActionsTypes.OPTIMIZED_AB_SPLIT:
      return createOptimizedABSplitAction();
    case StaticActionsTypes.WAIT_FOR_EVENT_SPLIT:
      return createWaitForEventSplitAction();
    case StaticActionsTypes.TRIGGER_EVENT_SPLIT:
      return createTriggerEventSplitAction();
    case StaticActionsTypes.SUBSCRIBER_ATTRIBUTE_SPLIT:
      return createSubscriberAttributeSplitAction();
    case StaticActionsTypes.UPDATE_SUBSCRIBER:
      return createUpdateSubscriberAction();
    case StaticActionsTypes.TEXT_TO_BUY:
      return createTextToBuyAction();
    case StaticActionsTypes.AI_MANAGED_MESSAGE:
      return createAIManagedMessageAction();
    case StaticActionsTypes.END:
      return createEndAction();
    default: {
      const selectedDynamicActionType = dynamicActionTypes.find(
        ({ type }) => selectedActionType === type,
      );
      if (selectedDynamicActionType)
        return createDynamicAction(selectedDynamicActionType);
      return null;
    }
  }
};

export const createActionCopy = (action: Action): Action => {
  return {
    ...cloneDeep(action),
    guid: uuidv4(),
  };
};

export const findActionIndexByGuid = (
  guid: string,
  actions: Action[],
): number | null => {
  const index = actions.findIndex((draftAction) => draftAction.guid === guid);
  return index !== -1 ? index : null;
};

export const findActionByGuid = (
  guid: string,
  actions: Action[],
): Action | null => {
  const index = findActionIndexByGuid(guid, actions);
  return index !== null ? actions[index] : null;
};

export const findActionByNextGuid = (
  guid: string,
  actions: Action[],
): Action | null => {
  const index = actions.findIndex((action) => action.next.includes(guid));
  return index !== -1 ? actions[index] : null;
};

export const replaceNextGuidInAction = (
  oldGuid: string,
  newGuids: string[],
  action: Action,
): void => {
  const index = action.next.indexOf(oldGuid);
  if (index !== -1) action.next.splice(index, 1, ...newGuids);
};

export const mergeActionConfig = (action: Action, config: unknown) => {
  if (
    isOptimizedABSplitAction(action) &&
    isOptimizedABSplitActionBackendConfig(config)
  ) {
    return {
      ...action,
      backendConfig: { ...action.backendConfig, ...config },
    };
  }

  return action;
};

export const mergeActionParams = (action: Action, params: unknown): Action => {
  if (isWaitAction(action) && isWaitActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isSendMessageAction(action) && isSendMessageActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isABSplitAction(action) && isABSplitActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isOptimizedABSplitAction(action) &&
    isOptimizedABSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isUpdateSubscriberAction(action) &&
    isUpdateSubscriberActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isWaitForEventSplitAction(action) &&
    isWaitForEventSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isTriggerEventSplitAction(action) &&
    isTriggerEventSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isSubscriberAttributeSplitAction(action) &&
    isSubscriberAttributeSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isTextToBuyAction(action) && isTextToBuyActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isAIManagedMessageAction(action) &&
    isAIManagedMessageActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isDynamicAction(action) && isDynamicActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  return action;
};

export const updateActionNext = (action: Action, next: string[]): Action => {
  const newAction = { ...action, next };

  return newAction;
};

export const textToBuyEventToSidebarType = (
  event: string,
): TextToBuySidebarType | null => {
  const map: Record<string, TextToBuySidebarType> = {
    PayOrderConfirmed: TextToBuySidebarNames.T2B_PREVIEW_PURCHASED,
    PayOrderCancelled: TextToBuySidebarNames.T2B_PREVIEW_CANCELLED,
    PayOrderExpired: TextToBuySidebarNames.T2B_PREVIEW_DIDNT_PURCHASE,
  };

  return map[event] || null;
};

export const findSourceActions = (
  guid: string,
  actions: Action[],
): Action[] => {
  return actions.filter((action) => action.next.includes(guid));
};

export const replaceActionGuidInActions = (
  oldGuid: string,
  newGuid: string,
  actions: Action[],
): Action[] => {
  const newActions = cloneDeep(actions);

  for (let i = 0; i < newActions.length; i += 1) {
    const action = newActions[i];

    if (action.guid === oldGuid) action.guid = newGuid;

    for (let j = 0; j < action.next.length; j += 1) {
      if (action.next[j] === oldGuid) action.next[j] = newGuid;
    }

    if (isSplitAction(action)) {
      for (let j = 0; j < action.params.cases.length; j += 1) {
        const branch = action.params.cases[j];
        if (branch.action_guid === oldGuid) branch.action_guid = newGuid;
      }
    }
  }

  return newActions;
};

export const createNewNext = (action: Action, targetGuid: string) => {
  if (isTextToBuyAction(action)) {
    return [targetGuid, targetGuid, targetGuid];
  }

  if (
    isABSplitAction(action) ||
    isOptimizedABSplitAction(action) ||
    isWaitForEventSplitAction(action) ||
    isTriggerEventSplitAction(action) ||
    isSubscriberAttributeSplitAction(action)
  ) {
    return [targetGuid, targetGuid];
  }

  // In the case of a dynamic split action, we need to create as many nexts as cases
  // as there are in the initialCases as defined in the dynamic action definition
  if (isDynamicSplitAction(action)) {
    const dynamicSplitField = getDynamicActionSplitField(action);

    if (dynamicSplitField) {
      return dynamicSplitField.options.defaultParamValue.map(() => targetGuid);
    }
  }

  return [targetGuid];
};

export const isBranchCompleted = (branch: ConditionCase): boolean => {
  return isConditionCase(branch);
};
