import {
  BodyText,
  Button,
  Heading,
  Icon,
  IconSet,
  Layout,
} from '@postscript/components';
import styled from 'styled-components';

const focusInputByName = (name: string): void => {
  const input: HTMLInputElement | null = document.querySelector(
    `[name=${name}]`,
  );
  input?.focus();
};

const StyledBodyText = styled(BodyText)`
  margin-top: var(--spacing-4);
`;

const Channels = styled.div`
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  border-bottom: 1px solid var(--border-color-dim);

  div + div {
    margin-top: var(--spacing-2);
  }
`;

const ProductArea = styled.div`
  & + & {
    margin-top: var(--spacing-4);
  }
`;

const ProductAreaHeading = styled(Heading).attrs({
  forwardedAs: 'h3',
  size: 'small',
})`
  margin-bottom: var(--spacing-3);
`;

interface NotificationProductArea {
  heading: string;
  children: React.ReactNode;
}

interface Props {
  type: 'personal' | 'business';
  emailInputName?: string;
  phoneNumberInputName?: string;
  phoneNumber: string;
  emailAddress: string;
  productAreas: NotificationProductArea[];
}

export default function NotificationPreferences({
  type,
  emailInputName,
  phoneNumberInputName,
  phoneNumber,
  emailAddress,
  productAreas,
}: Props): JSX.Element {
  return (
    <>
      <StyledBodyText bold>
        Notifications will be sent to your {type} email or phone number:
      </StyledBodyText>
      <Channels>
        <Layout gap="var(--spacing-1)">
          <Icon component={IconSet.Mail} size="medium" />
          <span>{emailAddress}</span>
          {emailInputName && (
            <Button
              size="small"
              variant="text"
              onClick={() => focusInputByName(emailInputName)}
            >
              Edit Email
            </Button>
          )}
        </Layout>
        <Layout gap="var(--spacing-1)">
          <Icon component={IconSet.Phone} size="medium" />
          {phoneNumber && <span>{phoneNumber}</span>}
          {phoneNumberInputName && (
            <Button
              size="small"
              variant="text"
              onClick={() => focusInputByName(phoneNumberInputName)}
            >
              {phoneNumber ? 'Edit' : 'Add'} Phone Number
            </Button>
          )}
        </Layout>
      </Channels>
      {productAreas.map(({ heading, children }) => (
        <ProductArea key={heading}>
          <ProductAreaHeading>{heading}</ProductAreaHeading>
          {children}
        </ProductArea>
      ))}
    </>
  );
}
