import { formatMessageRate } from 'components/billing/common/utils';

interface Props {
  typicalFee?: number;
}

export default function TypicalCarrierFees({
  typicalFee,
}: Props): JSX.Element | null {
  if (!typicalFee) return null;

  return <span>Typical carrier fees: {formatMessageRate(typicalFee)}</span>;
}
