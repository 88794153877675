/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { SUBSCRIBER_PROPERTY_TYPES } from 'components/Popup/constants';
import * as featureFlags from '../components/admin/utils/feature-flags';
import DelayedDate from '../components/filters/DelayedDate';
import DelayedInput from '../components/filters/DelayedInput';
import Helpers from './helpers';
import Requests from './network/network_requests';

const Filters = {
  expressionsObj: {
    equals: 'eq',
    'not equal to': 'ne',
    'less than': 'lt',
    'less than or equal to': 'le',
    'greater than': 'gt',
    'greater than or equal to': 'ge',
    includes: 'in',
    'does not include': 'ni',
    before: 'lt',
    'on or before': 'le',
    after: 'gt',
    'on or after': 'ge',
    'within last days': 'wl',
    'within last hours': 'wlh',
    'within last minutes': 'wlm',
    'before last days': 'bl',
    'before last hours': 'blh',
    'before last minutes': 'blm',
  },
  /**
   * Note: if you need to deprecate a segment filter, use the `deprecated: true` flag instead of deleting.
   * This will hide the deprecated segment in the create/update views but still allow the segment to render
   * in the readonly view
   */
  attributesObj: {
    'Total Spent': {
      id: 'total_spent_cents',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Average Order Value': {
      id: 'average_order_value_cents',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Number of Orders': {
      id: 'num_of_orders',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Customer Created Date': {
      id: 'created_at',
      version: 2,
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
    },
    'Number of Texts Sent': {
      id: 'total_texts_count',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Last Texted Date': {
      id: 'last_texted',
      version: 2,
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
    },
    'Last Order Date': {
      id: 'last_order_date',
      version: 2,
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
    },
    'Customer Phone Two Letter Country Code': {
      id: 'country_code',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Last Click Date': {
      id: 'subscriber_last_click_date',
      version: 2,
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
    },
    'Subscriber Created Date': {
      id: 'subscriber_created_at',
      version: 2,
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
    },
    'Subscriber Tags': {
      id: 'subscriber_tags',
      expressions: ['includes', 'does not include'],
      type: 'string',
    },
    'Subscriber is International': {
      id: 'subscriber_is_international',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
      deprecated: true,
    },
    'Subscriber is Currently in Automation': {
      id: 'subscriber_is_in_automation',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Subscriber Signed Up With Keyword': {
      id: 'subscriber_explicit_opt_in',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'Subscriber is a Customer': {
      id: 'subscriber_is_a_customer',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'Subscriber is Active ReCharge Subscriber': {
      id: 'is_active_recharge_customer',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'Subscriber Has Clicked': {
      id: 'subscriber_has_clicked',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'Purchased Product Names (legacy)': {
      id: 'product_title',
      expressions: ['includes', 'does not include'],
      type: 'select',
      deprecated: true,
    },
    'Purchased Product Names': {
      id: 'product_id',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Purchased Product Types': {
      id: 'product_type',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Purchased Product Tags': {
      id: 'product_tag',
      expressions: ['includes', 'does not include'],
      type: 'string',
    },
    'Standard Campaigns Clicked': {
      id: 'subscriber_clicked_campaign_id',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Standard Campaigns Received': {
      id: 'subscriber_received_campaign_id',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Campaign Undelivered': {
      id: 'subscriber_undelivered_campaign_id',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Currently in Campaign Flow': {
      id: 'subscriber_currently_in_campaign_flow',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Has been in Campaign Flow': {
      id: 'subscriber_has_been_in_campaign_flow',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Campaign Flows Clicked': {
      id: 'subscriber_clicked_campaign_flow_id',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Automation Flows Clicked': {
      id: 'subscriber_clicked_automation_flow_id',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Legacy Automations Clicked': {
      id: 'subscriber_has_clicked_automation',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Legacy Automations Received': {
      id: 'subscriber_has_received_automation',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Currently in Automation Flow': {
      id: 'subscriber_currently_in_automation_flow',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Has been in Automation Flow': {
      id: 'subscriber_has_been_in_automation_flow',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Keyword Most Recently Triggered': {
      id: 'subscriber_keyword_id',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Keywords Triggered': {
      id: 'subscriber_keywords',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Customer Tags': {
      id: 'customer_tag',
      expressions: ['includes', 'does not include'],
      type: 'string',
    },
    'Customer Shipping Address City': {
      id: 'customer_city',
      expressions: ['equals', 'not equal to'],
      type: 'string',
    },
    'Customer Shipping Address State': {
      id: 'customer_state',
      expressions: ['equals', 'not equal to'],
      type: 'string',
    },
    'Customer ID': {
      id: 'customer_id',
      expressions: ['equals', 'not equal to'],
      type: 'string',
    },
    'A/B Test Group': {
      id: 'ab_segment',
      expressions: ['equals', 'not equal to'],
      type: 'abtest',
    },
    'Subscriber In Klaviyo List or Segment': {
      id: 'subscriber_is_in_klaviyo_list',
      expressions: ['includes', 'does not include'],
      type: 'select',
    },
    'Subscriber Opt-In Source': {
      id: 'subscriber_opt_in_source',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Subscriber Phone State (US only)': {
      id: 'phone_state_code',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Subscriber Phone Two Letter Country Code': {
      id: 'phone_country_code',
      expressions: ['equals', 'not equal to'],
      type: 'select',
    },
    'Yotpo Customer Point Balance': {
      id: 'yotpo_point_balance',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Swell Customer Point Balance (Deprecated)': {
      id: 'swell_point_balance',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Number of Replies': {
      id: 'subscriber_num_replies',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Enrolled in LoyaltyLion': {
      id: 'loyalty_lion_enrolled',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'LoyaltyLion Points Balance': {
      id: 'loyalty_lion_point_balance',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Enrolled in Smile': {
      id: 'smile_enrolled',
      expressions: ['equals', 'not equal to'],
      type: 'bool',
    },
    'Smile Points Balance': {
      id: 'smile_point_balance',
      expressions: [
        'equals',
        'not equal to',
        'less than',
        'less than or equal to',
        'greater than',
        'greater than or equal to',
      ],
      type: 'number',
    },
    'Subscriber Carrier': {
      id: 'carrier',
      expressions: ['includes', 'does not include'],
      type: 'select',
      featureFlags: [featureFlags.CARRIER_SEGMENTS_ENABLED],
    },
    'Subscriber Received Klaviyo Email': {
      id: 'subscriber_received_klaviyo_email',
      expressions: ['includes', 'does not include'],
      type: 'select',
      featureFlags: [
        featureFlags.REDSHIFT_SEGMENTS,
        featureFlags.USE_KLAVIYO_EVENTS_V2,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Clicked Klaviyo Email': {
      id: 'subscriber_clicked_klaviyo_email',
      expressions: ['includes', 'does not include'],
      type: 'select',
      featureFlags: [
        featureFlags.REDSHIFT_SEGMENTS,
        featureFlags.USE_KLAVIYO_EVENTS_V2,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Submitted Popup': {
      id: 'subscriber_submitted_popup',
      expressions: ['equals', 'not equal to'],
      type: 'select',
      featureFlags: [featureFlags.POPUP_SUBSCRIBER_TARGETING],
    },
  },

  actionSegmentFilters: {
    'Subscriber Viewed Product': {
      id: 'subscriber_viewed_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Viewed Any Product': {
      id: 'subscriber_viewed_any_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 2,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Added Product To Cart': {
      id: 'product_added_to_cart',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Added Any Product To Cart': {
      id: 'product_added_to_cart_any',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 2,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Started Checkout With Product': {
      id: 'checkout_started_with_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Started Checkout With Any Product': {
      id: 'checkout_started_with_any_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 2,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Created Order With Product': {
      id: 'order_created_with_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Created Order With Any Product': {
      id: 'order_created_with_any_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 2,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'No Order Created With Product': {
      id: 'exclude_order_created_with_product',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
  },

  priceDropFilters: {
    'Subscriber Viewed Variant': {
      id: 'subscriber_viewed_product_variant',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
    'Subscriber Started Checkout With Variant': {
      id: 'checkout_started_with_product_variant',
      expressions: {
        equals: {
          input: 'date',
        },
        'not equal to': {
          input: 'date',
        },
        before: {
          input: 'date',
        },
        'on or before': {
          input: 'date',
        },
        after: {
          input: 'date',
        },
        'on or after': {
          input: 'date',
        },
        'within last days': {
          input: 'number',
          suffix: 'Days',
        },
        'within last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'within last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
        'before last days': {
          input: 'number',
          suffix: 'Days',
        },
        'before last hours': {
          input: 'number',
          suffix: 'Hours',
        },
        'before last minutes': {
          input: 'number',
          suffix: 'Minutes',
        },
      },
      version: 3,
      featureFlags: [
        featureFlags.ACTION_SEGMENT_FILTERS,
        featureFlags.REDSHIFT_SEGMENTS,
      ],
      singleSubscriberLookups: false,
    },
  },

  /**
   * Implement new input types for segment filters here.  Any input component
   * can be used if it accepts the following props:
   *
   * onChange - a function that is called with the updated input value
   * value - value to be passed into input
   * disabled - boolean to enable/disable input
   */

  inputs: {
    number: {
      component: DelayedInput,
      defaults: {
        size: 'sm',
        type: 'number',
      },
    },
    date: {
      component: DelayedDate,
      defaults: {
        date: null,
        small: true,
      },
      formatter: (value) => Helpers.formatDate(value),
    },
  },

  validateFilter(attributeName, value) {
    if (
      value != '' &&
      ['Total Spent', 'Average Order Value', 'Number of Orders'].indexOf(
        attributeName,
      ) >= 0
    ) {
      // Check that the value is valid
      if (parseInt(value) >= 0) {
        return true;
      }
      return false;
    }

    return true;
  },

  criterify(criteria) {
    for (const e in Filters.expressionsObj) {
      criteria = criteria.replace(`${Filters.expressionsObj[e]},`, `${e} `);
    }
    for (const a in Filters.attributesObj) {
      criteria = criteria.replace(`${Filters.attributesObj[a]},`, `${a} `);
    }
    return criteria;
  },

  async getProductFilters(search = '', limit = 50) {
    const path = `/segments/product_filters/v2?search=${search}&limit=${limit}`;

    const result = await Requests.get(path);

    if (result && result.error) return null;

    return result;
  },

  getKeywordList() {
    const path = '/keywords?start=0&limit=1000';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getCampaignList() {
    const path = '/campaigns/list';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getCarrierList() {
    const path = '/v2/messaging/carriers/list';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getCampaignFlowsList() {
    const path = '/v2/flowbuilder/flows/lookup/?type__eq=CAMPAIGN';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getAutomationList() {
    const path = '/automations/list';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getAutomationFlowsList() {
    const path = '/v2/flowbuilder/flows/lookup/?type__eq=AUTOMATION';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getKlaviyoEvents() {
    const path = '/v2/integrations/klaviyo/events/names';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getKlaviyoLists() {
    const path = '/v2/integrations/klaviyo/lists';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  getKlaviyoSegments() {
    const path = '/v2/integrations/klaviyo/segments';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  /**
   * Includes sources and popup_sources
   */
  getOptInSourceList() {
    const path = '/shop/opt_in_sources';
    const promise = new Promise(function (resolve, reject) {
      let result;
      Requests.get(path).then(function (result) {
        if (result && result.error) {
          resolve(null);
        } else {
          resolve(result);
        }
      });
    });
    return promise;
  },

  async getCustomPropertiesList(fetchCustomProperties) {
    try {
      const response = {};
      const customPropertiesResponse = await fetchCustomProperties();

      customPropertiesResponse.attributes.forEach((property) => {
        const label = `Custom Field ${property.key}`;
        const isMonthDayProperty =
          property.type === SUBSCRIBER_PROPERTY_TYPES.MONTH_AND_DAY;

        response[label] = {
          id: `custom_properties_${property.key}`,
          expressions: isMonthDayProperty
            ? ['equals', 'on or before', 'on or after']
            : [
                'equals',
                'not equal to',
                'less than',
                'less than or equal to',
                'greater than',
                'greater than or equal to',
                'includes',
                'does not include',
                'before',
                'after',
              ],
          type: isMonthDayProperty ? property.type : 'string',
        };
      });

      return response;
    } catch {
      return null;
    }
  },
};

export default Filters;
