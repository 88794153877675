/* eslint-disable no-unused-vars */
// Hook for accessing the API.

import { toast } from '@postscript/components';
import { useEffect, useState } from 'react';
import { Requests } from '../controllers/network';
import { rejectErrors } from '../controllers/utils';

const useAPIRequest = (url, config = {}) => {
  const {
    listener = [],
    params = {},
    onError = rejectErrors,
    catchError = (err) => toast.error(err),
  } = config;

  const [request, changeRequest] = useState(null);
  const [loaded, changeLoaded] = useState(false);

  // Creates a listener for the data. By default only
  // fires on component mounting.
  const parameters = new URLSearchParams(params).toString();
  const completeURL = `${url}?${parameters}`;

  const fetchFn = () => {
    changeLoaded(false);
    Requests.get(completeURL)
      .then(onError)
      .then(changeRequest)
      .then((_) => changeLoaded(true))
      .catch(catchError);
  };

  useEffect(fetchFn, [url, parameters, ...listener]);

  return {
    request,
    loaded,
    forceUpdate: fetchFn,
  };
};

export default useAPIRequest;
