import PageHeader from 'components/layout/PageHeader';
import { useFullScreenEditor } from 'controllers/contexts/fullScreenEditor';
import moment from 'moment';
import { useEffect } from 'react';
import { useShopDashboard } from '../hooks/useDashboard';
import ShopList from './ShopList';

const ShopDashboard = (): JSX.Element => {
  const { data, isLoading } = useShopDashboard();
  const now = moment();
  const timeString = now.format('h:mm A');
  const [, setIsFullScreenEditorMode] = useFullScreenEditor();

  useEffect(() => {
    setIsFullScreenEditorMode(true);

    return () => {
      setIsFullScreenEditorMode(false);
    };
  }, []);

  return (
    <div>
      <PageHeader
        pageTitle="Shop Activity"
        description={
          <>
            As of <strong>{timeString}</strong>
          </>
        }
      />
      {data && <ShopList data={data} isLoading={isLoading} />}
    </div>
  );
};

export default ShopDashboard;
