import Player from '@vimeo/player';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { logEvent } from 'utils/events';

const vimeoVideoId = '803514438';

async function trackVimeoPlayerEvents(iframe: HTMLIFrameElement) {
  const player = new Player(iframe);
  const vimeoVideoTitle = await player.getVideoTitle();
  const properties = {
    video_id: vimeoVideoId,
    video_title: vimeoVideoTitle,
  };

  player.on('play', () => logEvent('video played', properties));
  player.on('ended', () => logEvent('video ended', properties));
}

const Wrapper = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default function ExplainerVideo(): JSX.Element {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (!iframeRef.current) return;
    trackVimeoPlayerEvents(iframeRef.current);
  }, []);

  return (
    <Wrapper>
      <Iframe
        src={`https://player.vimeo.com/video/${vimeoVideoId}?h=ac805a4df6&badge=0&autopause=0&player_id=0&app_id=58479`}
        allowFullScreen
        title="Usage Billing"
        ref={iframeRef}
      />
    </Wrapper>
  );
}
