import { Switch } from '@postscript/components';
import { userHasLegacyRole } from 'components/account/AccountView/users/helpers';
import { useAgentActions } from 'controllers/contexts/agentActions';
import styled from 'styled-components';

const StyledSwitch = styled.div`
  margin-right: var(--spacing-2);
`;

const ToggleAgentActionRecording = () => {
  const { isRecording, toggleRecording }: any = useAgentActions();

  const isAiAlphaTester = userHasLegacyRole('AI_ALPHA_TESTER');

  // Don't allow toggling for non-AI users
  if (!isAiAlphaTester) return <></>;

  return (
    <StyledSwitch>
      <Switch
        id="agent-action-recording"
        label="Recording Actions"
        checked={isRecording}
        fieldSize="small"
        onChange={toggleRecording}
      />
    </StyledSwitch>
  );
};

export default ToggleAgentActionRecording;
