import {
  BodyText,
  Button,
  Checkbox,
  Icon,
  IconSet,
  Layout,
  LinkText,
} from '@postscript/components';
import FormikField from 'components/billing/common/FormikField';
import { nameofFactory } from 'components/flowBuilder/types/util';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { StyledTooltip } from '../styles';

export interface BillingNotificationsFormValues {
  spendThresholdOptedInEmail?: boolean;
  spendThresholdOptedInText?: boolean;
  formattedSpendThreshold?: string;
}

export const billingNotificationsSchema = {
  spendThresholdOptedInEmail: yup.boolean().optional(),
  spendThresholdOptedInText: yup.boolean().optional(),
  formattedSpendThreshold: yup.mixed().nullable(),
};

const nameof = nameofFactory<BillingNotificationsFormValues>();

interface Props {
  phoneNumberAvailable: boolean;
  type: 'personal' | 'business';
}

export default function BillingNotificationsFields({
  phoneNumberAvailable,
  type,
}: Props): JSX.Element {
  const inAccountSettings = type === 'business';

  const { values, dirty, isSubmitting } =
    useFormikContext<BillingNotificationsFormValues>();

  const displayTextChannel = values.spendThresholdOptedInText !== undefined;
  const displayEmailChannel = values.spendThresholdOptedInEmail !== undefined;

  return (
    <>
      {displayEmailChannel && (
        <FormikField
          name={nameof('spendThresholdOptedInEmail')}
          as={Checkbox}
          label="Email"
          checked={values.spendThresholdOptedInEmail}
        />
      )}
      {displayTextChannel && (
        <FormikField
          name={nameof('spendThresholdOptedInText')}
          as={Checkbox}
          label={
            <Layout gap="var(--spacing-1)">
              <span>Text message**</span>
              <Icon
                component={IconSet.Information}
                size="medium"
                data-for="send-time-tip"
                data-tip
              />
              <StyledTooltip id="send-time-tip" place="right" size="small">
                {phoneNumberAvailable
                  ? 'Message will be sent regardless of the time of day.'
                  : `Add a${
                      inAccountSettings ? ' business' : ''
                    } phone number to receive text message notifications`}
              </StyledTooltip>
            </Layout>
          }
          checked={
            phoneNumberAvailable ? values.spendThresholdOptedInText : false
          }
          disabled={!phoneNumberAvailable}
        />
      )}
      <BodyText size="x-small">
        **I agree to receive transactional SMS messages from Postscript. Msg &
        data rates may apply. Msg frequency varies. Reply STOP to cancel. View
        our{' '}
        <LinkText
          href="https://postscript.io/subscriber-messaging-terms"
          underline={false}
          target="_blank"
        >
          Terms of Service
        </LinkText>{' '}
        and{' '}
        <LinkText
          href="https://postscript.io/privacy"
          underline={false}
          target="_blank"
        >
          Privacy Policy
        </LinkText>
        .
      </BodyText>
      <Layout align="right">
        <Button
          size="small"
          variant="secondary"
          type="submit"
          disabled={!dirty || isSubmitting || !phoneNumberAvailable}
        >
          Save Preferences
        </Button>
      </Layout>
    </>
  );
}
