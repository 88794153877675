// Automatic shops switcher when ?swro=1234567 is encountered in a URL.
//
// The shop id is the value after the query parameter.
import Requests from 'controllers/network/network_requests';
import { useMemo } from 'react';
import cookie from 'react-cookies';

const Switcheroo = () => {
  const params = new URL(document.location.href).searchParams;

  useMemo(() => {
    const switchToId = params.get('swro');

    if (!switchToId || Number.isNaN(Number(switchToId))) return;

    Requests.post(`/admin/change_shop`, { new_shop_id: switchToId }).then(
      (result) => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('swro');

        if (result?.access_token && result?.refresh_token) {
          cookie.save('access_token', result.access_token, { path: '/' });
          cookie.save('refresh_token', result.refresh_token, { path: '/' });

          // If the cookie swap succeeds, we go to the new URL.
          window.location.href = currentUrl.toString();
        }
      },
    );
  }, [params.get('swro')]);

  return null;
};

export default Switcheroo;
