/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { useField } from 'formik';
import { Children, cloneElement } from 'react';
import { FormFeedback } from 'reactstrap';
import FormLabel from './FormLabel';

const FormInput = ({ label, children, className, style, ...props }) => {
  const [field, meta] = useField(props);

  const fieldChildren = Children.map(children, (child) => {
    return cloneElement(child, {
      invalid: meta.touched && meta.error,
      ...field,
      ...props,
    });
  });

  return (
    <div className={className} style={style}>
      <FormLabel id={props.id} name={props.name}>
        {label}
      </FormLabel>
      {fieldChildren}
      <FormFeedback>{meta.error}</FormFeedback>
    </div>
  );
};

export default FormInput;
