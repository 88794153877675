import { Banner, Icon, IconSet, LinkText } from '@postscript/components';
import styled from 'styled-components';

const StyledBanner = styled(Banner)<{ bannerColor: string }>`
  background: ${({ bannerColor }) => bannerColor};
  border: none;
  width: 100%;
  & > div > div > span {
    display: none;
  }
`;

const StyledBannerInner = styled.span`
  align-items: center;
  display: inline-grid;
  gap: var(--spacing-2);
  grid-template-columns: auto 1fr;
  vertical-align: top;
  width: 100%;
`;

export interface MessagePlannerBannerProps {
  body: string;
  bannerColor: string;
  onClose: () => void;
  ctaMessage?: string;
  ctaHandler?: () => void;
  icon?: string;
  iconColor?: string;
}
const MessagePlannerBanner = ({
  bannerColor,
  ctaMessage,
  ctaHandler,
  body,
  icon,
  iconColor = 'var(--black)',
  onClose,
}: MessagePlannerBannerProps): JSX.Element => (
  <StyledBanner
    bodyText={
      <StyledBannerInner>
        {icon && (
          <Icon
            component={(IconSet as { [key: string]: () => JSX.Element })[icon]}
            color={iconColor}
            size={22}
          />
        )}
        <span>
          {body}{' '}
          {ctaMessage && ctaHandler && (
            <LinkText
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                ctaHandler();
              }}
              underline
              color="var(--black) !important"
            >
              {ctaMessage}
            </LinkText>
          )}
        </span>
      </StyledBannerInner>
    }
    onDismiss={() => onClose()}
    bannerColor={bannerColor}
  />
);

export default MessagePlannerBanner;
