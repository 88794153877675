import { Plan } from 'components/billing/common/types';

const starterPackageName = 'Starter';

export function isMinSpendStarterPlan(plan?: Plan | null): boolean {
  return (
    !!plan?.minimumCommittedSpendCents &&
    plan.package?.name === starterPackageName
  );
}
