import { DynamicFieldsTypes } from 'components/flowBuilder/constants/dynamicFields';
import {
  BaseDynamicField,
  CheckboxDynamicField,
  DynamicField,
  DynamicFieldsType,
  FlowSelectionDynamicField,
} from 'components/flowBuilder/types/dynamicFields';

const isDynamicFieldsType = (name: unknown): name is DynamicFieldsType => {
  return Object.values<unknown>(DynamicFieldsTypes).includes(name);
};

const isBaseDynamicField = (field: unknown): field is BaseDynamicField => {
  const fieldValue = field as BaseDynamicField;

  if (!fieldValue || typeof fieldValue !== 'object') return false;
  if (!fieldValue.options || typeof fieldValue.options !== 'object')
    return false;
  if (!isDynamicFieldsType(fieldValue.type)) return false;

  return true;
};

export const isCheckboxDynamicField = (
  field: unknown,
): field is CheckboxDynamicField => {
  const fieldValue = field as CheckboxDynamicField;

  if (!isBaseDynamicField(fieldValue)) return false;
  if (fieldValue.type !== DynamicFieldsTypes.CHECKBOX) return false;
  if (
    !fieldValue.options.paramName ||
    typeof fieldValue.options.paramName !== 'string'
  )
    return false;
  if (
    fieldValue.options.iconName !== null &&
    typeof fieldValue.options.iconName !== 'string'
  )
    return false;
  if (!fieldValue.options.label || typeof fieldValue.options.label !== 'string')
    return false;
  if (
    !fieldValue.options.shortLabel ||
    typeof fieldValue.options.shortLabel !== 'string'
  )
    return false;

  return true;
};

export const isFlowSelectionDynamicField = (
  field: unknown,
): field is FlowSelectionDynamicField => {
  const fieldValue = field as FlowSelectionDynamicField;

  if (!isBaseDynamicField(fieldValue)) return false;
  if (fieldValue.type !== DynamicFieldsTypes.FLOW_SELECTION) return false;
  if (
    !fieldValue.options.paramName ||
    typeof fieldValue.options.paramName !== 'string'
  )
    return false;
  if (!fieldValue.options.label || typeof fieldValue.options.label !== 'string')
    return false;

  return true;
};

export const isDynamicField = (field: unknown): field is DynamicField => {
  return isCheckboxDynamicField(field) || isFlowSelectionDynamicField(field);
};
