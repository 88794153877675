import { api } from '../controllers/network/apiClient';

const JWT_TOKEN_ENDPOINT = '/v2/shops/zendesk-jwt/';

export const updateZendeskJWT = () => {
  /*
   * Load JWT into zendesk chat widget.
   * https://support.zendesk.com/hc/en-us/articles/360022185594
   */
  try {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: async (callback) => {
              const { zendesk_jwt: zendeskJWT } = await api.get(
                JWT_TOKEN_ENDPOINT,
              );
              callback(zendeskJWT);
            },
          },
        },
      },
    });
  } catch {
    /* Because this widget could be configured via Google Tag Manager
     * we want to fail gracefully.
     */
  }
};

export const hideZendeskWidget = () => {
  try {
    window.zE('webWidget', 'hide');
  } catch (e) {
    console.error(e);
  }
};

export const setZendeskShopTag = (shopTagInfo) => {
  try {
    window.zE('webWidget', 'chat:addTags', [shopTagInfo]);
  } catch (e) {
    console.error(e);
  }
};
