import FormItem from 'components/forms/FormItem';
import React from 'react';
import styled from 'styled-components';

/*
  Wraps an older FE repo FormItem component, which essentially adds a label and
  error message containers. They are in vanilla at the moment. The subcomponents
  of FormItem are using CL components.
*/
export interface EditorFormItemWrapperProps {
  children: React.ReactNode;
  isFullwidth?: boolean;
  [key: string]: any; // TODO(Kameron Ahler): 10 hail marys
}

interface StyledFormItemProps {
  $isFullwidth?: boolean;
}

const StyledFormItem = styled(FormItem)<StyledFormItemProps>`
  ${({ $isFullwidth }) => $isFullwidth && `grid-column: 1/-1};`}
  width: 100%;
`;

const EditorFormItemWrapper = ({
  children,
  isFullwidth = false,
  label,
  name,
  ...props
}: EditorFormItemWrapperProps): JSX.Element => {
  return (
    <StyledFormItem
      $isFullwidth={isFullwidth}
      label={label}
      name={name}
      {...props}
    >
      {children}
    </StyledFormItem>
  );
};

export default EditorFormItemWrapper;
