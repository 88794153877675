/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const initialState = {};

class DelayedInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(initialState));
    this.state.value = props.value;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
  }

  componentDidMount() {
    this.timer = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value !== prevState.value &&
      (nextProps.value || nextProps.value === 0) &&
      !prevState.value &&
      !prevState.hasLoaded
    ) {
      return {
        value: nextProps.value,
        hasLoaded: true,
      };
    }
    return null;
  }

  handleChange(e) {
    clearTimeout(this.timer);
    this.setState({
      value: e.target.value,
      hasLoaded: true,
    });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange();
    }
  }

  triggerChange() {
    const { value } = this.state;
    this.props.onChange(value);
  }

  render() {
    return (
      <Input
        data-cy="delayed-input"
        placeholder={this.props.placeholder ? this.props.placeholder : null}
        value={this.state.value || undefined}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onFocus={this.props.onFocus ? this.props.onFocus : null}
        onBlur={this.props.onBlur ? this.props.onBlur : null}
        disabled={this.props.disabled || false}
        bsSize={this.props.size ? this.props.size : null}
        type={this.props.type ? this.props.type : null}
        className={this.props.className ? this.props.className : null}
      />
    );
  }
}

DelayedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DelayedInput;
