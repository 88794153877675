import { IconSet } from '@postscript/components';
import {
  LegacyTagDelimiters,
  TagDelimiters,
} from 'components/flowBuilder/constants/tags';

export const BASE_FLOW_BUILDER_API_URL = '/v2/flowbuilder/';
export const BASE_FLOWS_URL = `${BASE_FLOW_BUILDER_API_URL}flows/`;
export const RESTORED_VERSION_QUERY_PARAM = 'restoredVersion';

export const ReactFlowDefaults = {
  X_POSITION: 400,
  ENABLED_X_POSITION: 36,
  Y_POSITION: 36,
} as const;

export const BASE_STEP_ELEMENT_WIDTH = 300;

export const StepElementsWidths = {
  END: 24,
  SEND_MESSAGE: BASE_STEP_ELEMENT_WIDTH,
  AB_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  OPTIMIZED_AB_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  WAIT_FOR_EVENT_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  TRIGGER_EVENT_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  SUBSCRIBER_ATTRIBUTE_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  AUTOMATION_TRIGGER: BASE_STEP_ELEMENT_WIDTH,
  CAMPAIGN_TRIGGER: BASE_STEP_ELEMENT_WIDTH,
  WAIT: BASE_STEP_ELEMENT_WIDTH,
  UPDATE_SUBSCRIBER: BASE_STEP_ELEMENT_WIDTH,
  TEXT_TO_BUY: BASE_STEP_ELEMENT_WIDTH,
  DYNAMIC: BASE_STEP_ELEMENT_WIDTH,
  AI_MANAGED_MESSAGE: BASE_STEP_ELEMENT_WIDTH,
} as const;

export const EdgeTypesNames = {
  DROPPABLE: 'Droppable',
  STATIC: 'smoothstep',
} as const;

export enum AnalyticsTypesNames {
  CONVERSION_RATE = 'conversion_rate',
  CREDITS_USED = 'credits_used',
  CTR = 'ctr',
  DELIVERABILITY = 'deliverability',
  EPM = 'epm',
  ORDERS = 'orders',
  PSPAY_ATTRIBUTED_REVENUE = 'pspay_attributed_revenue',
  PSPAY_ORDERS = 'pspay_orders',
  QUEUED_SUBSCRIBERS = 'queued_subscribers',
  REVENUE = 'revenue',
  TOTAL_CLICKS = 'total_clicks',
  TOTAL_FAILED = 'total_failed',
  TOTAL_SENT = 'total_sent',
  TOTAL_SUBSCRIBERS = 'total_subscribers',
  TOTAL_UNSUBS = 'total_unsubs',
  UCTR = 'uctr',
  UNIQUE_CLICKS = 'unique_clicks',
  UNIQUE_SUBSCRIBERS = 'unique_subscribers',
  UNSUBSCRIBE_RATE = 'unsubscribe_rate',
  VALID_MESSAGE_STATISTICS = 'valid_message_statistics',
}

export const AnalyticsGroupsTypesNames = {
  ORDERS_AND_CONVERSION_RATE: 'ORDERS_AND_CONVERSION_RATE',
  REVENUE_AND_EPM: 'REVENUE_AND_EPM',
  TOTAL_CLICKS_AND_CTR: 'TOTAL_CLICKS_AND_CTR',
  TOTAL_CLICKS_AND_CTR_AND_UCTR: 'TOTAL_CLICKS_AND_CTR_AND_UCTR',
  TOTAL_SENT_AND_DELIVERABILITY: 'TOTAL_SENT_AND_DELIVERABILITY',
  TOTAL_SENT_AND_UNSUBSCRIBE_RATE_AND_DELIVERABILITY:
    'TOTAL_SENT_AND_UNSUBSCRIBE_RATE_AND_DELIVERABILITY',
  TOTAL_SENT_AND_UNSUBSCRIBE_RATE: 'TOTAL_SENT_AND_UNSUBSCRIBE_RATE',
  TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE:
    'TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE',
} as const;

// List of overall flow analytics displayed in the flows list view
export const FlowsListAnalyticsGroups = [
  AnalyticsGroupsTypesNames.TOTAL_SENT_AND_UNSUBSCRIBE_RATE,
  AnalyticsGroupsTypesNames.TOTAL_CLICKS_AND_CTR,
  AnalyticsGroupsTypesNames.ORDERS_AND_CONVERSION_RATE,
  AnalyticsGroupsTypesNames.REVENUE_AND_EPM,
] as const;

// List of overall flow analytics displayed in the flow view
export const OverallAnalyticsGroups = [
  AnalyticsGroupsTypesNames.REVENUE_AND_EPM,
  AnalyticsGroupsTypesNames.ORDERS_AND_CONVERSION_RATE,
  AnalyticsGroupsTypesNames.TOTAL_CLICKS_AND_CTR_AND_UCTR,
  AnalyticsGroupsTypesNames.TOTAL_SENT_AND_DELIVERABILITY,
  AnalyticsGroupsTypesNames.TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE,
] as const;

// List of action analytics displayed in the flow view
export const ActionAnalyticsTypesNames = [
  AnalyticsTypesNames.REVENUE,
  AnalyticsTypesNames.EPM,
  AnalyticsTypesNames.ORDERS,
  AnalyticsTypesNames.CONVERSION_RATE,
  AnalyticsTypesNames.TOTAL_CLICKS,
  AnalyticsTypesNames.CTR,
  AnalyticsTypesNames.UCTR,
  AnalyticsTypesNames.TOTAL_SENT,
  AnalyticsTypesNames.UNSUBSCRIBE_RATE,
] as const;

export const FlowAnalyticsTypesNames = {
  OVERALL: 'overall',
  OVERALL_SLIM: 'overall-slim',
  OUTBOUND: 'outbound',
  ACTIONS: 'actions',
} as const;

const BaseFlowStatuses = {
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVAL_DENIED: 'APPROVAL_DENIED',
  SCHEDULED: 'SCHEDULED',
  ENDED: 'ENDED',
} as const;

export const AutomationFlowStatuses = {
  ...BaseFlowStatuses,
  DRAINING: 'DRAINING',
} as const;

export const CampaignFlowStatuses = {
  ...BaseFlowStatuses,
  COMPLETED: 'COMPLETED',
  ACTIVATION_ERROR: 'ACTIVATION_ERROR',
} as const;

export const FlowStatuses = {
  ...AutomationFlowStatuses,
  ...CampaignFlowStatuses,
} as const;

export const FlowTypes = {
  CAMPAIGN: 'CAMPAIGN',
  AUTOMATION: 'AUTOMATION',
} as const;

export const FlowOrigins = {
  TEMPLATE: 'TEMPLATE',
  NEW: 'NEW',
} as const;

export const FlowViewStatuses = {
  READ_ONLY: 'READ_ONLY',
} as const;

export const FlowViewStatusMetadata = {
  [FlowViewStatuses.READ_ONLY]: {
    badgeVariant: 'neutral',
    label: 'View only',
    value: FlowViewStatuses.READ_ONLY,
  },
} as const;

export const CampaignFlowStatusesMetadata = {
  [CampaignFlowStatuses.DRAFT]: {
    badgeVariant: 'neutral',
    label: 'Draft',
    value: CampaignFlowStatuses.DRAFT,
  },
  [CampaignFlowStatuses.ENABLED]: {
    badgeVariant: 'success',
    label: 'In progress',
    value: CampaignFlowStatuses.ENABLED,
  },
  [CampaignFlowStatuses.APPROVAL_PENDING]: {
    badgeVariant: 'info',
    label: 'Postscript Review',
    value: CampaignFlowStatuses.APPROVAL_PENDING,
  },
  [CampaignFlowStatuses.APPROVAL_DENIED]: {
    badgeVariant: 'error',
    label: 'Approval Denied',
    value: CampaignFlowStatuses.APPROVAL_DENIED,
  },
  [CampaignFlowStatuses.SCHEDULED]: {
    badgeVariant: 'info',
    label: 'Scheduled',
    value: CampaignFlowStatuses.SCHEDULED,
  },
  [CampaignFlowStatuses.COMPLETED]: {
    badgeVariant: 'success',
    label: 'Completed',
    value: CampaignFlowStatuses.COMPLETED,
  },
  [CampaignFlowStatuses.ACTIVATION_ERROR]: {
    badgeVariant: 'error',
    label: 'Activation error',
    value: CampaignFlowStatuses.ACTIVATION_ERROR,
  },
  [CampaignFlowStatuses.ENDED]: {
    badgeVariant: 'neutral',
    label: 'Ended',
    value: CampaignFlowStatuses.ENDED,
  },
} as const;

export const AutomationFlowStatusesMetadata = {
  [AutomationFlowStatuses.DRAFT]: {
    badgeVariant: 'neutral',
    label: 'Draft',
    value: AutomationFlowStatuses.DRAFT,
  },
  [AutomationFlowStatuses.ENABLED]: {
    badgeVariant: 'success',
    label: 'Activated',
    value: AutomationFlowStatuses.ENABLED,
  },
  [AutomationFlowStatuses.APPROVAL_PENDING]: {
    badgeVariant: 'info',
    label: 'Postscript Review',
    value: AutomationFlowStatuses.APPROVAL_PENDING,
  },
  [AutomationFlowStatuses.APPROVAL_DENIED]: {
    badgeVariant: 'error',
    label: 'Approval Denied',
    value: AutomationFlowStatuses.APPROVAL_DENIED,
  },
  [AutomationFlowStatuses.SCHEDULED]: {
    badgeVariant: 'info',
    label: 'Scheduled',
    value: AutomationFlowStatuses.SCHEDULED,
  },
  [AutomationFlowStatuses.ENDED]: {
    badgeVariant: 'neutral',
    label: 'Deactivated',
    value: AutomationFlowStatuses.ENDED,
  },
  [AutomationFlowStatuses.DRAINING]: {
    badgeVariant: 'neutral',
    label: 'Draining',
    value: AutomationFlowStatuses.DRAINING,
  },
} as const;

export const EMPTY_ANALYTICS = {
  [AnalyticsTypesNames.CREDITS_USED]: 0,
  [AnalyticsTypesNames.ORDERS]: 0,
  [AnalyticsTypesNames.REVENUE]: 0,
  [AnalyticsTypesNames.TOTAL_CLICKS]: 0,
  [AnalyticsTypesNames.TOTAL_SENT]: 0,
  [AnalyticsTypesNames.TOTAL_SUBSCRIBERS]: 0,
  [AnalyticsTypesNames.TOTAL_UNSUBS]: 0,
  [AnalyticsTypesNames.UNIQUE_CLICKS]: 0,
} as const;

export const ANALYTICS_REFETCH_INTERVAL = 1000 * 30; // 30 seconds

export const operationsDict: Record<string, string> = {
  // SIMPLE VALUES
  eq: 'is',
  neq: 'is not',
  in: 'is',
  not_in: 'is not',
  contains: 'contains',
  not_contains: 'does not contain',
  lt: 'is less than',
  gt: 'is greater than',
  lte: 'is at most',
  gte: 'is at least',
  between: 'is within',
  not_between: 'is not within',
  boolean: 'boolean',
  // ANY
  any_eq: 'any are',
  any_in: 'any are',
  any_contains: 'any contain',
  any_not_contains: 'any do not contain',
  any_neq: 'any are not',
  any_not_in: 'any are not',
  any_lt: 'any are less than',
  any_gt: 'any are greater than',
  any_lte: 'any are at most',
  any_gte: 'any are at least',
  any_between: 'any are within',
  any_not_between: 'any not within',
  // ALL
  all_contains: 'all contain',
  all_not_contains: 'all do not contain',
  all_eq: 'all are',
  all_in: 'all are',
  all_neq: 'all are not',
  all_not_in: 'all are not',
  all_lt: 'all are less than',
  all_gt: 'all are greater than',
  all_lte: 'all are at most',
  all_gte: 'all are at least',
  all_between: 'all are within',
  all_not_between: 'all not within',
  // NONE
  none_eq: 'none are',
  none_in: 'none are',
  none_neq: 'none are not',
  none_not_in: 'none are not',
  none_gt: 'none are greater than',
  none_gte: 'none are at least',
  none_lt: 'none are less than',
  none_lte: 'none are at most',
  none_between: 'none are within',
  none_not_between: 'none not within',
  none_contains: 'none contain',
  none_not_contains: 'none do not contain',
  none_boolean: 'none boolean',
};

export const IN_OPERATOR_VARIANTS = [
  'in',
  'not_in',
  'any_in',
  'any_not_in',
  'all_in',
  'all_not_in',
  'none_in',
  'none_not_in',
];
export const EQ_OPERATOR_VARIANTS = [
  'eq',
  'neq',
  'any_eq',
  'any_neq',
  'all_eq',
  'all_neq',
  'none_eq',
  'none_neq',
];
export const CONTAINS_OPERATOR_VARIANTS = [
  'contains',
  'not_contains',
  'any_contains',
  'any_not_contains',
  'all_contains',
  'all_not_contains',
  'none_contains',
  'none_not_contains',
];

export const ADD_TYPE = 'Add';
export const REMOVE_TYPE = 'Remove';

export const AND_JOIN_TYPE = 'And';
export const OR_JOIN_TYPE = 'Or';

const PLACEHOLDER_FIRST_NAME = 'Elizabeth';
const PLACEHOLDER_LAST_NAME = 'Smith';

export const PLACEHOLDER_SUBSCRIBER_MESSAGE_TEMPLATE_CONTEXT = {
  first_name: PLACEHOLDER_FIRST_NAME,
  last_name: PLACEHOLDER_LAST_NAME,
} as const;

export const QueryCacheTimes = {
  ZERO: 0,
  SHORT: 1000 * 60 * 5, // 5 minutes
  MEDIUM: 1000 * 60 * 15, // 15 minutes
} as const;

// These are just used by legacy stuff
export const MessageMaxChars = {
  SMS: 160,
  SMS_MULTIPART: 153,
  SMS_WITH_EMOJI: 70,
  SMS_WITH_EMOJI_MULTIPART: 67,
  MMS: 1600,
} as const;

export const MESSAGE_SHOP_NAME_TAG_KEY_PREFIX = `${TagDelimiters.START}shop.name${TagDelimiters.END}: `;
export const MESSAGE_LEGACY_SHOP_NAME_TAG_KEY_PREFIX = `${LegacyTagDelimiters.START}shop_name${LegacyTagDelimiters.END}: `;
export const MESSAGE_INITIAL_VALUE = `${MESSAGE_SHOP_NAME_TAG_KEY_PREFIX}Hi! This is a sample message.`;

export const FlowTemplateTypes = {
  AUTOMATION: 'flow-automation',
  CAMPAIGN: 'flow-campaign',
} as const;

export const WaitActionCategories = {
  TIMEDELTA: 'timedelta',
  SPECIFIC_DATETIME: 'specific_datetime',
  RELATIVE_WEEK: 'relative_week',
  RANDOM: 'random',
} as const;

export const DaysOfTheWeek = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
} as const;

export const FLOW_SESSION_STORAGE_KEY = 'flow';

export const TextToBuySidebarNames = {
  T2B_PREVIEW_PURCHASED: 't2b-preview-purchased',
  T2B_PREVIEW_CANCELLED: 't2b-preview-cancelled',
  T2B_PREVIEW_DIDNT_PURCHASE: 't2b-preview-didnt-purchase',
  T2B_PREVIEW_TIMED_OUT: 't2b-preview-timed-out',
} as const;

export const SidebarNames = {
  ...TextToBuySidebarNames,
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  LINKED_FLOWS: 'linked-flows',
  FLOW_VERSIONS: 'flow-versions',
  SAVE_FLOW_VERSION: 'save-flow-version',
  AI_MANAGED_MESSAGE: 'ai-managed-message',
  OPTIMIZED_AB_SPLIT_TEST_HISTORY: 'optimized-ab-split-test-history',
} as const;

export const DEFAULT_ELSE_BRANCH_LABEL = 'Above conditions not met';

export const DynamicIconSet = IconSet as unknown as Record<
  string,
  () => JSX.Element
>;

export const FilterContexts = {
  TRIGGER_EVENT: 'TriggerEvent',
  TRIGGER_EVENT_SPLIT: 'TriggerEventSplit',
  WAIT_FOR_EVENT_SPLIT: 'WaitForEventSplit',
} as const;

export const ValueOptionsSourceTypes = {
  EMBEDDED: 'Embedded',
  FLOW: 'Flow',
  PRODUCT: 'Product',
  SEGMENT: 'Segment',
} as const;

export const DEFAULT_VALUE_OPTIONS_GROUP_LABEL = 'Other';

export const PriceDropCategories = {
  ABSOLUTE: 'Absolute',
  PERCENT: 'Percent',
} as const;

// It's important we keep these in sync with the BE. If they ever change there, we need to change them here as well
export const PRICE_DROP_ABSOLUTE_AMOUNT_VARIABLE =
  'event.price_drop_amount_absolute';
export const PRICE_DROP_PERCENTAGE_AMOUNT_VARIABLE =
  'event.price_drop_amount_percentage';
export const PRICE_DROP_CATEGORY_VARIABLE = 'event.price_drop_category';

export const PriceDropAmountVariables = {
  ABSOLUTE: PRICE_DROP_ABSOLUTE_AMOUNT_VARIABLE,
  PERCENT: PRICE_DROP_PERCENTAGE_AMOUNT_VARIABLE,
} as const;

export const AIMessageFeedbackValues = {
  Positive: 'POSITIVE',
  Negative: 'NEGATIVE',
  Unset: '',
} as const;

export const OptimizationMetricOptions = [
  {
    value: 'conversion_rate',
    label: 'Click conversion rate',
    shortLabel: 'Click conv.',
  },
  {
    value: 'uctr',
    label: 'Unique click-through rate (UCTR)',
    shortLabel: 'UCTR',
  },
] as const;

export const CompletedOptimizedABSplitTestStatuses = {
  COMPLETED: 'COMPLETED',
  COMPLETED_MANUALLY: 'COMPLETED_MANUALLY',
} as const;

export const OptimizedABSplitTestStatuses = {
  ...CompletedOptimizedABSplitTestStatuses,
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  INCONCLUSIVE: 'INCONCLUSIVE',
} as const;

export const InfinityTestExperimentStatusState = {
  OPTIMIZING: 'Optimizing',
  EXPERIMENTING: 'Experimenting',
};
