/* eslint-disable camelcase */

import { FlowTypes } from 'components/flowBuilder/constants';
import { FlowType } from 'components/flowBuilder/types';
import { fetchFlows } from 'components/flowBuilder/utils/flows';
import { FeatureFlagsContext } from 'controllers/contexts/featureFlags';
import Helpers from 'controllers/helpers';
import { api } from 'controllers/network/apiClient';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

export type SearchResult = {
  id: string;
  name: string;
  path: string;
};

type SubscriberResults = {
  search_term: string;
  customer?: {
    customer_id: string;
    first_name: string;
    last_name: string;
  };
  customer_fact?: {
    phone_number?: string;
  };
  subscriber?: {
    customer_id?: string;
    phone_number: string;
  };
};

type SegmentResults = {
  segment_id: string;
  name: string;
};

type KeywordResults = {
  keyword_id: string;
  keyword: string;
};

type AppResults = {
  type: string;
};

type FeatureResults = {
  label: string;
  path: string;
};

type FeatureListProps = {
  label: string;
  path: string;
  featureFlag?: string;
};

const FeatureList: FeatureListProps[] = [
  { label: 'Account Settings', path: '/account/settings' },
  { label: 'Analytics', path: '/analytics/messages' },
  { label: 'Apps', path: '/integrations/all' },
  { label: 'Automations', path: '/automations' },
  { label: 'Campaigns', path: '/campaigns' },
  { label: 'Checkout Collection', path: '/checkout' },
  { label: 'Flow Builder', path: '/flows' },
  { label: 'Keywords', path: '/keywords' },
  { label: 'List Growth Tools', path: '/grow' },
  { label: 'Popups', path: '/popups' },
  { label: 'Reports', path: '/account/reports' },
  { label: 'Responses', path: '/responses' },
  { label: 'Segments', path: '/segments' },
];

export const Categories: { [key: string]: string } = {
  Subscribers: 'Subscribers',
  Campaigns: 'Campaigns',
  Automations: 'Automations',
  Segments: 'Segments',
  Keywords: 'Keywords',
  Apps: 'Apps',
  Features: 'Postscript Features',
};

type SearchCategoryProps = {
  [key: string]:
    | SearchResult[]
    | SubscriberResults[]
    | SegmentResults[]
    | KeywordResults[]
    | AppResults[]
    | FeatureResults[];
};

type SearchProps = {
  searchResults: SearchCategoryProps;
  hasSearchResults: boolean;
  loading: boolean;
};

const sortResultsByName = (results: SearchResult[]) =>
  results.sort((a: SearchResult, b: SearchResult) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

export const useGlobalSearch = (
  searchFocus?: boolean,
  searchValue?: string,
): SearchProps => {
  const [subscribers, setSubscriberSearchResults] = useState<any>([]);
  const [campaigns, setCampaignSearchResults] = useState<SearchResult[]>([]);
  const [automations, setAutomationSearchResults] = useState<SearchResult[]>(
    [],
  );
  const [segments, setSegmentSearchResults] = useState<any>([]);
  const [keywords, setKeywordSearchResults] = useState<any>([]);
  const [apps, setAppSearchResults] = useState<any>([]);
  const [features, setFeatureSearchResults] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const { hasFlag }: any = useContext(FeatureFlagsContext);

  const hasSearchResults =
    subscribers.length > 0 ||
    campaigns.length > 0 ||
    automations.length > 0 ||
    segments.length > 0 ||
    keywords.length > 0 ||
    apps.length > 0 ||
    features.length > 0;

  const flowsSearch = async (
    searchTerm: string,
    type: FlowType,
  ): Promise<SearchResult[]> => {
    try {
      const flows = await fetchFlows({
        name: searchTerm,
        type,
      });

      return flows.flows.map((f) => ({
        id: f.guid,
        name: f.name,
        path: `/flows/${f.guid}`,
      }));
    } catch {
      return [];
    }
  };

  const customerSearch = async (searchTerm: string) => {
    const customerSearch = await api.get(`/customers/search?q=${searchTerm}`);
    if (customerSearch?.error) {
      console.error(customerSearch.error);
    } else if (customerSearch?.values) {
      const customerSearchValues: Partial<SubscriberResults[]> =
        customerSearch.values;
      customerSearchValues.sort((a: any, b: any) => {
        if (a.search_term < b.search_term) return -1;
        if (a.search_term > b.search_term) return 1;
        return 0;
      });

      setSubscriberSearchResults(customerSearchValues);
    }
  };

  const automationSearch = async (searchTerm: string) => {
    let searchResults: SearchResult[] = [];
    const automationSearch = await api.get(
      `/automations?start=0&name=${searchTerm.toLowerCase()}`,
    );

    if (automationSearch?.error) {
      console.error(automationSearch.error);
    } else if (automationSearch?.automations) {
      searchResults = searchResults.concat(
        automationSearch.automations.automations.map((a: any) => ({
          id: a.id,
          name: a.name,
          path: `/automations/${a.id}`,
        })),
      );
    }

    const automationFlows = await flowsSearch(searchTerm, FlowTypes.AUTOMATION);
    searchResults = searchResults.concat(automationFlows);

    setAutomationSearchResults(
      sortResultsByName(searchResults).filter((s) => s.id && s.name),
    );
  };

  const campaignSearch = async (searchTerm: string) => {
    let searchResults: SearchResult[] = [];
    // Sent Campaigns
    const sentCampaigns = await api.get(
      `/campaigns/sent?title=${searchTerm.toLowerCase()}`,
    );
    if (sentCampaigns?.campaigns?.campaigns) {
      const { campaigns } = sentCampaigns.campaigns;

      searchResults = searchResults.concat(
        campaigns.map((c: any) => ({
          id: c.campaign_id,
          name: c.name,
          path: `/campaigns/${c.campaign_id}`,
        })),
      );
    }

    // Open Campaigns
    const openCampaigns = await api.get(
      `/campaigns/preview?title=${searchTerm.toLowerCase()}`,
    );
    if (openCampaigns?.campaigns?.campaigns) {
      const { campaigns } = openCampaigns.campaigns;

      searchResults = searchResults.concat(
        campaigns.map((c: any) => ({
          id: c.campaign_id,
          name: c.name,
          path: `/campaigns/${c.campaign_id}/preview`,
        })),
      );
    }

    const flowCampaigns = await flowsSearch(
      searchTerm.toLowerCase(),
      FlowTypes.CAMPAIGN,
    );
    searchResults = searchResults.concat(flowCampaigns);

    setCampaignSearchResults(
      sortResultsByName(searchResults).filter((s) => s.id && s.name),
    );
  };

  const segmentSearch = async (searchTerm: string) => {
    const segmentSearch = await api.get(
      `/segments/?start=0&name=${searchTerm.toLowerCase()}`,
    );
    if (segmentSearch?.segments?.segment) {
      const segments = segmentSearch.segments.segment;
      segments.sort((a: any, b: any) => {
        if (a.name && b.name)
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        return 0;
      });

      setSegmentSearchResults(segments);
    }
  };

  const keywordSearch = async (searchTerm: string) => {
    const keywordSearch = await api.get(
      `/keywords?start=0&name=${searchTerm.toLowerCase()}`,
    );
    if (keywordSearch?.keywords) {
      const { keywords } = keywordSearch;
      keywords.sort((a: any, b: any) =>
        a.keyword.toLowerCase() > b.keyword.toLowerCase() ? 1 : -1,
      );

      setKeywordSearchResults(keywords);
    }
  };

  const appSearch = async (searchTerm: string) => {
    const integrations: AppResults[] = Helpers.integrationArray;
    const filteredIntegrations = integrations.filter((integration: any) =>
      integration.type.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    filteredIntegrations.sort((a: any, b: any) =>
      a.type.toLowerCase() > b.type.toLowerCase()
        ? 1
        : b.type.toLowerCase() > a.type.toLowerCase()
        ? -1
        : 0,
    );

    setAppSearchResults(filteredIntegrations);
  };

  const featureSearch = async (searchTerm: string) => {
    const filteredFeatures = FeatureList.filter(
      (feature) =>
        feature.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!feature?.featureFlag || hasFlag(feature.featureFlag)),
    );

    setFeatureSearchResults(filteredFeatures);
  };

  const debouncedSearch = useCallback(
    debounce(async () => {
      if (!searchFocus || !searchValue || searchValue.length === 0) {
        setSubscriberSearchResults([]);
        setAutomationSearchResults([]);
        setCampaignSearchResults([]);
        setSegmentSearchResults([]);
        setKeywordSearchResults([]);
        setAppSearchResults([]);
        setFeatureSearchResults([]);
        return;
      }

      const customer = customerSearch(searchValue);
      const automation = automationSearch(searchValue);
      const campaign = campaignSearch(searchValue);
      const segment = segmentSearch(searchValue);
      const keyword = keywordSearch(searchValue);
      const app = appSearch(searchValue);
      const feature = featureSearch(searchValue);

      Promise.all([
        customer,
        automation,
        campaign,
        segment,
        keyword,
        app,
        feature,
      ]).then(() => {
        setLoading(false);
      });
    }, 300),
    [searchValue],
  );

  useEffect(() => {
    setLoading(true);
    debouncedSearch();
    return debouncedSearch.cancel;
  }, [debouncedSearch]);

  return {
    searchResults: {
      Subscribers: subscribers,
      Campaigns: campaigns,
      Automations: automations,
      Segments: segments,
      Keywords: keywords,
      Apps: apps,
      Features: features,
    },
    hasSearchResults,
    loading,
  };
};
