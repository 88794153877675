import { checkboxFieldSchema } from 'components/flowBuilder/components/forms/dynamicFields/CheckboxField';
import { flowIdParamSchema } from 'components/flowBuilder/components/forms/dynamicFields/FlowSelectionField';
import dynamicAddToFlowActionType from 'components/flowBuilder/constants/dynamicAddToFlowActionType.json';
import type {
  DynamicAction,
  DynamicActionType,
} from 'components/flowBuilder/types/actions';
import {
  isDynamicActionType,
  isDynamicSplitField,
} from 'components/flowBuilder/types/actions/typeGuards';
import type {
  BaseDynamicSplitField,
  CheckboxDynamicField,
  FlowSelectionDynamicField,
} from 'components/flowBuilder/types/dynamicFields';
import {
  isCheckboxDynamicField,
  isFlowSelectionDynamicField,
} from 'components/flowBuilder/types/dynamicFields/typeGuards';

export const DYNAMIC_ACTION_TYPES = [
  dynamicAddToFlowActionType,
] as DynamicActionType[];

interface DynamicActionTypesResponse {
  actionTypes: unknown[];
}

const isDynamicActionTypesResponse = (
  response: unknown,
): response is DynamicActionTypesResponse => {
  const responseValue = response as DynamicActionTypesResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.actionTypes || !Array.isArray(responseValue.actionTypes))
    return false;

  return true;
};

export const fetchDynamicActionTypes = async (): Promise<
  DynamicActionType[]
> => {
  const dynamicActionTypes: DynamicActionType[] = [];

  const dynamicActionTypesResponse: unknown = {
    actionTypes: DYNAMIC_ACTION_TYPES,
  };

  if (!isDynamicActionTypesResponse(dynamicActionTypesResponse))
    throw new Error('Unexpected Dynamic Action Types response');

  dynamicActionTypesResponse.actionTypes.forEach((actionType) => {
    if (isDynamicActionType(actionType)) dynamicActionTypes.push(actionType);
  });

  return dynamicActionTypes;
};

const isFlowSelectionDynamicFieldInActionComplete = (
  action: DynamicAction,
  field: FlowSelectionDynamicField,
) => {
  const flowIdParamValue = action.params[field.options.paramName];
  return flowIdParamSchema.isValidSync(flowIdParamValue);
};

const isCheckboxDynamicFieldInActionComplete = (
  action: DynamicAction,
  field: CheckboxDynamicField,
) => {
  const checkboxParamValue = action.params[field.options.paramName];
  return checkboxFieldSchema.isValidSync(checkboxParamValue);
};

export const isDynamicActionComplete = (
  action: DynamicAction,
  actionType: DynamicActionType,
): boolean => {
  return actionType.fields.every((field) => {
    if (
      isFlowSelectionDynamicField(field) &&
      isFlowSelectionDynamicFieldInActionComplete(action, field)
    )
      return true;
    if (
      isCheckboxDynamicField(field) &&
      isCheckboxDynamicFieldInActionComplete(action, field)
    )
      return true;

    return false;
  });
};

export const getDynamicActionSplitField = (
  action: DynamicAction,
): BaseDynamicSplitField | null => {
  const dynamicActionSchema = DYNAMIC_ACTION_TYPES.find((actionSchema) => {
    return actionSchema.type === action.type;
  });

  const dynamicSplitField = dynamicActionSchema?.fields.find((field) =>
    isDynamicSplitField(field),
  );

  // Yeah, we need to use `isDynamicSplitField` twice because TypeScript
  // won't infer the type of `dynamicSplitField` otherwise. We use it first
  // to find the field we care about it, then one more time so that TS
  // will cast the returned field as a BaseDynamicSplitField.
  if (dynamicSplitField && isDynamicSplitField(dynamicSplitField)) {
    return dynamicSplitField;
  }

  return null;
};
