import { TRIGGER_TYPES } from '.';

type FormOption = {
  label: string;
  value: string;
};

export const COLLECTION_ATTRIBUTE_OPTIONS = [
  { label: 'Phone', value: 'phone' },
  { label: 'Email » Phone', value: 'email,phone' },
  { label: 'Phone » Email', value: 'phone,email' },
];

// The options here need to match ISO-3166 alpha-2 standard, otherwise they will necessitate an SDK change to work.
export const COUNTRIES_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'US', value: 'US' },
  { label: 'US & Canada', value: 'US & Canada' },
  { label: 'Canada', value: 'Canada' },
  { label: 'All outside US', value: 'All outside US' },
  { label: 'All outside US & Canada', value: 'All outside US and Canada' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Australia', value: 'AU' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Australia & New Zealand', value: 'Australia and New Zealand' },
];

export const getCountriesOptions = (): FormOption[] => {
  return COUNTRIES_OPTIONS;
};

export const MOBILE_POPUP_SIZE_OPTIONS = [
  { label: 'Full', value: 'Full' },
  { label: 'Partial', value: 'Partial' },
];

export const DESKTOP_POPUP_SIZE_OPTIONS = [
  { label: 'Modal Window', value: 'Partial' },
  { label: 'Fullscreen', value: 'Fullscreen' },
  { label: 'Banner', value: 'Banner' },
];

export const BACKGROUND_STYLE_OPTIONS = [
  { label: 'Color', value: 'Color' },
  { label: 'Image', value: 'Image' },
];

export const CLOSE_ACTION_OPTIONS = [
  { label: 'Bubble', value: 'Bubble' },
  { label: 'Exit', value: 'Exit' },
];

const TIME_DELAY_OPTION = {
  label: 'Time Delay',
  value: TRIGGER_TYPES.DELAY,
} as const;
const CUSTOM_OPTION = {
  label: 'Custom',
  value: TRIGGER_TYPES.CUSTOM,
} as const;
const EXIT_INTENT_OPTION = {
  label: 'Exit Intent',
  value: TRIGGER_TYPES.EXIT,
} as const;

export const DESKTOP_POPUP_TRIGGER_OPTIONS = [
  TIME_DELAY_OPTION,
  EXIT_INTENT_OPTION,
  CUSTOM_OPTION,
];

export const MOBILE_POPUP_TRIGGER_OPTIONS = [TIME_DELAY_OPTION, CUSTOM_OPTION];
