import useGetUsageStatsTodaySum from 'components/billing/common/hooks/useGetUsageStatsTodaySum';
import {
  findActiveUsageCredits,
  sumUsageCredits,
} from 'components/billing/common/utils';
import { useEffect, useState } from 'react';
import { useGetUsageCredits } from '../../../context/useBilling';

export default function useGetUsageCreditRemaining(): number {
  const [remainingAmount, setRemainingAmount] = useState(0);
  const { data: usageCredits, isLoading: loadingUsageCredits } =
    useGetUsageCredits();
  const usageStatsTodaySum = useGetUsageStatsTodaySum();

  useEffect(() => {
    if (!usageCredits || loadingUsageCredits) {
      return;
    }

    const activeUsageCredits = findActiveUsageCredits(usageCredits);

    if (!activeUsageCredits) {
      return;
    }

    const { amountTotal, amountUsed } = sumUsageCredits(activeUsageCredits);

    const usageCreditRemaining = amountTotal - amountUsed;

    setRemainingAmount(usageCreditRemaining - usageStatsTodaySum);
  }, [usageStatsTodaySum, usageCredits, loadingUsageCredits]);

  return Math.max(remainingAmount, 0);
}
