// Tiny loading spinner component.

import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

const Wrapper = styled.div.attrs({
  className: 'w-100 p-4 flex-center',
})`
  min-height: 200px;
`;

const LoadingSpinnerBlock = ({ children = 'Loading...', ...props }) => (
  <Wrapper {...props}>
    <LoadingSpinner>{children}</LoadingSpinner>
  </Wrapper>
);

LoadingSpinnerBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

LoadingSpinnerBlock.defaultProps = {
  children: 'Loading...',
};

export default LoadingSpinnerBlock;
