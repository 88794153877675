export const PERMISSIONS = {
  billing_read: 'billing_read',
  user_read: 'user_read',
  user_write: 'user_write',
  user_delete: 'user_delete',
  limit_sms_sales_agent_access: 'limit_sms_sales_agent_access',
} as const;

export const LEGACY_ROLES = {
  BILLING_ADMIN: 'BILLING_ADMIN',
  SUPERUSER: 'SUPERUSER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  CONTENT_REVIEWER: 'CONTENT_REVIEWER',
  QA_TESTER: 'QA_TESTER',
  GTM_NOTIFICATION_RECIPIENT: 'GTM_NOTIFICATION_RECIPIENT',
  CONVERT_SALES_AGENT: 'CONVERT_SALES_AGENT',
  AI_ALPHA_TESTER: 'AI_ALPHA_TESTER',
  RECOMMENDED_ACTIONS_ALPHA: 'RECOMMENDED_ACTIONS_ALPHA',
  WORKSPACE_ALPHA_TESTER: 'WORKSPACE_ALPHA_TESTER',
  EMBEDDED_ANALYTICS_ADMIN: 'EMBEDDED_ANALYTICS_ADMIN',
} as const;

export const NOTIFICATION_NAME = {
  BILLING_SPEND_NOTIFICATION: 'BillingSpendNotification',
} as const;

export const NOTIFICATION_CHANNEL_TYPE = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
} as const;
