import { Button, Heading } from '@postscript/components';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface AuthPageInterface extends React.HTMLAttributes<HTMLElement> {
  headingText?: string;
  children: React.ReactNode;
}

const StyledAuthPageWrapper = styled.main`
  background: var(--surface-bkg-color);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-4);
  padding-bottom: 10vh;
`;

const StyledLoginWrapper = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  text-align: center;
`;

const StyledLogoLink = styled.a`
  width: 192px;
  height: 60px;
  margin: 0 auto var(--spacing-10);
  display: block;
  outline: none !important;
  transform-origin: center;

  path {
    transition: fill var(--hover-transition);
  }

  --logo-bkg-color: var(--purple-core);
  --logo-text-color: var(--white);

  --logo-bkg-hover-color: var(--purple-7);
  --logo-bkg-active-color: var(--purple-8);

  [data-theme='dark'] & {
    --logo-bkg-color: var(--purple-5);
    --logo-bkg-hover-color: var(--purple-6);
    --logo-bkg-active-color: var(--purple-7);
  }

  &:hover {
    --logo-bkg-color: var(--logo-bkg-hover-color);
  }

  &:active {
    --logo-bkg-color: var(--logo-bkg-active-color);
  }

  &:not(:hover):focus {
    --logo-bkg-color: var(--logo-bkg-hover-color);
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: var(--spacing-10);
`;

export const AuthButton = styled(Button)`
  width: 100%;
  text-align: center;

  &:not(:hover):not(:active):not(:focus) {
    background: var(--text-color);
  }
`;

export const AuthSmallLink = styled(Link)`
  text-align: center;

  &:link,
  &:visited {
    color: var(--text-color-dim);
  }
  &:hover {
    color: var(--link-color-hover);
    text-decoration: none;
  }
  &:active {
    color: var(--link-color-active);
  }
  &:focus {
    color: var(--link-color-hover);
  }
  &:link,
  &:visited,
  &:hover,
  &:active {
    display: block;
    font-weight: var(--body-text-normal-font-weight);
    padding: var(--spacing-4);
    outline: none;
  }
`;

const AuthPage = ({
  children,
  headingText = 'Welcome back!',
  ...props
}: AuthPageInterface): JSX.Element => (
  <StyledAuthPageWrapper {...props}>
    <StyledLoginWrapper>
      <StyledLogoLink href="https://postscript.io">
        <svg
          width="192"
          height="60"
          viewBox="0 0 192 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-cy="new-logo"
        >
          <path
            d="M0 55.8216C0 29.507 0 16.3498 8.17488 8.17488C16.3498 0 29.507 0 55.8216 0H161.461C178.029 0 191.461 13.4315 191.461 30V30C191.461 46.5685 178.029 60 161.461 60H0V55.8216Z"
            fill="var(--logo-bkg-color)"
          />
          <path
            d="M16.6406 18.5391H25.7979C31.2339 18.5391 34.5013 21.0941 34.5013 26.2402C34.5013 30.9593 31.8496 33.7481 26.2833 33.7481H21.9462V39.8312H16.6406V18.5391ZM26.0676 30.1885C28.3013 30.1885 29.5305 28.9908 29.5305 26.4986C29.5305 24.0716 28.171 22.7121 25.6474 22.7121H21.9462V30.1885H26.0676Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M35.0846 31.871C35.0846 26.662 38.5138 23.5879 43.788 23.5879C49.0621 23.5879 52.4936 26.662 52.4936 31.871C52.4936 37.08 49.0621 40.0913 43.788 40.0913C38.5138 40.0913 35.0846 37.0486 35.0846 31.871ZM47.5745 31.871C47.5745 29.0238 46.1835 27.3407 43.788 27.3407C41.3925 27.3407 39.9992 29.0238 39.9992 31.871C39.9992 34.7182 41.3925 36.3385 43.7857 36.3385C46.179 36.3385 47.5745 34.6868 47.5745 31.871V31.871Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M53.6923 34.462H58.0923C58.0923 35.9519 58.8361 36.9227 61.6518 36.9227C64.2069 36.9227 65.1147 36.1789 65.1147 35.0126C65.1147 34.4306 64.8226 33.9767 63.9911 33.7834C62.7612 33.5565 61.5184 33.4064 60.2698 33.334C57.9709 33.1407 56.1911 32.7856 55.2204 32.1047C54.7368 31.7374 54.3471 31.2609 54.0831 30.7141C53.8191 30.1673 53.6882 29.5657 53.7013 28.9587C53.7013 25.498 57.2586 23.5879 61.6923 23.5879C66.935 23.5879 69.3327 25.5946 69.3327 29.316H64.9956C64.9642 27.6328 64.1866 26.7587 61.7597 26.7587C59.5597 26.7587 58.5889 27.5025 58.5889 28.6036C58.5889 29.2778 58.8473 29.8014 59.7529 30.0283C60.5619 30.2216 61.9866 30.3519 63.7012 30.5137C65.6743 30.707 67.072 30.9002 68.263 31.5811C68.8122 31.9066 69.2659 32.3711 69.5784 32.9277C69.8908 33.4844 70.051 34.1136 70.0428 34.7519C70.0428 38.1227 67.1642 40.0913 61.6945 40.0913C56.0541 40.0935 53.6923 37.698 53.6923 34.462Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M72.8989 34.3311V27.6637H70.5371V23.8435H72.4472C73.191 23.8435 73.4495 23.4232 73.5461 22.4525L73.7708 20.2188H77.8158V23.8435H82.9214V27.6637H77.818V34.0727C77.818 35.7221 78.5955 36.239 80.5349 36.239C81.2848 36.2342 82.0327 36.159 82.7686 36.0143V39.7356C81.7478 39.9571 80.7074 40.0761 79.6629 40.0907C74.6787 40.0907 72.8989 37.8907 72.8989 34.3311Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M84.2025 34.462H88.6025C88.6025 35.9519 89.3463 36.9227 92.1621 36.9227C94.7194 36.9227 95.625 36.1789 95.625 35.0126C95.625 34.4306 95.3328 33.9767 94.5014 33.7834C93.2715 33.5562 92.0286 33.4061 90.78 33.334C88.4834 33.1407 86.7036 32.7856 85.7329 32.1047C85.2491 31.7375 84.859 31.2611 84.5946 30.7143C84.3302 30.1675 84.1989 29.5659 84.2115 28.9587C84.2115 25.498 87.7711 23.5879 92.2048 23.5879C97.4452 23.5879 99.8452 25.5946 99.8452 29.316H95.5059C95.4744 27.6328 94.6969 26.7587 92.2699 26.7587C90.0699 26.7587 89.0991 27.5025 89.0991 28.6036C89.0991 29.2778 89.3576 29.8014 90.2654 30.0283C91.0744 30.2216 92.4969 30.3519 94.2115 30.5137C96.1868 30.707 97.5823 30.9002 98.7755 31.5811C99.3247 31.9066 99.7784 32.3711 100.091 32.9277C100.403 33.4844 100.564 34.1136 100.555 34.7519C100.555 38.1227 97.6744 40.0913 92.207 40.0913C86.5643 40.0935 84.2025 37.698 84.2025 34.462Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M101.349 31.871C101.349 26.662 104.747 23.5879 109.664 23.5879C114.354 23.5879 117.428 26.2081 117.428 30.6418H113.1C113.066 28.5722 111.837 27.3407 109.832 27.3407C107.534 27.3407 106.273 28.9272 106.273 31.8351C106.273 34.6508 107.567 36.3295 109.734 36.3295C111.837 36.3295 113.104 35.1317 113.131 33.0935H117.468C117.468 37.6868 114.199 40.0823 109.475 40.0823C104.686 40.0913 101.349 37.0486 101.349 31.871Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M119.394 23.8463H124.313L124.117 28.4104H124.182C124.857 25.3677 126.578 23.5879 129.715 23.5879C132.985 23.5879 134.884 25.6913 134.884 29.8328C134.884 30.7317 134.787 32.1632 134.722 33.0036H130.266C130.331 32.2283 130.362 31.4306 130.362 30.934C130.362 28.6036 129.425 27.6643 127.872 27.6643C125.769 27.6643 124.313 29.3811 124.313 32.9407V39.8328H119.394V23.8463Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M136.715 18.249H141.634V22.1659H136.715V18.249ZM136.715 23.8468H141.634V39.8333H136.715V23.8468Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M144.142 23.8463H149.059L148.962 27.116H148.989C149.603 24.9137 151.641 23.5879 154.383 23.5879C158.85 23.5879 161.762 26.7587 161.762 31.4845C161.762 36.6531 158.841 40.0913 154.347 40.0913C151.425 40.0913 149.628 38.8935 148.911 36.9452H148.877C148.942 38.0126 149.039 39.5991 149.039 40.1811V46.2328H144.142V23.8463ZM156.891 31.6823C156.891 29.0935 155.403 27.3452 153.169 27.3452C150.711 27.3452 149.059 29.125 149.059 31.8126V32.5227C149.059 34.8216 150.711 36.3429 153.203 36.3429C155.403 36.3385 156.891 34.4598 156.891 31.6823Z"
            fill="var(--logo-text-color)"
          />
          <path
            d="M164.789 34.3311V27.6637H162.427V23.8435H164.337C165.081 23.8435 165.339 23.4232 165.436 22.4525L165.66 20.2188H169.705V23.8435H174.831V27.6637H169.708V34.0727C169.708 35.7221 170.485 36.239 172.427 36.239C173.176 36.2341 173.923 36.1589 174.658 36.0143V39.7356C173.637 39.9571 172.597 40.0761 171.553 40.0907C166.568 40.0907 164.789 37.8907 164.789 34.3311Z"
            fill="var(--logo-text-color)"
          />
        </svg>
      </StyledLogoLink>
      <StyledHeading size="large" forwardedAs="h1">
        {headingText}
      </StyledHeading>
      {children}
    </StyledLoginWrapper>
  </StyledAuthPageWrapper>
);

export default AuthPage;
