// Tiny loading spinner component.

import React from 'react';
import FontAwesome from 'react-fontawesome';

interface Props {
  children?: React.ReactNode;
}

const LoadingSpinner = ({
  children = 'Loading...',
  ...props
}: Props): JSX.Element => (
  <div {...props}>
    <div className="loading-spinner-decorate">
      <FontAwesome name="circle-o-notch" spin />
    </div>
    <span className="loading-spinner-text">{children}</span>
  </div>
);

export default LoadingSpinner;
