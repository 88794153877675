import { LinkText, toast } from '@postscript/components';
import { SDK_THEME_APP_EXTENSION } from 'components/admin/utils/feature-flags';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import { HELP_ARTICLES } from 'constants/constants';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useSdkAppExtensionStatus } from 'hooks/useSdkAppExtensionStatus';
import { useEffect, useState } from 'react';
import { BANNER_TYPES } from 'utils/events';

const SdkAppExtensionBanner = (): null => {
  const { hasLabsFlag } = usePSLabs();
  const [isBannerShown, setIsBannerShown] = useState(false);
  const hasSdkInstallStep = hasLabsFlag(SDK_THEME_APP_EXTENSION);
  const { addBanner, removeBanner } = useBanners();
  const { data } = useSdkAppExtensionStatus({
    enabled: hasSdkInstallStep,
    refetchOnWindowFocus: 'always',
  });

  useEffect(() => {
    if (!data || !hasSdkInstallStep) {
      return;
    }

    if (data?.hasExtension === false) {
      setIsBannerShown(true);
      addBanner({
        id: 'SDK_THEME_APP_EXTENSION_NOTIFICATION',
        data: {
          variant: 'warning',
          heading: 'Action Required',
          bodyText: (
            <>
              Because of a theme change in Shopify, Postscript&apos;s
              acquisition tools and automation events are not active on your
              website. To fix this, turn on the Postscript app embed in your
              Shopify theme&apos;s settings.{' '}
              <LinkText
                href={HELP_ARTICLES.addSdkThemeAppExtension}
                target="_blank"
              >
                View this article
              </LinkText>{' '}
              for more info.
            </>
          ),
          primaryAction: {
            text: 'Turn on App Embed',
            onClick: () => window.open(data?.installUrl, '_blank'),
          },
        },
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.SDK_APP_EXTENSION_INSTALL,
        },
      });
    }

    if (data?.hasExtension === true && isBannerShown) {
      removeBanner('SDK_THEME_APP_EXTENSION_NOTIFICATION');
      toast.success('Postscript app embed enabled');
    }
  }, [hasSdkInstallStep, data?.hasExtension]);

  return null;
};

export default SdkAppExtensionBanner;
