/* Notifications for user accounts. */

import { Banner } from '@postscript/components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Requests } from '../../../controllers/network';
import useAPIRequest from '../../../hooks/api';

const REFRESH_INTERVAL = 150000;

const StyledNotification = styled(Banner)`
  width: 100%;
  max-width: 768px;
  margin: var(--spacing-1) auto;
`;

const Notification = ({ notification, removeNotification }) => {
  const Wrapper = notification.path
    ? ({ children }) => <Link to={notification.path}>{children}</Link>
    : ({ children }) => <>{children}</>;
  return notification ? (
    <StyledNotification
      variant="guidance"
      bodyText={<Wrapper>{notification.content}</Wrapper>}
      onDismiss={() => removeNotification(notification.guid)}
    />
  ) : null;
};

const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-1);
  z-index: 9999;
`;

const NotificationList = ({ notifications, removeNotification }) => {
  const internals = Object.keys(notifications).map((key) => (
    <Notification
      key={key}
      id={key}
      notification={notifications[key]}
      removeNotification={removeNotification}
    />
  ));

  return <NotificationsContainer>{internals}</NotificationsContainer>;
};

const Notifications = ({ shopId }) => {
  const [notifications, changeNotifications] = useState({});

  const { request, loaded, forceUpdate } = useAPIRequest(
    `/notifications`,
    // Notification loading errors are ignored; it's not mission
    // critical so the user shouldn't be bothered.
    { listener: [shopId], catchError: () => {} },
  );

  useEffect(() => {
    const interval = window.setInterval(forceUpdate, REFRESH_INTERVAL);
    return () => window.clearInterval(interval);
  }, []);

  const removeNotification = (id) => {
    Requests.delete(`/notifications/${id}`).then(() => {
      changeNotifications((x) => ({ ...x, [id]: null }));
      forceUpdate();
    });
  };

  if (
    loaded &&
    // Reset notifications if there is a length diff
    Object.keys(request.notifications).length !==
      Object.keys(notifications).length
  ) {
    changeNotifications(request.notifications);
  }

  return loaded ? (
    <NotificationList
      notifications={notifications}
      removeNotification={removeNotification}
    />
  ) : null;
};

Notification.propTypes = {
  notification: PropTypes.shape({
    path: PropTypes.string.isRequired,
    guid: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  removeNotification: PropTypes.func.isRequired,
};

NotificationList.propTypes = {
  notifications: PropTypes.object.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

Notifications.propTypes = {
  shopId: PropTypes.number.isRequired,
};

export default Notifications;

export { Notifications, NotificationList, Notification };
