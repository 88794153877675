import {
  Banner,
  Button,
  Heading,
  LinkText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  toast,
} from '@postscript/components';
import { TableItemProps } from '@postscript/components/dist/esm/components/advanced/Table/types';
import useAccountStatus, {
  useSetAccountStatus,
} from 'components/account/useAccountStatus';
import { USAGE_BILLING_ENABLED } from 'components/admin/utils/feature-flags';
import {
  LONG_DATE_FORMAT,
  PAYMENT_PROVIDERS,
  PLAN_TYPES,
  RAC_APPROVED_SUCCESS_PATH,
  RAC_FLOW_BANNER_ID,
  RAC_STATUSES,
  TYPICAL_CARRIER_FEES_BY_COUNTRY,
} from 'components/billing/common/constants';
import {
  Plan,
  RecurringApplicationCharge,
} from 'components/billing/common/types';
import {
  findMessageRateByCodeAndType,
  formatDollars,
  formatMessageRate,
  getCents,
} from 'components/billing/common/utils';
import { useUsageBilling } from 'components/billing/context/usageBilling';
import {
  useGetAvailablePackages,
  useGetCurrentAndNextCycles,
  useGetCurrentPlan,
  useGetInvoicingSettings,
  useGetPaymentMethod,
  useSetCurrentPlan,
  useSetNextPlan,
} from 'components/billing/context/useBilling';
import { HELP_ARTICLES } from 'constants/constants';
import { BillingConsumer } from 'controllers/contexts';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  BANNER_TYPES,
  COMMON_EVENT_NAMES,
  logButtonClickEvent,
  logEvent,
  logLinkClickEvent,
  MODAL_TYPES,
  PRODUCT_AREAS,
} from 'utils/events';

const StyledModalHeader = styled(ModalHeader)`
  margin-bottom: var(--spacing-2);
`;

const StyledHeading = styled(Heading)`
  margin-bottom: calc(var(--spacing-2) * -1);
`;

const StyledTable = styled(Table)`
  tr {
    border: none;
  }

  th {
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
`;

const TableWrapper = styled.div`
  border-top: 2px solid var(--border-color-dim);
  margin-top: var(--spacing-2);

  table {
    margin-top: calc(var(--spacing-2) * -1);
  }
`;

const RolloverCredit = styled.span`
  color: var(--mint-6);
`;

const StyledModalFooter = styled(ModalFooter)`
  margin-top: var(--spacing-9);
`;

const StyledBanner = styled(Banner)`
  margin-bottom: var(--spacing-3);
`;

interface Props {
  close: () => void;
  toPlan: Plan;
  isUpgrade: boolean;
}

export default function ChangePlanModal({
  close,
  toPlan,
  isUpgrade,
}: Props): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const [proratedAmount, setProratedAmount] = useState(0);
  const [rac, setRac] = useState<RecurringApplicationCharge | null>(null);
  const { showPaymentMethodsModal, generateRac, enableUsageBilling } =
    useUsageBilling();
  const { data: paymentMethodData } = useGetPaymentMethod();
  const { paymentProvider, card, usBankAccount, recurringApplicationCharge } =
    paymentMethodData ?? {};
  const { mutateAsync: setCurrentPlan } = useSetCurrentPlan();
  const { mutateAsync: setNextPlan } = useSetNextPlan();
  const { data: currentPlan } = useGetCurrentPlan();
  const { data: { nextCycle } = {} } = useGetCurrentAndNextCycles();
  const { data: packages = [] } = useGetAvailablePackages();
  const { search: getFeatureFlags, hasFlag }: any = useFeatureFlags();
  const { push } = useHistory();
  const { user } = useUser();
  const { data: invoicingSettings } = useGetInvoicingSettings();
  const { manualPayments } = invoicingSettings ?? {};
  const isMigrationFromCredits = !hasFlag(USAGE_BILLING_ENABLED);
  const packageNames = {
    from_package_name: isMigrationFromCredits
      ? 'Legacy Billing'
      : currentPlan?.package?.name,
    to_package_name: packages.find(({ id }) => toPlan.packageId === id)?.name,
  };
  const isBilledViaStripe = paymentProvider === PAYMENT_PROVIDERS.STRIPE;
  const hasPaymentMethod = isBilledViaStripe
    ? !!card || !!usBankAccount || manualPayments
    : recurringApplicationCharge?.status === RAC_STATUSES.ACTIVE;
  const startDate = moment.utc(nextCycle?.startDate).format(LONG_DATE_FORMAT);
  const shouldShowCurrentPlan = !isUpgrade && !!currentPlan;
  const costPerCredit =
    getCents(user?.plan?.price) /
    (user?.billing_period_record?.number_of_texts_allowed || 0);
  const unusedCredits =
    (user?.billing_period_record?.number_of_texts_allowed || 0) -
    (user?.billing_period_record?.number_of_texts || 0);
  const rolloverCredit = (costPerCredit || 0) * unusedCredits;
  const { billingActive, isFetched } = useAccountStatus();
  const { mutateAsync: setAccountStatus } = useSetAccountStatus();

  const logModalDismiss = () => {
    logEvent(COMMON_EVENT_NAMES.MODAL_DISMISSED, {
      product_area: PRODUCT_AREAS.BILLING,
      modal_type: MODAL_TYPES.BILLING_UPGRADE,
      from_package_name: currentPlan?.package?.name,
      to_package_name: toPlan.package?.name,
    });
  };

  const dismissModal = () => {
    logModalDismiss();
    close();
  };

  const generateNewRac = async () => {
    return generateRac(
      isUpgrade
        ? `${RAC_APPROVED_SUCCESS_PATH}&target_plan_id=${toPlan.id}`
        : undefined,
    );
  };

  useEffect(() => {
    if (!isUpgrade || !toPlan) return;

    (async () => {
      try {
        const { amount } = await api.get(
          `/v2/billing/plans/cycle/current/proration/${toPlan.id}`,
        );

        setProratedAmount(amount);
      } catch (error) {
        toast.error(error as string);
      }
    })();
  }, []);

  useEffect(() => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: isUpgrade
        ? MODAL_TYPES.BILLING_UPGRADE
        : MODAL_TYPES.BILLING_DOWNGRADE,
      ...packageNames,
    });

    return () => {
      logModalDismiss();
    };
  }, []);

  useEffect(() => {
    if (
      !isUpgrade ||
      isBilledViaStripe ||
      hasPaymentMethod ||
      isMigrationFromCredits
    ) {
      return;
    }

    (async () => {
      try {
        const generatedRac = await generateNewRac();

        setRac(generatedRac as RecurringApplicationCharge);
      } catch (error) {
        toast.error(error as string);
      }
    })();
  }, [isUpgrade, isBilledViaStripe, hasPaymentMethod]);

  if (!toPlan) return null;

  const confirm = async () => {
    setLoading(true);

    try {
      if (isUpgrade) {
        if (isMigrationFromCredits) {
          await enableUsageBilling(PLAN_TYPES.STANDARD);
          await getFeatureFlags();
        }

        if (isFetched && !billingActive) {
          await setAccountStatus({
            billing: {
              account: 'ACTIVE',
            },
          });
        }

        await setCurrentPlan(toPlan.id);
        logEvent('plan upgraded', packageNames);

        if (isMigrationFromCredits) {
          push('/billing/plans');
        }
      } else {
        await setNextPlan(toPlan.id);
        logEvent('plan downgrade scheduled', packageNames);
      }

      toast.success('Your plan has been updated.');
    } catch (error) {
      toast.error(error as string);
    }

    setLoading(false);
    setTimeout(() => {
      close();
    }, 0);
  };

  const planDetailsTableRows: TableItemProps[] = [
    {
      nameColumn: {
        text: <strong>Platform fee</strong>,
        description: <>New plan starts on {startDate}</>,
      },
      dataColumns: [
        shouldShowCurrentPlan && (
          <Table.ItemNumber
            size="small"
            primary={
              <>
                {formatDollars((currentPlan?.saasFeeCents || 0) / 100, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
                /mo
              </>
            }
            secondary={currentPlan.package?.name}
          />
        ),
        <Table.ItemNumber
          size="small"
          primary={
            <>
              {formatDollars((toPlan.saasFeeCents || 0) / 100, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              /mo
            </>
          }
          secondary={toPlan.package?.name}
        />,
      ],
    },
    {
      nameColumn: {
        text: <strong>US message rates</strong>,
        description: (
          <>
            Typical carrier fees:{' '}
            {formatMessageRate(TYPICAL_CARRIER_FEES_BY_COUNTRY.US.sms)}/msg
          </>
        ),
      },
      dataColumns: [
        shouldShowCurrentPlan && (
          <Table.ItemNumber
            size="small"
            primary={
              <>
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    'US',
                    'SMS',
                  ) || currentPlan.smsMultiplier,
                )}
                /SMS
                <br />
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    'US',
                    'MMS',
                  ) || currentPlan.mmsMultiplier,
                )}
                /MMS
              </>
            }
          />
        ),
        <Table.ItemNumber
          size="small"
          primary={
            <>
              {formatMessageRate(
                findMessageRateByCodeAndType(
                  toPlan.messageRates,
                  'US',
                  'SMS',
                ) || toPlan.smsMultiplier,
              )}
              /SMS
              <br />
              {formatMessageRate(
                findMessageRateByCodeAndType(
                  toPlan.messageRates,
                  'US',
                  'MMS',
                ) || toPlan.mmsMultiplier,
              )}
              /MMS
            </>
          }
        />,
      ],
    },
    {
      nameColumn: {
        text: <strong>CA message rates</strong>,
        description: (
          <>
            Typical carrier fees:{' '}
            {formatMessageRate(TYPICAL_CARRIER_FEES_BY_COUNTRY.CA.sms)}/msg
          </>
        ),
      },
      dataColumns: [
        shouldShowCurrentPlan && (
          <Table.ItemNumber
            size="small"
            primary={
              <>
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    'CA',
                    'SMS',
                  ) || currentPlan.smsMultiplier,
                )}
                /SMS
                <br />
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    'CA',
                    'MMS',
                  ) || currentPlan.mmsMultiplier,
                )}
                /MMS
              </>
            }
          />
        ),
        <Table.ItemNumber
          size="small"
          primary={
            <>
              {formatMessageRate(
                findMessageRateByCodeAndType(
                  toPlan.messageRates,
                  'CA',
                  'SMS',
                ) || toPlan.smsMultiplier,
              )}
              /SMS
              <br />
              {formatMessageRate(
                findMessageRateByCodeAndType(
                  toPlan.messageRates,
                  'CA',
                  'MMS',
                ) || toPlan.mmsMultiplier,
              )}
              /MMS
            </>
          }
        />,
      ],
    },
    {
      nameColumn: {
        text: <strong>PR message rate</strong>,
        description: <>Carrier fees not applicable; MMS not supported</>,
      },
      dataColumns: [
        shouldShowCurrentPlan && (
          <Table.ItemNumber
            size="small"
            primary={
              <>
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    'PR',
                    'SMS',
                  ) ||
                    findMessageRateByCodeAndType(
                      currentPlan.messageRates,
                      '*',
                      'SMS',
                    ) ||
                    currentPlan.smsMultiplier * 10,
                )}
                /SMS
              </>
            }
          />
        ),
        <Table.ItemNumber
          size="small"
          primary={
            <>
              {formatMessageRate(
                findMessageRateByCodeAndType(
                  toPlan.messageRates,
                  'PR',
                  'SMS',
                ) ||
                  findMessageRateByCodeAndType(
                    toPlan.messageRates,
                    '*',
                    'SMS',
                  ) ||
                  toPlan.smsMultiplier * 10,
              )}
              /SMS
            </>
          }
        />,
      ],
    },
    {
      nameColumn: {
        text: <strong>All other countries</strong>,
        description: (
          <>
            <LinkText
              onClick={(event) =>
                logLinkClickEvent(event, {
                  product_area: PRODUCT_AREAS.BILLING,
                })
              }
              href={HELP_ARTICLES.carrierFees}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Carrier fees
            </LinkText>{' '}
            vary; MMS not supported
          </>
        ),
      },
      dataColumns: [
        shouldShowCurrentPlan && (
          <Table.ItemNumber
            size="small"
            primary={
              <>
                {formatMessageRate(
                  findMessageRateByCodeAndType(
                    currentPlan.messageRates,
                    '*',
                    'SMS',
                  ) || (currentPlan.smsMultiplier || 0) * 10,
                )}
                /SMS
              </>
            }
          />
        ),
        <Table.ItemNumber
          size="small"
          primary={
            <>
              {formatMessageRate(
                findMessageRateByCodeAndType(toPlan.messageRates, '*', 'SMS') ||
                  (toPlan.smsMultiplier || 0) * 10,
              )}
              /SMS
            </>
          }
        />,
      ],
    },
    ...(rolloverCredit > 0
      ? [
          {
            nameColumn: {
              text: <strong>Estimated rollover credit</strong>,
              description:
                'Previously purchased credits are applied to your account. Actual amount calculated when switch is confirmed.',
            },
            dataColumns: [
              null,
              <Table.ItemNumber
                size="small"
                primary={
                  <RolloverCredit>
                    + {formatDollars(rolloverCredit / 100)}
                  </RolloverCredit>
                }
              />,
            ],
          },
        ]
      : []),
  ];

  const billedTodayTableRows: TableItemProps[] = [
    {
      nameColumn: {
        text: <strong>Billed today</strong>,
        description: (
          <>
            By purchasing, you agree to{' '}
            <LinkText
              href="https://postscript.io/subscriber-messaging-terms"
              target="_blank"
            >
              Postscript&apos;s terms of service
            </LinkText>
          </>
        ),
      },
      dataColumns: [
        <Table.ItemNumber
          size="small"
          primary={formatDollars(proratedAmount < 0 ? 0 : proratedAmount / 100)}
        />,
      ],
    },
  ];

  return (
    <BillingConsumer>
      {({ billing }: any) => (
        <>
          <StyledModalHeader onCancel={dismissModal}>
            Switch your plan to {toPlan.name}?
          </StyledModalHeader>
          <ModalBody>
            {isMigrationFromCredits ? (
              <StyledBanner
                variant="guidance"
                bodyText={
                  <>
                    You&apos;re about to upgrade to usage billing! While you can
                    switch between other usage billing plans,{' '}
                    <strong>
                      you can&apos;t switch back to credit-based billing
                    </strong>
                    .
                    {billing.payment_provider !== 'stripe' &&
                      " After upgrading, you'll be prompted to give Postscript permission to bill you via Shopify."}
                  </>
                }
              />
            ) : null}
            {!isMigrationFromCredits &&
            isUpgrade &&
            isBilledViaStripe &&
            !hasPaymentMethod ? (
              <StyledBanner
                variant="warning"
                bodyText="You need to enter a payment method before upgrading plans."
                primaryAction={{
                  text: 'Add Payment Method',
                  onClick: () => {
                    close();
                    showPaymentMethodsModal();
                  },
                }}
              />
            ) : null}
            {!isMigrationFromCredits &&
            isUpgrade &&
            !isBilledViaStripe &&
            !hasPaymentMethod ? (
              <StyledBanner
                variant="warning"
                bodyText="Postscript needs your permission to charge you via Shopify."
                primaryAction={{
                  id: `${RAC_FLOW_BANNER_ID}-button-in-upgrade`,
                  text: 'Approve on Shopify',
                  onClick: async (event: any) => {
                    logButtonClickEvent(event, {
                      banner_type: BANNER_TYPES.BILLING_RAC_APPROVAL,
                    });

                    if (!rac) {
                      await generateNewRac();
                      toast.error('Please try again.');
                      return;
                    }

                    window.location.href = rac.confirmationUrl;
                  },
                }}
              />
            ) : null}
            {!shouldShowCurrentPlan && (
              <StyledHeading size="xx-small">New plan details</StyledHeading>
            )}
            <StyledTable
              id="plan-details-table"
              size="small"
              cardWrapper={false}
              globalColumnSettings={{
                name: { heading: shouldShowCurrentPlan ? 'Item' : ' ' },
                dataColumns: [
                  {
                    heading: shouldShowCurrentPlan ? 'Current plan' : ' ',
                    align: 'right',
                  },
                  {
                    heading: shouldShowCurrentPlan ? 'New plan' : ' ',
                    align: 'right',
                    minWidth: 120,
                  },
                ],
              }}
              items={planDetailsTableRows}
            />
            <TableWrapper>
              <StyledTable
                id="billed-today-table"
                size="small"
                cardWrapper={false}
                globalColumnSettings={{
                  name: { heading: ' ' },
                  dataColumns: [
                    {
                      align: 'right',
                      minWidth: 120,
                    },
                  ],
                }}
                items={billedTodayTableRows}
              />
            </TableWrapper>
          </ModalBody>
          <StyledModalFooter>
            <Button
              onClick={confirm}
              disabled={
                loading ||
                (!isMigrationFromCredits && isUpgrade && !hasPaymentMethod)
              }
              data-cy="confirm-change-plan-button"
            >
              Confirm{' '}
              {isMigrationFromCredits
                ? 'Upgrade'
                : isUpgrade
                ? 'Purchase'
                : 'Downgrade'}
            </Button>
            <Button variant="text" onClick={dismissModal}>
              Close
            </Button>
          </StyledModalFooter>
        </>
      )}
    </BillingConsumer>
  );
}
