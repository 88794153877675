import {
  BodyText,
  ButtonAnchor,
  Card,
  Heading,
  Icon,
  IconSet,
} from '@postscript/components';
import { BCC } from 'components/admin/utils/feature-flags';
import { useGetCurrentPlan } from 'components/billing/context/useBilling';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  background-image: radial-gradient(
      65.01% 106.72% at 103.89% 0%,
      rgba(0, 122, 255, 0.9) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      100.3% 136.72% at 2.28% 0%,
      rgba(30, 179, 146, 0.65) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      100.7% 100.7% at 50% 120.6%,
      rgba(255, 0, 153, 0.75) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, #000, #000);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-3);
  margin: var(--spacing-3) 0;
  box-shadow: none;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  margin-bottom: var(--spacing-2);

  span {
    margin: auto 0 auto var(--spacing-2);
  }
`;

const StyledButton = styled(ButtonAnchor)`
  margin-top: var(--spacing-2);
  white-space: nowrap;
  background-color: white;
`;

const AIAnnouncement = (): JSX.Element | null => {
  const { hasLabsFlag, hasInitialized } = usePSLabs();
  const { data, isLoading } = useGetCurrentPlan();

  if (
    isLoading ||
    hasLabsFlag(BCC) ||
    !hasInitialized ||
    !['Enterprise', 'Professional'].includes(data?.package?.name || '')
  ) {
    return null;
  }

  return (
    <StyledCard data-theme="dark">
      <StyledHeading size="xx-small">
        <Icon
          style={{ margin: '0' }}
          size={28}
          component={IconSet.PostscriptAi}
        />
        <span>Meet Postscript AI</span>
      </StyledHeading>
      <BodyText size="x-small" color="var(--text-color)">
        Postscript AI maximizes revenue by using your brand&apos;s unique voice,
        optimizing SMS performance across hundreds of message variants, and
        delivering actionable insights.
      </BodyText>
      <StyledButton
        variant="primary"
        href="https://postscript.io/ai"
        rel="noreferrer"
        target="_blank"
      >
        Learn More
      </StyledButton>
    </StyledCard>
  );
};

export default AIAnnouncement;
