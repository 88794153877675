import { Icon, IconSet, Layout, TabGroup } from '@postscript/components';
import { POPUP_SUBMIT_TWO_LINES } from 'components/admin/utils/feature-flags';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { usePopups } from 'controllers/contexts/popups';
import { getIn, useFormikContext } from 'formik';
import styled from 'styled-components';
import {
  EDITOR_GRID_AREAS,
  EMAIL_ATTRIBUTE_KEY,
  QUESTION_TYPES,
} from './constants';
import { useSetDefaultESPInPopupForm } from './hooks/useEmailIntegration';
import { PopupFormValues, PopupPage } from './types';
import { getTabName } from './utils/pageUtils';
import { hasQuestionOfType } from './utils/questionUtils';

export const PAGE_COLLECTION_ATTRIBUTE_PREFIX = 'page_';
export const NEW_PAGE_IDENTIFIER = 'NEW_POPUP_PAGE';

export const createNewPage = (
  hasTwoLineSubmit: boolean,
  existingPage?: PopupPage,
): PopupPage => {
  return {
    id: NEW_PAGE_IDENTIFIER,
    preHeadline: existingPage?.preHeadline ?? 'UNLOCK',
    headline: existingPage?.headline ?? '10% OFF',
    postHeadline: existingPage?.postHeadline ?? 'YOUR ORDER',
    submitButtonText:
      existingPage?.submitButtonText ??
      (hasTwoLineSubmit ? 'Sign up now' : 'Sign Up'),
    secondarySubmitButtonText: '',
    closeButtonText: existingPage?.closeButtonText ?? 'No Thanks',
    questions: [
      {
        text: 'What is your email?',
        type: QUESTION_TYPES.EMAIL,
        optInMethod: null,
        attributeKey: EMAIL_ATTRIBUTE_KEY,
      },
    ],
  };
};

const hasValidPageError = (pageErrors?: Record<keyof PopupPage, unknown>) => {
  /* yup returns {pages: {questions: [undefined, undefined]}} error after
  swapping questions, which is a bug, so we check that errors are comprised of
  at least one other type of error */
  if (!pageErrors) return false;

  const hasOneErrorProperty = Object.keys(pageErrors).length === 1;
  const isQuestionsProperty = Object.prototype.hasOwnProperty.call(
    pageErrors,
    'questions',
  );

  // we're only concerned when the questions property is the only key val
  if (hasOneErrorProperty && isQuestionsProperty) {
    // if every question entry is undefined or null, we know the error is bogus
    return !Object.values(
      pageErrors.questions as Record<
        'questions',
        Record<string, string>[] | undefined[] | null[]
      >,
    ).every((err) => !err);
  }

  return true;
};

const StyledTabGroup = styled(TabGroup)`
  grid-area: ${EDITOR_GRID_AREAS.TABS};
  justify-self: start;
  margin-bottom: var(--spacing-2);
`;

interface Props {
  isCssPreview?: boolean;
}

const PopupPageTabs = ({ isCssPreview }: Props): JSX.Element => {
  const { errors, values, setFieldValue } = useFormikContext<PopupFormValues>();
  const { collectionAttributes } = values;
  const pages = collectionAttributes.split(',');
  const {
    previewScreenState,
    updateIsPreviewCollapsed,
    updatePreviewScreenState,
  } = usePopups();
  const { hasLabsFlag } = usePSLabs();
  const hasTwoLineSubmit = hasLabsFlag(POPUP_SUBMIT_TWO_LINES);
  const setDefaultESPInPopupForm = useSetDefaultESPInPopupForm();

  return (
    <StyledTabGroup description="Popup Pages" id="popup-pages">
      {pages.map((page: string) => {
        const pageIndex =
          values.pages?.findIndex((p) => `page_${p.id}` === page) ?? -1;
        const hasError = hasValidPageError(getIn(errors, `pages.${pageIndex}`));
        return (
          <button
            data-selected={page === previewScreenState}
            key={page}
            type="button"
            onClick={() => {
              updateIsPreviewCollapsed(false);
              updatePreviewScreenState(page);
            }}
          >
            <Layout align="center" valign="center">
              {getTabName(page, values.pages?.[pageIndex])}
              {hasError && (
                <Icon
                  component={IconSet.WarningTriangle}
                  color="var(--error-color)"
                  style={{ position: 'relative' }}
                  size={20}
                  aria-label="A validation error exists within this step."
                />
              )}
            </Layout>
          </button>
        );
      })}
      {pages.length < 2 && !isCssPreview && (
        <button
          aria-label="Add step"
          type="button"
          onClick={() => {
            const existingPages = values.pages ?? [];

            const newPage = createNewPage(hasTwoLineSubmit, values.pages?.[0]);
            const newPageHasEmailQuestion = hasQuestionOfType(
              newPage.questions,
              QUESTION_TYPES.EMAIL,
            );

            if (newPageHasEmailQuestion) {
              setDefaultESPInPopupForm();
            }

            const screenId = `${PAGE_COLLECTION_ATTRIBUTE_PREFIX}${newPage.id}`;
            setFieldValue(
              'collectionAttributes',
              `${collectionAttributes},${screenId}`,
            );
            setFieldValue('pages', [...existingPages, newPage]);
            updateIsPreviewCollapsed(false);
            updatePreviewScreenState(screenId);
          }}
        >
          + Add Step
        </button>
      )}
    </StyledTabGroup>
  );
};

export default PopupPageTabs;
