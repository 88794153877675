import { toast } from '@postscript/components';
import useAccountStatus from 'components/account/useAccountStatus';
import { USAGE_BILLING_ENABLED } from 'components/admin/utils/feature-flags';
import {
  FeatureKeys,
  FreeTrial,
  FreeTrialInput,
  HasPackageFeatureArgs,
  Invoice,
  PlanTypes,
  Preferences,
  RecurringApplicationCharge,
  ValueOf,
} from 'components/billing/common/types';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import produce from 'immer';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { COMMON_EVENT_NAMES, logEvent, MODAL_TYPES } from 'utils/events';
import {
  DEFAULT_PREFERENCES,
  RAC_APPROVED_SUCCESS_PATH,
  RAC_STATUSES,
} from '../common/constants';
import { useHasActivePaymentMethod } from '../modules/payments/hooks/useHasActivePaymentMethod';
import PaymentMethodsModal from '../modules/payments/PaymentMethodsModal';
import { PayNowController } from '../modules/payments/payNow';
import useGetUsageCreditRemaining from '../modules/usageCredit/hooks/useGetUsageCreditRemaining';
import {
  useGetCurrentPlan,
  useGetInvoices,
  useGetInvoicingStatus,
  useGetPaymentMethod,
} from './useBilling';

const UPDATE_INVOICES = 'UPDATE_INVOICES';
const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
const UPDATE_FREE_TRIAL = 'UPDATE_FREE_TRIAL';
const UPDATE_SUMMARY_TOTAL = 'UPDATE_SUMMARY_TOTAL';
const UPDATE_IS_INITIAL_DATA_LOADED = 'UPDATE_IS_INITIAL_DATA_LOADED';

interface UpdateInvoices {
  type: typeof UPDATE_INVOICES;
  invoices: Invoice[];
}

interface UpdatePreferences {
  type: typeof UPDATE_PREFERENCES;
  preferences: Preferences;
}

interface UpdateFreeTrial {
  type: typeof UPDATE_FREE_TRIAL;
  freeTrial: FreeTrial;
}

interface UpdateSummaryTotal {
  type: typeof UPDATE_SUMMARY_TOTAL;
  summaryTotal: number;
}

interface UpdateIsInitialDataLoaded {
  type: typeof UPDATE_IS_INITIAL_DATA_LOADED;
  isInitialDataLoaded: boolean;
}

type ReducerAction =
  | UpdateInvoices
  | UpdatePreferences
  | UpdateFreeTrial
  | UpdateSummaryTotal
  | UpdateIsInitialDataLoaded;

interface State {
  invoices: Invoice[];
  preferences: Preferences;
  freeTrial: FreeTrial | null;
  summaryTotal: number;
  isInitialDataLoaded: boolean;
  enableUsageBilling: (planType: string) => Promise<void>;
  generateRac: (
    returnUrl?: string,
  ) => Promise<RecurringApplicationCharge | undefined>;
  updatePreferences: (preferences: Preferences) => void;
  hasPackageFeature: ({
    featureKey,
    legacyBillingShopHasFeature,
    internalUserHasFeature,
  }: HasPackageFeatureArgs) => boolean;
  updateSummaryTotal: (summaryTotal: number) => void;
  showPaymentMethodsModal: () => void;
  showPayNowModal: () => void;
  getFreeTrial: () => Promise<FreeTrial | undefined>;
  createFreeTrial: (freeTrial: FreeTrialInput) => Promise<void>;
  updateFreeTrial: (id: number, expiresAt: string) => Promise<void>;
  getFeatureSettings: (
    featureKey: FeatureKeys,
  ) => { [key: string]: any } | Record<string, unknown>;
}

const initialState: State = {
  invoices: [],
  preferences: DEFAULT_PREFERENCES,
  freeTrial: null,
  summaryTotal: 0,
  isInitialDataLoaded: false,
  enableUsageBilling: async () => undefined,
  generateRac: async () => undefined,
  updatePreferences: async () => undefined,
  hasPackageFeature: () => false,
  updateSummaryTotal: () => undefined,
  showPaymentMethodsModal: () => undefined,
  showPayNowModal: () => undefined,
  getFreeTrial: async () => undefined,
  createFreeTrial: async () => undefined,
  updateFreeTrial: async () => undefined,
  getFeatureSettings: () => ({}),
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case UPDATE_INVOICES:
      draft.invoices = action.invoices;
      break;
    case UPDATE_PREFERENCES:
      draft.preferences = action.preferences;
      break;
    case UPDATE_FREE_TRIAL:
      draft.freeTrial = action.freeTrial;
      break;
    case UPDATE_SUMMARY_TOTAL:
      draft.summaryTotal = action.summaryTotal;
      break;
    case UPDATE_IS_INITIAL_DATA_LOADED:
      draft.isInitialDataLoaded = action.isInitialDataLoaded;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const UsageBillingContext = createContext(initialState);
export const useUsageBilling = (): State => useContext(UsageBillingContext);

interface Props {
  children: JSX.Element;
}

export const UsageBillingProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    user: { is_admin: userIsAdmin },
  } = useUser();
  const { hasFlag, flags }: any = useFeatureFlags();
  const { showModal, hideModal } = useGlobalModal();
  const { data: currentPlan } = useGetCurrentPlan();
  const remainingCredits = useGetUsageCreditRemaining();
  const hasUsageCredit = remainingCredits > 0;
  const [reopenPayNowModal, setReopenPayNowModal] = useState(false);
  const [addPaymentMethodFinished, setAddPaymentMethodFinished] =
    useState(false);
  const { data: invoicingStatus, refetch: refetchInvoicingStatus } =
    useGetInvoicingStatus();
  const { data: paymentMethodData, refetch: refetchPaymentMethod } =
    useGetPaymentMethod();
  const { paymentProvider, recurringApplicationCharge } =
    paymentMethodData ?? {};
  const { hasActivePaymentMethod } = useHasActivePaymentMethod();
  const { refetch: refetchInvoices } = useGetInvoices();
  const {
    billingActive,
    installed,
    isFetched: accountStatusFetched,
  } = useAccountStatus();

  const generateRac = async (
    returnUrl?: string,
  ): Promise<RecurringApplicationCharge | undefined> => {
    return api.post(
      '/v2/billing/payments/shopify/recurring_application_charge',
      {
        returnUrl:
          window.location.origin + (returnUrl ?? RAC_APPROVED_SUCCESS_PATH),
      },
    );
  };

  const closeShowPaymentMethodsModal = (openPayNowAfterClose = false) => {
    if (openPayNowAfterClose) setAddPaymentMethodFinished(true);
    hideModal();
  };

  const showPaymentMethodsModal = (closeBtnTxt = 'Close') => {
    const openPayNowAfterClose = closeBtnTxt === 'Continue';
    showModal({
      dismissOnBackdropClick: false,
      children: (
        <PaymentMethodsModal
          close={() => closeShowPaymentMethodsModal(openPayNowAfterClose)}
          closeBtnTxt={closeBtnTxt}
        />
      ),
    });
  };

  const closePayNowModal = async () => {
    hideModal();
    refetchInvoices();
  };

  const showPayNowModal = () => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: MODAL_TYPES.BILLING_PAY_NOW,
    });

    showModal({
      dismissOnBackdropClick: false,
      children: (
        <PayNowController
          numberOfFailedInvoices={invoicingStatus?.numberOfFailedInvoices ?? 0}
          paymentProvider={paymentProvider}
          close={closePayNowModal}
          hasActivePaymentMethod={hasActivePaymentMethod}
          showPaymentMethodsModal={showPaymentMethodsModal}
          setReopenPayNowModal={setReopenPayNowModal}
          recurringApplicationChargeActive={
            recurringApplicationCharge?.status === RAC_STATUSES.ACTIVE
          }
          generateRac={generateRac}
        />
      ),
    });
  };

  useEffect(() => {
    if (reopenPayNowModal && addPaymentMethodFinished) {
      showPayNowModal();
      setReopenPayNowModal(false);
      setAddPaymentMethodFinished(false);
    }
  }, [reopenPayNowModal, addPaymentMethodFinished]);

  const enableUsageBilling = async (planType: ValueOf<PlanTypes>) => {
    await api.post(`/v2/billing/core/init/${planType}`);
  };

  const updatePreferences = (preferences: Preferences): void => {
    dispatch({
      type: UPDATE_PREFERENCES,
      preferences,
    });
  };

  const hasPackageFeature = ({
    featureKey,
    legacyBillingShopHasFeature = true,
    internalUserHasFeature = true,
  }: HasPackageFeatureArgs): boolean => {
    // Shop is not on Usage Billing and is not subject to feature gating
    if (!hasFlag(USAGE_BILLING_ENABLED)) return legacyBillingShopHasFeature;

    // Shop has no current plan, or it has not yet been loaded; re-render after plan state change will cause this function to be called again
    if (!currentPlan) return false;

    // User is internal/admin and is not subject to feature gating
    if (userIsAdmin && internalUserHasFeature) return true;

    // Shop is pre-packages and is not subject to feature gating
    if (!currentPlan.package) return true;

    const {
      package: { features },
    } = currentPlan;

    return !!features[featureKey as FeatureKeys];
  };

  const getFeatureSettings = (
    featureKey: FeatureKeys,
  ): Record<string, unknown> => {
    return currentPlan?.package?.featureSettings?.[featureKey]?.settings ?? {};
  };

  const updateSummaryTotal = (summaryTotal: number) => {
    dispatch({
      type: UPDATE_SUMMARY_TOTAL,
      summaryTotal,
    });
  };

  const getFreeTrial = async (): Promise<FreeTrial | undefined> => {
    try {
      if (!accountStatusFetched || !billingActive || !installed) return;

      const { freeTrialPeriods } = await api.get(
        '/v2/billing/plans/cycle/current/trials',
      );

      const freeTrial = freeTrialPeriods[0];

      dispatch({
        type: UPDATE_FREE_TRIAL,
        freeTrial: freeTrial || null,
      });

      return freeTrial;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const createFreeTrial = async (freeTrial: FreeTrialInput): Promise<void> => {
    const newFreeTrial = await api.post(
      '/v2/billing/admin/plans/trial',
      freeTrial,
    );

    dispatch({
      type: UPDATE_FREE_TRIAL,
      freeTrial: newFreeTrial,
    });
  };

  const updateFreeTrial = async (
    id: number,
    expiresAt: string,
  ): Promise<void> => {
    const updatedFreeTrial = await api.patch(
      `/v2/billing/admin/plans/trial/${id}`,
      { expiresAt },
    );

    dispatch({
      type: UPDATE_FREE_TRIAL,
      freeTrial: updatedFreeTrial,
    });
  };

  useEffect(() => {
    if (!hasFlag(USAGE_BILLING_ENABLED)) return;

    (async () => {
      await Promise.all([refetchPaymentMethod(), getFreeTrial()]);

      dispatch({
        type: UPDATE_IS_INITIAL_DATA_LOADED,
        isInitialDataLoaded: true,
      });

      await refetchInvoicingStatus();
    })();
  }, [flags, hasUsageCredit, accountStatusFetched]);

  const valueObj = useMemo(
    () => ({
      ...state,
      createFreeTrial,
      enableUsageBilling,
      generateRac,
      getFeatureSettings,
      getFreeTrial,
      hasPackageFeature,
      showPayNowModal,
      showPaymentMethodsModal,
      updateFreeTrial,
      updatePreferences,
      updateSummaryTotal,
    }),
    [state],
  );

  return (
    <UsageBillingContext.Provider value={valueObj}>
      {children}
    </UsageBillingContext.Provider>
  );
};
