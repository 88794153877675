import { BodyText } from '@postscript/components';
import { HUBSPOT_FORM_IDS } from 'components/billing/common/constants';
import LinkButton from 'components/billing/common/LinkButton';
import { Plan as PlanInterface } from 'components/billing/common/types';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import styled from 'styled-components';
import ContactSalesModal from './ContactSalesModal';
import Description from './Description';
import Features from './Features';
import PlanCTA from './PlanCTA';
import PlanHeading from './PlanHeading';
import PlanMessageRatesSummary from './PlanMessageRatesSummary';
import SubscriptionFee from './SubscriptionFee';

interface StyledPackageProps {
  isCurrent: boolean;
}

const StyledPackage = styled.div<StyledPackageProps>`
  flex: 1;
  background-color: ${({ isCurrent }) =>
    isCurrent ? 'var(--main-bkg-color)' : 'var(--surface-bkg-color)'};
  border-right: 2px solid var(--gray-3);
  color: var(--gray-9);
  min-width: 250px;

  &:last-child {
    border-right: none;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Section = styled.div`
  border-bottom: 2px solid var(--border-color-dim);
  padding: var(--spacing-3);

  &:last-child {
    border-bottom: none;
  }
`;

const TopSection = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 306px;
`;

const StyledDescription = styled(Description)`
  flex: 1;
`;

const CTAContainer = styled.div`
  text-align: center;
`;

const StyledCTA = styled(PlanCTA)`
  margin: var(--spacing-2) auto 0 auto;
`;

const StyledHeading = styled(PlanHeading)`
  margin-bottom: var(--spacing-3);
`;

interface Props {
  plan?: PlanInterface | null;
  name: string;
  includedFeaturesPlanName?: string;
  description: string;
  hasFreeTrialCTA: boolean;
  disclaimerText?: string;
  features: string[];
  isCurrent: boolean;
  isNext: boolean;
  isUpgrade: boolean;
  isDowngrade: boolean;
}

const Package = ({
  plan,
  name,
  description,
  hasFreeTrialCTA,
  disclaimerText,
  includedFeaturesPlanName,
  features,
  isCurrent,
  isNext,
  isUpgrade,
  isDowngrade,
}: Props): JSX.Element => {
  const { showModal, hideModal } = useGlobalModal();

  const handleContactSales = () => {
    showModal({
      dismissOnBackdropClick: false,
      children: (
        <ContactSalesModal
          formId={HUBSPOT_FORM_IDS.PROFESSIONAL}
          packageName="Professional"
          close={hideModal}
        />
      ),
    });
  };

  return (
    <StyledPackage isCurrent={isCurrent}>
      <Content data-cy={plan ? 'upgradable-plans' : 'enterprise-plan'}>
        <TopSection>
          <StyledHeading shouldShowCurrentBadge={isCurrent}>
            {name}
          </StyledHeading>
          <SubscriptionFee feeCents={plan?.saasFeeCents} />
          <StyledDescription>{description}</StyledDescription>
          <CTAContainer>
            {hasFreeTrialCTA && !isCurrent ? (
              <LinkButton type="button" onClick={handleContactSales}>
                Contact sales for a 30-day trial
              </LinkButton>
            ) : null}
            {disclaimerText && (
              <BodyText size="small">{disclaimerText}</BodyText>
            )}
            <StyledCTA
              planId={plan?.id}
              isCurrentPlan={isCurrent}
              isNextPlan={isNext}
              isUpgrade={isUpgrade}
              isDowngrade={isDowngrade}
            />
          </CTAContainer>
        </TopSection>
        <Section>
          <PlanMessageRatesSummary
            messageRates={plan?.messageRates}
            smsMultiplier={plan?.smsMultiplier}
            mmsMultiplier={plan?.mmsMultiplier}
          />
        </Section>
        <Section>
          <Features
            features={features}
            includedFeaturesPlanName={includedFeaturesPlanName}
          />
        </Section>
      </Content>
    </StyledPackage>
  );
};

export default Package;
