/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */

import { Button } from '@postscript/components';
import { Emoji } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'; // do we want to group these on app.js eventually?  to de-dupe -ct
import Events from '../../controllers/events';
import { feedbackLoops } from '../../providers';

const initialState = {
  modal: false,
  closed: false,
};

class UpsellModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.manageFeedbackLoop = this.manageFeedbackLoop.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.closeMe = this.closeMe.bind(this);
    this.upgradeMe = this.upgradeMe.bind(this);
  }

  componentDidMount() {
    Events.track('Viewed Upsell Modal');
    this.manageFeedbackLoop();
  }

  componentDidUpdate() {
    if (!this.state.closed) {
      this.manageFeedbackLoop();
    }
  }

  manageFeedbackLoop() {
    const { feedbackQueryParams } = this.props;
    let modalState = this.state.modal;
    // if the feedback loops are empty, there's nothing to evaluate
    if (!feedbackQueryParams || Object.keys(feedbackQueryParams).length < 1) {
      modalState = true;
    }
    for (const queryParam in feedbackQueryParams) {
      const queryValue = feedbackQueryParams[queryParam];
      // continue with a plan if necessary
      if (queryParam === 'charge_id' && queryValue) {
        modalState = false;
        break;
      }
    }
    // this really should be taken care of by the React LifeCycle
    // but since the App.js controls this, the check is best
    if (modalState != this.state.modal) {
      this.setState((state) => {
        return {
          ...state,
          modal: modalState,
        };
      });
    }
  }

  toggleModal() {
    this.setState({
      modal: false,
      closed: true,
    });
  }

  upgradeMe() {
    Events.track('Upsell Modal Upgrade Button Clicked');
    this.toggleModal();
  }

  closeMe() {
    Events.track('Closed Upsell Modal');
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          onClosed={this.closeMe}
        >
          <ModalHeader toggle={this.toggleModal}>
            Your credits are almost out!
            <Emoji emoji="money_with_wings" set="apple" size={32} />
          </ModalHeader>
          <ModalBody className="display-linebreaks">
            <div>
              Most of the credits for this month have already been used. At your
              current pace you will run out of credits soon, causing all
              automations and popup messages to pause and you to miss out on
              sales!
            </div>
            <br />
            <div>Upgrade your plan to prevent a pause on your messages.</div>
          </ModalBody>
          <ModalFooter>
            <Link to="/account/plan" onClick={this.upgradeMe}>
              <Button variant="primary">Upgrade Plan</Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UpsellModal.propTypes = {};

export default feedbackLoops(UpsellModal, 'change_plan', 'charge_id', false);
