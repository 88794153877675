import { BodyText } from '@postscript/components';
import React from 'react';
import styled from 'styled-components';

const StyledBodyText = styled(BodyText)`
  grid-column: 1/-1;
`;

const EditorFormDescription = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => <StyledBodyText size="small">{children}</StyledBodyText>;

export default EditorFormDescription;
