import { Icon, IconSet } from '@postscript/components';
import { POPUP_NEW_COMPLIANCE_TEXT } from 'components/admin/utils/feature-flags';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { useUser } from '../../controllers/contexts/user';
import Filters from '../../controllers/filters';
import { generateSubscribeText } from '../../utils/messages';

function findSelectedOption(options, keywordId) {
  return options.find((option) => option.value === keywordId);
}

const StyledDropdownIndicator = styled(Icon)`
  margin-left: 3px;
  outline: none;
  transition: transform var(--hover-transition);
  color: var(--black);

  [data-theme='dark'] & {
    color: var(--white);
  }

  &:focus-visible {
    background-color: var(--btn-color-focus);
    border-radius: var(--border-radius-round);
    box-shadow: inset 0 0 0 2px var(--btn-color-hover);
    color: var(--btn-color-active);
  }
`;

const DropdownIndicator = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    innerProps: { ...restInnerProps },
  } = props;

  return (
    <StyledDropdownIndicator
      component={IconSet.ChevronDown}
      size={24}
      tabIndex={0}
      {...restInnerProps}
    />
  );
};

// These classes at the styles that use them below are mostly a copy/paste from the component library
// since we currently don't have an async select component. This should be refactored if/when we have
// a reusable async select.
const RSControl = '.react-select__control';
const RSControlOpen =
  '.react-select__control--menu-is-open:not(.react-select__control--is-disabled)';
const RSControlFocused =
  '.react-select__control--is-focused:not(.react-select__control--is-disabled)';
const RSIndicators = '.react-select__indicators';
const RSSelectMenuOption = '.react-select__option';
const RSSelectMenuOptionFocused = '.react-select__option--is-focused';
const RSSelectMenuOptionSelected = '.react-select__option--is-selected';

const StyledAsyncSelect = styled(AsyncSelect)`
  ${RSControl} {
    border: 1px solid var(--border-color);
    color: var(--text-color);
  }

  ${RSIndicators} {
    padding: 0 9px 0 0;
  }

  ${RSControlFocused},
  ${RSControlOpen} {
    box-shadow: 0 0 5px var(--highlight-color);
    border-color: var(--highlight-color);
  }

  ${RSControlOpen} ${StyledDropdownIndicator} {
    transform: rotate(180deg);
  }

  ${RSSelectMenuOptionSelected} {
    background: var(--purple-core) !important;
    color: var(--white) !important;

    [data-theme='dark'] & {
      background: var(--mint-core) !important;
      color: var(--black) !important;
    }
  }

  ${RSSelectMenuOption} {
    color: var(--text-color);
    cursor: pointer;
    background-color: transparent;
    border-radius: var(--border-radius-small);
    transition-property: background-color, color;
    transition: var(--hover-transition) var(--transition-easing);
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;

    font: var(--body-text-small);
    padding: var(--spacing-1) var(--spacing-2);

    &:hover,
    &:active,
    &:focus-visible,
    &${RSSelectMenuOptionFocused} {
      color: var(--link-color);
    }

    &:hover,
    &${RSSelectMenuOptionFocused} {
      background-color: var(--gray-1);
    }
    &:active {
      background-color: var(--gray-2);
    }
    &:focus-visible {
      background-color: var(--gray-1);
    }

    [data-theme='dark'] &:hover,
    [data-theme='dark'] &${RSSelectMenuOptionFocused} {
      background-color: var(--purple-7);
    }
    [data-theme='dark'] &:active {
      background-color: var(--purple-6);
    }
    [data-theme='dark'] &:focus-visible {
      background-color: var(--purple-7);
    }
  }

  // Old styles that I'm leaving alone for now
  .react-select__menu {
    min-width: 100%;
    max-width: 300px;
    width: auto;
  }
`;

const MobileFormKeywordInput = () => {
  const [options, setOptions] = useState([]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { user } = useUser();
  const { hasLabsFlag } = usePSLabs();
  const hasUpdatedComplianceText = hasLabsFlag(POPUP_NEW_COMPLIANCE_TEXT);

  return (
    <StyledAsyncSelect
      classNamePrefix="react-select"
      name="keyword"
      cacheOptions
      loadOptions={async () => {
        const { keywords } = await Filters.getKeywordList();

        const options = keywords
          .filter(({ status }) => status !== 'DISABLED')
          .map(({ keyword_id: id, keyword }) => ({
            label: keyword,
            value: id,
          }));

        setOptions(options);

        // set initial value
        if (isNil(values.keywordId)) {
          setFieldValue('keywordLabel', options[0].label);
          setFieldValue('keywordId', options[0].value);
          setFieldValue(
            'smsMessage',
            generateSubscribeText(
              user.shop_name,
              options[0].label,
              !hasUpdatedComplianceText,
            ),
          );
        }

        return options;
      }}
      onChange={({ value }) => {
        const { label } = findSelectedOption(options, value);
        setFieldValue('keywordId', value);
        setFieldValue('keywordLabel', label);
        setFieldValue(
          'smsMessage',
          generateSubscribeText(
            user.shop_name,
            label,
            !hasUpdatedComplianceText,
          ),
        );
        setFieldTouched('smsMessage');
      }}
      value={findSelectedOption(options, values.keywordId)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      defaultOptions
    />
  );
};

export default MobileFormKeywordInput;
