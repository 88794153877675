import { Emoji } from 'emoji-mart';
import { ProcessNodeDefinitions } from 'html-to-react';
import React from 'react';
import HtmlParser from 'utils/HtmlParser';
import isValid from './isValid';

const customElements: Record<string, any> = {
  'x-emoji': Emoji,
};

const htmlParser = new HtmlParser(React);
const processNodeDefinitions = new ProcessNodeDefinitions(React);

// Custom instructions for processing nodes
const processingInstructions = [
  // Create instruction for custom elements
  {
    shouldProcessNode: (node: any): boolean => {
      // Process the node if it matches a custom element
      return (
        node.name &&
        customElements[node.name] &&
        node.attribs &&
        node.attribs['data-emoji']
      );
    },
    processNode: (node: any): JSX.Element => {
      const CustomElement = customElements[node.name];
      return (
        <CustomElement
          className="preview-emoji"
          emoji={node.attribs['data-emoji']}
          set="apple"
          size={16}
        />
      );
    },
  },
  // Default processing
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

const parseMessageForHtml = (text: string): string => {
  return htmlParser.parseWithInstructions(
    text,
    isValid,
    processingInstructions,
  );
};

export default parseMessageForHtml;
