import { logEvent, PRODUCT_AREAS } from 'utils/events';

export const logNavItemClicked = (
  event: React.MouseEvent<HTMLElement>,
  customText?: string,
): boolean =>
  logEvent('site nav item clicked', {
    href: (event.currentTarget as HTMLAnchorElement).href ?? undefined,
    location: 'site nav',
    productArea: PRODUCT_AREAS.GLOBAL,
    text:
      customText ??
      (event.target as HTMLAnchorElement | HTMLButtonElement).innerText ??
      'Icon or Badge',
  });
