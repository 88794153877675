import { Modal } from '@postscript/components';
import { useGlobalModal } from './globalModal';

export default function GlobalModalContainer(): JSX.Element {
  const { modal, hideModal } = useGlobalModal();

  return (
    <>
      <Modal
        isOpen={!!modal}
        onCancel={hideModal}
        dismissOnBackdropClick={modal?.dismissOnBackdropClick}
        style={modal?.style}
      >
        {modal?.children}
      </Modal>
    </>
  );
}
