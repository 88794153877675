/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Badge,
  BodyText,
  EyebrowText,
  Modal,
  ModalBody,
  ModalHeader,
} from '@postscript/components';
import parseMessageForHtml from 'components/responses/utils/parseMessageForHtml';
import parseMessagesForPreview from 'components/responses/utils/parseMessageForPreview';
import { useState } from 'react';
import styled from 'styled-components';
import { SYSTEM_EVENT_SALES_AI_CLASSIFICATION } from '../constants';
import { Message } from '../types';

const parseMessageText = (message: string) =>
  parseMessageForHtml(parseMessagesForPreview(message));

interface MessageProps {
  message: Message;
}

const StyledSystemMessageLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: currentColor;

  &:hover {
    color: var(--link-color);
    text-decoration: underline;
  }
  &:active {
    color: var(--link-color-hover);
  }
  &:focus-visible {
    color: var(--link-color);
    text-decoration: underline;
  }
`;

const ClassificationText = ({ message, text }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const regex = /:\s*(.*?)\s*\(/;
  const data = message?.eventData;

  let match;

  // Parsing text is specifically for classification events, but not other AI events
  if (
    typeof text === 'string' &&
    message.type === SYSTEM_EVENT_SALES_AI_CLASSIFICATION
  ) {
    match = text.match(regex);
  }

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalCancel = () => {
    setIsOpen(false);
  };

  let Text;

  if (match && match[1] && match.index) {
    const classification = match[1];
    const beforeClassification = text.substring(
      0,
      match.index + match[0].indexOf(classification),
    );
    const afterClassification = text.substring(
      match.index + match[0].indexOf(classification) + classification.length,
    );

    Text = (
      <span>
        {beforeClassification}
        <Badge>{classification}</Badge>
        {afterClassification}
      </span>
    );
  } else {
    Text = <span>{text}</span>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onCancel={handleModalCancel}>
        <ModalHeader onCancel={handleModalCancel}>Event Info</ModalHeader>
        <ModalBody>
          {Object.entries(data)
            .sort(([key1, value1], [key2, value2]) => {
              const getValueLength = (value: any) => {
                try {
                  return JSON.stringify(value).length;
                } catch {
                  return 0;
                }
              };

              const length1 = getValueLength(value1);
              const length2 = getValueLength(value2);

              return length1 - length2; // Sort by ascending length
            })
            .map(([key, value]) => (
              <div style={{ marginBottom: 'var(--spacing-2)' }}>
                <EyebrowText>{key}</EyebrowText>
                {(() => {
                  if (value == null) {
                    return <BodyText size="small">No data available</BodyText>;
                  }

                  // Check if value is an object and not an array
                  if (typeof value === 'object' && !Array.isArray(value)) {
                    try {
                      // Safely stringify the object
                      const jsonString = JSON.stringify(value, null, 2);
                      return (
                        <pre>
                          <code>{jsonString}</code>
                        </pre>
                      );
                    } catch (error) {
                      console.error('Error stringifying object:', error);
                      return (
                        <BodyText size="small">Error displaying data</BodyText>
                      );
                    }
                  }

                  if (typeof value === 'string' || typeof value === 'number') {
                    return <BodyText size="small">{value}</BodyText>;
                  }

                  return (
                    <BodyText size="small">Unsupported data type</BodyText>
                  );
                })()}
              </div>
            ))}
        </ModalBody>
      </Modal>
      <StyledSystemMessageLink onClick={handleModalOpen}>
        {Text}
      </StyledSystemMessageLink>
    </>
  );
};

const ClassificationSystemMessage = ({
  message,
}: MessageProps): JSX.Element => {
  return (
    <ClassificationText
      message={message}
      text={parseMessageText(message.text)}
    />
  );
};

export default ClassificationSystemMessage;
