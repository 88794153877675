/* eslint-disable @typescript-eslint/no-use-before-define */

import {
  StaticComputedStepsTypes,
  StaticStepsTypes,
  StaticStepsWithFormTypes,
  StaticStepsWithInfoTypes,
} from 'components/flowBuilder/constants/steps';
import {
  isAction,
  isActionConfig,
  isActionParams,
  isActionWithForm,
  isStaticAction,
  isStaticActionWithForm,
} from 'components/flowBuilder/types/actions/typeGuards';
import type {
  AutomationTriggerStep,
  AutomationTriggerStepParams,
  BaseStaticStepWithForm,
  BaseStaticStepWithInfo,
  BaseStep,
  BaseStepConfig,
  BaseStepInfo,
  BaseStepParams,
  BaseStepWithForm,
  BaseStepWithInfo,
  BranchInfoStep,
  BranchInfoStepInfo,
  CampaignTriggerStep,
  CampaignTriggerStepParams,
  ReconveningBranchesStep,
  StaticComputedStep,
  StaticComputedStepsType,
  StaticStep,
  StaticStepParams,
  StaticStepsType,
  StaticStepsWithFormType,
  StaticStepsWithInfoType,
  StaticStepWithForm,
  StaticStepWithInfo,
  Step,
  StepConfig,
  StepParams,
  StepWithForm,
  TextToBuyBranchInfoStep,
  TextToBuyBranchInfoStepInfo,
  TriggerStep,
  TriggerStepType,
} from 'components/flowBuilder/types/steps';

/* ------------------------------ Static Steps ------------------------------ */

export const isStaticStepsType = (type: unknown): type is StaticStepsType => {
  return Object.values<unknown>(StaticStepsTypes).includes(type);
};

export const isStaticStepsWithFormType = (
  type: unknown,
): type is StaticStepsWithFormType => {
  return Object.values<unknown>(StaticStepsWithFormTypes).includes(type);
};

export const isStaticStepsWithInfoType = (
  type: unknown,
): type is StaticStepsWithInfoType => {
  return Object.values<unknown>(StaticStepsWithInfoTypes).includes(type);
};

export const isStaticComputedStepsType = (
  type: unknown,
): type is StaticComputedStepsType => {
  return Object.values<unknown>(StaticComputedStepsTypes).includes(type);
};

export const isBaseStaticStepWithForm = (
  step: unknown,
): step is BaseStaticStepWithForm => {
  const stepValue = step as BaseStaticStepWithForm;

  if (!isBaseStepWithForm(stepValue)) return false;
  if (!isStaticStepsWithFormType(stepValue.type)) return false;

  return true;
};

export const isBaseStaticStepWithInfo = (
  step: unknown,
): step is BaseStaticStepWithInfo => {
  const stepValue = step as BaseStaticStepWithInfo;

  if (!isBaseStepWithInfo(stepValue)) return false;
  if (!isStaticStepsWithInfoType(stepValue.type)) return false;

  return true;
};

export const isAutomationTriggerStep = (
  step: unknown,
): step is AutomationTriggerStep => {
  const stepValue = step as AutomationTriggerStep;

  if (!isBaseStaticStepWithForm(stepValue)) return false;
  if (!isAutomationTriggerStepType(stepValue.type)) return false;
  if (!isAutomationTriggerStepParams(stepValue.params)) return false;

  return true;
};

export const isCampaignTriggerStep = (
  step: unknown,
): step is CampaignTriggerStep => {
  const stepValue = step as CampaignTriggerStep;

  if (!isBaseStaticStepWithForm(stepValue)) return false;
  if (!isCampaignTriggerStepType(stepValue.type)) return false;
  if (!isCampaignTriggerStepParams(stepValue.params)) return false;

  return true;
};

export const isTriggerStep = (step: unknown): step is TriggerStep => {
  return isAutomationTriggerStep(step) || isCampaignTriggerStep(step);
};

export const isStaticStepWithForm = (
  step: unknown,
): step is StaticStepWithForm => {
  return isStaticActionWithForm(step) || isTriggerStep(step);
};

export const isBranchInfoStep = (step: unknown): step is BranchInfoStep => {
  const stepValue = step as BranchInfoStep;

  if (!isBaseStaticStepWithInfo(stepValue)) return false;
  if (!isBranchInfoStepType(stepValue.type)) return false;
  if (!isBranchInfoStepInfo(stepValue.info)) return false;

  return true;
};

export const isTextToBuyBranchInfoStep = (
  step: unknown,
): step is TextToBuyBranchInfoStep => {
  const stepValue = step as TextToBuyBranchInfoStep;

  if (!isBaseStaticStepWithInfo(stepValue)) return false;
  if (!isTextToBuyBranchInfoStepType(stepValue.type)) return false;
  if (!isTextToBuyBranchInfoStepInfo(stepValue.info)) return false;

  return true;
};

export const isStaticStepWithInfo = (
  step: unknown,
): step is StaticStepWithInfo => {
  return isBranchInfoStep(step) || isTextToBuyBranchInfoStep(step);
};

export const isReconveningBranchesStep = (
  step: unknown,
): step is ReconveningBranchesStep => {
  const stepValue = step as ReconveningBranchesStep;

  if (!isBaseStep(stepValue)) return false;
  if (!isReconveningBranchesStepType(stepValue.type)) return false;

  return true;
};

export const isStaticComputedStep = (
  step: unknown,
): step is StaticComputedStep => {
  return isStaticStepWithInfo(step) || isReconveningBranchesStep(step);
};

export const isStaticStep = (step: unknown): step is StaticStep => {
  return (
    isStaticAction(step) ||
    isStaticStepWithForm(step) ||
    isStaticComputedStep(step)
  );
};

/* --------------------------------- Steps ---------------------------------- */

export const isBaseStep = (step: unknown): step is BaseStep => {
  const stepValue = step as BaseStep;

  if (!stepValue || typeof stepValue !== 'object') return false;
  if (!stepValue.guid || typeof stepValue.guid !== 'string') return false;
  if (!stepValue.type || typeof stepValue.type !== 'string') return false;
  if (!stepValue.next || !Array.isArray(stepValue.next)) return false;
  if (stepValue.next.some((guid) => !guid || typeof guid !== 'string'))
    return false;

  return true;
};

export const isBaseStepWithForm = (step: unknown): step is BaseStepWithForm => {
  const stepValue = step as BaseStepWithForm;

  if (!isBaseStep(stepValue)) return false;
  if (!isBaseStepParams(stepValue.params)) return false;

  return true;
};

export const isBaseStepWithInfo = (step: unknown): step is BaseStepWithInfo => {
  const stepValue = step as BaseStepWithInfo;

  if (!isBaseStep(stepValue)) return false;
  if (!isBaseStepInfo(stepValue.info)) return false;

  return true;
};

export const isStepWithForm = (step: unknown): step is StepWithForm => {
  return isActionWithForm(step) || isStaticStepWithForm(step);
};

export const isStep = (step: unknown): step is Step => {
  return isAction(step) || isStaticStep(step);
};

/* --------------------------- Static Step Types ---------------------------- */

export const isAutomationTriggerStepType = (
  type: unknown,
): type is typeof StaticStepsTypes.AUTOMATION_TRIGGER => {
  return type === StaticStepsTypes.AUTOMATION_TRIGGER;
};

export const isCampaignTriggerStepType = (
  type: unknown,
): type is typeof StaticStepsTypes.CAMPAIGN_TRIGGER => {
  return type === StaticStepsTypes.CAMPAIGN_TRIGGER;
};

export const isTriggerStepType = (type: unknown): type is TriggerStepType => {
  return isAutomationTriggerStepType(type) || isCampaignTriggerStepType(type);
};

export const isBranchInfoStepType = (
  type: unknown,
): type is typeof StaticStepsTypes.BRANCH_INFO => {
  return type === StaticStepsTypes.BRANCH_INFO;
};

export const isTextToBuyBranchInfoStepType = (
  type: unknown,
): type is typeof StaticStepsTypes.TEXT_TO_BUY_BRANCH_INFO => {
  return type === StaticStepsTypes.TEXT_TO_BUY_BRANCH_INFO;
};

export const isReconveningBranchesStepType = (
  type: unknown,
): type is typeof StaticStepsTypes.RECONVENING_BRANCHES => {
  return type === StaticStepsTypes.RECONVENING_BRANCHES;
};

/* --------------------------- Static Step Params --------------------------- */

export const isAutomationTriggerStepParams = (
  params: unknown,
): params is AutomationTriggerStepParams => {
  const paramsValue = params as AutomationTriggerStepParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!paramsValue.triggerEvents || !Array.isArray(paramsValue.triggerEvents))
    return false;
  if (!paramsValue.cancelEvents || !Array.isArray(paramsValue.cancelEvents))
    return false;

  return true;
};

export const isCampaignTriggerStepParams = (
  params: unknown,
): params is CampaignTriggerStepParams => {
  const paramsValue = params as CampaignTriggerStepParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.segmentIds)) return false;
  if (paramsValue.segmentIds.some((segmentId) => typeof segmentId !== 'number'))
    return false;
  if (!Array.isArray(paramsValue.excludeSegmentIds)) return false;
  if (
    paramsValue.excludeSegmentIds.some(
      (segmentId) => typeof segmentId !== 'number',
    )
  )
    return false;
  if (typeof paramsValue.safeSend !== 'boolean') return false;
  if (!Array.isArray(paramsValue.cancelEvents)) return false;

  return true;
};

export const isStaticStepParams = (
  params: unknown,
): params is StaticStepParams => {
  return (
    isAutomationTriggerStepParams(params) || isCampaignTriggerStepParams(params)
  );
};

/* ------------------------------ Step Params ------------------------------- */

export const isBaseStepParams = (params: unknown): params is BaseStepParams => {
  return !!params && typeof params === 'object';
};

export const isStepParams = (params: unknown): params is StepParams => {
  return isActionParams(params) || isStaticStepParams(params);
};

/* ---------------------------- Static Step Info ---------------------------- */

export const isBranchInfoStepInfo = (
  info: unknown,
): info is BranchInfoStepInfo => {
  const infoValue = info as BranchInfoStepInfo;

  if (!isBaseStepInfo(infoValue)) return false;
  if (typeof infoValue.index !== 'number') return false;

  return true;
};

export const isTextToBuyBranchInfoStepInfo = (
  info: unknown,
): info is TextToBuyBranchInfoStepInfo => {
  const infoValue = info as TextToBuyBranchInfoStepInfo;

  if (!isBaseStepInfo(infoValue)) return false;

  return true;
};

/* ------------------------------- Step Info -------------------------------- */

export const isBaseStepInfo = (info: unknown): info is BaseStepInfo => {
  return !!info && typeof info === 'object';
};

/* ------------------------------ Step Config ------------------------------ */

export const isBaseStepConfig = (config: unknown): config is BaseStepConfig => {
  return !!config && typeof config === 'object';
};

export const isStepConfig = (config: unknown): config is StepConfig => {
  return isActionConfig(config);
};
