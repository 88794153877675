import { toast } from '@postscript/components';
import { api } from 'controllers/network/apiClient';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

export interface SpendNotificationSettings {
  enabled: boolean;
  thresholdAmount: number; // cents
}

export const SPEND_NOTIFICATION_SETTINGS = 'spend_notification_settings';

const staleTime = 1000 * 60;

function getSpendNotificationSettings(): Promise<SpendNotificationSettings> {
  return api.get(
    '/v2/billing/transactional_messaging/usage_spend_alert_settings',
  );
}

export const useGetSpendNotificationSettings = (
  options?: UseQueryOptions<
    SpendNotificationSettings,
    any,
    SpendNotificationSettings,
    [typeof SPEND_NOTIFICATION_SETTINGS]
  >,
): UseQueryResult<SpendNotificationSettings> => {
  return useQuery([SPEND_NOTIFICATION_SETTINGS], getSpendNotificationSettings, {
    ...options,
    staleTime,
  });
};

function setSpendNotificationSettings(
  settings: SpendNotificationSettings,
): Promise<SpendNotificationSettings> {
  return api.post(
    '/v2/billing/transactional_messaging/usage_spend_alert_settings',
    settings,
  );
}

export const useSetSpendNotificationSettings = (): UseMutationResult<
  SpendNotificationSettings,
  any,
  SpendNotificationSettings
> => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: SpendNotificationSettings) => setSpendNotificationSettings(values),
    {
      onSuccess: () => queryClient.refetchQueries(SPEND_NOTIFICATION_SETTINGS),
      onError: toast.error,
    },
  );

  return mutation;
};
