/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import {
  Banner,
  BodyText,
  Button,
  LinkText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from '@postscript/components';
import { TableItemProps } from '@postscript/components/dist/esm/components/advanced/Table/types';
import {
  CHARACTER_COUNT_HELP_URL,
  TYPICAL_CARRIER_FEES_BY_COUNTRY,
} from 'components/billing/common/constants';
import {
  MessageRate,
  MessageTypes,
  TypicalCarrierFeesCountryCodes,
} from 'components/billing/common/types';
import {
  findMessageRateByCodeAndType,
  formatMessageRate,
} from 'components/billing/common/utils';
import { HELP_ARTICLES } from 'constants/constants';
import { uniqBy } from 'lodash';
import { useEffect } from 'react';
import styled from 'styled-components';
import {
  COMMON_EVENT_NAMES,
  logEvent,
  MODAL_TYPES,
  PRODUCT_AREAS,
} from 'utils/events';

interface CombinedMessageRate {
  countryCode: string;
  countryName: string;
  smsRate: number;
  mmsRate: number;
}

export function combineMessageRates(
  messageRates: MessageRate[],
  fallbackMessageRates: { [key: string]: CombinedMessageRate },
): CombinedMessageRate[] {
  const rates: CombinedMessageRate[] = [];

  const uniqueRatesByCountry = uniqBy(messageRates, 'countryCode');

  const getRateByCountryAndType = (
    countryCode: string,
    type: MessageTypes,
  ): number => {
    const fallbackRate = fallbackMessageRates[countryCode];

    const messageType = type === 'SMS' ? 'smsRate' : 'mmsRate';

    return (
      messageRates.find(
        (messageRate) =>
          messageRate.countryCode === countryCode &&
          messageRate.messageType === type,
      )?.messageRate ||
      fallbackRate?.[messageType] ||
      0
    );
  };

  for (const rate of uniqueRatesByCountry) {
    const { countryCode } = rate;

    rates.push({
      countryCode,
      countryName: rate.countryName || 'All other countries',
      smsRate: getRateByCountryAndType(countryCode, 'SMS'),
      mmsRate: getRateByCountryAndType(countryCode, 'MMS'),
    });
  }

  return rates;
}

const StyledFooterFineprint = styled(BodyText)`
  margin-bottom: 0;
  margin-right: auto;
  align-self: center;
`;

const TableItem = ({
  messageRate,
  carrierFee,
}: {
  messageRate: number;
  carrierFee: number | undefined;
}) => (
  <Table.ItemNumber
    size="small"
    primary={!messageRate ? 'N/A' : formatMessageRate(messageRate)}
    secondary={
      carrierFee ? (
        <>
          Typical carrier fees:
          <br />
          {formatMessageRate(carrierFee)}
        </>
      ) : null
    }
  />
);

interface Props {
  messageRates: MessageRate[];
  smsMultiplier: number;
  mmsMultiplier: number;
  close: () => void;
}

export default function AllMessageRatesModal({
  messageRates,
  smsMultiplier,
  mmsMultiplier,
  close,
}: Readonly<Props>): JSX.Element {
  const fallbackMessageRates = {
    US: {
      countryCode: 'US',
      countryName: 'United States',
      smsRate: smsMultiplier,
      mmsRate: mmsMultiplier,
    },
    CA: {
      countryCode: 'CA',
      countryName: 'Canada',
      smsRate: smsMultiplier,
      mmsRate: mmsMultiplier,
    },
    PR: {
      countryCode: 'PR',
      countryName: 'Puerto Rico',
      smsRate:
        findMessageRateByCodeAndType(messageRates, '*', 'SMS') ||
        smsMultiplier * 10,
      mmsRate: 0,
    },
    '*': {
      countryCode: '*',
      countryName: 'All other countries',
      smsRate: smsMultiplier * 10,
      mmsRate: 0,
    },
  };

  // We pass fallbacks into the combine function because it's possible that a shop has an SMS rate for a country but not an MMS rate, etc. so we combine with a fallback
  const combinedMessageRates = combineMessageRates(
    messageRates,
    fallbackMessageRates,
  );

  const findCombinedRate = (countryCode: string) => {
    return combinedMessageRates.find(
      (rate) => rate.countryCode === countryCode,
    );
  };

  // Default set of countries codes that we always show
  const defaultCountryCodes = ['US', 'CA', 'PR', '*'];

  const allOtherRates = combinedMessageRates.filter(
    ({ countryCode }) => !defaultCountryCodes.includes(countryCode),
  );

  const sortedRates: CombinedMessageRate[] = [
    findCombinedRate('US') || fallbackMessageRates.US,
    findCombinedRate('CA') || fallbackMessageRates.CA,
    findCombinedRate('PR') || fallbackMessageRates.PR,
    ...allOtherRates,
    findCombinedRate('*') || fallbackMessageRates['*'],
  ];

  const tableRows: TableItemProps[] = [];

  sortedRates.map(({ countryCode, countryName, smsRate, mmsRate }) => {
    const typicalCarrierFees =
      TYPICAL_CARRIER_FEES_BY_COUNTRY[
        countryCode as TypicalCarrierFeesCountryCodes
      ];

    tableRows.push({
      nameColumn: {
        text: countryName,
      },
      dataColumns: [
        <TableItem
          messageRate={smsRate}
          carrierFee={typicalCarrierFees?.sms}
        />,
        <TableItem
          messageRate={mmsRate}
          carrierFee={typicalCarrierFees?.mms}
        />,
      ],
    });
  });

  useEffect(() => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: MODAL_TYPES.BILLING_ALL_MESSAGE_RATES,
      product_area: PRODUCT_AREAS.BILLING,
    });
  }, []);

  return (
    <>
      <ModalHeader onCancel={close}>All message rates</ModalHeader>
      <ModalBody>
        <Banner
          variant="guidance"
          bodyText={
            <>
              Not all countries or mobile carriers support delivery of images
              (MMS), and messages may be sent as plain text and emojis (SMS).
              Multi-part messages, emojis, and images impact the number of{' '}
              <LinkText
                href={CHARACTER_COUNT_HELP_URL}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                characters allowed
              </LinkText>{' '}
              per message.
            </>
          }
          style={{ marginBottom: 'var(--spacing-3)' }}
        />
        <Table
          id="message-rates-table"
          size="small"
          cardWrapper={false}
          globalColumnSettings={{
            name: { heading: 'Country' },
            dataColumns: [
              {
                heading: 'Cost per SMS*',
              },
              {
                heading: 'Cost per MMS*',
              },
            ],
          }}
          items={tableRows}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="text" onClick={close}>
          Close
        </Button>
        <StyledFooterFineprint size="x-small">
          &#42;Carrier fees vary.{' '}
          <LinkText
            href={HELP_ARTICLES.carrierFees}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Learn More
          </LinkText>
        </StyledFooterFineprint>
      </ModalFooter>
    </>
  );
}
