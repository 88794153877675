/* eslint-disable camelcase */
export const States = ['warning', 'disabled', 'enabled', 'remove'] as const;
export type StateType = typeof States[number];

export const MMSFilters = ['all', 'tfn', 'shortcode', 'shopId'] as const;
export type MMSFilterType = typeof MMSFilters[number];

export const AnnouncementType = {
  MESSAGE_PLANNER: 'message-planner',
  APP_WIDE: 'app-wide',
} as const;

export type Banner = {
  target: MMSFilterType;
  id: number;
  title: string;
  message: string;
  idList?: string;
  ctaUrl?: string;
  ctaText?: string;
  color?: string;
  startAt?: string;
  endAt?: string;
  enabled?: boolean;
  announcementType?: string;
  icon?: string;
  iconColor?: string;
};

export type MMSBanner = Banner & {
  state: StateType;
};

export const MMSFilterNames = {
  all: 'all Postscript users',
  tfn: 'TFNs',
  shortcode: 'Shortcodes',
  shopId: 'the specified shop',
} as const;

export const formatShopIDs = (shopIDs?: number[]): string | undefined => {
  if (!shopIDs) return undefined;

  // eslint-disable-next-line no-useless-escape
  const shopIDsWithBrackets = `[${shopIDs.join(',').replace(/[\[\]']+/g, '')}]`;

  return shopIDsWithBrackets;
};

export const validateShopIDs = (shopIDs?: string): number[] | undefined => {
  try {
    if (!shopIDs) return undefined;

    // eslint-disable-next-line no-useless-escape
    const trimmedShopIDs = shopIDs.replace(/[\[\]']+/g, '');

    const stringList = trimmedShopIDs.split(',');
    const list: number[] = stringList.map((str) => {
      const num = Number(str);
      if (!Number.isNaN(num)) {
        return num;
      }
      throw new Error();
    });

    if (list.length > 0) return list;

    return undefined;
  } catch (_: any) {
    return undefined;
  }
};

export const DEFAULT_COLOR = 'var(--gray-2)';

export const isMessagePlannerAnnouncement = (banner: Banner): boolean =>
  banner.announcementType === AnnouncementType.MESSAGE_PLANNER;
