import { Banner } from '@postscript/components';
import {
  useGetCurrentAndNextCycles,
  useGetCurrentPlan,
  useGetNextPlan,
} from 'components/billing/context/useBilling';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import CancelPlanChangeModal from './CancelPlanChangeModal';

const StyledBanner = styled(Banner)`
  margin-bottom: var(--spacing-3);
`;

export default function PlanChangeBanner(): JSX.Element | null {
  const { data: currentPlan } = useGetCurrentPlan();
  const { data: nextPlan } = useGetNextPlan();
  const { data: { nextCycle } = {} } = useGetCurrentAndNextCycles();
  const { showModal } = useGlobalModal();

  if (!currentPlan?.package || !nextPlan?.package || !nextCycle) {
    return null;
  }

  const nextPackageId = nextPlan.package.id;
  const currentPackageId = currentPlan.package.id;
  const nextCycleHasPackageChange = nextPackageId !== currentPackageId;
  const packageChangeWasScheduledByAdmin = Boolean(
    nextCycle.scheduledPlanChange,
  );

  if (!nextCycleHasPackageChange) return null;

  const nextPackageName = nextPlan.package.name;
  const currentPackageName = currentPlan.package.name;

  return (
    <StyledBanner
      variant="guidance"
      bodyText={
        <>
          Your plan change from <strong>{currentPackageName}</strong> to{' '}
          <strong>{nextPackageName}</strong> will go into effect on{' '}
          {DateTime.fromISO(nextCycle.startDate).toLocaleString(
            DateTime.DATE_MED,
          )}
          .
        </>
      }
      primaryAction={
        packageChangeWasScheduledByAdmin
          ? undefined
          : {
              text: 'Cancel Plan Change',
              onClick: () =>
                showModal({
                  dismissOnBackdropClick: true,
                  children: <CancelPlanChangeModal />,
                }),
            }
      }
    />
  );
}
