import { Button, toast } from '@postscript/components';
import useImageFileUpload from 'components/flowBuilder/hooks/useImageFileUpload';
import { formatErrorMessage } from 'components/flowBuilder/utils/errors';
import { useFormikContext } from 'formik';
import { useRef } from 'react';
import styled from 'styled-components';
import { User } from '../types';

const maxImageSize = 5000000; // 5 MB
const maxImageSizeError = 'File size must be less than 5 MB';
const imageUploadUrl = '/user/image/upload';

const AvatarWrapper = styled.div`
  padding-top: 6px;
  text-align: center;
`;

const AvatarContainer = styled.div`
  background-color: var(--gray-2);
  background-size: cover;
  background-position: center center;
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius-round);
  margin-bottom: var(--spacing-1);
`;

export default function Avatar(): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isUploadingImageFile, uploadImageFile] = useImageFileUpload({
    maxImageSize,
    maxImageSizeError,
    imageUploadUrl,
  });
  const { values, setFieldValue } = useFormikContext<User>();
  const { profile } = values;

  const onFileChange = async (file?: File) => {
    try {
      if (!file) return;
      setFieldValue('profile.avatarUrl', await uploadImageFile(file));
    } catch (error) {
      toast.error(formatErrorMessage(error));
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <AvatarWrapper>
      <AvatarContainer
        style={{
          backgroundImage: `url(${profile?.avatarUrl})`,
        }}
      />
      <Button
        type="button"
        size="small"
        variant="text"
        onClick={triggerFileInput}
        disabled={isUploadingImageFile}
      >
        {profile?.avatarUrl ? (
          'Replace'
        ) : (
          <>
            Upload
            <br />
            Avatar
          </>
        )}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={({ currentTarget: { files } }) => {
          onFileChange(files?.[0]);
        }}
        hidden
      />
    </AvatarWrapper>
  );
}
