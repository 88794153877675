/* eslint-disable react/prop-types */
import { useRouteMatch, withRouter } from 'react-router-dom';

const DelightedRating = ({ user, location: { pathname } }) => {
  if (
    !window.delighted ||
    !useRouteMatch({ path: pathname, exact: true }) ||
    !user.postscript_contact_email ||
    !user.has_onboarded
  ) {
    return null;
  }

  return (
    <div>
      {window.delighted.survey({
        email: user.postscript_contact_email,
        properties: {
          shop_id: user.email,
        },
      })}
    </div>
  );
};

export default withRouter(DelightedRating);
