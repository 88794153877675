import { Button, ModalBody, ModalFooter, toast } from '@postscript/components';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useState } from 'react';
import { logEvent } from 'utils/events';

export default function PaymentMethodDeleteConfirmation(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const {
    stepData: { paymentMethodToDeleteId },
    defaultPaymentMethodId,
    paymentMethods,
    updateStep,
    deletePaymentMethod,
    getPaymentMethods,
  } = usePaymentMethods();

  const paymentMethodType =
    paymentMethods.find(({ id }) => id === paymentMethodToDeleteId)?.card !==
    null
      ? 'card'
      : 'ach';

  const deleteMethod = async () => {
    try {
      if (
        !paymentMethodToDeleteId ||
        paymentMethodToDeleteId === defaultPaymentMethodId
      ) {
        return toast.error('Cannot delete default payment method.');
      }

      setIsLoading(true);

      await deletePaymentMethod(paymentMethodToDeleteId);

      logEvent('payment method removed', {
        payment_method_type: paymentMethodType,
      });

      await getPaymentMethods(); // Updates the list of all available payment methods here

      updateStep('management');
      toast.success('Payment method deleted.');
    } catch (error) {
      setIsLoading(false);
      toast.error(error as string);
    }
  };

  return (
    <>
      <ModalBody>
        Are you sure you want to delete this payment method? You can add this
        method again if you want to use it in the future.
      </ModalBody>
      <ModalFooter>
        <Button
          variant="delete"
          onClick={deleteMethod}
          disabled={isLoading}
          data-cy="confirm-delete-button"
        >
          Delete Payment Method
        </Button>
      </ModalFooter>
    </>
  );
}
