import { Heading, Icon, IconSet } from '@postscript/components';
import styled from 'styled-components';

interface Props {
  shouldShowCurrentBadge?: boolean;
  children: string;
  className?: string;
}

const StyledHeading = styled(Heading).attrs({
  forwardedAs: 'h3',
  size: 'small',
  color: 'var(--highlight-color)',
})`
  display: inline-block;
`;

const StyledIcon = styled(Icon).attrs({
  size: 'large',
  color: 'var(--green-5)',
})`
  vertical-align: sub;
  margin: 0 0 0 var(--spacing-2);
  transform: scale(1.3);
`;

export default function PlanHeading({
  children,
  shouldShowCurrentBadge,
  className,
}: Props): JSX.Element {
  return (
    <div className={className}>
      <StyledHeading data-cy="plan-name">{children}</StyledHeading>
      {shouldShowCurrentBadge ? (
        <StyledIcon component={IconSet.CheckCircle} />
      ) : null}
    </div>
  );
}
