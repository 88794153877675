/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { Button, Layout, SelectMenu, Table } from '@postscript/components';
import FormRow from 'components/forms/FormRow';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import {
  SalesShopData,
  ShopDashboardResponse,
} from 'components/sales/hooks/useDashboard';
import { api } from 'controllers/network/apiClient';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import { setTitleWithCount } from '../utils/setTitleWithCount';

const FormColumn = styled(FormGroup).attrs({
  className: 'mr-2',
})`
  min-width: 400px;
  flex: auto;
  @media (min-width: 1200px) {
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  place-items: center;
  gap: var(--spacing-1);
`;

const StyledLayout = styled(Layout)`
  margin-bottom: var(--spacing-3);

  & > div {
    width: 100%;
  }
`;

const PAGE_LIMIT = 20;

const SortColumns = {
  NUM_TOTAL_PROSPECTS: 'num_total_prospects',
  PROSPECTS_PER_AGENT: 'prospects_per_agent',
  OLDEST_PROSPECT: 'oldest_prospect',
} as const;

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

const SortOptions = [
  {
    label: 'Oldest Response',
    value: {
      sortColumn: SortColumns.OLDEST_PROSPECT,
      sortOrder: SortOrders.DESC,
    },
  },
  {
    label: 'Total Prospects',
    value: {
      sortColumn: SortColumns.NUM_TOTAL_PROSPECTS,
      sortOrder: SortOrders.DESC,
    },
  },
  {
    label: 'Prospect : Agent Ratio',
    value: {
      sortColumn: SortColumns.PROSPECTS_PER_AGENT,
      sortOrder: SortOrders.DESC,
    },
  },
] as const;

type SortOption = typeof SortOptions[number];

interface ShopListItemProps {
  shop: SalesShopData;
}

export const getPercentText = (numerator: number, denominator: number) => {
  if (denominator === 0 || numerator === 0) {
    return '--';
  }
  return `${((Math.max(0, numerator) / denominator) * 100).toFixed(2)} %`;
};

const roundAgentsPerShop = (num: number): string => {
  const rounded = num.toFixed(1);
  return rounded.endsWith('.0') ? String(parseInt(rounded)) : rounded;
};

export const getShopRow = ({ shop }: ShopListItemProps) => {
  const {
    name,
    id,
    total_unresolved_messages,
    unassigned_unresolved_messages,
    online_agent_ids,
    oldest_prospect_receive_time,
  } = shop;

  const assignedProspects = Math.max(
    0,
    total_unresolved_messages - unassigned_unresolved_messages,
  );
  const totalProspects = Math.max(0, total_unresolved_messages);
  const relativeTime = oldest_prospect_receive_time
    ? moment.utc(oldest_prospect_receive_time).fromNow(true)
    : '--';

  return (
    <Table.Item
      key={id}
      dataColumns={[
        <Table.ItemNumber
          key="prospects"
          primary={`${totalProspects} prospect${
            totalProspects === 1 ? '' : 's'
          }`}
          secondary={`${assignedProspects} assigned`}
        />,
        <Table.ItemNumber
          key="ratios"
          primary={
            online_agent_ids.length > 0
              ? `${roundAgentsPerShop(
                  totalProspects / online_agent_ids.length,
                )} : 1`
              : `${totalProspects} : 0`
          }
          secondary={`${online_agent_ids.length} agent${
            online_agent_ids.length === 1 ? '' : 's'
          }`}
        />,
        <Table.ItemNumber key="oldest-prospect" primary={relativeTime} />,
      ]}
      nameColumn={{
        description: id,
        text: <strong>{name}</strong>,
      }}
    />
  );
};

interface ShopListProps {
  data: ShopDashboardResponse;
  isLoading: boolean;
}

interface SortConfig {
  column: string;
  order: string;
}

const prospectsPerAgentSort = (
  a: SalesShopData,
  b: SalesShopData,
  asc: boolean,
) => {
  const aProspectsPerAgent =
    a.online_agent_ids.length === 0
      ? a.total_unresolved_messages
      : a.total_unresolved_messages / a.online_agent_ids.length;

  const bProspectsPerAgent =
    b.online_agent_ids.length === 0
      ? b.total_unresolved_messages
      : b.total_unresolved_messages / b.online_agent_ids.length;

  if (asc) {
    return aProspectsPerAgent - bProspectsPerAgent;
  }
  return bProspectsPerAgent - aProspectsPerAgent;
};

const oldestProspectSort = (
  a: SalesShopData,
  b: SalesShopData,
  asc: boolean,
) => {
  if (!a.oldest_prospect_receive_time && !b.oldest_prospect_receive_time)
    return 0;
  if (!a.oldest_prospect_receive_time) return 1;
  if (!b.oldest_prospect_receive_time) return -1;

  const dateA = moment(a.oldest_prospect_receive_time);
  const dateB = moment(b.oldest_prospect_receive_time);

  if (asc) {
    return dateA.diff(dateB);
  }
  return dateB.diff(dateA);
};

const totalProspectsSort = (
  a: SalesShopData,
  b: SalesShopData,
  asc: boolean,
) => {
  if (asc) {
    return a.total_unresolved_messages - b.total_unresolved_messages;
  }
  return b.total_unresolved_messages - a.total_unresolved_messages;
};

const validationSchema = Yup.object().shape({
  shopIds: Yup.array()
    .of(Yup.number())
    .required('Please select at least one shop'),
});

interface Option {
  label?: string;
  value: number;
}

interface AllShopsResponse {
  shops: Option[];
}

const ShopList = ({ data, isLoading }: ShopListProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortOptions, setSortOptions] = useState<SortConfig>({
    column: SortColumns.OLDEST_PROSPECT,
    order: SortOrders.DESC,
  });

  const { data: shops } = useQuery({
    queryKey: ['sms-sales-shops'],
    queryFn: (): Promise<AllShopsResponse> => api.get('/admin/get_all_shops'),
    select: (data) => data.shops,
  });

  const searchParams = new URLSearchParams(window.location.search);

  const shopsFilter = searchParams.get('shops') ?? '';
  const shopIdParams = shopsFilter.split(',').filter((shopId) => shopId !== '');

  const shopData: SalesShopData[] = data?.shops.filter((shop) => {
    if (!shopIdParams || shopIdParams.length === 0) {
      return true;
    }
    return shopIdParams.map((shopId) => +shopId).includes(shop.id);
  });

  const totalProspects =
    shopData.length === 0
      ? 0
      : shopData
          .map((shop) => shop.total_unresolved_messages)
          .reduce((a, b) => a + b);

  // Need to set the page title with the total prospect count
  setTitleWithCount(totalProspects);

  const uniqueAgents = new Set<number>();
  shopData.forEach((shop) => {
    shop.online_agent_ids.forEach((agentId) => {
      uniqueAgents.add(agentId);
    });
  });
  const totalUniqueAgents = uniqueAgents.size;

  const options = useMemo(() => {
    if (!shops) return [];

    const filterShops = (shop: Option) => {
      const shopName = shop.label?.toLowerCase();
      const shopId = shop.value.toString();
      const searchString = search.toLowerCase();
      return (
        shopId.startsWith(searchString) || shopName?.includes(searchString)
      );
    };

    const formatShop = (shop: Option) => ({
      label: `${shop.label} (${shop.value})`,
      value: shop.value,
    });

    const allShops = shops.filter(filterShops).map(formatShop).slice(0, 50);

    return [
      {
        label: 'All',
        options: allShops,
      },
    ];
  }, [shops, search]);

  const sortedShopData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return shopData.sort((a, b) => {
      // Prospects per agent sort
      if (sortOptions.column === SortColumns.PROSPECTS_PER_AGENT) {
        return prospectsPerAgentSort(
          a,
          b,
          sortOptions.order === SortOrders.ASC,
        );
      }

      // Total prospects sorting
      if (sortOptions.column === SortColumns.NUM_TOTAL_PROSPECTS) {
        return totalProspectsSort(a, b, sortOptions.order === SortOrders.ASC);
      }

      // Oldest prospect sorting
      if (sortOptions.column === SortColumns.OLDEST_PROSPECT) {
        return oldestProspectSort(a, b, sortOptions.order === SortOrders.DESC);
      }
      return 0;
    });
  }, [sortOptions, data]);

  const ratio =
    totalUniqueAgents === 0
      ? `${totalProspects} : 0`
      : `${roundAgentsPerShop(totalProspects / totalUniqueAgents)} : 1`;

  return (
    <>
      <Formik<{ shopIds: number[] }>
        initialValues={{
          shopIds: shopIdParams.map((shopId) => +shopId),
        }}
        onSubmit={(values) => {
          if (!values.shopIds) {
            return;
          }
          const shopIdsQueryParam = values.shopIds.join(',');

          const searchParams = new URLSearchParams(window.location.search);

          searchParams.set('shops', shopIdsQueryParam);

          const baseUrl = window.location.origin + window.location.pathname;

          if (values.shopIds.length === 0) {
            window.location.href = baseUrl;
            return;
          }

          window.location.href = `${baseUrl}?${searchParams.toString()}`;
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, isSubmitting }) => (
          <StyledLayout>
            <Form>
              <FormRow>
                <FormColumn>
                  <Field
                    name="shopIds"
                    as={SelectMenu}
                    options={options}
                    onChange={(value: Option[]) =>
                      setFieldValue(
                        'shopIds',
                        value.map((v) => v.value),
                      )
                    }
                    value={shops?.filter((shop) =>
                      values.shopIds.includes(shop.value),
                    )}
                    placeholder="Select shops"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    onInputChange={setSearch}
                    inputValue={search}
                    isMulti
                  />
                </FormColumn>
              </FormRow>

              <Container style={{ marginLeft: 'auto' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setFieldValue('shopIds', []);
                    submitForm();
                  }}
                >
                  Clear
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Filter
                </Button>
              </Container>
            </Form>
          </StyledLayout>
        )}
      </Formik>
      <Table
        filters={
          <SelectMenu
            fieldWidth={250}
            isClearable={false}
            onChange={(option) => {
              const { sortColumn, sortOrder } = (option as SortOption).value;
              setCurrentPage(1);
              setSortOptions({ column: sortColumn, order: sortOrder });
            }}
            options={Object.values(SortOptions)}
            value={Object.values(SortOptions).find(
              ({ value }) =>
                value.sortColumn === sortOptions.column &&
                value.sortOrder === sortOptions.order,
            )}
          />
        }
        globalColumnSettings={{
          dataColumns: [
            {
              heading: `Prospects (${totalProspects})`,
              headingTooltip: 'Total prospects for all shops',
            },
            {
              heading: `Ratios (${ratio})`,
              headingTooltip: 'Overall ratio of prospects to agents',
            },
            {
              heading: 'Oldest Response',
            },
          ],
          name: {
            heading: 'Name',
            minWidth: 100,
          },
        }}
        emptyState={{
          heading: "There's nothing here",
          description: 'No shops exist',
        }}
        id="shop-list-table"
        isEmpty={(data?.shops || []).length === 0}
        isLoading={isLoading}
        labelledBy="shop-list-table"
        loadingState={<LoadingSpinner>Loading shops...</LoadingSpinner>}
        size="medium"
        pagination={{
          currentPage,
          selectPage: setCurrentPage,
          totalPages: Math.ceil(((shopData || []).length ?? 0) / PAGE_LIMIT),
        }}
      >
        {sortedShopData
          .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
          .map((shop) => {
            return getShopRow({
              shop,
            });
          })}
      </Table>
    </>
  );
};

export default ShopList;
