import { api } from 'controllers/network/apiClient';
import { isNaN, isNumber } from 'lodash';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { PopupSchedule, PopupTypes } from '../types';
import { Popup } from './usePopups';

const POPUPS_QUERY = 'popups';
const BASE_URL = '/v2/popups';

export const usePopup = (id?: Popup['id'] | string): UseQueryResult<Popup> => {
  const isValidId: boolean = (id && isNumber(+id) && !isNaN(+id)) || false;
  const popupId: Popup['id'] | undefined = id ? +id : undefined;
  return useQuery([POPUPS_QUERY, popupId], () => api.get(`${BASE_URL}/${id}`), {
    enabled: isValidId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreatePopup = (): UseMutationResult<
  Popup,
  unknown,
  PopupTypes
> => {
  const client = useQueryClient();
  return useMutation(
    (type: PopupTypes) => {
      return api.post(`${BASE_URL}`, {
        popup_type: type,
      });
    },
    {
      onSettled: () => {
        client.invalidateQueries(POPUPS_QUERY);
      },
    },
  );
};

export const useClonePopup = (
  id?: Popup['id'],
): UseMutationResult<Popup, unknown, void> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      if (!id) throw new Error('No popup id provided');
      return api.post(`${BASE_URL}/${id}/clone`);
    },
    {
      onSettled: () => client.invalidateQueries(POPUPS_QUERY),
    },
  );
};

export const useRemovePopup = (
  id?: Popup['id'],
): UseMutationResult<Popup, unknown, void> => {
  const client = useQueryClient();
  return useMutation(
    () => {
      if (!id) throw new Error('No popup id provided');
      return api.delete(`${BASE_URL}/${id}`);
    },
    {
      onSettled: () => client.invalidateQueries(POPUPS_QUERY),
    },
  );
};

export const useUpdatePopup = (
  id?: Popup['id'],
): UseMutationResult<Popup, unknown, Partial<Popup>> => {
  const client = useQueryClient();
  return useMutation(
    (newPopup: Partial<Popup>) => {
      if (!id) throw new Error('No popup id provided');
      return api.put(`${BASE_URL}/${id}`, newPopup);
    },
    {
      onMutate: async (newPopup: Partial<Popup>) => {
        await client.cancelQueries(POPUPS_QUERY);
        const previousPopup = client.getQueryData([POPUPS_QUERY, id]);
        client.setQueryData([POPUPS_QUERY, id], (old: Partial<Popup> = {}) => ({
          ...old,
          ...newPopup,
        }));
        return { previousPopup };
      },
      onError: (err, newPopup, ctx) => {
        client.setQueriesData([POPUPS_QUERY, id], ctx?.previousPopup);
      },
      onSettled: () => {
        client.invalidateQueries(POPUPS_QUERY);
        client.invalidateQueries([POPUPS_QUERY, id]);
      },
    },
  );
};

export const useCreateSchedulePopup = (
  id?: Popup['id'],
): UseMutationResult<null, unknown, PopupSchedule> => {
  const client = useQueryClient();
  return useMutation(
    (schedule: PopupSchedule) => {
      if (!id) throw new Error('No popup id provided');
      return api.post(`${BASE_URL}/${id}/schedule`, schedule);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(POPUPS_QUERY);
      },
    },
  );
};

export const useUpdatePopupSchedule = (
  popupId: Popup['id'],
  scheduleId?: number, // TODO(Kameron Ahler): why doesn't Popup['schedule']['id'] work for this
): UseMutationResult<null, unknown, PopupSchedule> => {
  const client = useQueryClient();
  return useMutation(
    (schedule: PopupSchedule) => {
      if (!popupId) throw new Error('No popup id provided');
      if (!scheduleId) throw new Error('No schedule id provided');
      return api.put(`${BASE_URL}/${popupId}/schedule/${scheduleId}`, schedule);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(POPUPS_QUERY);
      },
    },
  );
};

export const useDeletePopupSchedule = (
  popupId: Popup['id'],
  scheduleId?: number, // TODO(Kameron Ahler): why doesn't Popup['schedule']['id'] work for this
): UseMutationResult<null, unknown, any> => {
  const client = useQueryClient();
  return useMutation<any, unknown, any>(
    () => {
      if (!popupId) throw new Error('No popup id provided');
      if (!scheduleId) throw new Error('No schedule id provided');
      return api.delete(`${BASE_URL}/${popupId}/schedule/${scheduleId}`);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(POPUPS_QUERY);
      },
    },
  );
};

export const useShopifyPages = (): UseQueryResult<string[]> => {
  return useQuery(['shopify-pages'], async (): Promise<string[]> => {
    const { pages } = await api.get(`/v2/platforms/shopify/pages`);
    return pages;
  });
};

export default usePopup;
