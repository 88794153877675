/* eslint-disable react/no-array-index-key */
import { BodyText, Icon, IconSet } from '@postscript/components';
import styled from 'styled-components';

const StyledLi = styled.li`
  display: flex;
  list-style-type: none;
  padding-bottom: var(--spacing-1);
`;

const StyledBodyText = styled(BodyText).attrs({ color: 'var(--text-color)' })`
  font-weight: var(--body-text-bold-font-weight);
  margin-bottom: var(--spacing-1);
`;

interface Props {
  features?: string[];
  includedFeaturesPlanName?: string | null;
  className?: string;
}

export default function Features({
  features,
  includedFeaturesPlanName,
  className,
}: Props): JSX.Element {
  return (
    <div className={className}>
      {includedFeaturesPlanName ? (
        <StyledBodyText>All of {includedFeaturesPlanName} plus:</StyledBodyText>
      ) : null}
      <ul data-cy="plan-features">
        {features?.map((featureName, index) => (
          <StyledLi key={index}>
            <Icon
              component={IconSet.CheckCircleFilled}
              color="var(--green-5)"
              size="large"
              style={{
                verticalAlign: 'middle',
                marginRight: 'var(--spacing-1)',
              }}
            />
            <div>{featureName}</div>
          </StyledLi>
        ))}
      </ul>
    </div>
  );
}
