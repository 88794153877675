export const CUSTOM_DATE_PROPERTY_EVENT_TYPE = 'CustomDateProperty';
export const PRICE_DROP_EVENT_TYPE = 'PriceDrop';
export const SUBSCRIBER_CLICKED_LINK_EVENT_TYPE = 'SubscriberClickedLink';

export const TRANSACTIONAL_MESSAGE_ELIGIBLE_EVENT_TYPES = [
  'AftershipDelivered',
  'AftershipFailedAttempt',
  'AftershipInTransit',
  'AftershipOutForDelivery',
  'RechargeChargeCreated',
  'RechargeChargePaid',
  'RechargeChargeUpcoming',
  'RechargeCustomerActivated',
  'RechargeCustomerDeactivated',
  'RechargeOrderCreated',
  'ShopifyAttemptedDelivery',
  'ShopifyFulfillmentCreated',
  'ShopifyOrderCreated',
  'ShopifyOrderDelivered',
  'ShopifyOrderFulfilled',
  'ShopifyOrderInTransit',
  'ShopifyOrderOutForDelivery',
  'ShopifyOrderReadyForPickup',
];

export const DISALLOWED_CANCELLATION_EVENT_TYPES = [
  CUSTOM_DATE_PROPERTY_EVENT_TYPE, // We're currently not supporting custom date properties for cancellation events (FBP-1163)
  PRICE_DROP_EVENT_TYPE, // We're currently not supporting price drop for cancellation events (FBP-1505)
];

export const RECOMMENDED_CANCELLATION_EVENT_TYPES = ['SubscriberViewedProduct'];

// For compliance reasons, we restrict non-determinate wait categories for some events
export const WAIT_TIMEDELTA_ONLY_EVENT_TYPES = [
  'ProductAddedToCart',
  'RechargeCheckoutStarted',
  'ShopifyCheckoutStarted',
  'SubscriberViewedProduct',
];

export const DISALLOWED_WAIT_FOR_EVENT_TYPES = [
  'AddedViaAnotherFlow',
  'ProductAddedToCart',
  'RechargeCheckoutStarted',
  'ShopifyCheckoutStarted',
  'SubscriberCreated',
  'SubscriberViewedProduct',
  CUSTOM_DATE_PROPERTY_EVENT_TYPE,
  PRICE_DROP_EVENT_TYPE,
];

export const AI_MANAGED_MESSAGE_ALLOWED_EVENT_TYPES = [
  'SubscriberViewedProduct',
  'ShopifyCheckoutStarted',
];
