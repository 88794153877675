import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import constants from '../../controllers/constants';

const base = `${constants.apiHost}/vcards/file/`;

const getVcardFileUrl = (shopId) => {
  return `${base}${shopId}`;
};

getVcardFileUrl.propTypes = {
  shopId: PropTypes.number.isRequired,
};

const isVcardFileUrl = (url) => {
  return url && !isEmpty(url) && url.includes(base);
};

export default getVcardFileUrl;

export { isVcardFileUrl };
