import React from 'react';
import styled from 'styled-components';

interface SentimentBarProps {
  negative: number;
  neutral: number;
  positive: number;
}

const SentimentBarContainer = styled.div`
  display: flex;
  width: 24px;
  height: 6px;
  border-radius: var(--border-radius-round);
  overflow: hidden;
`;

const SentimentSegment = styled.div<{ color: string; width: number }>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.width}%;
  min-width: 3px;
  max-width: calc(100% - 6px);
  height: 100%;
`;

const SentimentBar: React.FC<SentimentBarProps> = ({
  negative,
  neutral,
  positive,
}) => {
  const total = negative + neutral + positive;
  const negativePercentage = (negative / total) * 100;
  const neutralPercentage = (neutral / total) * 100;
  const positivePercentage = (positive / total) * 100;

  return (
    <SentimentBarContainer>
      <SentimentSegment
        color="var(--color-sentiment-icon-negative)"
        width={negativePercentage}
      />
      <SentimentSegment color="transparent" width={neutralPercentage} />
      <SentimentSegment
        color="var(--color-sentiment-icon-positive)"
        width={positivePercentage}
      />
    </SentimentBarContainer>
  );
};

export default SentimentBar;
