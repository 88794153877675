import { LinkText } from '@postscript/components';
import styled from 'styled-components';

export const StyledBannerContainer = styled.div`
  margin-bottom: var(--spacing-3);
`;

export const StyledLinkText = styled(LinkText)`
  &:link,
  &:visited {
    color: var(--text-color);
  }

  &:hover {
    color: var(--text-color);
  }
`;
