/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { toast } from '@postscript/components';
import {
  formatShopIDs,
  validateShopIDs,
} from 'components/admin/Announcements/utils';
import { api } from 'controllers/network/apiClient';
import produce from 'immer';
import { createContext, useContext, useEffect, useReducer } from 'react';

export type MMSStatus = {
  warning: {
    allShops?: boolean;
    tfn?: boolean;
    shortcode?: boolean;
    isWarningByShopId?: boolean;
    shopIDs?: string;
  };
  disabled: {
    allShops?: boolean;
    tfn?: boolean;
    shortcode?: boolean;
    isDisabledByShopId?: boolean;
    shopIDs?: string;
  };
};

type MMSState = MMSStatus & {
  getStatus: () => MMSStatus;
  setStatus: (status: MMSStatus) => void;
};

const initialStatus = {
  warning: {},
  disabled: {},
};

const initialState: MMSState = {
  ...initialStatus,
  getStatus: () => initialStatus,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStatus: (_: MMSStatus) => undefined,
};

const defaultStatus: MMSStatus = {
  warning: {
    allShops: false,
    tfn: false,
    shortcode: false,
    isWarningByShopId: false,
  },
  disabled: {
    allShops: false,
    tfn: false,
    shortcode: false,
    isDisabledByShopId: false,
  },
};

export const MMSStatusContext = createContext(initialState);
export const useMMSStatus = () => useContext(MMSStatusContext);

const STATUS = 'STATUS';

type MMSStatusPayload = {
  mms_warning_for_all_shops?: boolean;
  mms_warning_for_shop_ids?: boolean;
  mms_warning_shop_ids?: string;
  mms_warning_for_all_shops_on_dsc?: boolean;
  mms_warning_for_all_shops_on_tfn?: boolean;
  mms_disabled_for_all_shops?: boolean;
  mms_disabled_for_shop_ids?: boolean;
  mms_disabled_shop_ids?: string;
  mms_disabled_for_all_shops_on_dsc?: boolean;
  mms_disabled_for_all_shops_on_tfn?: boolean;
};

const payloadToStatus = (payload: MMSStatusPayload): MMSStatus => {
  let warningShopIds = payload?.mms_warning_shop_ids?.replace(/['"]+/g, '');
  let disabledShopIds = payload?.mms_disabled_shop_ids?.replace(/['"]+/g, '');

  if (warningShopIds === '[]') warningShopIds = undefined;
  if (disabledShopIds === '[]') disabledShopIds = undefined;

  const status: MMSStatus = {
    warning: {
      allShops: payload?.mms_warning_for_all_shops,
      tfn: payload?.mms_warning_for_all_shops_on_tfn,
      shortcode: payload?.mms_warning_for_all_shops_on_dsc,
      isWarningByShopId: payload?.mms_warning_for_shop_ids,
      shopIDs: warningShopIds,
    },
    disabled: {
      allShops: payload?.mms_disabled_for_all_shops,
      tfn: payload?.mms_disabled_for_all_shops_on_tfn,
      shortcode: payload?.mms_disabled_for_all_shops_on_dsc,
      isDisabledByShopId: payload?.mms_disabled_for_shop_ids,
      shopIDs: disabledShopIds,
    },
  };

  return status;
};

const statusToPayload = (status: MMSStatus): MMSStatusPayload => {
  const warningShopIds = formatShopIDs(validateShopIDs(status.warning.shopIDs));
  const disabledShopIds = formatShopIDs(
    validateShopIDs(status.disabled.shopIDs),
  );

  const payload: MMSStatusPayload = {
    mms_warning_for_all_shops: status.warning.allShops,
    mms_warning_for_shop_ids: status.warning.isWarningByShopId,
    mms_warning_shop_ids: warningShopIds,
    mms_warning_for_all_shops_on_dsc: status.warning.shortcode,
    mms_warning_for_all_shops_on_tfn: status.warning.tfn,
    mms_disabled_for_all_shops: status.disabled.allShops,
    mms_disabled_for_shop_ids: status.disabled.isDisabledByShopId,
    mms_disabled_shop_ids: disabledShopIds,
    mms_disabled_for_all_shops_on_dsc: status.disabled.shortcode,
    mms_disabled_for_all_shops_on_tfn: status.disabled.tfn,
  };

  return payload;
};

const reducerFn = (draft: any, action: any) => {
  switch (action.type) {
    case STATUS:
      draft.warning = action.data.warning;
      draft.disabled = action.data.disabled;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

interface MMSStatusProps {
  children: JSX.Element;
}

export const MMSStatusProvider = ({ children }: MMSStatusProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateStatus = (status: MMSStatus) => {
    dispatch({
      type: STATUS,
      data: status,
    });
  };

  const getStatus = async () => {
    try {
      const payload = await api.get('/v2/messaging/mms/status');

      if (!payload) {
        updateStatus(defaultStatus);
        return;
      }

      const status = payloadToStatus(payload);
      updateStatus(status);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const setStatus = async (newStatus: MMSStatus) => {
    try {
      const payload = statusToPayload(newStatus);
      await api.post('/v2/messaging/mms/status', payload);
      updateStatus(newStatus);
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <MMSStatusContext.Provider
      value={{
        ...state,
        getStatus,
        setStatus,
      }}
    >
      {children}
    </MMSStatusContext.Provider>
  );
};
