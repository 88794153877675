/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Button, toast } from '@postscript/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'; // do we want to group these on app.js eventually?  to de-dupe -ct
import Events from '../../controllers/events';
import Requests from '../../controllers/network/network_requests';

const initialState = {
  modal: true,
};

class AppModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.toggleModal = this.toggleModal.bind(this);
    this.closeMe = this.closeMe.bind(this);
    this.completeMe = this.completeMe.bind(this);
  }

  componentDidMount() {}

  toggleModal() {
    this.setState({
      modal: false,
    });
  }

  completeMe() {
    this.toggleModal();
    if (this.props.modalObj)
      Events.track('Complete Modal Clicked', {
        header: this.props.modalObj.header,
      });
    if (this.props.modalObj && this.props.modalObj.announcement_id) {
      const path = '/shop/followed_announcement';
      const payload = {
        announcement_id: this.props.modalObj.announcement_id,
      };
      Events.track('Request Complete Announcement');
      Requests.post(path, payload).then(
        function (result) {
          if (result && result.error) {
            Events.track('Complete Announcement Error');
            toast.error(result.error);
            this.setState({
              saveClicked: false,
            });
          } else if (result) {
            Events.track('Complete Accouncement Success');
          }
        }.bind(this),
      );
    }
  }

  closeMe() {
    this.props.updateAppModal(null);
  }

  render() {
    return (
      <div>
        {this.props.modalObj ? (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            onClosed={this.closeMe}
          >
            <ModalHeader toggle={this.toggleModal}>
              {this.props.modalObj.header}
            </ModalHeader>
            <ModalBody className="display-linebreaks">
              <div>{this.props.modalObj.body}</div>
              <br />
              {this.props.modalObj.media_url ? (
                <div className="announcement-modal-image-container">
                  <img
                    src={this.props.modalObj.media_url}
                    alt="announcement-image"
                    className="announcement-modal-image"
                  />
                </div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              {this.props.modalObj.path ? (
                <Link to={this.props.modalObj.path} onClick={this.completeMe}>
                  <Button variant="primary">
                    {this.props.modalObj.button}
                  </Button>
                </Link>
              ) : (
                <Button variant="primary" onClick={this.completeMe}>
                  {this.props.modalObj.button}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    );
  }
}

AppModal.propTypes = {};

export default AppModal;
