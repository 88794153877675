import { InvoicesResponse } from 'components/billing/common/types';
import { PaymentMethodsProvider } from 'components/billing/context/paymentMethods';
import { useGetFailedInvoices } from 'components/billing/context/useBilling';
import { useEffect, useState } from 'react';
import {
  createPayNowTableRows,
  handleApproveOnShopify,
  handlePayInvoices,
  sortInvoicesByDate,
} from './PayNowHelperFunctions';
import { PayNowModal } from './PayNowModal';
import { PaymentProcessingResult, PayNowControllerProps } from './types';

export const PayNowController = ({
  close,
  generateRac,
  hasActivePaymentMethod,
  numberOfFailedInvoices,
  paymentProvider,
  recurringApplicationChargeActive,
  setReopenPayNowModal,
  showPaymentMethodsModal,
}: PayNowControllerProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentsProcessing, setPaymentsProcessing] = useState(false);
  const [approveOnShopifyProcessing, setApproveOnShopifyProcessing] =
    useState(false);
  const [invoices, setInvoices] = useState<InvoicesResponse>();
  const [retryPayment, setRetryPayment] = useState(false);
  const [paymentProcessingResult, setPaymentProcessingResult] =
    useState<PaymentProcessingResult>({
      paidInvoices: 0,
      paymentProcessingFinished: false,
      totalInvoices: 0,
    });
  const { data: unpaidInvoices, isLoading: unpaidInvoicesLoading } =
    useGetFailedInvoices();

  useEffect(() => {
    setModalOpen(true);
    if (!unpaidInvoicesLoading && unpaidInvoices) {
      setInvoices(sortInvoicesByDate(unpaidInvoices));
    }
  }, [paymentProvider, unpaidInvoices, unpaidInvoicesLoading]);

  const switchToAddPaymentMethodsModal = () => {
    setReopenPayNowModal(true);
    showPaymentMethodsModal('Continue');
  };

  const { shopInvoices } = invoices ?? {};
  const { tableRows, totalAmount } = createPayNowTableRows({
    shopInvoices,
    paymentsProcessing,
  });

  if (!paymentProvider) return <></>;
  return (
    <PaymentMethodsProvider>
      <PayNowModal
        unpaidInvoicesLoading={unpaidInvoicesLoading}
        retryPayment={retryPayment}
        closeModal={close}
        modalOpen={modalOpen}
        numberOfFailedInvoices={numberOfFailedInvoices}
        totalAmount={totalAmount}
        hasActivePaymentMethod={hasActivePaymentMethod}
        tableRows={tableRows}
        switchToAddPaymentMethodsModal={switchToAddPaymentMethodsModal}
        handlePayInvoices={() =>
          handlePayInvoices({
            invoices,
            setPaymentsProcessing,
            setRetryPayment,
            setPaymentProcessingResult,
            setInvoices,
          })
        }
        paymentsProcessing={paymentsProcessing}
        paymentProcessingResult={paymentProcessingResult}
        recurringApplicationChargeActive={recurringApplicationChargeActive}
        handleApproveOnShopify={() =>
          handleApproveOnShopify({
            setApproveOnShopifyProcessing,
            generateRac,
          })
        }
        approveOnShopifyProcessing={approveOnShopifyProcessing}
        paymentProvider={paymentProvider}
      />
    </PaymentMethodsProvider>
  );
};
