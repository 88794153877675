/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import {
  Badge,
  BodyText,
  Button,
  Datepicker,
  Input,
  Layout,
  SelectMenu,
  Table,
} from '@postscript/components';
import FormRow from 'components/forms/FormRow';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import { SessionDashboardResponse } from 'components/sales/hooks/useDashboard';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import { SessionInfo, ShopInfo } from '../types';

const FormColumn = styled(FormGroup).attrs({
  className: 'mr-2',
})`
  min-width: 400px;
  flex: auto;
  margin-right: var(--spacing-1);
  @media (min-width: 1200px) {
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  place-items: center;
  gap: var(--spacing-1);
`;

const StyledLayout = styled(Layout)`
  margin-bottom: var(--spacing-3);

  & > div {
    width: 100%;
  }
`;

const PAGE_LIMIT = 20;

const SortColumns = {
  CREATED_AT: 'created',
  UPDATED_AT: 'updated',
  NAME: 'name',
} as const;

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

const SortOptions = [
  {
    label: 'Went Online',
    value: {
      sortColumn: SortColumns.CREATED_AT,
      sortOrder: SortOrders.DESC,
    },
  },
  {
    label: 'Went Offline',
    value: {
      sortColumn: SortColumns.UPDATED_AT,
      sortOrder: SortOrders.DESC,
    },
  },
  {
    label: 'Name',
    value: { sortColumn: SortColumns.NAME, sortOrder: SortOrders.ASC },
  },
] as const;

type SortOption = typeof SortOptions[number];

interface SessionListItemProps {
  agentId: number;
  agentName: string;
  agentUsername: string;
  active: boolean;
  shopIds: number[];
  createdAt: string;
  updatedAt: string;
  nameMapping: ShopInfo;
}

const getSessionRow = ({
  agentId,
  agentName,
  agentUsername,
  active,
  shopIds,
  createdAt,
  updatedAt,
  nameMapping,
}: SessionListItemProps) => {
  const shopNames = shopIds.map((shopId) => nameMapping[shopId]);

  return (
    <Table.Item
      key={agentId}
      dataColumns={[
        <Table.ItemNumber
          key="agent-prospects"
          primary={<BodyText>{createdAt}</BodyText>}
        />,
        <Table.ItemNumber
          key="agent-prospects"
          primary={!active ? <BodyText>{updatedAt}</BodyText> : '--'}
        />,
        <Table.ItemNumber
          key="agent-shops"
          primary={
            shopNames.length > 0 ? (
              <div
                key={agentId}
                data-testid="agent-shops-column"
                style={{
                  marginBottom: 'var(--spacing-2)',
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto',
                  gap: 'var(--spacing-1)',
                }}
              >
                {shopIds.map((shopId) => (
                  <div key={shopId}>
                    <Badge>{nameMapping[shopId]}</Badge>
                  </div>
                ))}
              </div>
            ) : (
              '--'
            )
          }
        />,
      ]}
      nameColumn={{
        description: agentUsername,
        text: agentName,
      }}
    />
  );
};

interface SessionsListProps {
  data: SessionDashboardResponse;
  isLoading: boolean;
  timeFilter?: string;
}

interface SortConfig {
  column: string;
  order: string;
}

interface TimeInputEvent {
  target: {
    value: string;
  };
}

const validationSchema = Yup.object().shape({
  date: Yup.string(),
  time: Yup.string(),
});

const createdAtSort = (a: SessionInfo, b: SessionInfo) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

const nameSort = (a: SessionInfo, b: SessionInfo) => {
  return a.name.localeCompare(b.name);
};

const SessionsList = ({
  data,
  isLoading,
  timeFilter,
}: SessionsListProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);

  const [sortOptions, setSortOptions] = useState<SortConfig>({
    column: SortColumns.CREATED_AT,
    order: SortOrders.DESC,
  });
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  const sessionsData: SessionInfo[] = data.agent_sessions;

  const sortedSessionData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return sessionsData.sort((a, b) => {
      // Session creation sorting
      if (sortOptions.column === SortColumns.CREATED_AT) {
        return createdAtSort(a, b);
      }

      // Name sorting
      if (sortOptions.column === SortColumns.NAME) {
        return nameSort(a, b);
      }
      return 0;
    });
  }, [sortOptions, data]);

  return (
    <>
      <Formik<{ date: string; time: string }>
        initialValues={{
          date: timeFilter ? timeFilter.split('T')[0] : moment().toString(),
          time: timeFilter ? timeFilter.split('T')[1] : '',
        }}
        onSubmit={(values) => {
          if (!values.time || !values.date) {
            window.location.href =
              window.location.origin + window.location.pathname;
            return;
          }
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('datetime', `${values.date}T${values.time}`);
          const baseUrl = window.location.origin + window.location.pathname;
          window.location.href = `${baseUrl}?${searchParams.toString()}`;
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, isSubmitting }) => (
          <StyledLayout>
            <Form>
              <FormRow>
                <>
                  <FormColumn>
                    <Field
                      id="sessionDate"
                      name="sessionDate"
                      label="On this date..."
                      as={Datepicker}
                      date={values.date}
                      onDateChange={(date: string) =>
                        setFieldValue('date', date)
                      }
                      isOutsideRange={() => false}
                      positionCalendarAbsolutely
                    />
                  </FormColumn>
                  <FormColumn>
                    <Field
                      id="sessionTime"
                      name="sessionTime"
                      as={Input}
                      label="At this time..."
                      type="time"
                      value={values.time}
                      onChange={({ target: { value } }: TimeInputEvent) =>
                        setFieldValue('time', value)
                      }
                    />
                  </FormColumn>
                </>
              </FormRow>
              <Container style={{ marginRight: 'auto' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setFieldValue('date', moment().toString());
                    setFieldValue('time', '');
                    submitForm();
                  }}
                >
                  Clear
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Filter
                </Button>
              </Container>
            </Form>
          </StyledLayout>
        )}
      </Formik>

      <Table
        filters={
          <>
            <SelectMenu
              fieldWidth={250}
              isClearable={false}
              onChange={(option) => {
                const { sortColumn, sortOrder } = (option as SortOption).value;
                setCurrentPage(1);
                setSortOptions({ column: sortColumn, order: sortOrder });
              }}
              options={Object.values(SortOptions)}
              value={Object.values(SortOptions).find(
                ({ value }) =>
                  value.sortColumn === sortOptions.column &&
                  value.sortOrder === sortOptions.order,
              )}
            />
          </>
        }
        globalColumnSettings={{
          dataColumns: [
            {
              heading: 'Went Online',
            },
            {
              heading: 'Went Offline',
            },
            {
              heading: 'Shops',
              minWidth: 450,
            },
          ],
          name: {
            heading: 'Name',
            minWidth: 100,
          },
        }}
        emptyState={{
          heading: "There's nothing here",
          description: 'No sessions exist',
        }}
        search={{
          placeholder: 'Search agent sessions',
          onChange: (e) => {
            setCurrentSearchTerm(e.target.value);
          },
          id: 'agent-session-list-search',
          value: currentSearchTerm,
        }}
        id="agent-list-table"
        isEmpty={data?.agent_sessions.length === 0}
        isLoading={isLoading}
        labelledBy="agent-sessions-list-table"
        loadingState={<LoadingSpinner>Loading sessions...</LoadingSpinner>}
        size="medium"
        pagination={{
          currentPage,
          selectPage: setCurrentPage,
          totalPages: Math.ceil(
            (data?.agent_sessions.length ?? 0) / PAGE_LIMIT,
          ),
        }}
      >
        {sortedSessionData
          .filter(
            (sessionData) =>
              sessionData.username.includes(currentSearchTerm) ||
              sessionData.name.includes(currentSearchTerm),
          )
          .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
          .map((sessionData) => {
            return getSessionRow({
              active: sessionData.active,
              agentId: sessionData.user_id,
              agentName: sessionData.name,
              agentUsername: sessionData.username,
              createdAt: sessionData.created_at,
              nameMapping: data.shop_names,
              shopIds: sessionData.shop_ids,
              updatedAt: sessionData.updated_at,
            });
          })}
      </Table>
    </>
  );
};

export default SessionsList;
