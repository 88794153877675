/* eslint-disable prefer-template */
import { Icon, IconSet, Tooltip } from '@postscript/components';
import styled from 'styled-components';

export interface UserIconProps {
  color?: string;
  display?: string;
  title?: string;
  image?: string;
  backgroundColor?: string;
  id: string;
  isShop?: boolean;
}

const RoundedIcon = styled.div<UserIconProps>`
  border-radius: var(--border-radius-round);
  background: ${(props) =>
    props.image
      ? 'url(' + props.image + ')'
      : props.backgroundColor || 'var(--purple-core)'};
  width: 24px;
  height: 24px;
  cursor: default;
  color: ${({ color }) => color};
  float: right;
  font: var(--body-text-small);
  font-weight: var(--body-text-bold-font-weight);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: auto;
  margin-bottom: var(--spacing-1);
  margin-left: var(--spacing-1);
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
`;

const UserIcon = ({
  color,
  display,
  title,
  backgroundColor,
  image,
  id,
  isShop,
}: UserIconProps): JSX.Element => {
  const displayName =
    display
      ?.split(' ')
      .map((name) => name[0])
      .slice(0, 2)
      .join('') || '';

  const bub = (
    <Icon component={IconSet.Bub} size={18} color="var(--purple-core)" />
  );

  return (
    <>
      <Tooltip id={`user-avatar-tooltip-${id}`} size="small">
        {title}
      </Tooltip>
      <RoundedIcon
        className="message-avatar"
        color={color}
        title={title}
        backgroundColor={backgroundColor}
        image={image}
        data-tip
        data-for={`user-avatar-tooltip-${id}`}
        id={id}
      >
        {!image && isShop ? bub : !image && displayName}
      </RoundedIcon>
    </>
  );
};

export default UserIcon;
