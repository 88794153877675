/* eslint-disable no-self-assign */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { omit } from 'lodash';
import Requests from './network/network_requests';

/**
 * Consumes a shop-owned entity's data and performs a set of transforms on it
 * in order to get the post data needed. An optional shop (as the first argument
 * can be passed or leave it null to use the currently selected shop). The transforms
 * can be a Promise, function or object but keep in mind only a function transform will
 * receive the previously transformed data.
 *
 * @param {Object} shop - (Optional) The optional shop object to save to
 * @param {Object} entity - (Required) The entity object to be saved
 * @param {string} path - (Required) The POST path to send the data to
 * @param {Array<Promise|Function|any>} transforms - A set of transforms to perform to get the POST body
 * @return Promise<Object, Error>
 * @see Promise, Requests
 */
const saveEntityInto = function (shop, entity, path, transforms) {
  // assure we loop through an actual array
  if (!Array.isArray(transforms)) {
    transforms = transforms ? [transforms] : [];
  }
  // filter out any transform that isn't a function
  transforms = [
    entity,
    ...transforms.filter((transform) => typeof transform === 'function'),
  ];
  // let's use the `.syncAll` method to snychronously build the post data
  return Promise.syncAll(transforms).then((postData) => {
    // add the shop id to the entity data
    if (shop) {
      postData = { ...postData, shop_id: shop.value };
    }

    // return the post request
    return Requests.post(path, postData).then((result) => {
      // this can be abstracted away to something prettier
      if (result && result.error) {
        throw new Error(result.error);
      } else {
        return Promise.resolve(result);
      }
    });
  });
};

/**
 * A convenience method to save a segment into a shop. This method simply wraped
 * the `saveEntityInto` method below with the proper transforms and predefined
 * path needed to save a segment into a specific shop.
 *
 * @param {Object} shop - (Optional) The optional shop object to save to
 * @param {Object} segment - (Required) The entity object to be saved
 */
const cloneSegmentInto = function (shop, segment) {
  // setup the transforms to get and seed the post data properly
  return saveEntityInto(shop, segment, '/segments/save', [
    function () {
      return {
        name: `${this.name} (copy)`,
        user_filter_array: this.filter_string,
        include_all_subscribers: this.include_all_subscribers,
      };
    }.bind(segment),
  ]);
};

/**
 * A convenience method to save an automation into a shop. This method simply wraped
 * the `saveEntityInto` method below with the proper transforms and predefined
 * path needed to save a automation into a specific shop.
 *
 * @param {Object} shop - (Optional) The optional shop object to save to
 * @param {Object} automation - (Required) The entity object to be saved
 */
const cloneAutomationInto = function (shop, automation) {
  let calledDelete = false;
  automation = { ...automation };
  // setup the transforms to get and seed the post data properly
  return saveEntityInto(shop, automation, '/automations/save', [
    function () {
      const payload = {
        name: `${this.name} (copy)`,
        trigger: this.trigger,
        once_per_subscriber: this.once_per_subscriber,
        transactional: this.transactional_allowed && 'requested',
        enabled: this.status !== 'Disabled',
      };
      if (this.cancel_triggers)
        payload.cancel_trigger_id = this.cancel_triggers.map(
          (cancel_trigger) => cancel_trigger.id,
        );
      if (this.user_filter_criteria)
        payload.user_filter_array = this.user_filter_criteria;
      if (this.object_filter_criteria_v1)
        payload.object_filter_criteria_v1 = this.object_filter_criteria_v1;

      return payload;
    }.bind(automation),
  ]).then((result) => {
    if (result && (result.error || !result.automation)) {
      throw new Error(
        result.error ||
          `Something went wrong while trying to clone ${automation.name} into ${shop.label}.`,
      );
    }
    return saveEntityInto(shop, result.automation, '/automations/update', [
      function (res) {
        return {
          automation_id: res.id,
          messageArray: [
            ...this.automation_messages.map((message) => {
              const formattedMessage = {
                timeDelay: message.minutes_after_last_message,
                message: message.message,
                ...omit(message, ['message_id', 'automation_id']),
              };

              return formattedMessage;
            }),
          ],
        };
      }.bind(automation),
    ])
      .then((res) => {
        if (res && res.error) {
          calledDelete = true;
          // delete the automation
          return saveEntityInto(
            shop,
            result.automation,
            '/automations/delete',
            [
              function (auto) {
                return {
                  automation_id: auto.id,
                };
              },
            ],
          ).then(() => {
            throw new Error(res.error);
          });
        }
        return res;
      })
      .catch((err) => {
        if (calledDelete) throw err;
        return saveEntityInto(shop, result.automation, '/automations/delete', [
          function (auto) {
            return {
              automation_id: auto.id,
            };
          },
        ]).then(() => {
          throw new Error(`Something went wrong: ${err.toString()}.`);
        });
      });
  });
};

export default { saveEntityInto, cloneSegmentInto, cloneAutomationInto };
