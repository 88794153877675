import { FieldLabel, Layout, SelectMenu } from '@postscript/components';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { VIEW_OPTIONS } from '../constants';
import { useView } from '../hooks/useView';
import { getMaxViewLevelForUser } from '../utils/roles';

export const ViewSelector = (): JSX.Element => {
  const { user } = useUser();
  const { view, setView } = useView();
  const maxViewLevel = getMaxViewLevelForUser(user);

  if (maxViewLevel === VIEW_OPTIONS.MERCHANT) {
    return <></>;
  }

  const availableOptions = [
    VIEW_OPTIONS.MERCHANT,
    VIEW_OPTIONS.SALES_ASSOCIATE,
  ];
  if (maxViewLevel === VIEW_OPTIONS.AI_DEBUGGER) {
    availableOptions.push(VIEW_OPTIONS.AI_DEBUGGER);
  }

  interface OptionType {
    label: string;
    value: string;
  }

  const selectMenuItems: OptionType[] = availableOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const selectOption = (newOption: OptionType) => {
    if (newOption && newOption.value) {
      setView(newOption.value);
      localStorage.setItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY, newOption.value);
    }
  };

  const selectedOption = selectMenuItems.find(
    (option) => option.value === view,
  );

  return (
    <Layout valign="center" gap="var(--spacing-1)" style={{ width: 252 }}>
      <FieldLabel
        fieldSize="small"
        id="view-select-menu-label"
        htmlFor="view-select-menu"
        style={{ paddingBottom: 0 }}
      >
        View level
      </FieldLabel>
      <SelectMenu
        fieldSize="small"
        labelledBy="view-select-menu-label"
        id="view-select-menu"
        options={selectMenuItems}
        isClearable={false}
        placeholder="Select a view level"
        value={selectedOption}
        fieldWidth={180}
        onChange={(newValue) => {
          selectOption(newValue as OptionType);
        }}
      />
    </Layout>
  );
};
