import {
  Button,
  FieldLabel,
  Input,
  Layout,
  ModalBody,
  ModalFooter,
  toast,
} from '@postscript/components';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PAYMENT_METHOD_BANNER_ID } from 'components/billing/common/constants';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useGetPaymentMethod } from 'components/billing/context/useBilling';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useUser } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import { Field, Form, Formik, FormikValues } from 'formik';
import styled from 'styled-components';
import { logEvent } from 'utils/events';
import * as yup from 'yup';

const StyledLayout = styled(Layout)`
  width: 100%;
  margin-top: var(--spacing-1);

  div:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    display: block;

    div {
      margin-right: 0;
    }

    label {
      margin-top: var(--spacing-1);
    }
  }
`;

const FieldGroup = styled.div`
  flex: 1;
  margin-bottom: auto !important;
`;

const StyledStripeElement = styled.div`
  .StripeElement {
    background: var(--main-bkg-color);
    color: var(--text-color);
    position: relative;
    outline: none;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-x-small);
    transition: border-color var(--hover-transition);
    height: var(--spacing-6);
    padding: var(--spacing-1) calc(var(--spacing-2) + 3px);

    &:hover {
      border-color: var(--highlight-color);
    }

    &::-moz-focus-inner {
      margin-top: -2px;
      margin-bottom: -2px;
      border: 0;
      padding: 0;
    }
  }

  .StripeElement--focus {
    box-shadow: 0 0 5px var(--highlight-color);
    border-color: var(--highlight-color);
  }

  .StripeElement--invalid {
    border-color: var(--error-color);

    &:hover {
      border-color: var(--error-color);
    }
  }

  .StripeElement--invalid.StripeElement--focus {
    box-shadow: 0 0 5px var(--error-color);
    border-color: var(--error-color);
  }

  .StripeElement--webkit-autofill,
  .StripeElement--webkit-autofill:hover,
  .StripeElement--webkit-autofill:focus {
    -webkit-text-fill-color: var(--main-bkg-color);
    box-shadow: 0 0 0 1000px var(--main-bkg-color) inset;
  }
`;

const localStyles = getComputedStyle(document.body);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: localStyles.getPropertyValue('--body-text-typeface'),
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: '300',
      '::placeholder': {
        color: localStyles.getPropertyValue('--gray-5'),
      },
    },
  },
};

export default function PaymentMethodCardForm(): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const {
    updateStep,
    getPaymentMethods,
    getUnverifiedPaymentMethods,
    setDefaultPaymentMethod,
  } = usePaymentMethods();
  const { removeBanner } = useBanners();
  const {
    user: {
      data: { first_name: firstName, last_name: lastName },
    },
  } = useUser();
  const { refetch: refetchPaymentMethod } = useGetPaymentMethod();

  const userName = `${firstName} ${lastName}`;

  const submit = async ({ name }: FormikValues) => {
    if (!elements || !stripe) return;

    const { clientSecret } = await api.post(
      '/v2/billing/payments/stripe/setup_intents',
    );

    const cardInput = elements.getElement(CardNumberElement);

    if (!cardInput) return;

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardInput,
        billing_details: {
          name,
        },
      },
    });

    if (error || !setupIntent) {
      return toast.error(error?.message || 'Please try again.');
    }

    logEvent('payment method added', {
      payment_method_type: 'card',
    });

    await Promise.all([
      getPaymentMethods(),
      getUnverifiedPaymentMethods(),
      refetchPaymentMethod(),
    ]);

    if (setupIntent.payment_method) {
      try {
        await setDefaultPaymentMethod(setupIntent.payment_method);
        await refetchPaymentMethod();
        removeBanner(PAYMENT_METHOD_BANNER_ID);
      } catch {
        toast.error('Unable to set new payment method as default.');
      }
    }

    toast.success('Payment method added.');
    updateStep('management');
  };

  return (
    <Formik
      validationSchema={yup.object({
        name: yup.string().required('Please provide a cardholder name.'),
      })}
      initialValues={{
        name: userName,
      }}
      onSubmit={submit}
      validateOnBlur={false}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <ModalBody>
            <StyledLayout>
              <FieldGroup>
                <Field
                  id="name"
                  name="name"
                  label="Cardholder name"
                  as={Input}
                  error={touched.name && errors.name}
                  errorMessage={errors.name}
                  placeholder="First &amp; last name"
                  data-cy="name-input"
                />
              </FieldGroup>
              <FieldGroup>
                <FieldLabel htmlFor="card-number">Card number</FieldLabel>
                <StyledStripeElement data-cy="number-input-container">
                  <CardNumberElement options={ELEMENT_OPTIONS} />
                </StyledStripeElement>
              </FieldGroup>
            </StyledLayout>
            <StyledLayout>
              <FieldGroup>
                <FieldLabel
                  htmlFor="card-expiration"
                  style={{ marginTop: 'var(--spacing-1)' }}
                >
                  Expiration date
                </FieldLabel>
                <StyledStripeElement data-cy="expiration-input-container">
                  <CardExpiryElement
                    id="card-expiration"
                    options={ELEMENT_OPTIONS}
                  />
                </StyledStripeElement>
              </FieldGroup>
              <FieldGroup>
                <FieldLabel
                  htmlFor="card-cvc"
                  style={{ marginTop: 'var(--spacing-1)' }}
                >
                  Security code
                </FieldLabel>
                <StyledStripeElement data-cy="cvc-input-container">
                  <CardCvcElement id="card-cvc" options={ELEMENT_OPTIONS} />
                </StyledStripeElement>
              </FieldGroup>
            </StyledLayout>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={!stripe || isSubmitting}
              data-cy="add-card-submit-button"
            >
              Save Card
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}
