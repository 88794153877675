import { BodyText, Card, Heading, Layout } from '@postscript/components';
import { USER_NOTIFICATION_PREFERENCES } from 'components/admin/utils/feature-flags';
import PageHeader from 'components/layout/PageHeader';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useUser } from 'controllers/contexts/user';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NOTIFICATION_NAME } from '../constants';
import { getShopId, userHasLegacyRole } from '../helpers';
import {
  useGetUserNotificationPreferences,
  useGetUserPhoneNumber,
  useGetUserProfile,
} from '../hooks/useUsers';
import { NotificationMapping } from '../types';
import UserBillingNotificationsForm from './notificationPreferences/billing/UserBillingNotificationsForm';
import NotificationPreferences from './notificationPreferences/NotificationPreferences';
import ProfileForm from './ProfileForm';
import UserSettingsForm from './UserSettingsForm';

const StyledHeading = styled(Heading)`
  margin-bottom: var(--spacing-1);
`;

const StyledBodyText = styled(BodyText)`
  margin-bottom: var(--spacing-6);
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 600px;
`;

export default function Profile(): JSX.Element | null {
  const { push } = useHistory();
  const {
    user: { guid, username },
  } = useUser();
  const { data: profile } = useGetUserProfile();
  const { hasInitialized, hasLabsFlag } = usePSLabs();
  const userNotificationPreferencesEnabled =
    hasInitialized && hasLabsFlag(USER_NOTIFICATION_PREFERENCES);
  const shopId = getShopId();
  const { data: phoneNumber } = useGetUserPhoneNumber(guid, shopId, {
    enabled: userNotificationPreferencesEnabled,
  });

  const { data: userNotificationsData } = useGetUserNotificationPreferences({
    enabled: userNotificationPreferencesEnabled,
  });

  const notificationMapping: NotificationMapping = {};

  const { notifications } = userNotificationsData ?? { notifications: [] };

  notifications.forEach((notification) => {
    notificationMapping[notification.notificationName] =
      notification.notificationChannels;
  });

  const hasBillingSpendNotification =
    NOTIFICATION_NAME.BILLING_SPEND_NOTIFICATION in notificationMapping;

  const productAreas = [];

  if (hasBillingSpendNotification) {
    productAreas.push({
      heading: 'Billing',
      children: (
        <UserBillingNotificationsForm
          channels={
            notificationMapping[NOTIFICATION_NAME.BILLING_SPEND_NOTIFICATION]
          }
          type="personal"
        />
      ),
    });
  }

  const isMyShopifyUser = username.includes('.myshopify.com');
  const isAiAlphaTester = userHasLegacyRole('AI_ALPHA_TESTER');
  const showUserSettings = isAiAlphaTester;

  if (isMyShopifyUser) {
    push('/');
    return null;
  }

  const user = {
    guid,
    username,
    profile,
  };

  return (
    <>
      <PageHeader pageTitle="Profile" />
      <Layout vertical gap="var(--spacing-6)">
        <StyledCard>
          <StyledHeading forwardedAs="h2" size="medium">
            Personal contact information
          </StyledHeading>
          <StyledBodyText size="small">
            This information is visible to your team.
          </StyledBodyText>
          <ProfileForm user={user} />
        </StyledCard>
        {userNotificationPreferencesEnabled && (
          <StyledCard>
            <StyledHeading forwardedAs="h2" size="medium">
              Notification preferences
            </StyledHeading>
            <NotificationPreferences
              type="personal"
              phoneNumber={phoneNumber ?? ''}
              emailAddress={username}
              phoneNumberInputName="phoneNumber"
              productAreas={productAreas}
            />
          </StyledCard>
        )}
        {showUserSettings && (
          <StyledCard>
            <StyledHeading forwardedAs="h2" size="medium">
              Settings
            </StyledHeading>
            <StyledBodyText size="small">
              User functionality and settings
            </StyledBodyText>
            <UserSettingsForm />
          </StyledCard>
        )}
      </Layout>
    </>
  );
}
