import { COMMON_EVENT_NAMES, logEvent, ProductAreas } from 'utils/events';

interface LogErrorBoundaryError {
  error: unknown;
  productArea: ProductAreas;
  location?: string;
}

export const logErrorBoundaryError = ({
  error,
  location,
  productArea,
}: LogErrorBoundaryError): void => {
  // The error could have any shape. Amplitude requires a record, and ignores
  // properties with undefined values.
  if (typeof error !== 'object' && typeof error !== 'string') return;

  logEvent(COMMON_EVENT_NAMES.ERROR_BOUNDARY_ERROR, {
    ...(typeof error === 'object' && error),
    ...(typeof error === 'string' && { cause: error }),
    location,
    product_area: productArea,
  });
};
