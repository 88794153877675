/* eslint-disable react/prop-types */
import { AGENT_EVENTS_TO_RECORD } from 'components/responses/constants';
import { SUBSCRIBER_QUERY_KEY } from 'components/sales/hooks/useSubscriber';
import { api } from 'controllers/network/apiClient';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

const AgentActionsContext = createContext(null);
export const useAgentActions = () => useContext(AgentActionsContext);

export const AgentActionsProvider = ({ children }) => {
  const [actions, setActions] = useState([]);
  const [lastAction, setLastAction] = useState(null);
  const [isRecording, setIsRecording] = useState(true);
  const [recommendationContextBuffer, setRecommendationContextBuffer] =
    useState([]);
  const queryClient = useQueryClient();
  const actionsRef = useRef(actions);

  const addAgentAction = (subscriberId, actionData, recContextBuffer) => {
    // Check if the new action's subscriber ID is different from the last action's subscriber ID
    if (lastAction && subscriberId !== lastAction.subscriberId) {
      // If they are different, reset the actions array to only include the new action
      setActions([{ subscriberId, ...actionData }]);
    } else {
      // If they are the same, add the new action to the existing actions array
      setActions((prevActions) => [
        ...prevActions,
        { subscriberId, ...actionData },
      ]);
    }

    setLastAction({ subscriberId, ...actionData });
    setRecommendationContextBuffer(recContextBuffer);
  };

  const toggleRecording = () => {
    setIsRecording((prevState) => !prevState);
  };

  const sendActionsToAPI = async (actions) => {
    try {
      if (actions?.length < 1) return;

      // Get last inbound message TODO: convo object
      const subscriberId = actions[0]?.subscriberId;

      const { data: subscriber, success } = queryClient.getQueryData([
        SUBSCRIBER_QUERY_KEY,
        subscriberId,
      ]);

      if (!success) return;

      const lastIncomingMessage = subscriber?.chat_history
        .slice()
        .reverse()
        .find((message) => message.direction === 'incoming');

      await api.post('/v2/convert/classifications', {
        subscriber_id: subscriberId,
        message: lastIncomingMessage,
        agent_actions: actions,
        recommended_action_contexts: recommendationContextBuffer,
      });
    } catch (err) {
      console.log('error: ', err);
    } finally {
      setActions([]);
      setLastAction(null);
    }
  };

  useEffect(() => {
    if (
      lastAction &&
      [AGENT_EVENTS_TO_RECORD.SUBSCRIBER_RESOLVED].includes(
        lastAction.action_name,
      )
    ) {
      sendActionsToAPI(actions);
    }
  }, [actions, lastAction]);

  useEffect(() => {
    actionsRef.current = actions;
  }, [actions]);

  useEffect(() => {
    const handleLogEvent = (event) => {
      const data = event?.detail?.event_properties;

      if (!data?.action || !data?.subscriber_id || !isRecording) return;

      addAgentAction(
        data.subscriber_id,
        {
          action_name: data.action,
          action_data: data,
        },
        data.recommendation_actual_context,
      );
    };

    window.addEventListener('logAgentEvent', handleLogEvent);

    return () => {
      window.removeEventListener('logAgentEvent', handleLogEvent);
    };
  }, [isRecording]);

  return (
    <AgentActionsContext.Provider
      value={{ addAgentAction, toggleRecording, isRecording }}
    >
      {children}
    </AgentActionsContext.Provider>
  );
};
