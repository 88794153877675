import {
  BodyText,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@postscript/components';
import React, { useState } from 'react';

interface Props {
  heading: string;
  content: React.ReactNode;
  confirmation: React.ReactNode;
  cancellation: React.ReactNode;
  onConfirm: (arg?: any) => Promise<void>;
  isOpen: boolean;
  close: () => void;
  isRenderedWithGlobalModal?: boolean;
}

const ConfirmationModal = ({
  heading,
  content,
  confirmation,
  cancellation,
  onConfirm,
  isOpen,
  close,
  isRenderedWithGlobalModal = false,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const Children = () => (
    <>
      <ModalHeader>{heading}</ModalHeader>
      <ModalBody>
        <BodyText style={{ marginBottom: 'var(--spacing-4)' }}>
          {content}
        </BodyText>
      </ModalBody>
      <ModalFooter>
        <Button
          size="medium"
          onClick={async () => {
            setLoading(true);
            onConfirm()
              .then(close)
              .finally(() => setLoading(false));
          }}
          disabled={loading}
          data-testid="confirmation-button"
        >
          {confirmation}
        </Button>
        <Button size="medium" variant="text" onClick={close}>
          {cancellation}
        </Button>
      </ModalFooter>
    </>
  );

  if (isRenderedWithGlobalModal) {
    return <Children />;
  }

  return (
    <Modal isOpen={isOpen} onCancel={close}>
      <Children />
    </Modal>
  );
};

export default ConfirmationModal;
