import { Badge } from '@postscript/components';
import { StyledLinkText } from 'components/billing/common/styles';
import styled from 'styled-components';
import { logButtonClickEvent } from 'utils/events';
import useAccountStatus from './useAccountStatus';

const Wrapper = styled.div`
  font-size: 12px;
`;

const StyledBadge = styled(Badge)`
  font-size: 12px;
  margin-right: 2px;
`;

const LinkText = styled(StyledLinkText)`
  margin-left: 2px;

  svg {
    color: var(--text-color);
  }
`;

export default function AccountStatusCTA(): JSX.Element | null {
  const { billingActive, installed, isFetched } = useAccountStatus();

  if (!isFetched) return null;

  if (!installed) {
    return (
      <Wrapper>
        <StyledBadge variant="error">Uninstalled</StyledBadge>•
        <LinkText
          arrowSize={20}
          href="https://apps.shopify.com/postscript-sms-marketing"
          rel="noopener noreferrer"
          target="_blank"
          onClick={logButtonClickEvent}
        >
          Install Postscript
        </LinkText>
      </Wrapper>
    );
  }

  if (!billingActive) {
    return (
      <Wrapper>
        <StyledBadge variant="warning">Deactivated</StyledBadge>•
        <LinkText
          arrowSize={20}
          href="/billing/plans/compare"
          onClick={logButtonClickEvent}
        >
          View plans
        </LinkText>
      </Wrapper>
    );
  }

  return null;
}
