import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ViewContext from './hooks/useView';
import ConversationalInsights from './pages/ConversationalInsights';
import ConversationIntelligence from './pages/ConversationIntelligence';
import Performance from './pages/Performance';
import { getMaxViewLevelForUser } from './utils/roles';

const Routes = (): JSX.Element | null => {
  const { url } = useRouteMatch();
  const { user } = useUser();
  const [view, setView] = useState(
    localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ||
      getMaxViewLevelForUser(user),
  );

  return (
    <ViewContext.Provider value={{ view, setView }}>
      <Switch>
        <Route path={`${url}/performance`} component={Performance} />
        <Route
          path={`${url}/conversations`}
          component={ConversationalInsights}
        />
        <Route
          path={`${url}/conversation-intelligence`}
          component={ConversationIntelligence}
        />
        <Redirect to={`${url}/performance`} />
      </Switch>
    </ViewContext.Provider>
  );
};

export default Routes;
