/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { SingleDatePicker } from 'react-dates';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const initialState = {};

class DelayedInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(initialState));
    this.state.date = props.date;

    if (props.value) {
      this.state.date = moment(props.value);
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
  }

  componentDidMount() {
    this.timer = null;
  }

  handleChange(date) {
    clearTimeout(this.timer);
    this.setState(
      {
        date,
      },
      () => {
        if (!date) {
          this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
        } else {
          this.triggerChange();
        }
      },
    );
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange();
    }
  }

  triggerChange() {
    if (
      this.props.onDateChange &&
      typeof this.props.onDateChange === 'function'
    ) {
      this.props.onDateChange(this.state.date);
    }

    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(this.state.date);
    }
  }

  render() {
    return (
      <SingleDatePicker
        date={this.state.date} // momentPropTypes.momentObj or null
        onDateChange={this.handleChange} // PropTypes.func.isRequired
        onKeyDown={this.handleKeyDown}
        focused={this.state.focused} // PropTypes.bool
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
        hideKeyboardShortcutsPanel
        isOutsideRange={() => false}
        small={this.props.small}
        disabled={this.props.disabled || false}
        numberOfMonths={1}
      />
    );
  }
}

DelayedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DelayedInput;
