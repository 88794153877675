import { Banner } from '@postscript/components';
import React from 'react';

type BannerColor = string;

interface BannerProps extends React.ComponentProps<typeof Banner> {
  color?: BannerColor;
}

const AnnouncementBanner = ({ color, ...props }: BannerProps): JSX.Element => {
  const getVariantFromColor = (color: BannerColor) => {
    if (color.includes('error')) {
      return 'critical';
    }
    if (color.includes('orange') || color.includes('yellow')) {
      return 'warning';
    }
    if (color.includes('green')) {
      return 'success';
    }
    if (color.includes('blue')) {
      return 'guidance';
    }
    return 'default';
  };
  return (
    <Banner
      {...props}
      variant={color ? getVariantFromColor(color) : undefined}
    />
  );
};

export default AnnouncementBanner;
