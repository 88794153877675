import { toast } from '@postscript/components';
import produce from 'immer';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { api } from '../network/apiClient';

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const DATA_TYPE = 'flags';

const MAX_LOADING_DURATION = 3000;

const defaultInitialState = {
  loading: false,
  hasInitialized: false,
  [DATA_TYPE]: {},
};

export const FeatureFlagsContext = createContext(defaultInitialState);
export const useFeatureFlags = () => useContext(FeatureFlagsContext);

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      draft.hasInitialized = true;
      break;
    case SET_ITEMS:
      draft[DATA_TYPE] = action.data;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const FeatureFlagsProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setItems = (id, data) => {
    dispatch({
      type: SET_ITEMS,
      id,
      data,
    });
  };

  const timeout = () => {
    setTimeout(() => {
      setLoading(false);
    }, MAX_LOADING_DURATION);
  };

  const search = async () => {
    setLoading(true);
    timeout();

    try {
      const { data } = await api.get('/shop/feature_flags');

      setItems(DATA_TYPE, data);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const hasFlag = (flagName) => {
    return state.flags && state.flags[flagName];
  };

  const updateFlags = async (payload) => {
    try {
      const { flags } = await api.put('/v2/shops/feature_flags/', payload);

      setItems(DATA_TYPE, flags);

      toast.success('Successfully updated feature flags.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <FeatureFlagsContext.Provider
      value={{
        ...state,
        setItems,
        search,
        hasFlag,
        updateFlags,
      }}
    >
      {state.hasInitialized && !state.loading && children}
    </FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

FeatureFlagsProvider.defaultProps = {
  initialState: defaultInitialState,
};
