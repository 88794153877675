import { Input } from '@postscript/components';
import { FieldAttributes } from 'formik';
import FormikField from './FormikField';

export interface Props extends FieldAttributes<any> {
  name: string;
}

export default function FormikFieldText({
  name,
  ...props
}: Props): JSX.Element {
  return <FormikField name={name} {...props} as={Input} />;
}
