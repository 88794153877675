/* eslint-disable block-scoped-var */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import { isEmpty } from 'lodash';
import {
  CORDIAL_INTEGRATION,
  ITERABLE_INTEGRATION,
  LISTRAK_INTEGRATION,
  MAILCHIMP_INTEGRATION,
  OMNISEND_INTEGRATION,
  YOTPO_LOYALTY_V2,
} from '../components/admin/utils/feature-flags';
import OneClickPopupLogo from '../img/1clickpopup_logo.png';
import AfterShipLogo from '../img/aftership_logo.png';
import AliaLogo from '../img/alia_logo.png';
import AlloyLogo from '../img/alloy_logo.png';
import AmpedLogo from '../img/amped_logo.png';
import AwtomicLogo from '../img/awtomic_logo.png';
import BlackcrowaiLogo from '../img/blackcrowai_logo.png';
import BlotoutLogo from '../img/blotout_logo.png';
import BuilderIoLogo from '../img/builder_logo.png';
import CartHookLogo from '../img/carthook_logo.png';
import SubscriberApiLogo from '../img/code_icon.png';
import CordialLogo from '../img/cordial_logo.png';
import DelightChatLogo from '../img/delightchat_logo.png';
import DigiohLogo from '../img/digioh_logo.png';
import ElevarLogo from '../img/elevar_logo.png';
import FondueLogo from '../img/fondue_logo.png';
import FormtoroLogo from '../img/formtoro_logo.png';
import FrontLogo from '../img/front_logo.png';
import GladlyLogo from '../img/gladly_logo.png';
import GojiLogo from '../img/goji_logo.png';
import GorgiasLogo from '../img/gorgias_logo.png';
import GrowaveLogo from '../img/growave_logo.png';
import HelpScoutLogo from '../img/helpscout_logo.png';
import HiTideLogo from '../img/hitide_logo.svg';
import InveterateLogo from '../img/inveterate_logo.png';
import IterableLogo from '../img/iterable_logo.png';
import JunipLogo from '../img/junip_logo.png';
import JustunoLogo from '../img/justuno_logo.png';
import KlaviyoLogo from '../img/klaviyo_logo.png';
import KustomerLogo from '../img/kustomer_logo.png';
import ListrakLogo from '../img/listrak_logo.png';
import LittledataLogo from '../img/littledata_logo.png';
import LoopLogo from '../img/loop_logo.svg';
import LoyaltyLionLogo from '../img/loyaltylion.svg';
import MailchimpLogo from '../img/mailchimp_logo.png';
import MalomoLogo from '../img/malomo_logo.png';
import MaverickLogo from '../img/maverick_logo.png';
import MonocleLogo from '../img/monocle_logo.png';
import NorthbeamLogo from '../img/northbeam_logo.png';
import NovelLogo from '../img/novel_logo.svg';
import NovelWalletsLogo from '../img/novel_wallets_logo.svg';
import OctaneaiLogo from '../img/octaneai_logo.png';
import OneClickUpsellLogo from '../img/ocu_logo.png';
import OkendoLogo from '../img/okendo_logo.png';
import OmnisendLogo from '../img/omnisend_logo.png';
import OptiMonkLogo from '../img/optimonk_logo.png';
import PageflyLogo from '../img/pagefly_logo.png';
import PersonizelyLogo from '../img/personizely_logo.svg';
import PrehookLogo from '../img/prehook_logo.png';
import PriveLogo from '../img/prive_logo.png';
import PrivyLogo from '../img/privy_logo.png';
import ReAmazeLogo from '../img/reamaze_logo.png';
import ReChargeLogo from '../img/recharge_logo.png';
import RepeatLogo from '../img/repeat_logo.png';
import ReploLogo from '../img/replo_logo.png';
import RepLogo from '../img/rep_logo.png';
import RetentionLogo from '../img/retention_logo.png';
import ReviewsioLogo from '../img/reviewsio_logo.png';
import RichpanelLogo from '../img/richpanel_logo.png';
import RivoLogo from '../img/rivo_logo.png';
import SegunoLogo from '../img/seguno_logo.svg';
import ShopperApprovedLogo from '../img/ShopperApproved_logo.png';
import ShopSheriffLogo from '../img/shop_sheriff_logo.png';
import SkioLogo from '../img/skio_logo.png';
import SlackLogo from '../img/slack_logo.png';
import SmartrrLogo from '../img/smartrr_logo.png';
import SmileLogo from '../img/smile_logo.png';
import SocialSnowballLogo from '../img/social_snowball_logo.png';
import SpellboundLogo from '../img/spellbound_logo.png';
import StayAiLogo from '../img/stayai_logo.png';
import SubscribfyLogo from '../img/subscribfy_logo.png';
import SumoLogo from '../img/sumo_logo.png';
import SuperfiliateLogo from '../img/superfiliate_logo.png';
import SwaptLogo from '../img/swapt_logo.png';
import SwymLogo from '../img/swym_logo.png';
import TripleWhaleLogo from '../img/triple_whale_logo.png';
import ViralSweepLogo from '../img/viralsweep_logo.png';
import WheelioLogo from '../img/wheelio_logo.png';
import WisepopsLogo from '../img/wisepops_logo.png';
import WondermentLogo from '../img/wonderment_logo.png';
import YotpoLoyaltyLogo from '../img/yotpo_logo.png';
import ZapierLogo from '../img/zapier_logo.png';
import ZaymoLogo from '../img/zaymo_logo.png';
import ZendeskLogo from '../img/zendesk_logo.png';

/* eslint-disable import/extensions */
const Helpers = {
  commaMe(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
  },

  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  },

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  },

  replaceCharacterInStringAt(str, index, replacement) {
    return str.substr(0, index) + replacement + str.substr(index + 1);
  },

  /**
   * Sanitzes html input and removes unsafe tags such that the parser below will not fail
   * when reaching invalid tags.
   *
   * @param {string} html - html body to sanitize
   * @returns sanitzed html with unsafe gt/lt replaced with entity tags
   */
  escapeUnsafeHtml(html) {
    const pattern = /(<)((([a-z\-]*)([^a-z\s\-_\/\>"]+)([a-z]*))+)(\/?>?)/gim;
    const replacedString = html.replace(
      pattern,
      (_, p1, p2, p3, p4, p5, p6, p7) => {
        const tokens = [];

        if (p1) {
          tokens.push(p1.replace('<', '&#60;'));
        }

        tokens.push(p2);

        if (p7 && p7.includes('>')) {
          tokens.push(p7.replace('>', '&#62;'));
        }

        return tokens.join('');
      },
    );

    // Recurse if there are still matches
    if (pattern.test(replacedString)) {
      return this.escapeUnsafeHtml(replacedString);
    }

    return replacedString;
  },

  formatDate(date) {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  },

  formatDatetime(date) {
    if (!date) {
      return null;
    }
    let dateTimeString;
    let ampm = 'am';
    const d = new Date(date);
    let hours = `${d.getHours()}`;
    let minutes = `${d.getMinutes()}`;
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (hours >= 12) ampm = 'pm';
    if (hours >= 13) hours = (hours - 12).toString();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (hours.length < 2) hours = `0${hours}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    dateTimeString = [year, month, day].join('-');

    dateTimeString += ` ${hours}:${minutes}${ampm}`;

    return dateTimeString;
  },

  formatLocalDatetime(date) {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    const localDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    return Helpers.formatDatetime(localDate);
  },

  formatTimeSince(date, isISO8601Format) {
    if (!date) {
      return null;
    }
    let dateTimeString;

    const d = new Date(isISO8601Format ? date.replace(/\s/, 'T') : date);
    const t = new Date();

    const diff = Math.floor(
      t.getTime() + t.getTimezoneOffset() * 60000 - d.getTime(),
    );

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const seconds = Math.floor(diff / second);
    const minutes = Math.floor(diff / minute);
    const hours = Math.floor(diff / hour);
    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years > 0) {
      dateTimeString = years === 1 ? `${years} year ago` : `${years} years ago`;
    } else if (months > 0) {
      dateTimeString =
        months === 1 ? `${months} month ago` : `${months} months ago`;
    } else if (days > 0) {
      dateTimeString = days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
      dateTimeString = hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
      dateTimeString =
        minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else if (seconds > 0) {
      dateTimeString =
        seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
    } else {
      dateTimeString = null;
    }

    return dateTimeString;
  },

  formatTimeUntil(date) {
    if (!date) {
      return null;
    }
    let dateTimeString;

    const d = new Date(date);
    const t = new Date();

    const diff = Math.floor(
      d.getTime() - (t.getTime() + t.getTimezoneOffset() * 60000),
    );

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const seconds = Math.floor(diff / second);
    const minutes = Math.floor(diff / minute);
    const hours = Math.floor(diff / hour);
    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years > 0) {
      dateTimeString = years === 1 ? `${years} year` : `${years} years`;
    } else if (months > 0) {
      dateTimeString = months === 1 ? `${months} month` : `${months} months`;
    } else if (days > 0) {
      dateTimeString = days === 1 ? `${days} day` : `${days} days`;
    } else if (hours > 0) {
      dateTimeString = hours === 1 ? `${hours} hour` : `${hours} hours`;
    } else if (minutes > 0) {
      dateTimeString =
        minutes === 1 ? `${minutes} minute` : `${minutes} minutes `;
    } else if (seconds > 0) {
      dateTimeString =
        seconds === 1 ? `${seconds} second` : `${seconds} seconds`;
    } else {
      dateTimeString = null;
    }

    return dateTimeString;
  },

  formatMinutesUntil(minutes) {
    let timeString;

    const minute = 1;
    const hour = minute * 60;
    const day = hour * 24;

    var minutes = Math.floor(minutes / minute);
    const hours = Math.floor(minutes / hour);
    const days = Math.floor(minutes / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (months > 0) {
      timeString =
        months === 1 ? `${months} month later` : `${months} months later`;
    } else if (days > 0) {
      timeString = days === 1 ? `${days} day later` : `${days} days later`;
    } else if (hours > 0) {
      timeString = hours === 1 ? `${hours} hour later` : `${hours} hours later`;
    } else if (minutes > 0) {
      timeString =
        minutes === 1 ? `${minutes} minute later` : `${minutes} minutes later`;
    } else {
      timeString = null;
    }

    return timeString;
  },

  findLongestWord(str) {
    const strSplit = str.split(' ');
    let longestWord = 0;
    for (let i = 0; i < strSplit.length; i++) {
      if (strSplit[i].length > longestWord) {
        longestWord = strSplit[i].length;
      }
    }
    return longestWord;
  },

  formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return;

    // Avoid formatting shortcodes
    if (!isEmpty(phoneNumber) && phoneNumber.length < 6) return phoneNumber;

    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  },

  formatUSPhoneNumberNoPlusOne(phoneNumber) {
    if (!phoneNumber) return;

    // Avoid formatting shortcodes
    if (!isEmpty(phoneNumber) && phoneNumber.length < 6) return phoneNumber;

    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  },

  formatLabelAndPhone(label, phoneNumber) {
    const formattedPhone = this.formatPhoneNumber(phoneNumber);
    let cleanedLabel = label.slice(0, 2).toUpperCase();
    if (cleanedLabel === 'US') cleanedLabel = 'All Others';
    return [cleanedLabel, ': ', formattedPhone].join('');
  },

  getReplyToBuyString(r2b, product_name, variant_name) {
    if (
      !r2b ||
      !product_name ||
      !variant_name ||
      !r2b.shopify_product_id ||
      !r2b.shopify_variant_id ||
      !r2b.quantity ||
      !r2b.price
    )
      return '';
    let str = 'Reply YES to buy or ADDRESS to confirm address\n\n';
    str += `Product: ${Helpers.titleCase(product_name).replace(
      new RegExp('[0-9]+.?[0-9]*% off auto renew'),
      '',
    )}`;
    // If variant name is like Default Title, don't include it
    // This is done on the server too
    if (!variant_name.toLowerCase().includes('default')) {
      str += ` (${Helpers.titleCase(variant_name)})\n`;
    }
    str += `Quantity: ${r2b.quantity}\n`;
    str += `Total: $${Number(r2b.price * r2b.quantity).toFixed(
      2,
    )} + tax & shipping`;
    return str;
  },

  getDomainWithoutWWW(domain) {
    let newDomain = domain;
    if (domain.indexOf('www.') > -1) {
      newDomain = domain.substr(domain.indexOf('www.') + 4);
    }
    return newDomain;
  },

  titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  untouchableSegments: [
    'All Customers',
    'All Customers and Subscribers',
    'All Customers (INTL Included)',
    'All Customers and Subscribers (INTL Included)',
  ],

  bestPracticesArray: [
    {
      id: 1,
      dependency: null,
      name: 'image',
      description: 'Add a photo or GIF to your message to increase engagement',
      documentationLink:
        'https://help.postscript.io/hc/en-us/articles/1260804684689',
      value: 0.3,
    },
    {
      id: 2,
      dependency: 1,
      name: 'gif',
      description:
        'Use an animated .gif file as your media to make the message more fun',
      documentationLink:
        'https://help.postscript.io/hc/en-us/articles/1260804681669',
      value: 0.11,
    },
    {
      id: 3,
      dependency: null,
      name: 'link',
      description:
        'Include a link in your message to drive clicks and purchases',
      documentationLink:
        'https://help.postscript.io/hc/en-us/articles/1260804685589',
      value: 0.4,
    },
    {
      id: 4,
      dependency: null,
      name: 'tag',
      description: 'Use tags in your message to make it more personal',
      documentationLink:
        'https://help.postscript.io/hc/en-us/articles/1260804633250',
      value: 0.1,
    },
    {
      id: 6,
      dependency: null,
      name: 'charLength',
      description:
        'Keep your message under 200 characters to feel more conversational',
      documentationLink:
        'https://help.postscript.io/hc/en-us/categories/1260801575369',
      value: 0.12,
    },
  ],

  blacklistedShortDomains: [
    'goo.gl',
    'bit.ly',
    'tinyurl.com',
    'tiny.cc',
    'lc.chat',
    'is.gd',
    'soo.gd',
    's2r.co',
  ],

  keywordActionsArray: [
    {
      label: 'Reply',
      type: 'reply',
      icon: 'reply',
      color: 'purple',
    },
    {
      label: 'Opt-In',
      type: 'opt_in',
      icon: 'sign-in',
      color: 'primary',
    },
    {
      label: 'HTTP Request',
      type: 'request',
      icon: 'external-link-square',
      color: 'info',
    } /* ,
    {
      label: 'Automation',
      type: 'automation',
      icon: 'cogs'
    } */,
  ],

  httpContentTypeArray: [
    'application/json',
    'application/x-www-form-urlencoded',
    'text/plain',
  ],

  httpMethodArray: [
    'GET',
    'POST' /* ,
    'PUT',
    'DELETE',
    'HEAD',
    'PATCH',
    'OPTIONS' */,
  ],

  integrationArray: [
    {
      type: 'gorgias',
      label: 'Gorgias',
      description: 'All-in-one customer support and live chat helpdesk',
      image_url: GorgiasLogo,
      tag: null,
      hidden: false,
      article: null,
    },
    {
      type: 'Gorgias',
      label: 'Gorgias (Legacy)',
      description: 'All-in-one customer support and live chat helpdesk',
      image_url: GorgiasLogo,
      tag: null,
      hidden: false,
      article: null,
    },
    {
      type: 'AfterShip',
      label: 'AfterShip',
      description: 'Automated tracking system for E-Commerce',
      image_url: AfterShipLogo,
      tag: null,
      hidden: false,
      article: null,
    },
    {
      type: 'Swell',
      label: 'Yotpo Loyalty',
      description: 'Loyalty Rewards for Shopify',
      image_url: YotpoLoyaltyLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804630610',
      url: 'https://www.yotpo.com/',
      fields: [
        {
          name: 'guid',
          desc: "A Unique Identifier for your Yotpo account. In your Yotpo account, click on 'Settings' and copy your 'GUID'",
          title: 'Yotpo GUID',
          tooltip: 'Yotpo Account -> Settings -> GUID',
          type: 'text',
        },
        {
          name: 'api_key',
          desc: "The API key for your Yotpo account. In your Yotpo account, go to 'Settings' and copy your 'API Key'.",
          title: 'Yotpo API Key',
          tooltip: 'Yotpo Account -> Settings -> API Key',
          type: 'text',
        },
      ],
    },
    {
      type: 'yotpo_loyalty',
      label: 'Yotpo Loyalty V2',
      alloyIntegrationName: 'Yotpo Loyalty',
      description: 'Loyalty Rewards for Shopify',
      image_url: YotpoLoyaltyLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804630610',
      articleOnly: false,
      url: 'https://www.yotpo.com/platform/loyalty/',
      featureFlag: YOTPO_LOYALTY_V2,
    },
    {
      type: 'LoyaltyLion',
      label: 'LoyaltyLion',
      description: 'LoyaltyLion Points, Rewards, and Referrals for Shopify',
      image_url: LoyaltyLionLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/6950820517403-Set-Up-Your-LoyaltyLion-Integration',
      url: 'https://loyaltylion.com/',
      fields: [
        {
          name: 'token',
          desc: 'A unique token that can be found in your LoyaltyLion settings.',
          title: 'LoyaltyLion Token',
          tooltip: 'LoyaltyLion -> Manage -> Configuration Settings -> Token',
          type: 'text',
        },
        {
          name: 'secret',
          desc: 'A secret key that can be found in your LoyaltyLion settings.',
          title: 'LoyaltyLion Secret Key',
          tooltip: 'LoyaltyLion -> Manage -> Configuration Settings -> Secret',
          type: 'text',
        },
      ],
    },
    {
      type: 'Smile',
      label: 'Smile',
      description: 'Loyalty Points, Rewards, and Referrals for Shopify',
      image_url: SmileLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/6961624291995-Set-Up-Your-Smile-Integration',
      url: 'https://smile.io/',
      fields: [
        {
          name: 'api_key',
          desc: 'A unique private API key that can be found in your Smile settings.',
          title: 'Smile Private API Key',
          tooltip:
            'Smile Admin -> Settings -> Platform -> Show More -> Private',
          type: 'text',
        },
      ],
    },
    {
      type: 'listrak',
      label: 'Listrak',
      description: 'Email marketing automation platform',
      image_url: ListrakLogo,
      tag: null,
      hidden: false,
      article: null, // TODO: Fill me in once we have a Help Center article
      url: 'https://www.listrak.com/',
      featureFlag: LISTRAK_INTEGRATION,
      integrationType: 'email',
      fields: [
        {
          name: 'client_id',
          desc: 'Client ID for the integration that can be found in your Listrak integration settings.',
          title: 'Listrak API Client ID',
          tooltip:
            'Integrations -> Integration Management -> Open the Integration -> Setup Tab -> Integration Settings -> Client ID',
          type: 'text',
        },
        {
          name: 'client_secret',
          desc: 'Client secret for the integration that can be found in your Listrak integration settings.',
          title: 'Listrak API Client Secret',
          tooltip:
            'Integrations -> Integration Management -> Open the Integration -> Setup Tab -> Integration Settings -> Client Secret',
          type: 'text',
        },
      ],
    },
    {
      type: 'mailchimp',
      label: 'Mailchimp',
      description: 'Email marketing automation platform',
      image_url: MailchimpLogo,
      tag: null,
      hidden: false,
      article: null, // TODO: Fill me in once we have a Help Center article
      url: 'https://www.mailchimp.com/',
      featureFlag: MAILCHIMP_INTEGRATION,
      integrationType: 'email',
      fields: [
        {
          name: 'api_key',
          desc: 'A unique private API key that can be found in your Mailchimp account.',
          title: 'Mailchimp API Key',
          tooltip:
            'Mailchimp Account -> Profile -> Extras -> API Keys -> Your API Keys',
          type: 'text',
        },
        {
          name: 'data_center',
          desc: 'Data center for your admin console.',
          title: 'Mailchimp Data Center',
          tooltip:
            'It is located in the URL as https://<data_center>.admin.mailchimp.com/, eg. "us21".',
          type: 'text',
        },
      ],
    },
    {
      type: 'cordial',
      label: 'Cordial',
      description: 'Email marketing automation platform',
      image_url: CordialLogo,
      tag: null,
      hidden: false,
      article: null, // TODO: Fill in once we have a Help Center article
      url: 'https://cordial.com',
      featureFlag: CORDIAL_INTEGRATION,
      integrationType: 'email',
      fields: [
        {
          name: 'api_key',
          desc: 'Unique private API key that can be found in your Cordial account. Please allow this key to be used by all IP ranges, ie. "0.0.0.0/0".',
          title: 'Cordial API Key',
          tooltip: 'Account -> Administration -> API Keys',
          type: 'text',
        },
        {
          name: 'path_url',
          desc: 'The Path URL associated with your account. Please leave empty if your account uses https://admin.cordial.io.',
          title: 'Cordial Path URL',
          tooltip:
            'If it exists, it is before the "admin" in "https://<path_url>.admin.cordial.io".',
          type: 'text',
        },
      ],
    },
    {
      type: 'omnisend',
      label: 'Omnisend',
      description: 'Email marketing automation platform',
      image_url: OmnisendLogo,
      tag: null,
      hidden: false,
      article: null, // TODO: Fill in once we have a Help Center article
      url: 'https://omnisend.com',
      featureFlag: OMNISEND_INTEGRATION,
      integrationType: 'email',
      fields: [
        {
          name: 'api_key',
          desc: 'Unique private API key that can be found in your Omnisend account. This key should have the "Contacts" permissions.',
          title: 'Omnisend API Key',
          tooltip: 'Account -> Store Settings -> API Keys',
          type: 'text',
        },
      ],
    },
    {
      type: 'iterable',
      label: 'Iterable',
      description: 'Email marketing automation platform',
      image_url: IterableLogo,
      tag: null,
      hidden: false,
      article: null, // TODO: Fill in once we have a Help Center article
      url: 'https://iterable.com',
      featureFlag: ITERABLE_INTEGRATION,
      integrationType: 'email',
      fields: [
        {
          name: 'api_key',
          desc: 'Unique private API key that can be found in your Iterable account. API key should be set to Server-side type.',
          title: 'Iterable API Key',
          tooltip: 'From the top bar, navigate to Integrations -> API Keys',
          type: 'text',
        },
      ],
    },
    {
      type: 'Klaviyo',
      label: 'Klaviyo',
      description: 'Marketing automation and email platform',
      image_url: KlaviyoLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804632750',
      url: 'https://klaviyo.com',
      integrationType: 'email',
    },
    {
      type: 'CartHook',
      label: 'CartHook',
      description: 'Checkout and e-commerce funnels',
      image_url: CartHookLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804685469',
    },
    {
      type: 'Digioh',
      label: 'Digioh',
      description:
        'Collect Subscribers with Digioh Forms, Sidebars, and Lightboxes',
      image_url: DigiohLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804628950',
      articleOnly: true,
    },
    {
      type: 'Junip',
      label: 'Junip',
      description: 'Collect Product Reviews via SMS',
      image_url: JunipLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804681449',
      articleOnly: true,
    },
    {
      type: 'Justuno',
      label: 'Justuno',
      description: 'Visitor conversion optimization suite',
      image_url: JustunoLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804685509',
    },
    {
      type: 'Privy',
      label: 'Privy',
      description: 'Pop-ups & Spin-to-Wins to boost growth quickly',
      image_url: PrivyLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804685049',
      articleOnly: true,
    },
    {
      type: 'Sumo',
      label: 'Sumo',
      description: 'Pop-Ups to boost conversion and sales',
      image_url: SumoLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804684709',
      articleOnly: true,
    },
    {
      type: 'Okendo',
      label: 'Okendo',
      description: 'Collect UGC and ask for reviews via SMS',
      image_url: OkendoLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804628910',
      articleOnly: true,
    },
    {
      type: 'OptiMonk',
      label: 'OptiMonk',
      description: 'On-site messages & exit intent popups for your store',
      image_url: OptiMonkLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804632590',
      articleOnly: true,
    },
    {
      type: 'Zapier',
      label: 'Zapier',
      description: 'Integrations to automate your work',
      image_url: ZapierLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804633210',
    },
    {
      type: 'ReCharge',
      label: 'ReCharge',
      description: 'Billing and subscriptions for e-commerce',
      image_url: ReChargeLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804685449',
    },
    {
      type: 'Subscriber API',
      label: 'Subscriber API',
      description: 'Add subscribers using the Postscript API ',
      image_url: SubscriberApiLogo,
      tag: null,
      hidden: false,
      article:
        'https://developers.postscript.io/docs/how-to-add-subscribers-using-api',
    },
    {
      type: 'Wheelio',
      label: 'Wheelio',
      description: 'Collect Subscribers with Wheelio "Spin to Win"',
      image_url: WheelioLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804628930',
      articleOnly: true,
    },
    {
      type: 'Zendesk',
      label: 'Zendesk',
      description: 'Customer service software and support ticket system',
      image_url: ZendeskLogo,
      tag: null,
      hidden: false,
      article: null,
    },
    {
      type: 'OneClickUpsell',
      label: 'OneClickUpsell',
      description: 'Upsell tools from Zipify',
      image_url: OneClickUpsellLogo,
      tag: null,
      hidden: false,
      article: null,
    },
    {
      type: 'Alloy',
      label: 'Alloy',
      description: 'Run Your Store with Automated Workflows',
      image_url: AlloyLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804680949',
      articleOnly: true,
    },
    {
      type: 'Wonderment',
      label: 'Wonderment',
      description: 'Reporting and SMS Alerts for Stores on Shipping Delays',
      image_url: WondermentLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804427230',
      articleOnly: true,
    },
    {
      type: 'Wisepops',
      label: 'Wisepops',
      description: 'Create beautiful and contextual popups, banners and bars',
      image_url: WisepopsLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4403206153627',
      articleOnly: true,
    },
    {
      type: 'Prehook',
      label: 'Prehook',
      description:
        'Drive Revenue and Capture More Leads with Interactive Quizzes',
      image_url: PrehookLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4403299513755',
      articleOnly: true,
    },
    {
      type: 'Builder.io',
      label: 'Builder.io',
      description: 'Drag and drop page builder and CMS',
      image_url: BuilderIoLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804807209',
      articleOnly: true,
    },
    {
      type: 'ViralSweep',
      label: 'ViralSweep',
      description:
        'Viral sweepstakes platform for growing your email & SMS list',
      image_url: ViralSweepLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4405052830363',
      articleOnly: true,
    },
    {
      type: 'Help Scout',
      label: 'Help Scout',
      description: 'An all-in-one customer service platform',
      image_url: HelpScoutLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804629890',
      articleOnly: true,
    },
    {
      type: 'Richpanel',
      label: 'Richpanel',
      description: 'Scale your customer service. Without hiring more agents.',
      image_url: RichpanelLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/1260804681089#About-Richpanel',
      articleOnly: true,
    },
    {
      type: 'Kustomer',
      label: 'Kustomer',
      description: 'Top-Rated CRM for Customer Service',
      image_url: KustomerLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/1260804682429',
      articleOnly: true,
    },
    {
      type: 'DelightChat',
      label: 'DelightChat',
      description: 'Grow your Ecommerce brand with delightful customer service',
      image_url: DelightChatLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4406180858267',
      articleOnly: true,
    },
    {
      type: 'Gladly',
      label: 'Gladly',
      description:
        'The service platform that treats customers like people, not tickets',
      image_url: GladlyLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4406185796251',
      articleOnly: true,
    },
    {
      type: 'Re:amaze',
      label: 'Re:amaze',
      description:
        'Integrated customer service, live chat, and helpdesk platform',
      image_url: ReAmazeLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4406186774939',
      articleOnly: true,
    },
    {
      type: 'Malomo',
      label: 'Malomo',
      description: 'Shipment tracking platform for ecommerce brands',
      image_url: MalomoLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/4408298133147-Set-Up-Your-Malomo-Integration',
      articleOnly: true,
    },
    {
      type: 'Social Snowball',
      label: 'Social Snowball',
      description: 'Affiliate marketing for modern eCommerce',
      image_url: SocialSnowballLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4409885654171',
      articleOnly: true,
    },
    {
      type: 'Shop Sheriff',
      label: 'Shop Sheriff',
      description: 'AMP and PWA builder for stores',
      image_url: ShopSheriffLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/1260805823249-Set-Up-Your-Shop-Sheriff-AMP-Integration',
      articleOnly: true,
    },
    {
      type: 'Slack',
      label: 'Slack',
      description: 'Converse with your customers over text using Slack!',
      image_url: SlackLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/4557709698203-Set-Up-Your-Slack-Integration#Create-a-Postscript-API-Key',
      articleOnly: true,
    },
    {
      type: 'Growave',
      label: 'Growave',
      description: 'All-in-one marketing platform',
      image_url: GrowaveLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4413127681435',
      articleOnly: true,
    },
    {
      type: 'Repeat',
      label: 'Repeat',
      description: 'Increasing Repeat Rates for CPG Brands',
      image_url: RepeatLogo,
      tag: null,
      hidden: false,
      article: 'https://help.postscript.io/hc/en-us/articles/4763582028315',
      articleOnly: true,
    },
    {
      type: 'Triple Whale',
      label: 'Triple Whale',
      description: 'Centralize your metrics from all the tools you use',
      image_url: TripleWhaleLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/8166542914587-Set-Up-Your-Triple-Whale-Integration',
      articleOnly: true,
    },
    {
      type: 'Smartrr',
      label: 'Smartrr',
      description: 'Simple subscription management',
      image_url: SmartrrLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/8299392432923-Set-Up-Your-Smartrr-Integration',
      articleOnly: true,
    },
    {
      type: 'Front',
      label: 'Front',
      description: 'Customer communication hub',
      image_url: FrontLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/9006594706075-Set-Up-Your-Front-Integration',
      articleOnly: true,
    },
    {
      type: 'Prive',
      label: 'Prive',
      description: 'Powerful subscriptions',
      image_url: PriveLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/10069481393563-Set-Up-Your-Prive-Integration',
      articleOnly: true,
    },
    {
      type: 'Swym',
      label: 'Swym',
      description: 'Automatic back in stock texts',
      image_url: SwymLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/9903515341851-Set-Up-Your-Swym-Integration',
      articleOnly: true,
    },
    {
      type: 'Spellbound',
      label: 'Spellbound',
      description: 'Grow your list via embedded sign-up forms in your email',
      image_url: SpellboundLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/11909245978523-Set-Up-Your-Spellbound-Integration',
      articleOnly: true,
    },
    {
      type: 'Rep',
      label: 'Rep',
      description:
        'Acquire new text subscribers via your AI-powered sales assistant',
      image_url: RepLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/11639919795355-Set-Up-Your-Rep-Integration',
      articleOnly: true,
    },
    {
      type: 'Fondue',
      label: 'Fondue',
      description:
        'Offering Cashback in order to increase list growth and conversion',
      image_url: FondueLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/12311826727067-Set-Up-Your-Fondue-Integration',
      articleOnly: true,
    },
    {
      type: 'Littledata',
      label: 'Littledata',
      description:
        'Accurate data for modern DTC brands directly to your Google Analytics',
      image_url: LittledataLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/12498793044507-Set-Up-Your-Littledata-Integration',
      articleOnly: true,
    },
    {
      type: 'Novel',
      label: 'Novel',
      description: 'Acquire SMS subscribers via shoppable videos',
      image_url: NovelLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/13060227940763-Set-Up-Your-Novel-Integration#How-a-Novel-Integration-Benefits-You',
      articleOnly: true,
    },
    {
      type: 'Swapt',
      label: 'Swapt',
      description: 'Personalized experiences via QR codes',
      image_url: SwaptLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/13065056717211-Set-Up-Your-Swapt-Integration#About-Swapt',
      articleOnly: true,
    },
    {
      type: 'Amped',
      label: 'Amped',
      description: 'No Code Popup Builder for DTC',
      image_url: AmpedLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/10763808610459-Set-Up-Your-Amped-Integration',
      articleOnly: true,
    },
    {
      type: 'Northbeam',
      label: 'Northbeam',
      description: 'Universal Ad Attribution Platform',
      image_url: NorthbeamLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/14218351707931-Set-Up-Your-Northbeam-Integration',
      articleOnly: true,
    },
    {
      type: 'Rivo',
      label: 'Rivo',
      description: 'Customizable Loyalty Platform',
      image_url: RivoLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/13823352278299-Set-Up-Your-Rivo-Integration',
      articleOnly: true,
    },
    {
      type: 'Maverick',
      label: 'Maverick',
      description: 'AI Videos at Scale',
      image_url: MaverickLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/14270294430747-Set-Up-Your-Maverick-Integration',
      articleOnly: true,
    },
    {
      type: 'Skio',
      label: 'Skio',
      description: 'Subscription Management',
      image_url: SkioLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/14683733894555-Set-Up-Your-Skio-Integration',
      articleOnly: true,
    },
    {
      type: 'Goji',
      label: 'Goji',
      description: 'Grow your SMS list & increase your sales',
      image_url: GojiLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/15312504156827-Set-Up-Your-Goji-Integration',
      articleOnly: true,
    },
    {
      type: 'Stay AI',
      label: 'Stay AI',
      description: 'Increase subscription performance with SMS',
      image_url: StayAiLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/15310832769051-Set-Up-Your-Stay-Ai-Integration',
      articleOnly: true,
    },
    {
      type: 'Replo',
      label: 'Replo',
      description: 'Embed your SMS forms into your page builder',
      image_url: ReploLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/17016240251291-Set-Up-Your-Replo-Integration',
      articleOnly: true,
    },
    {
      type: 'Retention.com',
      label: 'Retention.com',
      description: 'Trigger more abandonment events using Reclaim',
      image_url: RetentionLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/15949943424667-Set-Up-Your-Retention-com-Integration',
      articleOnly: true,
    },
    {
      type: 'Inveterate',
      label: 'Inveterate',
      description:
        'Offer benefits like exclusive discounts, member pricing, store credit back, and special access',
      image_url: InveterateLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/16575695534875-Set-Up-Your-Inveterate-Integration',
      articleOnly: true,
    },
    {
      type: 'HiTide',
      label: 'HiTide',
      description:
        'Direct message (DM) automation platform that helps brands convert Instagram followers into SMS subscribers',
      image_url: HiTideLogo,
      tag: null,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/16721583086363-Set-Up-Your-HiTide-Integration',
      articleOnly: true,
    },
    {
      type: 'Blotout',
      label: 'Blotout',
      description: 'Re-engage high intent subscribers',
      image_url: BlotoutLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/18562421695899-Set-Up-Your-Blotout-Integration',
      articleOnly: true,
    },
    {
      type: 'Personizely',
      label: 'Personizely',
      description: 'Connect your Personizely pop ups to your Postscript list',
      image_url: PersonizelyLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/16576748104091-Set-Up-Your-Personizely-Integration',
      articleOnly: true,
    },
    {
      type: '1ClickPopup',
      label: '1ClickPopup',
      description: 'Sync your SMS subscribers into Postscript',
      image_url: OneClickPopupLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/18261396671387-Set-Up-Your-1ClickPopup-Integration',
      articleOnly: true,
    },
    {
      type: 'Awtomic',
      label: 'Awtomic',
      description:
        'Awtomic provides shoppers tools to easily manage subscription products',
      image_url: AwtomicLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/20729852984347-Set-Up-Your-Awtomic-Integration',
      articleOnly: true,
    },
    {
      type: 'Loop Subscriptions',
      label: 'Loop Subscriptions',
      description:
        'Offer your customers a personalized and automated subscription experience',
      image_url: LoopLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/18571941202587-Set-Up-Your-Loop-Integration',
      articleOnly: true,
    },
    {
      type: 'Seguno',
      label: 'Seguno',
      description:
        'Forward SMS subscribers collected with Seguno popups to Postscript',
      image_url: SegunoLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/19833311605275-Set-Up-Your-Seguno-Integration',
      articleOnly: true,
    },
    {
      type: 'Novel Wallets',
      label: 'Novel Wallets',
      description: 'Drive conversions with Apple and Google wallet passes',
      image_url: NovelWalletsLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/19832028429723-Set-Up-Your-Novel-Wallets-Integration',
      articleOnly: true,
    },
    {
      type: 'Black Crow AI',
      label: 'Black Crow AI',
      description:
        'Enable Smart ID and Predictive offer to increase list growth and incremental revenue',
      image_url: BlackcrowaiLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/24465630680987-Set-Up-Your-Black-Crow-AI',
      articleOnly: true,
    },
    {
      type: 'Elevar',
      label: 'Elevar',
      description:
        'Session Enrichment that instantly improves campaign ROI by recognizing returning users',
      image_url: ElevarLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/22270156915227-Set-Up-Your-Elevar-Integration',
      articleOnly: true,
    },
    {
      type: 'Zaymo',
      label: 'Zaymo',
      description: 'Supercharge your conversion with interactive emails',
      image_url: ZaymoLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/24234501592347-Set-Up-Your-Zaymo-Integration',
      articleOnly: true,
    },
    {
      type: 'PageFly',
      label: 'PageFly',
      description: 'Embed SMS sign up forms into custom pages',
      image_url: PageflyLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/27591952342555-Set-Up-Your-PageFly-Integration',
      articleOnly: true,
    },
    {
      type: 'Superfiliate',
      label: 'Superfiliate',
      description: 'Contact, Engage, and Educate your influencers via text',
      image_url: SuperfiliateLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/26150493745179-Set-Up-Your-Superfiliate-Integration',
      articleOnly: true,
    },
    {
      type: 'Shopper Approved',
      label: 'Shopper Approved',
      description: 'Use SMS flows to automate responses to customer reviews',
      image_url: ShopperApprovedLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/22271663931291-Set-Up-Your-Shopper-Approved-Integration',
      articleOnly: true,
    },
    {
      type: 'Reviews.io',
      label: 'Reviews.io',
      description:
        'Automate SMS flows to reviews and surveys by rating or type',
      image_url: ReviewsioLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/25329276441243-Set-Up-Your-Reviews-io-Integration',
      articleOnly: true,
    },
    {
      type: 'Formtoro',
      label: 'Formtoro',
      description: 'Collect SMS subscribers via Formtoros pop ups',
      image_url: FormtoroLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/26952562296987-Set-Up-Your-Formtoro-Integration',
      articleOnly: true,
    },
    {
      type: 'Alia',
      label: 'Alia',
      description: 'Grow your SMS list using Alias pop ups',
      image_url: AliaLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/27155704671899-Set-Up-Your-Alia-Integration',
      articleOnly: true,
    },
    {
      type: 'Subscribfy',
      label: 'Subscribfy',
      description: 'Update your SMS subscribers on their membership status',
      image_url: SubscribfyLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/27851761760283-Set-Up-Your-Subscribfy-Integration',
      articleOnly: true,
    },
    {
      type: 'Monocle',
      label: 'Monocle',
      description:
        ' Use AI-powered incentives that maximize list growth and revenue',
      image_url: MonocleLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/27854608759323-Set-Up-Your-Monocle-Integration',
      articleOnly: true,
    },
    {
      type: 'Octane AI',
      label: 'Octane AI',
      description:
        ' Contact and segment your subscribers based on their quiz results',
      image_url: OctaneaiLogo,
      hidden: false,
      article:
        'https://help.postscript.io/hc/en-us/articles/27922629326491-Set-Up-Your-Octane-Integration',
      articleOnly: true,
    },
  ],

  checkBlacklistedDomains(text) {
    let blacklisted = false;
    Helpers.blacklistedShortDomains.forEach((b) => {
      const regex = new RegExp(b, 'i');
      if (text.match(regex)) {
        blacklisted = true;
      }
    });
    return blacklisted;
  },

  checkAlphaNumeric(text) {
    const regex = new RegExp('^[a-z0-9]+$', 'i');
    if (text.match(regex)) {
      return true;
    }
    return false;
  },

  checkAlphaNumericLeadingSpecialChar(text) {
    const pattern = new RegExp('^[_&+-]?[a-z0-9]+$', 'i');
    return !!text.match(pattern);
  },

  checkWhitespacesNextToLinks(text) {
    let linkTagNeedsWhitespace = false;
    const shopLinkString = '{shop_link}';
    const shopLinkIndex = text.indexOf(shopLinkString);
    if (
      shopLinkIndex >= 0 &&
      text[shopLinkIndex + shopLinkString.length] &&
      text[shopLinkIndex + shopLinkString.length] !== ' ' &&
      text[shopLinkIndex + shopLinkString.length] !== '\n' &&
      text.length >= shopLinkIndex + shopLinkString.length
    ) {
      linkTagNeedsWhitespace = true;
    } else if (
      shopLinkIndex >= 0 &&
      text[shopLinkIndex - 1] &&
      text[shopLinkIndex - 1] !== ' ' &&
      text[shopLinkIndex - 1] !== '\n'
    ) {
      linkTagNeedsWhitespace = true;
    }
    const abandonedLinkString = '{abandoned_cart_link}';
    const abandonedLinkIndex = text.indexOf(abandonedLinkString);
    if (
      abandonedLinkIndex >= 0 &&
      text[abandonedLinkIndex + abandonedLinkString.length] &&
      text[abandonedLinkIndex + abandonedLinkString.length] !== ' ' &&
      text[abandonedLinkIndex + abandonedLinkString.length] !== '\n' &&
      text.length >= abandonedLinkIndex + abandonedLinkString.length
    ) {
      linkTagNeedsWhitespace = true;
    } else if (
      abandonedLinkIndex >= 0 &&
      text[abandonedLinkIndex - 1] &&
      text[abandonedLinkIndex - 1] !== ' ' &&
      text[abandonedLinkIndex - 1] !== '\n'
    ) {
      linkTagNeedsWhitespace = true;
    }

    const { urlRegex } = Helpers;
    urlRegex.lastIndex = 0;
    const matchArray = text.match(urlRegex);
    if (matchArray) {
      return matchArray.some((m) => {
        const linkIndex = text.indexOf(m);
        const needsWhitespace =
          (linkIndex >= 0 &&
            text[linkIndex + m.length] &&
            text[linkIndex + m.length] !== ' ' &&
            text[linkIndex + m.length] !== '\n' &&
            text.length >= linkIndex + m.length) ||
          (linkIndex >= 0 &&
            text[linkIndex + m.length - 1] &&
            text[linkIndex + m.length - 1] === '.') ||
          (linkIndex >= 0 &&
            text[linkIndex + m.length - 1] &&
            text[linkIndex + m.length - 1] === ',') ||
          (linkIndex >= 0 &&
            text[linkIndex - 1] &&
            text[linkIndex - 1] !== ' ' &&
            text[linkIndex - 1] !== '\n');

        text = text.replace(
          text.substring(linkIndex, linkIndex + m.length),
          '',
        );
        return needsWhitespace;
      });
    }
  },

  checkWhitespacesNextToTags(text) {
    if (!Helpers.messageTags || Helpers.messageTags.length == 0) return false;
    let tagNeedsWhitespage = false;
    const checkedTagObj = {};
    Helpers.messageTags.forEach((mt) => {
      const reg = new RegExp(mt.value, 'g');
      reg.lastIndex = 0;
      const matchArray = text.match(reg);
      if (matchArray) {
        matchArray.forEach((m) => {
          const fromIndex = checkedTagObj.hasOwnProperty(m)
            ? checkedTagObj[m] + 1
            : 0;
          const linkIndex = text.indexOf(m, fromIndex);
          if (linkIndex >= 0) checkedTagObj[m] = linkIndex;

          // Check if our tag is inside a link
          const { urlRegex } = Helpers;
          urlRegex.lastIndex = 0;
          const matchLink = text.match(urlRegex);
          if (matchLink) {
            var insideLink = false;
            matchLink.some((n) => {
              const urlIndex = text.indexOf(n);
              if (
                linkIndex > urlIndex &&
                linkIndex + m.length <= urlIndex + n.length
              ) {
                insideLink = true;
              }
            });
          }

          if (
            linkIndex >= 0 &&
            text[linkIndex + m.length] &&
            text[linkIndex + m.length] !== ' ' &&
            text[linkIndex + m.length] !== '\n' &&
            !Helpers.punctuationArray.includes(text[linkIndex + m.length]) &&
            text.length >= linkIndex + m.length &&
            !insideLink
          ) {
            tagNeedsWhitespage = true;
          } else if (
            linkIndex >= 0 &&
            text[linkIndex - 1] &&
            text[linkIndex - 1] !== ' ' &&
            text[linkIndex - 1] !== '\n' &&
            !insideLink
          ) {
            tagNeedsWhitespage = true;
          }
        });
      }
    });
    return tagNeedsWhitespage;
  },

  checkInvalidLinks(text) {
    const { unstrictUrlRegex } = Helpers;
    unstrictUrlRegex.lastIndex = 0;

    const matchArray = (text.match(unstrictUrlRegex) || []).filter(
      (match) =>
        !['https://', 'http://'].some((acceptedPref) =>
          match.includes(acceptedPref),
        ),
    );

    return matchArray;
  },

  punctuationArray: ['-', ',', '.', ':', ';', '?', '!'],

  checkTextForKeyword(keyword, text) {
    // find all UPPERCASE keyword matches,
    // loop through and check if they are at the beginning/end or have whitespace
    const keywordRegex = new RegExp(keyword, 'g');
    const matchArray = text.match(keywordRegex);
    let keywordChecksOut = false;
    if (matchArray) {
      matchArray.forEach((m) => {
        keywordChecksOut = true;
        const keywordIndex = text.indexOf(m);
        if (
          keywordIndex >= 0 &&
          text[keywordIndex + m.length] &&
          text[keywordIndex + m.length] !== ' ' &&
          text[keywordIndex + m.length] !== '\n' &&
          text.length >= keywordIndex + m.length
        ) {
          keywordChecksOut = false;
        } else if (
          keywordIndex >= 0 &&
          text[keywordIndex - 1] &&
          text[keywordIndex - 1] !== ' ' &&
          text[keywordIndex - 1] !== '\n'
        ) {
          keywordChecksOut = false;
        }
      });
    }
    return keywordChecksOut;
  },

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  },

  urlRegex:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=,]{1,256}\.[a-z]{2,12}\b(([-a-zA-Z0-9@$:%_\+.~#?&//=*,]|[[{][a-z_]+}]*)*)/g,

  unstrictUrlRegex:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,

  curlyRegex: /\{([^}]+)\}/g,

  comRegex: /\s(https?:\/\/)?[a-zA-Z0-9\._-]+\.com/g,

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      const range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  },

  setCaretToPos(input, pos) {
    Helpers.setSelectionRange(input, pos, pos);
  },

  checkStringForCoupon(text) {
    const regex = /_coupon}/g;
    return regex.test(text);
  },

  checkStringForTemplatePlaceholder(text) {
    const regex = /\[(.*)\]/g;
    return regex.test(text);
  },

  messageTags: [
    {
      label: 'First Name',
      value: '{first_name}',
    },
    {
      label: 'Last Name',
      value: '{last_name}',
    },
    {
      label: 'Shop Name',
      value: '{shop_name}',
    },
    {
      label: 'Shop Link',
      value: '{shop_link}',
    },
  ],

  jsonCheck(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  suggestedBackgroundColorArray: [
    '#ecf0f1',
    '#dde4e6',
    '#bdc3c7',
    '#f1edec',
    '#ffffff',
    '#212529',
    '#7f8c8d',
    '#2c3e50',
    '#000000',
    '#c0392b',
  ],

  suggestedTextColorArray: [
    '#212529',
    '#7f8c8d',
    '#2c3e50',
    '#000000',
    '#c0392b',
    '#ecf0f1',
    '#dde4e6',
    '#bdc3c7',
    '#f1edec',
    '#ffffff',
  ],

  suggestedButtonColorArray: [
    '#3498db',
    '#2ecc71',
    '#1abc9c',
    '#9b59b6',
    '#f39c12',
    '#e67e22',
    '#e74c3c',
    '#34495e',
  ],

  formatIntegration: (name) => {
    let newName = name.replaceAll(/ /g, '');
    newName = newName.replaceAll('.', '');
    newName = newName.replaceAll(':', '');
    return newName.toLowerCase();
  },
};

export default Helpers;
